import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import buttonCloseMenu from '../assets/icons/buttonCloseMenu.png';
import UserData from './UserData';
import SesionClose from './SessionClose';

const UserMenuModalCss = styled.section`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5;

  div {
    margin: 10px;
  }
  p {
    height: 16px;
    width: 85px;
    color: #797979;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
  }
  h1 {
    height: 46px;
    width: 195px;
    color: #1e1045;
    font-family: Roboto;
    font-size: 19px;
    letter-spacing: 0;
    line-height: 23px;
  }

  button {
    outline: none;
    border: none;
    margin-right: 10px;
    :active {
      background: white;
    }
  }
`;

const MenuModal = styled.div`
  background: rgb(10, 8, 28, 0.9);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  opacity: 1;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};

  article {
    animation: modalIn 0.5s forwards;
  }

  @keyframes modalIn {
    0% {
      transform: ${(props) =>
        props.open ? 'translatex(3000px)' : 'translatex(5px)'};
    }
    60% {
      transform: ${(props) =>
        props.open ? 'translatex(25px)' : 'translatex(-10px)'};
    }
    75% {
      transform: ${(props) =>
        props.open ? 'translatex(-10px)' : 'translatex(25px)'};
    }
    90% {
      transform: ${(props) =>
        props.open ? 'translatex(5px)' : 'translatex(3000px)'};
    }
  }
`;
const UserMenuModal = ({ open, setOpen }) => {
  return (
    <>
      <MenuModal open={open}>
        <article
          style={{ paddingLeft: '20%' }}
          className="absolute inset-y-0 right-0  max-w-full flex"
          aria-labelledby="slide-over-heading"
        >
          <div className="w-screen ">
            <div className="h-full flex flex-col py-3 bg-white shadow-xl overflow-y-scroll">
              <div className="py-1  divide-y divide-red-100">
                <UserMenuModalCss>
                  <div>
                    <p>Liberty Seguros</p>
                    <h1>Mis negocios en línea de Pyme</h1>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      <img src={buttonCloseMenu} alt="boton de cerrar" />
                    </button>
                  </div>
                </UserMenuModalCss>
              </div>
              <UserData />
              <SesionClose />
            </div>
          </div>
        </article>
      </MenuModal>
    </>
  );
};

export default UserMenuModal;

UserMenuModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
