/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Button from 'components/Button';
import Loader from 'components/Loader';
import sessionService from 'services/auth/session';
import { useLocation } from 'react-router-dom';
import { fetchQuote } from 'reducers/quoteInfo';
import logo from '../assets/images/logos/principal.png';
import forward from '../assets/icons/forward.svg';
import user from '../assets/icons/user.svg';
import UserMenuItem from '../components/UserMenuItem';
import UserMenuModal from '../components/UserMenuModal';
import ActionConfirm from './modals/ActionConfirm';
import NotificationPolicyEmit from '../components/emit_components/basics/NotificationTooltip/NotificationPolicyEmit';
import dashboardService from '../services/dashboard';

const HeaderStyle = styled.div`
  background-color: var(--liberty-yellow);
  p {
    color: var(--liberty-blue);
  }
`;

const LineSeparatorStyle = styled.span`
  border: 1px solid var(--liberty-blue);
  background-color: var(--liberty-blue);
  opacity: 0.7;
`;

export default function Header() {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [exit, setExit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lastSession, setLastSession] = useState({});
  const [isOpenNotifications, setIsOpenNotifications] = useState(false);

  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };

  const actualRoute = useLocation()?.pathname;

  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    async function getSession() {
      const { data } = await sessionService(
        'getSession',
        {
          CustomerTypeId: 'Broker',
          Broker: sessionInfoObj.agente,
        },
        customHeaders
      );
      setLastSession(data);
    }
    getSession();
  }, []);

  const quoteSave = () => {
    setLoading(true);
    if (actualRoute === '/quote') {
      dispatch(fetchQuote(null, customHeaders));
    }
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  useEffect(() => {
    dashboardService
      .getProcesses({
        broker_id: sessionInfoObj.agente,
        status: 'EMISSION_COMPLETE',
        isNotification: true,
      })
      .then((response) => {
        setNotificationData(response.data.data);
      });
  }, []);

  return (
    <HeaderStyle
      data-testid="header-test"
      className="w-full flex justify-center p-4"
    >
      {loading && <Loader />}
      <div className="w-full md:w-c-container flex justify-between">
        <div className="flex items-center">
          <img alt="logo" src={logo} />

          <div className="sm:flex hidden items-center">
            <LineSeparatorStyle className="box-border w-px h-4 opacity-30 mx-12" />
            <a
              className="text-sm font-medium cursor-pointer"
              onClick={quoteSave}
            >
              Ir a mis negocios
            </a>
            <img alt="forward" src={forward} className="w-2 h-2 m-1" />
          </div>
        </div>
        <div className="flex items-center">
          <UserMenuItem lastSession={lastSession} />
          <p className="text-sm hidden md:block mx-2">
            {sessionInfoObj && sessionInfoObj.nombreUsuario}
          </p>
          <button
            type="button"
            style={{ outline: 'none' }}
            className="bg-white w-8 h-8 p-2 rounded-full ml-1 mr-3"
            onClick={() => {
              setModalOpen(!modalOpen);
            }}
          >
            <img src={user} alt="icon user" />
          </button>
          {actualRoute === '/' && notificationData.length > 0 && (
            <>
              <div className="border-l border-black h-8"> </div>
              <div className="relative">
                <NotificationPolicyEmit
                  isOpen={isOpenNotifications}
                  setIsOpen={setIsOpenNotifications}
                  notificationData={notificationData}
                  setLoading={setLoading}
                />
              </div>
            </>
          )}
          <div className="md:hidden">
            <UserMenuModal open={modalOpen} setOpen={setModalOpen} />
          </div>
          <Button
            onClick={() => {
              setExit(!exit);
            }}
            className="hidden md:block shadow-none"
          >
            Cerrar sesión
          </Button>
          <ActionConfirm
            showModal={exit}
            setShowModal={setExit}
            type="caution"
            title="¿Quieres cerrar sesión?"
            cancelButton="Cancelar"
            butonContinue="Sí, cerrar sesión"
          />
        </div>
      </div>
    </HeaderStyle>
  );
}
