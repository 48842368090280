import React from 'react';
import Proptypes from 'prop-types';
import Button from '../../../basics/form/Button';
import RejectedDocumentIcon from '../../../../../assets/icons/rejected-document.svg';
import ModalWrapper from '../../../basics/modal/ModalWrapper';

const ModalSorry = ({ showModal, onEndClick, onAdjustClick }) => {
  const handleSetShowModal = (show) => {
    if (!show) {
      onAdjustClick();
    }
  };
  return (
    <ModalWrapper
      className="z-50 bg-indigo-900 bg-opacity-50"
      size="screen-sm"
      setShowModal={handleSetShowModal}
      showModal={showModal}
    >
      <div className="text-center pt-16 bg-gray-100 mt-12 md:mt-0">
        <h2 className="mb-2 text-2xl font-semibold text-gray-900">
          Lo sentimos
        </h2>
        <p className="m-0 mb-6 text-sm font-medium text-gray-700 px-8">
          La identificación que ingresaste no nos permite continuar con el
          proceso
        </p>
        <div className="bg-gray-100">
          <img src={RejectedDocumentIcon} alt="" className="w-34 m-auto mb-6" />
        </div>
        <div className="flex flex-col justify-center m-auto mb-12 px-4 sm:flex-row sm:gap-4">
          <Button
            className="w-full sm:m-0 sm:w-48 md:m-0 md:w-48"
            onClick={onEndClick}
          >
            Terminar
          </Button>
          <Button
            primary
            className="w-full sm:m-0 md:m-0 sm:w-48 md:w-48"
            onClick={onAdjustClick}
          >
            Ajustar
          </Button>
        </div>
        <div className="bg-gray-200 w-full px-12 py-4 sm:px-24 sm:py-6">
          <p className="text-gray-900 text-xs m-0 sm:text-sm">
            {`Para más información comunícate desde Bogotá a la línea `}
            <span className="text-mediumTeal">307 7050</span>
            {` o desde cualquier lugar del país `}
            <span className="text-mediumTeal">018000 113390</span>
          </p>
        </div>
      </div>
    </ModalWrapper>
  );
};

ModalSorry.propTypes = {
  onAdjustClick: Proptypes.func.isRequired,
  onEndClick: Proptypes.func.isRequired,
  showModal: Proptypes.bool.isRequired,
};

export default ModalSorry;
