export const styles = {
  text: {
    fontSize: 14,
  },
  fullWidth: {
    width: '100%',
  },
  normalDay: {
    color: 'black',
    width: '35px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '3px',
    padding: '1px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    cursor: 'pointer',
  },
  notInThisMonthDay: {
    color: 'Lightgray',
  },
  selectedDay: {
    width: '35px',
    height: '35px',
    backgroundColor: '#28A3AF',
    borderRadius: '50px',
    margin: '3px',
    boxShadow: 'none',
    padding: '1px',
    cursor: 'pointer',
  },
  notSelectableDay: {
    color: 'gray',
    cursor: 'not-allowed',
    '&:focus': {
      outline: 'none',
    },
  },
  notAcceptedDay: {
    backgroundColor: 'Lightgray',
    color: 'white',
    cursor: 'not-allowed',
  },
  todayPaper: {
    cursor: 'pointer',
    color: ' gray',
  },
  fullHeightCenter: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  absoluteRight: {
    position: 'absolute',
    right: '3%',
    top: 0,
    bottom: 0,
    zIndex: 50,
  },
  inputIcon: {
    width: 24,
    height: 24,
  },
  inputContainer: { position: 'relative', display: 'block' },
  toolbarContainer: {
    margin: 0,
    textAlign: 'initial',
    background: '#1A1446',
    padding: '20px 20px 60px 20px',
  },
  yearText: { fontSize: '15px', color: 'Lightgray', fontWeight: 'normal' },
  dayMonthText: { fontSize: '30px', color: 'white', fontWeight: 'normal' },
};

export const inputStyles = {
  root: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',

      '& fieldset': {
        borderRadius: '.25rem',
        borderColor: 'gray',
        borderWidth: 2,
      },
      '&:hover fieldset': {
        borderColor: 'gray',
        borderWidth: 2,
      },
      '&.Mui-focused fieldset': {
        borderColor: 'gray',
        borderWidth: 2,
      },
      '&.Mui-disabled': {
        color: '#343741',
      },
    },
  },
};
