/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import Modal from 'components/Modal';
import Button from 'components/Button';
import TimePlussIcon from 'assets/icons/time-pluss.svg';
import AlertIcon from 'assets/icons/alert-fill.svg';
import moment from 'moment';
import PropTypes from 'prop-types';
import useWindowDimensions from 'utils/helpers/resolution';
import { tokenAPI } from 'services/auth/sessionInfo';
import sessionService from 'services/auth/session';
import SESSION_CONST from '../../utils/constants/Session';
import DigitalClock from '../../components/DigitalClock';

const urlOficina = process.env.REACT_APP_URL_OFICINA;

const TimeOut = ({ showModal, setShowModal, keepSession }) => {
  const limitTime = SESSION_CONST.SESSION_FRONT_MODAL / 1000;
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [time, setTime] = useState(moment(limitTime * 1000));
  const { width } = useWindowDimensions();

  const [sessionInfo, setSessionInfo] = useState(
    sessionStorage.getItem('info')
  );

  const handleRedirectLogin = () => {
    window.location.replace(urlOficina);
  };

  const hideModal = () => {
    setShowModal(false);
    setElapsedTime(0);
  };

  const handleCloseSession = async (redirect = false) => {
    const tempSessionInfo = sessionStorage.getItem('info');
    setSessionInfo(tempSessionInfo);
    const sessionInfoObj = JSON.parse(tempSessionInfo);
    await tokenAPI(
      'removeToken',
      {
        ...sessionInfoObj,
        // ip: sessionIp,
      },
      {
        headers: { Authorization: `Bearer ${sessionInfoObj.token}` },
      }
    ).catch((err) => {
      console.error(err);
    });
    sessionStorage.removeItem('info');
    if (redirect) handleRedirectLogin();
  };

  const handleRenewToken = async () => {
    if (sessionInfo) {
      keepSession();
      const { agente, token, ip, nombreUsuario } =
        typeof sessionInfo === 'string' ? JSON.parse(sessionInfo) : sessionInfo;
      await tokenAPI(
        'updateToken',
        {
          agente,
          nombreUsuario,
          token,
          ip,
        },
        {
          headers: { Authorization: `Bearer ${sessionInfo.token}` },
        }
      );
      sessionService(
        SESSION_CONST.PATH_SESSION_UPDATE,
        {
          CustomerTypeId: 'Broker',
          Broker: '4091482',
        },
        {
          Authorization: `Bearer ${token}`,
          authorizedIp: String(ip),
        }
      );
      hideModal();
    }
  };

  useEffect(() => {
    let timerId = null;
    if (showModal) {
      if (elapsedTime < limitTime) {
        timerId = setInterval(() => {
          setElapsedTime(elapsedTime + 1);
          setTime(moment((limitTime - (elapsedTime + 1)) * 1000));
        }, 1000);
      } else {
        setIsTimeOut(true);
        handleCloseSession();
      }
    }
    return () => clearInterval(timerId);
  });

  if (isTimeOut) {
    return (
      <Modal
        showModal={showModal}
        setShowModal={handleRedirectLogin}
        lg={false}
        showButtonClose={false}
      >
        <div className="flex flex-col items-center md:max-w-xl m-auto md:mt-10">
          <img
            style={{
              width: width < 768 ? 50 : 60,
              height: width < 768 ? 50 : 60,
            }}
            src={AlertIcon}
            alt="Alt - Caution"
          />
          <h4 className="mt-4 font-medium text-center dark-blue md:mb-10 px-6 mx-6 sm:px-1 sm:mx-1">
            Tu sesión ha finalizado.
          </h4>
          <div className="flex flex-col sm:flex-row justify-center items-center w-full py-2 px-4 md:py-6 md:px-16 mt-3 md:mt-5 sm:bg-lightTeal ">
            <Button
              className="sm:px-10 md:m-2"
              primary
              onClick={() => handleRedirectLogin()}
            >
              Iniciar Sesión
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
  return (
    <Modal
      showModal={showModal}
      setShowModal={handleRenewToken}
      lg={false}
      showButtonClose={false}
    >
      <div className="flex flex-col items-center md:max-w-xl m-auto">
        <img
          style={{
            width: width < 768 ? 66 : 99,
            height: width < 768 ? 61 : 93,
          }}
          src={TimePlussIcon}
          alt="Alt - Caution"
        />
        <h4 className="mt-4 font-medium text-center dark-blue">
          <span className={width < 768 ? 'block my-0 leading-none' : 'mr-2'}>
            ¿Estás ahí?
          </span>
          Tu sesión finalizará en:
        </h4>
        <DigitalClock time={time} />
        <p className="md:font-medium color-gray-600 mt-1 md:mb-10">
          Si deseas continuar en sesión,
          <br />
          presiona el botón
          <span className="font-extrabold ml-1">"Continuar"</span>
        </p>
        <div className="flex flex-col sm:flex-row justify-center items-center w-full py-2 px-4 md:py-6 md:px-16 mt-3 md:mt-5 sm:bg-lightTeal ">
          <Button
            className="sm:px-10 md:m-2 w-full"
            onClick={() => handleCloseSession(true)}
          >
            Cerrar Sesión
          </Button>
          <Button
            className="sm:px-10 md:m-2"
            primary
            onClick={() => handleRenewToken()}
          >
            Continuar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TimeOut;

TimeOut.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  keepSession: PropTypes.func.isRequired,
};
