import React from 'react';
import PropTypes from 'prop-types';
import dashed from '../assets/images/dashed.svg';
import dashedError from '../assets/images/dashed-error.svg';

export default function DashedCard({ children, type }) {
  return (
    <div
      style={{
        backgroundImage: `url(${type === 'info' ? dashed : dashedError})`,
      }}
      className="bg-tealBackground h-full"
    >
      {children}
    </div>
  );
}

DashedCard.propTypes = {
  children: PropTypes.object.isRequired,
  type: PropTypes.string,
};

DashedCard.defaultProps = {
  type: 'info',
};
