import { OPENL_CONFIG, HEADERS_JSON } from 'utils/constants/DefaultBodies';
import { addRespversionCodi } from './quoteInfo';

const urlOpenlConfig = process.env.REACT_APP_URL_OPENL_CONFIG;

const initialState = {
  loading: false,
  data: [],
  childCoverages: [],
  coverages: [],
  error: null,
};

function filterCoveragesByType(coverages, type) {
  const filteredCoverages = coverages.filter((coverage) => {
    return coverage.results[0][0].tipoAmparo === type;
  });
  return filteredCoverages;
}

export const fetchOpenlConfigStart = () => ({
  type: 'FETCH_OPENL_CONFIG_START',
  error: false,
});

export const fetchOpenlConfigSuccess = (payload) => ({
  type: 'FETCH_OPENL_CONFIG_SUCCESS',
  payload,
});

export const fetchOpenlConfigError = (payload) => ({
  type: 'FETCH_OPENL_CONFIG_ERROR',
  payload,
  error: true,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_OPENL_CONFIG_START': {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case 'FETCH_OPENL_CONFIG_SUCCESS': {
      return {
        ...state,
        loading: false,
        data: action.payload,
        coverages: action.payload.results
          ? [
            ...filterCoveragesByType(action.payload.results[4][0][1], '1'),
            ...filterCoveragesByType(action.payload.results[4][0][1], '2'),
          ]
          : [],
        childCoverages: action.payload.results
          ? filterCoveragesByType(action.payload.results[4][0][1], '3')
          : [],
      };
    }
    case 'FETCH_OPENL_CONFIG_ERROR': {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const fetchOpenlConfig = (customHeaders) => async (
  dispatch,
  getState,
  { axios }
) => {
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };
  dispatch(fetchOpenlConfigStart());
  try {
    const { data } = await axios.post(
      `${urlOpenlConfig}/request`,
      OPENL_CONFIG,
      { headers }
    );
    dispatch(fetchOpenlConfigSuccess(data));
    const RespversionCodi = data?.results?.[6]?.[0]?.[1]?.[0]?.RespversionCodi || undefined;
    dispatch(addRespversionCodi({ RespversionCodi }));
  } catch (e) {
    dispatch(fetchOpenlConfigError(e));
  }
};
