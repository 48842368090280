const SESSION_CONST = {
  USER_EVENTS: [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress',
  ],
  SESSION_TIME: 270000,
  SESSION_FRONT_ALERT: 180000,
  SESSION_FRONT_MODAL: 120000,
  TOKEN_UPDATE: 'updateToken',
  TOKEN_REMOVE: 'removeToken',
  PATH_SESSION_UPDATE: 'updateSession',
};

export default SESSION_CONST;
