/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable-next-line no-console */
/* eslint-disable no-sequences */

import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { fetchPerson, removePerson } from 'reducers/person';
import { fetchQuote, createUpdateTaker, takerRemove } from 'reducers/quoteInfo';
import { updateBodyPdf } from 'reducers/pdfQuote';
// components
import Back from 'components/Back';
import Input from 'components/Input';
import Select from 'components/Select';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Loader from 'components/Loader';
// layouts
import RestrictedPerson from 'layouts/modals/RestrictedPerson';
// constants
import {
  ID_INPUT_EMAIL,
  ID_TYPE_CONTACT_EMAIL,
  ID_INPUT_FIRST_NAME,
  ID_INPUT_FIRST_LASTNAME,
  ID_PERSON_LEGAL,
  ID_INPUT_BUSINESS_NAME,
  ID_INPUT_IDENTIFICATION_NUMBER,
} from 'utils/constants/person';
import { INFO_REQUEST } from 'utils/constants/DefaultBodies';
// helpers
import { getValidations } from 'utils/helpers/reactFormValidations';
import { isObjectEmpty, isArrayEmpty } from 'utils/helpers/object';
import { jsonUpperCase } from 'utils/helpers/normalizeValues';

export default function Taker({ currentTab, setCurrentTab }) {
  const [identitiesForm, setIdentitiesForm] = useState([]);
  const [optionsIdentities, setOptionsIdentities] = useState([]);
  const [isRestrictedPerson, setIsRestrictedPerson] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [disabledInputs, setDisabledInputs] = useState({});

  const dispatch = useDispatch();

  const identities = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[1][0][1]
      : []
  );
  const bodyPdf = useSelector((state) => state.pdfQuote.requestBody);
  const selectedBusiness = useSelector(
    (state) => state.quoteInfo.data.business
  );

  const person = useSelector((state) =>
    state.person.data ? state.person.data : {}
  );

  const loading = useSelector(
    ({ quoteInfo, person: personState }) =>
      personState.loading || quoteInfo.loading
  );
  const takerOne = useSelector((state) =>
    state.quoteInfo?.data?.taker ? state.quoteInfo?.data?.taker : null
  );

  let taker;

  if (Object.entries(takerOne).length === 0) {
    taker = {};
  } else if (takerOne.selectedOption.label === 'NIT') {
    taker = { ...takerOne };
  } else {
    let firtsLastName = '';
    let firstName = '';

    if (takerOne?.PrimApellido.split(' ').length === 1)
      firtsLastName = takerOne?.PrimApellido;
    else if (takerOne?.PrimApellido.split(' ')[1] === 'null')
      firtsLastName = takerOne?.PrimApellido.split(' ')[0];
    else firtsLastName = takerOne?.PrimApellido;

    if (takerOne?.PrimNombre.split(' ').length === 1)
      firstName = takerOne?.PrimNombre;
    else if (takerOne?.PrimNombre.split(' ')[1] === 'null')
      firstName = takerOne?.PrimNombre.split(' ')[0];
    else firstName = takerOne?.PrimNombre;

    taker = {
      ...takerOne,
      PrimApellido: firtsLastName,
      PrimNombre: firstName,
    };
  }

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    getValues,
    reset,
  } = useForm({
    defaultValues: taker,
  });

  const [selectedOption, setSelectedOption] = useState(
    taker?.selectedOption || {}
  );

  const setValueInput = useCallback(
    (key, value) => {
      setValue(key, value);
      setDisabledInputs((prevState) => ({ ...prevState, [key]: true }));
    },
    [setValue, setDisabledInputs]
  );

  const getUserEmail = useCallback(
    (contact) => {
      if (!isArrayEmpty(contact)) {
        contact.forEach((item) => {
          if (item.tipoContacto.codigo === ID_TYPE_CONTACT_EMAIL) {
            setValue(ID_INPUT_EMAIL, item.textoContacto);
          }
        });
      }
    },
    [setValue]
  );
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };
  const getLegalUserInformation = useCallback(
    (data) => {
      setValueInput(ID_INPUT_BUSINESS_NAME, data.personaJuridica.razonSocial);
      getUserEmail(data.contacto);
    },
    [setValueInput, getUserEmail]
  );

  const getUserInformation = useCallback(
    (data) => {
      setValueInput(ID_INPUT_FIRST_NAME, data.personaNatural.primerNombre);
      setValueInput(
        ID_INPUT_FIRST_LASTNAME,
        data.personaNatural.primerApellido
      );
      getUserEmail(data.contacto);
    },
    [setValueInput, getUserEmail]
  );

  const getInformation = useCallback(
    (data) => {
      if (
        data.consultarPersonaRs.persona.tipoPersona.codigo === ID_PERSON_LEGAL
      ) {
        getLegalUserInformation(data.consultarPersonaRs.persona);
      } else {
        getUserInformation(data.consultarPersonaRs.persona);
      }
    },
    [getLegalUserInformation, getUserInformation]
  );

  useEffect(() => {
    if (!isArrayEmpty(identities)) {
      setIdentitiesForm([]);
      const destructuringData = () => {
        identities.forEach((item) => {
          const input = item.results[0][0];
          const form = item.results[1][0][1];
          input.form = form;
          setIdentitiesForm((prevState) => [...prevState, input]);
        });
      };
      destructuringData();
    }
  }, [identities]);

  useEffect(() => {
    if (!isArrayEmpty(identitiesForm)) {
      setOptionsIdentities([]);
      identitiesForm.forEach((item) => {
        const option = item;
        option.label = item.descripcion;
        option.value = item.tipoIdentificacion;
        if (option.visible) {
          setOptionsIdentities((prevState) => [...prevState, option]);
          if (!isObjectEmpty(selectedOption)) {
            setSelectedOption(selectedOption);
          } else if (option.defecto) {
            setSelectedOption(option);
          }
        }
      });
    }
  }, [identitiesForm]);

  useEffect(() => {
    if (!isObjectEmpty(person)) {
      const {
        personaEncontradaIAXIS,
        persona,
        personaRestringida,
      } = person.consultarPersonaRs;
      if (personaRestringida) {
        setShowModal(true);
      } else {
        setDisabledInputs({ [ID_INPUT_IDENTIFICATION_NUMBER]: true });
        setIsRestrictedPerson(false);
        if (personaEncontradaIAXIS && persona) {
          const registeredPerson = persona;
          if (registeredPerson.tipoDocumento.codigo === 37) {
            dispatch(
              createUpdateTaker({
                selectedOption,
                NumIdentificacion: registeredPerson.numeroDocumento,
                RazSocial: registeredPerson.personaJuridica.razonSocial,
              })
            );
            dispatch(
              updateBodyPdf({
                ...bodyPdf,
                taker: {
                  ...bodyPdf.taker,
                  docType: selectedOption.tipoIdentificacion,
                  docNumber: registeredPerson.numeroDocumento,
                  name: `${registeredPerson.personaJuridica.razonSocial}`,
                },
              })
            );
          } else {
            dispatch(
              createUpdateTaker({
                selectedOption,
                NumIdentificacion: registeredPerson.numeroDocumento,
                PrimNombre: `${registeredPerson.personaNatural.primerNombre} ${registeredPerson.personaNatural.segundoNombre}`,
                PrimApellido: `${registeredPerson.personaNatural.primerApellido} ${registeredPerson.personaNatural.segundoApellido}`,
              })
            );

            const nameTaker = `${
              registeredPerson.personaNatural.primerNombre !== null
                ? registeredPerson.personaNatural.primerNombre
                : ''
            } ${
              registeredPerson.personaNatural.segundoNombre !== null
                ? registeredPerson.personaNatural.segundoNombre
                : ''
            } ${
              registeredPerson.personaNatural.primerApellido !== null
                ? registeredPerson.personaNatural.primerApellido
                : ''
            } ${
              registeredPerson.personaNatural.segundoApellido !== null
                ? registeredPerson.personaNatural.segundoApellido
                : ''
            }`;
            dispatch(
              updateBodyPdf({
                ...bodyPdf,
                taker: {
                  ...bodyPdf.taker,
                  docType: selectedOption.tipoIdentificacion,
                  docNumber: registeredPerson.numeroDocumento,
                  name: nameTaker,
                },
              })
            );
          }
        } else if (
          takerOne.NumIdentificacion !== getValues().NumIdentificacion
        ) {
          takerOne.IsNewPersonIaxis = true;
          reset({
            ...getValues(),
            PrimNombre: '',
            PrimApellido: '',
            Nemial: '',
          });

          dispatch(takerRemove());
        }
        dispatch(fetchQuote(null, customHeaders));
      }
    } else {
      setShowModal(false);
      setIsRestrictedPerson(true);
      setDisabledInputs({});
    }
  }, [person]);

  useEffect(
    () => () => {
      dispatch(removePerson());
    },
    []
  );

  useEffect(() => {
    if (
      !isRestrictedPerson &&
      !isObjectEmpty(person) &&
      person.consultarPersonaRs.personaEncontradaIAXIS
    ) {
      getInformation(person);
    }
  }, [isRestrictedPerson, person, getInformation]);

  const onChangeSelectIdentities = (option) => {
    [
      ID_INPUT_EMAIL,
      ID_INPUT_FIRST_NAME,
      ID_INPUT_FIRST_LASTNAME,
      ID_INPUT_BUSINESS_NAME,
      ID_INPUT_IDENTIFICATION_NUMBER,
    ].forEach((item) => setValue(item, null));
    setIsRestrictedPerson(true);
    setDisabledInputs({});

    dispatch(createUpdateTaker({}));

    setSelectedOption(option);
    reset({});
  };

  const finishProcess = () => {
    setCurrentTab(0);
    setShowModal(false);
  };

  const validUser = async (formData) => {
    if (formData.NumIdentificacion) {
      try {
        await dispatch(
          fetchPerson(
            {
              method: 'post',
              path: '/consultarpersona',
              parameters: {
                consultarPersonaRq: {
                  infoRequest: INFO_REQUEST,
                  tipoDocumento: {
                    codigo: selectedOption.tipoIdentificacion,
                  },
                  numeroDocumento: formData.NumIdentificacion,
                  consultarCreditScore: false,
                  consultarAniosExperiencia: false,
                },
              },
            },
            customHeaders
          )
        );
      } catch (error) {
        console.error(error.toString());
      }
    }
  };

  const onSubmit = () => {
    dispatch(
      createUpdateTaker({
        selectedOption,
        ...jsonUpperCase(getValues()),
      })
    );
    dispatch(fetchQuote(null, customHeaders));
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const navigateStep = () => {
    if (!bodyPdf.taker) {
      let name;
      getValues().RazSocial
        ? (name = getValues().RazSocial.toUpperCase())
        : (name = `${getValues().PrimNombre.toUpperCase()} ${getValues().PrimApellido.toUpperCase()}`);
      dispatch(
        updateBodyPdf({
          ...bodyPdf,
          taker: {
            ...bodyPdf.taker,
            docType: selectedOption.value,
            docNumber: Number(getValues().NumIdentificacion),
            name,
          },
        })
      );
    }
    dispatch(
      createUpdateTaker({
        selectedOption,
        ...jsonUpperCase(getValues()),
      })
    );
    dispatch(fetchQuote('takerCall', customHeaders));
    setCurrentTab(currentTab + 1);
  };

  return (
    <React.Fragment key="taker">
      <div className="md:w-c-container m-auto hidden md:block">
        <Back onClick={() => setCurrentTab(currentTab - 1)} />
      </div>
      <div className="flex justify-center">
        {loading && <Loader />}
        <Modal showModal={showModal} setShowModal={setShowModal} lg={false}>
          <RestrictedPerson
            setShowModal={setShowModal}
            finishProcess={finishProcess}
          />
        </Modal>
        <div className="flex relative md:w-c-container flex-col justify-center p-4 relative">
          <h3 className="mt-6 mb-3 dark-blue text-center">
            Información del tomador
          </h3>
          <div className="flex justify-center">
            <p className="text-center">
              Tipo de negocio seleccionado:
              <span className="ml-2 medium-teal">{selectedBusiness.name}</span>
            </p>
            {/* <p className="flex sm:hidden text-center">
            Ingresa la información del tomador
          </p> */}
          </div>
          {identitiesForm && (
            <div className="flex flex-col justify-center">
              <div className="self-center my-8 grid grid-cols-1 md:grid-cols-2 gap-6 max-w-4xl w-full">
                <div className="flex space-x-1 grid-cols-2">
                  <div className="w-2 mt-3">
                    {' '}
                    <span className="text-red-600	"> * </span>{' '}
                  </div>
                  <div className="w-full">
                    <Select
                      placeholder="Tipo de identificación"
                      options={optionsIdentities}
                      valueDefault={selectedOption}
                      onChange={onChangeSelectIdentities}
                      optionKey="tipoIdentificacion"
                      optionLabel="descripcion"
                    />
                  </div>
                </div>
                {!isObjectEmpty(selectedOption) &&
                  selectedOption.form.slice(1).map((item, index) => {
                    if (
                      index < 1 ||
                      !isRestrictedPerson ||
                      !isObjectEmpty(taker)
                    ) {
                      return (
                        <div
                          key={item.id}
                          className="flex space-x-1 grid-cols-2"
                        >
                          <div className="w-full">
                            <Input
                              placeholder={item.descripcion}
                              type={item.tipo}
                              name={item.id}
                              ref={register(getValidations(item))}
                              inputClass={item.inputClass}
                              controlled={false}
                              errorMessage={
                                errors[item.id] && errors[item.id].message
                              }
                              disabled={disabledInputs[item.id]}
                            />
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
              </div>
              {!isRestrictedPerson ? (
                <div className="self-center">
                  <Button className="sm:px-10" onClick={onSubmit}>
                    Guardar y salir
                  </Button>
                  <Button
                    className="sm:px-12"
                    primary
                    onClick={handleSubmit(navigateStep)}
                  >
                    Continuar
                  </Button>
                </div>
              ) : (
                <Button
                  className="self-center sm:px-12"
                  primary
                  onClick={handleSubmit(validUser)}
                >
                  Validar
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

Taker.propTypes = {
  setCurrentTab: PropTypes.func,
  currentTab: PropTypes.number,
};

Taker.defaultProps = {
  setCurrentTab: undefined,
  currentTab: 1,
};
