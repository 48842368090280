import * as actions from '../constants';

const initialState = {
  loading: false,
  data: {},
  isReadyEmission: false,
  isReadySQS: false,
  error: null,
};

export const fetchEmitInfoStart = () => ({
  type: actions.FETCH_INFO_EMIT_START,
  error: false,
});

export const fetchEmitInfoSuccess = (payload) => ({
  type: actions.FETCH_INFO_EMIT_SUCCES,
  payload,
});

export const fetchEmitInfoError = (payload) => ({
  type: actions.FETCH_INFO_EMIT_ERROR,
  payload,
});

export const updateRolesEmitInfo = (payload) => ({
  type: actions.UPDATE_ROLES_EMIT_INFO,
  payload,
});

export const updateVigencyDateEmitInfo = (payload) => ({
  type: actions.UPDATE_VIGENCY_DATE_EMIT_INFO,
  payload,
});

export const updateCurrentTabEmitInfo = (payload) => ({
  type: actions.UPDATE_CURRENT_TAB,
  payload,
});

export const updateReadyEmission = (payload) => ({
  type: actions.UPDATE_READY_EMISSION,
  payload,
});

export const updatePolicyNumberCaratula = (payload) => ({
  type: actions.UPDATE_POLICY_NUMBER_CARATULA,
  payload,
});

export const updatePolicyNumberCertificado = (payload) => ({
  type: actions.UPDATE_POLICY_NUMBER_CERTIFICADO,
  payload,
});

export const updateProcessNumberCaratula = (payload) => ({
  type: actions.UPDATE_PROCESS_NUMBER_CARATULA,
  payload,
});

export const updatePolicyBarcode = (payload) => ({
  type: actions.UPDATE_POLICY_BARCODE,
  payload,
});

export const updateAddBeneficiary = (payload) => ({
  type: actions.UPDATE_ADD_BENEFICIARY,
  payload,
});

export const readySQS = () => ({
  type: actions.READY_SQS,
});

/**
 * @param {boolean} value
 */
export const setLoading = (value) => ({
  type: actions.EMIT_SET_LOADING,
  payload: value,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.EMIT_SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case actions.FETCH_INFO_EMIT_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case actions.FETCH_INFO_EMIT_SUCCES: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    }
    case actions.FETCH_INFO_EMIT_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case actions.READY_SQS: {
      return {
        ...state,
        loading: false,
        isReadySQS: true,
        error: null,
      };
    }
    case actions.UPDATE_ROLES_EMIT_INFO: {
      return {
        ...state,
        data: {
          ...state.data,
          roles: action.payload.roles,
        },
      };
    }
    case actions.UPDATE_VIGENCY_DATE_EMIT_INFO: {
      return {
        ...state,
        data: {
          ...state.data,
          insurability_statement_start_date:
            action.payload.insurability_statement_start_date,
        },
      };
    }
    case actions.UPDATE_CURRENT_TAB: {
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          step: action.payload.step,
        },
      };
    }
    case actions.UPDATE_READY_EMISSION: {
      return {
        ...state,
        isReadyEmission: action.payload,
      };
    }
    case actions.UPDATE_POLICY_NUMBER_CARATULA: {
      return {
        ...state,
        data: {
          ...state.data,
          policy_number_caratula: action.payload,
        },
      };
    }
    case actions.UPDATE_PROCESS_NUMBER_CARATULA: {
      return {
        ...state,
        data: {
          ...state.data,
          process_number_caratula: action.payload,
        },
      };
    }
    case actions.UPDATE_POLICY_NUMBER_CERTIFICADO: {
      return {
        ...state,
        data: {
          ...state.data,
          process_number_certificado: action.payload,
        },
      };
    }
    case actions.UPDATE_POLICY_BARCODE: {
      return {
        ...state,
        data: {
          ...state.data,
          barcode: action.payload,
        },
      };
    }
    case actions.UPDATE_ADD_BENEFICIARY: {
      return {
        ...state,
        data: {
          ...state.data,
          beneficiary: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
}
