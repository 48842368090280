import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  requestUpdateRoles,
  requestUpdateVigencyDate,
  updateVigencyDate,
  updateRoleObject,
  deleteRole,
  createNewRole,
  createPolicy,
  emitPolicySQS,
} from '../../../emit_store/actions/emitInfo';

import RolesTemplate from '../../../components/emit_components/templates/roles/Roles';

const START_SQS_RISKS = 1;

const Roles = () => {
  const dispatch = useDispatch();
  const emitInfo = useSelector((state) => state.emitInfo);
  const isReadySQS = useSelector((state) => state.emitInfo.isReadySQS);

  const diasIniVigencia = useSelector((state) =>
    Number(state.openlConfig?.data?.results[16][0][1][0]?.diasIniVigencia)
  );

  const handleUpdateVigencyDate = (vigencyDate) => {
    dispatch(updateVigencyDate(vigencyDate));
  };

  const handleUpdateRoleObject = (roleIdSelected, key, value) => {   
    dispatch(updateRoleObject(roleIdSelected, key, value));
  };

  const handleCreateNewRole = (newRole) => {
    dispatch(createNewRole(newRole));
  };

  const handleDeleteRole = (roleIdSelected) => {
    dispatch(deleteRole(roleIdSelected));
  };

  const handleSaveEmitInfo = () => {
    if (emitInfo.data.insurability_statement_start_date) {
      dispatch(requestUpdateVigencyDate());
    }
    dispatch(requestUpdateRoles());
    setTimeout(() => {
      window.location.reload();
    }, 4000);
  };

  const handleContinue = () => {
    dispatch(requestUpdateRoles());
    dispatch(requestUpdateVigencyDate());
    if (emitInfo.data.quote_data.risks.length > START_SQS_RISKS) {
      setTimeout(() => {
        dispatch(emitPolicySQS());
      }, 4000);
    } else {
      setTimeout(() => {
        dispatch(createPolicy());
      }, 4000);
    }
  };

  const handleRetryCreatePolicy = () => {
    dispatch(createPolicy());
  };

  return (
    !emitInfo.loading && (
      <RolesTemplate
        bussinessType={emitInfo.data.business.name}
        vigencyDate={emitInfo.data.insurability_statement_start_date}
        maxDaysCalendar={diasIniVigencia}
        handleUpdateVigencyDate={handleUpdateVigencyDate}
        roleInfo={emitInfo.data.roles}
        handleUpdateRoleObject={handleUpdateRoleObject}
        handleCreateNewRole={handleCreateNewRole}
        handleDeleteRole={handleDeleteRole}
        handleSaveEmitInfo={handleSaveEmitInfo}
        handleContinue={handleContinue}
        handleRetryCreatePolicy={handleRetryCreatePolicy}
        showModalSQS={isReadySQS}
      />
    )
  );
};

export default Roles;
