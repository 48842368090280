import React from 'react';
import PropTypes from 'prop-types';
import QuestionIcon from 'assets/icons/question.svg';
import Tooltip from '../Tooltip';

const QuestionTooltip = ({ content, right, left }) => (
  <div className="w-4 h-4">
    <Tooltip
      right={right}
      left={left}
      content={<p className="text-xs text-white">{content}</p>}
    >
      <img src={QuestionIcon} alt="question-icon" />
    </Tooltip>
  </div>
);

QuestionTooltip.propTypes = {
  content: PropTypes.string.isRequired,
  right: PropTypes.string,
  left: PropTypes.string,
};

QuestionTooltip.defaultProps = {
  right: '',
  left: '',
};

export default QuestionTooltip;
