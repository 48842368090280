import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// components
import Button from 'components/Button';
import Switch from 'components/Switch';
// utils
import { setData, getData } from 'utils/helpers/localStorage';
import { ACTIVITIES_MODAL_HELP } from 'utils/constants/StorageKeys';
import { formatPrice } from 'utils/helpers/normalizeValues';
// assets
import dollar from 'assets/icons/dollar.svg';
import bag from 'assets/icons/bag.svg';
import cart from 'assets/icons/cart.svg';
import link from 'assets/icons/link.svg';
import asterisk from 'assets/icons/asterisk.svg';
import '../../assets/styles/scss/layouts/modals/ModalWhatCanQuote.scss';

const ModalWhatCanQuote = ({ setShowModal }) => {
  const values = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[6][0][1][0]
      : []
  );

  const onChangeShowAgain = (event) => {
    setData(ACTIVITIES_MODAL_HELP, event.target.checked);
  };

  return (
    <div className="flex flex-col space-y-8">
      <h1 className="mt-4 font-bold text-center dark-blue">
        Conoce lo que puedes cotizar
      </h1>
      <div className="flex flex-wrap">
        <div className="w-full p-3 md:w-1/2 lg:w-1/3 md:p-0">
          <div className="flex lg:justify-center items-center">
            <div className="container-icon">
              <img src={dollar} alt="dollar" />
            </div>
            <div>
              <p>Cotiza negocios hasta</p>
              <h3 className="dark-blue">
                $
                {`
                ${
                  values.cotizacionNegocio &&
                  formatPrice(values.cotizacionNegocio)
                }
                `}
              </h3>
              <p className="text-base">
                en valor de daños materiales de tu negocio
              </p>
            </div>
          </div>
        </div>
        <div className="w-full p-3 md:w-1/2 lg:w-1/3 md:p-0">
          <div className="flex lg:justify-center items-center">
            <div className="container-icon">
              <img src={cart} alt="cart" />
            </div>
            <div>
              <p>Asegura riesgo de</p>
              <h3 className="dark-blue">{`${values.aseguraRiesgo}*`}</h3>
            </div>
          </div>
        </div>
        <div className="w-full p-3 md:w-1/2 lg:w-1/3 md:p-0">
          <div className="flex lg:justify-center items-center">
            <div className="container-icon">
              <img src={bag} alt="bag" />
            </div>
            <div>
              <p>Asegura hasta</p>
              <h3 className="dark-blue">{`${values.numeroRiesgos} Riesgos`}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="content-activities sm:flex justify-center items-center">
        <p className="text-xs sm:text-base flex sm:items-center justify-center text-center px-4 sm:px-0 items-start">
          <img className="w-4 h-4 mr-1" src={asterisk} alt="link" />
          Conoce las actividades no permitidas para este producto.
        </p>
        <Link
          to="/restricted-activities"
          className="text-xs sm:text-base flex items-center medium-teal lg:ml-2 justify-center"
        >
          Ver actividades restringidas
          <img className="w-4 h-4 ml-1" src={link} alt="link" />
        </Link>
      </div>
      <div className="content-quotation flex flex-col space-y-6 items-center justify-center p-10">
        <div className="sm:flex hidden flex-col space-y-6 items-center justify-center">
          <h4 className="dark-blue center-align text-center">
            Si tu negocio cumple estas condiciones
          </h4>
          <Button primary onClick={() => setShowModal(false)}>
            ¡Cotiza ya!
          </Button>
        </div>
        <div className="flex sm:hidden flex-col space-y-6 items-center justify-center hide-on-med-and-up">
          <h4 className="dark-blue center-align text-center">
            ¿Quieres saber si tu negocio aplica?
          </h4>
          <Button primary onClick={() => setShowModal(false)}>
            Comenzar
          </Button>
        </div>
        <Switch
          onChange={onChangeShowAgain}
          defaultChecked={getData(ACTIVITIES_MODAL_HELP) === 'true'}
          label="No volver a mostrar"
        />
      </div>
    </div>
  );
};

export default ModalWhatCanQuote;

ModalWhatCanQuote.propTypes = {
  setShowModal: PropTypes.func,
};

ModalWhatCanQuote.defaultProps = {
  setShowModal: undefined,
};
