import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Pill style component
 */
const defaultClasses =
  'px-3 py-1 rounded-full text-sm whitespace-no-wrap bg-gray-100 sm:bg-white sm:bg-white border-solid sm:border-none';

const PillStyle = styled.button`
  background-color: ${(props) =>
    props.activated ? 'var(--light-teal)' : null};
  color: ${(props) =>
    props.activated ? 'var(--liberty-blue)' : 'var(--gray-400)'};
  border: ${(props) =>
    props.activated ? '1px solid var(--medium-teal)' : null};
  font-weight: ${(props) => (props.activated ? '700' : '400')};

  &:focus {
    outline: none;
  }
`;

/**
 * Pill component
 * @param {*} props
 */
const Pill = (props) => {
  const { activated, children, onClick, className } = props;
  return (
    <PillStyle
      data-testid="custom-pill"
      type="button"
      className={`${defaultClasses} ${className}`}
      activated={activated}
      onClick={onClick}
    >
      {children}
    </PillStyle>
  );
};

export default Pill;

/**
 * PropTypes
 */
Pill.propTypes = {
  /**
   * Activated validation
   * @param {bool} activated
   */
  activated: PropTypes.bool,
  /**
   * Children component
   * @param {oneOfType} children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Action call
   * @param {func} onClick
   */
  onClick: PropTypes.func,
  /**
   * className
   * @param {bool} className
   */
  className: PropTypes.string,
};

/**
 * Default props
 */
Pill.defaultProps = {
  children: null,
  activated: null,
  onClick: null,
  className: null,
};
