import axios from 'axios';
import axiosRetry from 'axios-retry';

const customRetryDelay = (retryCount) => {
  return 6000; // 30 segundos en milisegundos
};

export const client = axios.create();
export const clientRetry = axios.create();

axiosRetry(clientRetry, {
  retries: 3,
  retryCondition: () => true,
  retryDelay: customRetryDelay,
});
