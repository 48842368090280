import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

import { isObjectEmpty } from 'utils/helpers/object';
import FiletUpload from 'components/emit_components/basics/Sarlaft/FileUpload';
import Input from '../../basics/form/Input';
import Select from '../../basics/form/Select';
import Button from '../../basics/form/Button';
import RadioButtonComponent from '../../basics/form/RadioButtonComponent';

import {
  NaturalPersonRegisterOptions,
  optionsIdentities,
  optionsAddress,
  optionsMonths,
  FILE_TYPES
} from '../../../../utils/constants/emit_constants';
import {
  departments,
  cities,
} from '../../../../utils/constants/emit_constants/departments&cities';
import caution from '../../../../assets/icons/caution.svg';

import { validationRules } from '../../../../utils/helpers/stringValidations';

const HelperText = styled.span`
  color: var(--gray-400);
`;

const ErrorText = styled.span`
  color: red;
  display: flex;
  font-size: 0.75rem;
`;

const TextFieldCustom = withStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#fff',
      border: '1px solid #C0BFC0',
      borderColor: 'var(--gray-300)',
      color: 'var(--gray-600)',
      background: '#ffffff',
      minHeight: '3rem',
      borderRadius: '5px',
      fontSize: '1rem',

      '& .MuiAutocomplete-popupIndicator': {
        '& path': {
          d:
            "path('M 12.018 14.88 L 19.577 7.47 l 1.204 1.124 L 12.032 17.243 L 3.298 8.649 L 4.486 7.477 Z')",
        },
      },

      '&.Mui-focused fieldset': {
        backgroundColor: '#fff',
      },

      '&.Mui-error': {
        backgroundColor: 'rgba(255, 245, 245, var(--bg-opacity))',
        borderColor: 'red',
      },
    },

    '& .MuiFilledInput-underline:after, & .MuiFilledInput-underline:before': {
      border: 'none',
    },

    '& .MuiFormLabel-root.Mui-focused': {
      color: 'var(--gray-600)',
    },

    '& .MuiFormLabel-root.Mui-error': {
      color: 'var(--gray-600)',
    },
  },
})(TextField);

const NaturalPerson = ({
  roleSchema,
  setContinueFormRoleInfo,
  setCloseModal,
  roleSelection,
  exceptionSarlaft,
  typeOfIdentification
}) => {
  const [optionDays, setOptionDays] = useState([]);
  const [optionYears, setOptionYears] = useState([]);

  const {
    control,
    register,
    errors,
    watch,
    setValue,
    getValues,
    formState,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: roleSchema,
  });
  const handleSaveForm = () => {
    if (roleSelection.isInsured && !roleSelection.isTaker) {
      sessionStorage.setItem('habeasData', getValues('habeasData'));
    }
    const format = {
      ...getValues(),
      day: getValues().day.code ? getValues().day.code : getValues().day,
      month: getValues().month.code
        ? getValues().month.code
        : getValues().month,
      year: getValues().year.code ? getValues().year.code : getValues().year,
    };
    setContinueFormRoleInfo(format);
  };

  useEffect(() => {
    register('department', NaturalPersonRegisterOptions.department);
    register('city', NaturalPersonRegisterOptions.city);

    const arrayDays = [...Array(31).keys()].map((day) => ({
      code: String(day + 1),
    }));
    setOptionDays(arrayDays);

    const arrayYears = [...Array(new Date().getFullYear() - 1900 + 1).keys()]
      .map((i) => i + 1900)
      .reverse()
      .map((year) => ({ code: String(year) }));
    setOptionYears(arrayYears);

    trigger();
  }, []);
  const showFileInsured = roleSelection.isInsured;
  const showFileBeneficiary = roleSelection.isBeneficiary;

  const documentType = watch('documentType')?.code;
  const documentNumber = watch('documentNumber');
  const keyFile = exceptionSarlaft ? 'habeasData' : 'sarlaftFile';

  const showFileTaker = roleSelection.isTaker && (!roleSelection.hasHabeasData || !exceptionSarlaft);

  const fileTaker = showFileTaker ? watch(keyFile) : undefined;
  const fileInsured = (showFileInsured || showFileBeneficiary)  ? watch('habeasData') : undefined;

  const { isSarlaft } = roleSelection;

  useEffect(() => {
    if (!isSarlaft && showFileTaker) {
      register(keyFile, {
        required: {
          value: showFileTaker,
          message: `Archivo de ${exceptionSarlaft ? 'habeas data' : 'sarlaft '
            } requerido`,
        },
      });
      trigger();
    }
  }, [showFileTaker]);

  useEffect(() => {
    if ((showFileInsured || showFileBeneficiary) && typeOfIdentification !== '37' && !roleSelection.isTaker && (!isSarlaft && !roleSelection.hasHabeasData)) {
      register('habeasData', {
        required: {
          value: (showFileInsured || showFileBeneficiary),
          message: `Archivo de habeas data requerido`,
        },
      });
      trigger();
    }
  }, [showFileInsured, showFileBeneficiary]);

  
  const handleFileChange = (value) => {
    setValue(keyFile, value, {
      shouldValidate: true,
    });
    trigger();
  };
  const handleFileChangeInsured = (value) => {
    setValue("habeasData", value, {
      shouldValidate: true,
    });
    trigger();
  };


  const fileIsRequired =
    ((showFileTaker && !isSarlaft) || (fileTaker && showFileTaker)) && (!roleSelection.hasHabeasData || !exceptionSarlaft);
  return (
    <div className="text-left dark-blue mb-2 mt-4 animate__animated animate__fadeInUp">
      <div className="flex items-center flex-col m-auto">
        <Row className="space-y-8 px-3 md:max-w-3xl mx-auto">
          <Col
            sm={12}
            className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-6"
          >
            <Controller
              key="controller-documentType-NaturalPerson"
              as={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Select
                  placeholder="Tipo de identificación"
                  options={optionsIdentities}
                  optionKey="name"
                  optionLabel="name"
                  errorMessage={
                    errors.documentType && errors.documentType.message
                  }
                  disabled={!!roleSchema.documentType?.code}
                  onChange={() => trigger('documentType')}
                />
              }
              name="documentType"
              control={control}
              rules={NaturalPersonRegisterOptions.documentType}
              valueDefault={roleSchema.documentType}
            />

            <Input
              placeholder="Número de identificación"
              type="number"
              name="documentNumber"
              ref={register(NaturalPersonRegisterOptions.documentNumber)}
              controlled={false}
              errorMessage={
                errors.documentNumber && errors.documentNumber.message
              }
              disabled={!!roleSchema.documentNumber}
            />
          </Col>
          <Col
            lg={12}
            className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-6"
          >
            <Input
              placeholder="Primer Nombre*"
              type="text"
              name="firstName"
              inputClass="w-100"
              controlled={false}
              errorMessage={errors.firstName ? errors.firstName.message : ''}
              ref={register(NaturalPersonRegisterOptions.firstName)}
              disabled={!!roleSchema.firstName}
            />
            <Input
              placeholder="segundo Nombre (Opcional)"
              type="text"
              name="secondName"
              inputClass=""
              controlled={false}
              errorMessage={errors.secondName ? errors.secondName.message : ''}
              ref={register(NaturalPersonRegisterOptions.secondName)}
              disabled={!!roleSchema.secondName}
            />
          </Col>
          <Col
            lg={12}
            className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-6"
          >
            <Input
              placeholder="Primer Apellido*"
              type="text"
              name="surname"
              inputClass="w-100"
              controlled={false}
              errorMessage={errors.surname ? errors.surname.message : ''}
              ref={register(NaturalPersonRegisterOptions.surname)}
              disabled={!!roleSchema.surname}
            />
            <Input
              placeholder="Segundo Apellido (Opcional)"
              type="text"
              name="secondSurname"
              inputClass=""
              controlled={false}
              errorMessage={
                errors.secondSurname ? errors.secondSurname.message : ''
              }
              ref={register(NaturalPersonRegisterOptions.secondSurname)}
              disabled={!!roleSchema.secondSurname}
            />
          </Col>
          <Col lg={6}>
            <p>Género*</p>
            {roleSchema.gender?.code && roleSchema.gender?.code !== 'null' ? (
              <div className="w-full flex flex-col md:flex-row md:justify-between">
                <Controller
                  key="controller-gender-NaturalPerson"
                  as={
                    // eslint-disable-next-line react/jsx-wrap-multilines

                    <RadioButtonComponent
                      key="1"
                      description={roleSchema.gender.name}
                      constructionSelect={roleSchema.gender?.name || ''}
                      onChange={() => {
                        setValue('gender', '', { shouldValidate: true });
                        setValue('gender', roleSchema.gender, {
                          shouldValidate: true,
                        });
                      }}
                      errorMessage={errors.gender?.message || ''}
                    />
                  }
                  name="gender"
                  control={control}
                  rules={NaturalPersonRegisterOptions.gender}
                />
              </div>
            ) : (
              <>
                <div>
                  {errors.gender && (
                    <ErrorText>
                      {errors.gender.message && (
                        <img
                          className="w-4 mr-1"
                          src={caution}
                          alt="icon caution"
                        />
                      )}
                      <span className="truncate">{errors.gender.message}</span>
                    </ErrorText>
                  )}
                </div>
                <div className="w-full flex flex-col md:flex-row md:justify-between">
                  <Controller
                    key="controller-gender-NaturalPerson"
                    as={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <>
                        <RadioButtonComponent
                          key="1"
                          description="Hombre"
                          constructionSelect={getValues().gender?.name || ''}
                          onChange={(gender) => {
                            setValue('gender', '', { shouldValidate: true });
                            setValue(
                              'gender',
                              { name: gender, code: '1' },
                              { shouldValidate: true }
                            );
                          }}
                          errorMessage={errors.gender?.message || ''}
                        />
                        <RadioButtonComponent
                          key="2"
                          description="Mujer"
                          constructionSelect={getValues().gender?.name || ''}
                          onChange={(gender) => {
                            setValue('gender', '', { shouldValidate: true });
                            setValue(
                              'gender',
                              { name: gender, code: '2' },
                              { shouldValidate: true }
                            );
                          }}
                          errorMessage={errors.gender?.message || ''}
                        />
                      </>
                    }
                    name="gender"
                    control={control}
                    rules={NaturalPersonRegisterOptions.gender}
                  />
                </div>
              </>
            )}
          </Col>
          <Col lg={6}>
            <p>Fecha de nacimiento*</p>
            <div className="w-full flex flex-row mt-4 space-x-3">
              <Controller
                key="controller-day-NaturalPerson"
                as={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Select
                    placeholder="Día"
                    options={optionDays}
                    optionKey="code"
                    optionLabel="code"
                    errorMessage={errors.day ? errors.day.message : ''}
                    disabled={!!roleSchema.day}
                    onChange={() => trigger('day')}
                  />
                }
                name="day"
                control={control}
                rules={NaturalPersonRegisterOptions.day}
                valueDefault={roleSchema.day ? { code: roleSchema.day } : ''}
              />
              <Controller
                key="controller-month-NaturalPerson"
                as={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Select
                    placeholder="Mes"
                    options={optionsMonths}
                    optionKey="code"
                    optionLabel="name"
                    errorMessage={errors.month ? errors.month.message : ''}
                    disabled={!!roleSchema.month}
                    onChange={() => trigger('month')}
                  />
                }
                name="month"
                control={control}
                rules={NaturalPersonRegisterOptions.month}
                valueDefault={
                  roleSchema.month ? optionsMonths[roleSchema.month] : ''
                }
              />
              <Controller
                key="controller-year-NaturalPerson"
                as={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Select
                    placeholder="Año"
                    options={optionYears}
                    optionKey="code"
                    optionLabel="code"
                    errorMessage={errors.year ? errors.year.message : ''}
                    disabled={!!roleSchema.year}
                    onChange={() => trigger('year')}
                  />
                }
                name="year"
                control={control}
                rules={NaturalPersonRegisterOptions.year}
                valueDefault={roleSchema.year ? { code: roleSchema.year } : ''}
              />
            </div>
          </Col>
          <Col
            lg={6}
            className="flex flex-col"
          >
            <Controller
              key={`email`}
              render={() => (
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Input
                  placeholder="Correo electrónico*"
                  type="email"
                  name="email"
                  inputClass=""
                  controlled={true}
                  errorMessage={errors.email ? errors.email.message : ''}
                  disabled={false}
                  onChange={(e) => {
                    const { value } = e.target
                    setValue('email', value.toLowerCase());
                    trigger('email');
                  }}
                  value={getValues().email}

                                   


                />
              )}
              name={'email'}
              control={control}
              rules={validationRules}
            />

            <HelperText className="text-xs mt-2">
              Ejemplo: nombre@mail.com
            </HelperText>
          </Col>

          <Col lg={6}>
            {!isObjectEmpty(getValues()) && (

              <div className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-3">
                <div className="relative w-full">
                  {errors.department && (
                    <ErrorText className="absolute top-0 -mt-5">
                      {errors.department.message && (
                        <img
                          className="w-4 mr-1"
                          src={caution}
                          alt="icon caution"
                        />
                      )}
                      <span className="truncate">
                        {errors.department.message}
                      </span>
                    </ErrorText>
                  )}
                  <Autocomplete
                    value={getValues().department}
                    onChange={(event, newValue) => {
                      setValue('department', newValue, {
                        shouldValidate: true,
                      });
                      setValue('city', '', {
                        shouldValidate: true,
                      });
                      trigger();
                    }}
                    options={departments}
                    getOptionLabel={(option) => option.name}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextFieldCustom
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        error={errors.department}
                        label="Departamento*"
                        variant="filled"
                        size="small"
                      />
                    )}
                  />
                </div>
                <div className="relative w-full">
                  {errors.city && (
                    <ErrorText className="absolute top-0 -mt-5">
                      {errors.city.message && (
                        <img
                          className="w-4 mr-1"
                          src={caution}
                          alt="icon caution"
                        />
                      )}
                      <span className="truncate">{errors.city.message}</span>
                    </ErrorText>
                  )}
                  <Autocomplete
                    value={getValues().city}
                    onChange={(event, newValue) => {
                      setValue('city', newValue, {
                        shouldValidate: true,
                      });
                      trigger();
                    }}
                    options={cities[getValues().department?.code] || []}
                    getOptionLabel={(option) => option.name}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                      <TextFieldCustom
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        error={errors.city}
                        label="Ciudad*"
                        variant="filled"
                        size="small"
                      />
                    )}
                  />
                </div>
              </div>
            )}
          </Col>

          <Col
            sm={12}
            className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-6"
          >
            <Input
              placeholder="Teléfono Móvil*"
              type="number"
              name="phoneNumber"
              inputClass="w-100"
              controlled={false}
              errorMessage={
                errors.phoneNumber ? errors.phoneNumber.message : ''
              }
              ref={register(NaturalPersonRegisterOptions.phoneNumber)}
            />
            <Controller
              key="controller-addressType-NaturalPerson"
              as={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Select
                  placeholder="Tipo de dirección*"
                  options={optionsAddress}
                  optionKey="code"
                  optionLabel="name"
                  errorMessage={
                    errors.addressType ? errors.addressType.message : ''
                  }
                  onChange={() => trigger('addressType')}
                />
              }
              name="addressType"
              control={control}
              rules={NaturalPersonRegisterOptions.addressType}
              valueDefault={
                roleSchema.addressType.code ? roleSchema.addressType : ''
              }
            />
          </Col>
          <Col sm={12} className="flex flex-col ">
            <Input
              placeholder="Dirección*"
              type="text"
              name="address"
              inputClass="w-100"
              controlled={false}
              errorMessage={errors.address ? errors.address.message : ''}
              ref={register(NaturalPersonRegisterOptions.address)}
            />
            <HelperText className="text-xs mt-2">
              Ejemplo: calle 1234 b No 24 a 54 b
            </HelperText>
          </Col>
        </Row>
      </div>
      <Row>
        <Col sm={12}>
          {fileIsRequired && (
            <>
              <FiletUpload
                documentType={documentType}
                documentNumber={documentNumber}
                onChange={handleFileChange}
                value={fileTaker || ''}
                typeFile={
                  (exceptionSarlaft) ? FILE_TYPES.HABEAS_DATA : FILE_TYPES.SARLAFT
                }
              />
              <p className="text-center text-red-600">
                {(exceptionSarlaft)
                  ? errors?.habeasData?.message
                  : errors?.sarlaftFile?.message}
              </p>

            </>
          )}
          {(((showFileInsured || showFileBeneficiary) && typeOfIdentification !== '37' && !roleSelection.isTaker) && (!roleSelection.hasHabeasData && !isSarlaft)) && (
            <>
              <FiletUpload
                documentType={documentType}
                documentNumber={documentNumber}
                onChange={handleFileChangeInsured}
                value={fileInsured || ''}
                typeFile={FILE_TYPES.HABEAS_DATA}
              />
              <p className="text-center text-red-600">
                {errors?.habeasData?.message}
              </p>

            </>
          )}
        </Col>
      </Row>
      <div className="flex items-center flex-col px-3 md:max-w-3xl mx-auto">
        <div className="flex justify-center">
          <Button className="mr-4" onClick={setCloseModal}>
            Cancelar
          </Button>
          <Button
            primary
            disabled={!formState.isValid}
            onClick={handleSaveForm}
          >
            Guardar
          </Button>
        </div>
      </div>
    </div>
  );
};

NaturalPerson.propTypes = {
  roleSchema: PropTypes.object.isRequired,
  setContinueFormRoleInfo: PropTypes.func.isRequired,
  setCloseModal: PropTypes.func.isRequired,
  roleSelection: PropTypes.object.isRequired,
  exceptionSarlaft: PropTypes.bool.isRequired,
  typeOfIdentification: PropTypes.string.isRequired,
};

export default NaturalPerson;
