import React from 'react';
import PropTypes from 'prop-types';
import { MemoryRouter as Router, Switch, Route } from 'react-router-dom';
import Modal from 'components/Modal';
import ModalWhatCanQuote from 'layouts/modals/ModalWhatCanQuote';
import RestrictedActivities from 'layouts/modals/RestrictedActivities';

const Navigation = ({ showModal, setShowModal }) => {
  return (
    <Modal showModal={showModal} setShowModal={setShowModal}>
      <Router>
        <Switch>
          <Route exact path="/">
            <ModalWhatCanQuote setShowModal={setShowModal} />
          </Route>
          <Route exact path="/restricted-activities">
            <RestrictedActivities setShowModal={setShowModal} />
          </Route>
        </Switch>
      </Router>
    </Modal>
  );
};

export default Navigation;

Navigation.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func,
};

Navigation.defaultProps = {
  setShowModal: undefined,
};
