import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import caution from 'assets/icons/caution.svg';
import NumberFormat from 'react-number-format';

/**
 * Input default classes
 */
const inputDefaultClasses =
  'relative box-border h-12 py-2 px-2 border border-solid rounded text-base';

/**
 * Input style component
 */
const InputStyle = styled.div`
  border-color: var(--gray-300);
  color: var(--gray-600);
  width: 100%;

  &.error {
    border-color: red;
    .custom-label {
      color: red;
    }
  }

  input:focus {
    outline: none;
  }

  label {
    width: calc(100% - 0.5rem);
    left: 0.5rem;
    top: 0.75rem;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .custom-label {
    position: absolute;
    left: 0;
    top: ${(props) => props.positionLabel === 'top' && '-24px'};
    bottom: ${(props) => props.positionLabel === 'bottom' && '-22px'};
    color: gray;
    width: calc(100% - 0.5rem);
    line-height: 12px;
  }

  input:not(:placeholder-shown) ~ label,
  input:focus ~ label,
  // input:valid ~ label,
  input:disabled ~ label {
    top: 0.25rem;
    font-size: 12px;
    color: var(--gray-400);
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

/**
 * Input component
 * @param {*} props
 */
const Input = forwardRef((props, ref) => {
  const {
    type,
    disabled,
    placeholder,
    value,
    onChange,
    onBlur,
    className,
    name,
    controlled,
    errorMessage,
    inputClass,
    customLabel,
    positionLabel,
    onValueChange,
    customOnBlur,
    customOnMouseOut,
  } = props;

  const handleOnBlur = (e) => {
    customOnBlur(e);
    onBlur(e);
  };

  const handleCustomOnMouseOut = (e) => {
    customOnMouseOut(e);
  };

  return (
    <div className="flex space-x-1 grid-cols-2">
      {name !== 'CompDirecc' && name !== 'NomRiesgo' && (
        <div className="w-2 mt-3">
          {' '}
          <span className="text-red-600	"> * </span>
        </div>
      )}

      <InputStyle
        data-testid="custom-input"
        className={`${inputDefaultClasses} ${className} ${
          errorMessage && 'error bg-red-100'
        }`}
        positionLabel={positionLabel}
      >
        {(customLabel || errorMessage) && (
          <div className="custom-label flex text-xs mt-2">
            {errorMessage && (
              <img className="w-4 mr-1" src={caution} alt="icon caution" />
            )}
            <span className="overflow-ellipsis">
              {errorMessage || customLabel}
            </span>
          </div>
        )}

        {(() => {
          switch (type) {
            case 'currency':
              return (
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="$ "
                  className={`w-full mt-3 ${
                    errorMessage && 'bg-red-100'
                  } ${inputClass}`}
                  onChange={onChange}
                  placeholder=" "
                  onValueChange={onValueChange}
                  onBlur={handleOnBlur}
                  onMouseOut={handleCustomOnMouseOut}
                  defaultValue={value}
                />
              );
            case 'currency_dynamic':
              return (
                <NumberFormat
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="$ "
                  className={`w-full mt-3 ${
                    errorMessage && 'bg-red-100'
                  } ${inputClass}`}
                  onChange={onChange}
                  placeholder=" "
                  onValueChange={onValueChange}
                  onBlur={handleOnBlur}
                  onMouseOut={handleCustomOnMouseOut}
                  value={controlled ? value : undefined}
                />
              );
            default:
              return (
                <input
                  type={type}
                  className={`w-full mt-3 ${
                    errorMessage && 'bg-red-100'
                  } ${inputClass}`}
                  disabled={disabled}
                  value={controlled ? value : undefined}
                  onChange={onChange}
                  name={name}
                  ref={ref}
                  onBlur={handleOnBlur}
                  onMouseOut={handleCustomOnMouseOut}
                  placeholder=" "
                />
              );
          }
        })()}
        <label className="absolute pointer-events-none text-base truncate">
          {placeholder}
        </label>
      </InputStyle>
    </div>
  );
});

export default Input;

/**
 * PropsTypes
 */
Input.propTypes = {
  /**
   * Input type
   * @param {string} type
   */
  type: PropTypes.string,
  /**
   * Disabled
   * @param {bool} disabled
   */
  disabled: PropTypes.bool,
  /**
   * Placeholder
   * @param {string} placeholder
   */
  placeholder: PropTypes.string,
  /**
   * Value
   * @param {string} value
   */
  value: PropTypes.string,
  /**
   * Action call
   * @param {func} onChange
   */
  onChange: PropTypes.func,
  /**
   * On blur
   * @param {func} onBlur
   */
  onBlur: PropTypes.func,
  /**
   * className
   * @param {string} className
   */
  className: PropTypes.string,
  /**
   * Name
   * @param {string} name
   */
  name: PropTypes.string,
  /**
   * Controlled
   * @param {bool} controlled
   */
  controlled: PropTypes.bool,
  /**
   * Name
   * @param {string} errorMessage
   */
  errorMessage: PropTypes.string,
  /**
   * Name
   * @param {string} inputClass
   */
  inputClass: PropTypes.string,
  /**
   * customLabel
   * @param {string} customLabel
   */
  customLabel: PropTypes.string,
  /**
   * positionLabel
   * @param {string} positionLabel
   */
  positionLabel: PropTypes.string,
  /**
   * positionLabel
   * @param {func} onValueChange
   */
  onValueChange: PropTypes.func,
  /**
   * On blur
   * @param {func} customOnBlur
   */
  customOnBlur: PropTypes.func,
  /**
   * On customOnMouseOut
   * @param {func} customOnMouseOut
   */
  customOnMouseOut: PropTypes.func,
};

/**
 * Default props
 */
Input.defaultProps = {
  /**
   * Input type
   * @param {string} type
   */
  type: 'text',
  /**
   * Disabled
   * @param {bool} disabled
   */
  disabled: false,
  /**
   * Placeholder
   * @param {string} placeholder
   */
  placeholder: '',
  /**
   * Value
   * @param {string} value
   */
  value: '',
  /**
   * Action call
   * @param {func} onChange
   */
  onChange: () => {
    // do nothing
  },
  /**
   * On blur
   * @param {func} onBlur
   */
  onBlur: () => {
    // do nothing
  },
  /**
   * class name
   * @param {string} className
   */
  className: '',
  /**
   * name
   * @param {string} name
   */
  name: '',
  /**
   * controlled
   * @param {bool} controlled
   */
  controlled: true,
  /**
   * errorMessage
   * @param {string} errorMessage
   */
  errorMessage: '',
  /**
   * inputClass
   * @param {string} inputClass
   */
  inputClass: '',
  /**
   * inputClass
   * @param {string} customLabel
   */
  customLabel: null,
  /**
   * inputClass
   * @param {string} positionLabel
   */
  positionLabel: 'top',
  /**
   * Action call
   * @param {func} onValueChange
   */
  onValueChange: () => {
    // do nothing
  },
  /**
   * On blur
   * @param {func} customOnBlur
   */
  customOnBlur: () => {
    // do nothing
  },
  customOnMouseOut: () => {
    // do nothing
  },
};
