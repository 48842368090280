import React, { useState } from 'react';
import PropTypes from 'prop-types';
import search from 'assets/icons/search.svg';

/**
 * FInder component
 * @param {*} props
 */
const Finder = (props) => {
  const { placeholder, action, onKeyDown } = props;
  const [inputValue, setInputValue] = useState('');

  return (
    <React.Fragment key="Finder">
      <div className="flex flex-col relative w-full sm:w-3/5 my-4">
        <div className="flex flex-row rounded border border-solid border-gray-200">
          <input
            className="flex-auto p-3 shadow-md rounded-l-sm"
            onChange={(e) => {
              e.preventDefault();
              setInputValue(e.target.value);
              action(e.target.value);
            }}
            value={inputValue}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
          />
          <button
            type="button"
            style={{ backgroundColor: '#99E5EA' }}
            className="w-12 rounded border-none shadow-md"
            onClick={(e) => {
              e.preventDefault();
              action(inputValue);
            }}
          >
            <img className="inline" src={search} alt="buscar" />
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Finder;

/**
 * PropsTypes
 */
Finder.propTypes = {
  /**
   * Placeholder
   * @param {string} placeholder
   */
  placeholder: PropTypes.string,
  /**
   * Call action into Parent component
   * @param {func} action
   */
  action: PropTypes.func,
  /**
   * Call onKeyDown into Parent component
   * @param {func} onKeyDown
   */
  onKeyDown: PropTypes.func,
};

/**
 * Default props
 */
Finder.defaultProps = {
  /**
   * Placeholder
   * @param {string} placeholder
   */
  placeholder: '',
  /**
   * Call action into Parent component
   * @param {func} action
   */
  action: null,
  /**
   * Call onKeyDown into Parent component
   * @param {func} onKeyDown
   */
  onKeyDown: null,
};
