import React from 'react';
import RadioButtonComponent from 'components/RadioButtonComponent';
import Modal from 'components/Modal';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import useWindowDimensions from 'utils/helpers/resolution';
import styled from 'styled-components';
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import caution from 'assets/icons/caution.svg';
import 'swiper/swiper.scss';

const Container = styled.div`
  .error-label {
    color: red;
    width: calc(100% - 0.5rem);
  }
`;

const SwiperContainer = styled.div`
  .swiper-button-prev:after,
  .swiper-button-next:after {
    color: var(--gray-300);
    font-weight: bold;
    font-size: 28px;
    margin-top: -150px;
    outline: none;
  }
  .swiper-pagination-bullet {
    background: var(--gray-500);
    opacity: 1;
    outline: none;
  }
  .swiper-pagination-bullet-active {
    background: var(--medium-teal);
    outline: none;
  }
`;

const ConstructionsTypes = (props) => {
  const {
    constructions,
    constructionSelect,
    setConstructionType,
    showModal,
    setShowModal,
    onChange,
    onValueChange,
    errorMessage,
  } = props;
  SwiperCore.use([Navigation, Pagination, A11y]);
  const { width } = useWindowDimensions();
  return (
    <Container className="w-full">
      <Modal showModal={showModal} setShowModal={setShowModal}>
        <h3 className="text-center text-blue w-2/4 my-2 mx-auto leading-tight md:mb-8">
          Tipos de construcción
        </h3>
        <SwiperContainer>
          <Swiper
            spaceBetween={50}
            slidesPerView={width < 768 ? 1 : constructions.length}
            navigation={width < 768}
            pagination={width < 768}
          >
            {constructions.map((item) => (
              <SwiperSlide
                key={`${item.codigoDescripcion}${item.codigoRespuesta}`}
              >
                <>
                  <img
                    src={require(`assets/icons/${item.ubicacion}`)}
                    alt="Liberty Seguros - Tipo de construcción"
                    className="w-8/12 m-auto my-4"
                  />
                  <h4 className="text-center text-xl text-blue">
                    {item.descripcion}
                  </h4>
                  <p className="w-9/12 m-auto text-center py-4 mb-4">
                    {item.literales}
                  </p>
                </>
              </SwiperSlide>
            ))}
          </Swiper>
        </SwiperContainer>
        <div className="flex justify-center mt-4 mb-8 px-4">
          <Button primary onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </div>
      </Modal>
      <p className="color-gray-600 mt-2 mb-2">
        <span className="text-red-600	"> * </span> Tipo de construcción
        {/* <button
          className="text-xs text-mediumTeal font-medium mx-2 cursor-pointer focus:outline-none focus:border-none viewExamples"
          onClick={() => setShowModal(!showModal)}
          type="button"
        >
          Ver ejemplos
        </button> */}
      </p>
      {errorMessage && (
        <div className="error-label flex text-xs">
          <img className="w-4 mr-1" src={caution} alt="icon caution" />
          <span className="truncate">{errorMessage}</span>
        </div>
      )}
      <div className="w-full flex flex-col md:flex-row md:justify-between gap-5">
        {constructions.map((item) => (
          <RadioButtonComponent
            key={`${item.codigoDescripcion}${item.codigoRespuesta}`}
            description={item.descripcion}
            literales={item.literales}
            ubicacion={item.ubicacion}
            setConstructionType={setConstructionType}
            constructionSelect={constructionSelect}
            errorMessage={errorMessage}
            onChange={() => {
              onChange(item);
              onValueChange();
            }}
          />
        ))}
      </div>
    </Container>
  );
};

export default ConstructionsTypes;

ConstructionsTypes.propTypes = {
  constructions: PropTypes.array.isRequired,
  setConstructionType: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  constructionSelect: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
};
