import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Back from 'components/Back';
import Button from 'components/Button';
import Finder from 'components/Finder';
import { formatWord } from 'utils/helpers/normalizeValues';
import success from 'assets/images/success.svg';
import notFound from 'assets/images/not-found.svg';
import noRows from 'assets/images/no-rows.svg';
import { createUpdateBusiness } from 'reducers/quoteInfo';
import { updateTabQuote } from 'reducers/activeQuoteTab';

/**
 * Container
 */
const Container = styled.div`
  border: 1px solid var(--gray-200);
`;

/**
 * Div style for rows filtered
 */
const Results = styled.div`
  min-width: fit-content;
  button:focus {
    background-color: #f0fbfc;
    outline: none;
    width: 100%;
  }
`;
/**
 * Elements style
 */
const H1 = styled.h1`
  color: var(--liberty-blue);
`;

const H3 = styled.h3`
  color: var(--gray-600);
`;

const Label = styled.label`
  color: var(--gray-400);
`;

const P = styled.p`
  color: var(--gray-600);
`;

const Bg = styled.div`
  background-color: var(--light-teal);
`;

/**
 * RestrictedActivities component
 */
const RestrictedActivities = ({ setShowModal }) => {
  const dispatch = useDispatch();
  const [rowsFiltered, setRowsFiltered] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [activitySelected, setActivitySelected] = useState(null);

  const activities = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[5][0][1]
      : []
  );

  /**
   * Rows filter
   */
  const rowsFilter = (value) => {
    setRowsFiltered([]);
    setInputValue(value);
    if (value.length > 3) {
      const filtered = activities.filter((row) => {
        const n = formatWord(row.actividad).indexOf(formatWord(value));
        if (n >= 0) {
          return true;
        }
        return false;
      });
      setRowsFiltered(filtered);
      setActivitySelected();
    }
  };

  /**
   * Enter key press
   * @param {*} e
   */
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      rowsFilter(e.target.value);
    }
  };

  return (
    <React.Fragment key="restricted-activities">
      <div className="absolute top-0 left-0 mt-1">
        <Link to="/">
          <Back />
        </Link>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col items-center px-2 py-4">
          <H1 className="text-3xl font-medium text-center">
            Actividades Restringidas
          </H1>
          <P className="text-sm text-center">
            Ingresa la actividad y revisa si está permitida para este producto
          </P>
          <Finder
            placeholder="Ingresa el nombre de la actividad"
            action={rowsFilter}
            onKeyDown={handleKeyDown}
          />
        </div>

        <Bg className="flex-auto flex flex-col items-center px-2 pt-4">
          <H3 className="text-xl font-bold text-center text-2xl">
            Resultados de tu búsqueda
          </H3>
          <P className="text-sm text-center">
            Selecciona la opción para verificar si es posible hacer la
            cotización
          </P>

          <Container
            className={`mt-2 m-12 bg-white w-full sm:w-5/6 rounded overflow-auto ${
              inputValue.length > 0 && rowsFiltered.length === 0
                ? 'whitespace-normal'
                : 'whitespace-no-wrap'
            } `}
          >
            <Results className="h-40">
              {rowsFiltered.map((activity) => (
                <button
                  key={`activity-${activity.actividad}`}
                  type="button"
                  className="flex items-center h-12 text-sm text-gray-800 flex-no-wrap"
                  onClick={(e) => {
                    e.preventDefault();
                    setActivitySelected(activity);
                  }}
                >
                  <span className="px-4">{activity.actividad}</span>
                </button>
              ))}

              {inputValue.length > 3 && rowsFiltered.length === 0 && (
                <div className="text-center pt-6">
                  <img
                    className="m-auto"
                    src={noRows}
                    alt="Ups, lo sentimos no hemos encontrado lo que buscas"
                  />
                  <p className="text-base">
                    Ups, lo sentimos no hemos encontrado lo que buscas
                  </p>
                  <Label className="text-sm">
                    verifica e intenta nuevamente
                  </Label>
                </div>
              )}
            </Results>
          </Container>
        </Bg>
        {activitySelected && activitySelected?.restringido === '1' && (
          <Bg className="flex-auto flex flex-col items-center -mt-4">
            <P className="w-full sm:w-4/6 text-sm text-center px-2">
              Lo sentimos no es posible cotizar por medio de la herramienta
              negocios que correspondan a&nbsp;
              <b>{activitySelected.actividad}</b>
            </P>
            <img
              className="w-1/3 md:w-32"
              src={notFound}
              alt="Actividad restringida"
            />
          </Bg>
        )}
        {activitySelected && activitySelected?.restringido === '0' && (
          <Bg className="flex-auto flex flex-col items-center -mt-4">
            <P className="w:full sm:w-4/6 text-sm text-center px-2">
              Tenemos buenas noticias, al parecer&nbsp;
              <b>{activitySelected.actividad}</b>
              &nbsp;es un tipo de negocio que puedes cotizar por la herramienta
            </P>
            <div>
              <Button
                primary
                className="w-auto"
                onClick={(e) => {
                  e.preventDefault();
                  setShowModal(false);
                  dispatch(
                    createUpdateBusiness({
                      business: {
                        answerCode: activitySelected.respuesta,
                        name: activitySelected.actividad,
                      },
                    })
                  );
                  dispatch(updateTabQuote({ index: 1 }));
                }}
              >
                ¡Cotiza ya!
              </Button>
            </div>
            <img
              className="w-1/3 md:w-1/12"
              src={success}
              alt="Actividad no restringida"
            />
          </Bg>
        )}
      </div>
    </React.Fragment>
  );
};

export default RestrictedActivities;

RestrictedActivities.propTypes = {
  setShowModal: PropTypes.func,
};

RestrictedActivities.defaultProps = {
  setShowModal: undefined,
};
