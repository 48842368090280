/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Checkbox from 'components/Checkbox';
import InputLimit from 'components/InputLimit';
import Input from 'components/Input';
import { getValidations } from 'utils/helpers/reactFormValidations';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import { validateValueSubItems, updateSubitemCheck } from 'reducers/quoteInfo';
import { getRequestCapitalCalculation } from 'utils/helpers/businessCapital';
import { fetchOpenlCapital } from 'reducers/openlCapital';
import clsx from 'clsx';
import CONST_SCV from '../utils/constants/scv';
import percentaje from '../utils/helpers/percentaje';

const Accordeon = styled.div`
  width: 100%;
  visibility: ${(props) => (props.show ? 'visible' : 'collapse')};
  height: ${(props) => (props.show ? 'auto' : '0px')};
  transition: 0.5s;
  padding: 0 0 15px 0;
`;
Accordeon.displayName = 'AccordeonBox';

const SubCoverageItem = (props) => {
  const {
    item,
    shelterInfo,
    draftIndex,
    riskIndex,
    subCoverageValidate,
  } = props;
  const { control, errors, setValue, setError, trigger } = useFormContext();
  const [itemValue, setItemValue] = useState(null);
  const subcoverage = item.results[0][0];
  // esta con logica cambiada el initial check, 0 equivale a true!!
  const initialCheck = item.results[0][0].check === '0';
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(initialCheck);
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const basicData = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[6][0][1][0]
      : []
  );

  const returnService = useSelector((state) => state.quoteInfo.data);

  const selectedBusiness = useSelector(
    (state) => state.quoteInfo.data.business
  );

  const drafts = useSelector((state) =>
    state.openlConfig.data.results ? state.quoteInfo.data.drafts : []
  );

  const openlCoveragesSpecs = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[4][0][1]
      : []
  );

  const capitalCalculation = () => {
    const request = getRequestCapitalCalculation(
      {
        capitalMaximo: basicData.cotizacionNegocio,
        businessCode: selectedBusiness.answerCode,
        draft: drafts[draftIndex],
        templateCapital: drafts[draftIndex].risks,
        templateCapital2: drafts[draftIndex].policyRisks,
        idComponent: 'subItemCoverage',
      },
      openlCoveragesSpecs
    );
    const customHeaders = {
      authorization: `Bearer ${sessionInfoObj.token}`,
      authorizedip: sessionInfoObj.ip,
    };

    dispatch(fetchOpenlCapital(request, customHeaders, draftIndex));
  };
  const validateSubItemValue = (value) => {
    const { dependencia, codigoAmparo } = subcoverage;
    dispatch(
      validateValueSubItems({
        dependencia,
        codigoAmparo,
        value,
        draftIndex,
        riskIndex,
      })
    );
    capitalCalculation();
  };

  const validateSubItemsEl = (selected) => {
    const { dependencia, codigoAmparo } = subcoverage;
    dispatch(
      updateSubitemCheck({
        draftIndex,
        riskIndex,
        dependencia,
        codigoAmparo,
        selected,
      })
    );
  };

  useEffect(() => {
    const shelters = returnService.drafts[draftIndex].risks[riskIndex].amparos;
    if (shelters) {
      shelters.map((fatherItem) =>
        fatherItem.propiedadesAmparo.map((childItem) => {
          if (subcoverage.codigoAmparo == childItem.codigo) {
            if (childItem.selected == 1) {
              return setChecked(true);
            }
            return setChecked(false);
          }
        })
      );
    }
  }, []);

  useEffect(() => {
    setItemValue(shelterInfo.valor);
    setValue(`${item.results[0][0].codigoAmparo}`, shelterInfo.valor, {
      shouldValidate: true,
      shouldDirty: true,
    });
  }, [shelterInfo]);

  return (
    <>
      <Checkbox
        checkedProp={checked}
        changeProp={() => {
          setChecked(!checked);
          validateSubItemsEl(!checked);
          capitalCalculation();
          if (checked) {
            subCoverageValidate();
          }
        }}
        dontModify={item.results[0][0].modificableCapital === 'NO'}
      />
      <span className="flex-auto">{item.results[0][0].garantia}</span>
      {item.results[0][0].modificableCapital === 'NO' ? (
        <div className="mr-6 mt-3">
          <InputLimit value={shelterInfo.valor} />
        </div>
      ) : (
        checked && (
          <div className={clsx('mr-4 md:mr-12 mt-3')}>
            <Controller
              name={`${item.results[0][0].codigoAmparo}`}
              control={control}
              render={({ value }) => (
                <Input
                  placeholder="Valor"
                  errorMessage={
                    errors[item.results[0][0].codigoAmparo] &&
                    errors[item.results[0][0].codigoAmparo].message
                  }
                  onValueChange={({ floatValue }) => {
                    const { codigoAmparo } = item.results[0][0];
                    if (floatValue === 0) {
                      setError(codigoAmparo, {
                        shouldFocus: true,
                        type: 'required',
                      });
                      return;
                    }
                    setItemValue(floatValue);
                    const match = CONST_SCV.SCV_CHILDRENS.find(
                      (scvItem) => scvItem.code.toString() === codigoAmparo
                    );
                    if (match) {
                      match.childrens.forEach((child) => {
                        const mustToCalc = CONST_SCV.CALC.some(
                          (codigo) => parseInt(child, 10) === codigo
                        );
                        let porcentajeDefecto = null;
                        let valorMaximo = null;
                        if (mustToCalc) {
                          const childMatch = openlCoveragesSpecs.find(
                            (coverage) =>
                              coverage.results[0][0].codigoAmparo ===
                              child.toString()
                          );
                          if (childMatch) {
                            porcentajeDefecto = parseFloat(
                              childMatch.results[0][0].porcentajeDefecto
                            );

                            valorMaximo = parseFloat(
                              childMatch.results[0][0].valorMaximo
                            );
                          }
                        }
                        setValue(
                          `${child.toString()}`,
                          mustToCalc &&
                            porcentajeDefecto !== null &&
                            valorMaximo !== null
                            ? percentaje.percentajeFn(
                                porcentajeDefecto,
                                valorMaximo,
                                value
                              )
                            : floatValue,
                          {
                            shouldValidate: true,
                            shouldDirty: true,
                          }
                        );
                      });
                    }
                    setValue(`${codigoAmparo}`, floatValue, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    trigger(`${codigoAmparo}`);
                  }}
                  customOnMouseOut={() => {
                    if (!value || value === 0) return;
                    validateSubItemValue(value);
                  }}
                  positionLabel="top"
                  type="currency_dynamic"
                  value={value}
                />
              )}
              rules={getValidations({
                validacionMinMax: shelterInfo.validacionMinMax,
                obligatoriedad: '1',
                required: true,
              })}
            />
          </div>
        )
      )}
    </>
  );
};

export default SubCoverageItem;

SubCoverageItem.propTypes = {
  item: PropTypes.object.isRequired,
  shelterInfo: PropTypes.object.isRequired,
  draftIndex: PropTypes.string.isRequired,
  riskIndex: PropTypes.string.isRequired,
  subCoverageValidate: PropTypes.func.isRequired,
};
