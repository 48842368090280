/* eslint-disable camelcase */
import axios from 'axios';
import { getSessionHeaders } from 'utils/helpers/sessionData';

const baseAPI = process.env.REACT_APP_URL_EMIT;

// eslint-disable-next-line camelcase
const getProcesses = ({ broker_id, status, isNotification = false }) =>
  axios.post(
    `${baseAPI}/emission/index`,
    {
      data: { broker_id, status, isNotification },
    },
    { headers: getSessionHeaders() }
  );

const updateSeenNotifications = ({ emission_ids }) =>
  axios.post(
    `${baseAPI}/emission/seenNotification`,
    {
      emission_ids,
    },
    { headers: getSessionHeaders() }
  );

export default {
  getProcesses,
  updateSeenNotifications,
};
