import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import Input from 'components/Input';
import Select from 'components/Select';
import styled from 'styled-components';
import {
  toJSONValidation,
  addRulesValidation,
} from 'utils/helpers/reactFormValidations';
import PropTypes from 'prop-types';
import infoIcon from '../assets/icons/info_icon_new.svg';
/**
 * Helper text
 */
const HelperText = styled.span`
  color: var(--gray-400);
`;

const Address = ({ fields, setFilledAddress, children, currentRisk }) => {
  const {
    control,
    register,
    trigger,
    errors,
    clearErrors,
    setValue,
    getValues,
    setError,
  } = useFormContext();

  const [floorNumber, setFloorNumber] = useState(currentRisk.NumPiso || '');
  useEffect(() => {
    if (currentRisk.NumPiso) {
      setFloorNumber(currentRisk.NumPiso.value);
    }
  }, [currentRisk]);

  const numPisos = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[2][0][1][4].results[1][0][1]
      : []
  );

  const funcMap = {
    floorNumber,
    GET_NUMPISOS: numPisos,
  };
  const rules = addRulesValidation(
    fields[1].results[0][0].id,
    getValues,
    setError,
    clearErrors,
    fields[1].results[1][0][1]
  );
  return (
    <React.Fragment key="address-component">
      <div>
        <Input
          key={fields[0].results[0][0].id}
          placeholder={fields[0].results[0][0].descripcion}
          type={fields[0].results[0][0].tipo}
          name={fields[0].results[0][0].id}
          value={getValues(fields[0].results[0][0].id)}
          onChange={(event) => {
            clearErrors(fields[0].results[0][0].id);
            setValue(fields[0].results[0][0].id, event.target.value);
            trigger(fields[0].results[0][0].id);
          }}
          ref={register(toJSONValidation(fields[0].results[1][0][1]))}
          inputClass={fields[0].results[0][0].inputClass}
          controlled
          errorMessage={
            errors[fields[0].results[0][0].id] &&
            errors[fields[0].results[0][0].id].message
          }
          onBlur={setFilledAddress}
        />
        <HelperText className="text-xs">
          {fields[0].results[0][0].textoDeAyuda}
        </HelperText>
        <div>{children}</div>
      </div>

      <div className="flex">
        <div className="w-2/5 pr-2">
          <div className="flex space-x-1 grid-cols-3">
            <div className="w-2 mt-3">
              {' '}
              <span className="text-red-600	"> * </span>
            </div>

            <Controller
              key={`controller-${fields[1].results[0][0].id}`}
              render={() => (
                <Select
                  name={fields[1].results[0][0].id}
                  placeholder={fields[1].results[0][0].descripcion}
                  options={funcMap[fields[1].results[0][0].options]}
                  onValueChange={(value) => {
                    setValue(fields[1].results[0][0].id, value);
                    trigger(fields[1].results[0][0].id);
                  }}
                  optionKey={fields[1].results[0][0].optionKey}
                  optionLabel={fields[1].results[0][0].optionLabel}
                  errorMessage={
                    errors[fields[1].results[0][0].id] &&
                    errors[fields[1].results[0][0].id].message
                  }
                  valueDefault={currentRisk[fields[1].results[0][0].id]}
                />
              )}
              name={fields[1].results[0][0].id}
              control={control}
              defaultValue={null}
              rules={toJSONValidation(rules)}
              valueDefault={currentRisk[fields[1].results[0][0].id]}
            />
            <img
              className="h-4 w-4 cursor-pointer"
              src={infoIcon}
              alt="info"
              title="info"
            />

            <div className="tooltip_certificate">
              <div className="container_tooltip">
                <div className="tooltip_info">
                  <span>Número de pisos donde se encuentra el riesgo</span>
                </div>
                <div className="triangulo" />
              </div>
            </div>
          </div>
        </div>
        <div className="w-3/5">
          <div>
            <Input
              key={fields[2].results[0][0].id}
              placeholder={fields[2].results[0][0].descripcion}
              type={fields[2].results[0][0].tipo}
              name={fields[2].results[0][0].id}
              value={getValues(fields[2].results[0][0].id)}
              onChange={(event) => {
                clearErrors(fields[2].results[0][0].id);
                setValue(fields[2].results[0][0].id, event.target.value);
                trigger(fields[2].results[0][0].id);
              }}
              ref={register(toJSONValidation(fields[2].results[1][0][1]))}
              inputClass={fields[2].results[0][0].inputClass}
              controlled
              errorMessage={
                errors[fields[2].results[0][0].id] &&
                errors[fields[2].results[0][0].id].message
              }
            />
            <HelperText className="text-xs">
              <HelperText className="text-xs">
                {fields[2].results[0][0].textoDeAyuda}
              </HelperText>
            </HelperText>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Address;

/**
 * Field
 */
const Field = PropTypes.shape();

/**
 * PropsTypes
 */
Address.propTypes = {
  /**
   * Fields array
   * @param {arrayOf} fields
   */
  fields: PropTypes.arrayOf(Field),
  /**
   * @param {func} raiseAddressFilled
   */
  setFilledAddress: PropTypes.func,
  /**
   * @param {string} filledAddress
   */
  /**
   * @param {object} children
   */
  children: PropTypes.object,
  /**
   * @param {object} currentRisk
   */
  currentRisk: PropTypes.object,
};

/**
 * Default props
 */
Address.defaultProps = {
  /**
   * Fields array
   * @param {arrayOf} fields
   */
  fields: [],
  setFilledAddress: PropTypes.func,
  children: PropTypes.object,
  currentRisk: PropTypes.object,
};
