export const FETCH_INFO_EMIT_START = 'FETCH_INFO_EMIT_START';
export const FETCH_INFO_EMIT_SUCCES = 'FETCH_INFO_EMIT_SUCCES';
export const FETCH_INFO_EMIT_ERROR = 'FETCH_INFO_EMIT_ERROR';
export const UPDATE_ROLES_EMIT_INFO = 'UPDATE_ROLES_EMIT_INFO';
export const UPDATE_VIGENCY_DATE_EMIT_INFO = 'UPDATE_VIGENCY_DATE_EMIT_INFO';
export const UPDATE_CURRENT_TAB = 'UPDATE_CURRENT_TAB';
export const UPDATE_READY_EMISSION = 'UPDATE_READY_EMISSION';
export const UPDATE_POLICY_NUMBER_CARATULA = 'UPDATE_POLICY_NUMBER_CARATULA';
export const UPDATE_PROCESS_NUMBER_CARATULA = 'UPDATE_PROCESS_NUMBER_CARATULA';
export const UPDATE_POLICY_NUMBER_CERTIFICADO =
  'UPDATE_POLICY_NUMBER_CERTIFICADO';
export const UPDATE_POLICY_BARCODE = 'UPDATE_POLICY_BARCODE';
export const UPDATE_ADD_BENEFICIARY = 'UPDATE_ADD_BENEFICIARY';
export const READY_SQS = 'READY_SQS';
export const EMIT_SET_LOADING = 'EMIT_SET_LOADING';
