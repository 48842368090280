export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

export const isArrayEmpty = (arr) => {
  if (Array.isArray(arr)) {
    return arr.length <= 0;
  }
  return true;
};

export const groupObjectsByKey = (arr, key) => {
  if (arr && key) {
    const obj = {};
    arr.forEach((element) => {
      const k = `cod-${element[key]}`;
      if (obj[k]) {
        obj[k].push(element);
      } else {
        obj[k] = [element];
      }
    });
    return obj;
  }
  return null;
};

export const getNestedValue = (values, keys) => {
  let value = values;
  keys.forEach((key) => {
    value = value[key];
  });
  return value;
};
