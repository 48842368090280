import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const RadioContainer = styled.div`
  border: ${(props) =>
    props.select
      ? '2px solid var(--medium-teal)'
      : '1px solid var(--gray-300)'};
  position: absolute;
  bottom: ${(props) => props.bottom};
  left: ${({ left }) => left || 0}
  right: ${({ right }) => right || 0}
  margin: 0;
  padding: 0;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RadioSelect = styled.div`
  background-color: var(--medium-teal);
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2px;
  left: 2px;
  width: calc(${(props) => props.width} - 8px);
  height: calc(${(props) => props.height} - 8px);
  border-radius: 50%;
`;

const RadioButtonItem = (props) => {
  const { select, width, height, right, left, bottom, direction } = props;
  return (
    <RadioContainer
      select={select}
      width={width}
      height={height}
      right={right}
      left={left}
      bottom={bottom}
      direction={direction}
    >
      {select && <RadioSelect width={width} height={height} />}
    </RadioContainer>
  );
};

export default RadioButtonItem;

RadioButtonItem.propTypes = {
  select: PropTypes.bool.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  right: PropTypes.any.isRequired,
  left: PropTypes.any.isRequired,
  direction: PropTypes.string.isRequired,
  bottom: PropTypes.string.isRequired,
};
