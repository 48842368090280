import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { createDraft } from 'reducers/quoteInfo';
import Modal from 'components/Modal';
import Button from 'components/Button';
import duplicateBg from 'assets/icons/duplicate-bg.svg';

const H1 = styled.h1`
  color: var(--liberty-blue);
`;

export default function DuplicateQuote(props) {
  const { showModal, setShowModal, draft, quoteNumber, draftCount, quoteSaveReload } = props;

  const dispatch = useDispatch();

  const duplicate = () => {
    let drafNew = Object.assign({}, draft);
    drafNew.draftVersionId = `${quoteNumber}#${draftCount}`;
    dispatch(createDraft(drafNew));
    quoteSaveReload();
    setShowModal(false);
  };

  return (
    <Modal showModal={showModal} setShowModal={setShowModal} lg={false}>
      <div className="flex flex-col m-auto">
        <div className="relative w-20 h-20 m-auto bg-lightTeal rounded-full border border-solid border-teal">
          <img
            src={duplicateBg}
            alt="Duplicar cotización"
            className="w-12	absolute inset-0 m-auto"
          />
        </div>
        <H1 className="text-center text-2xl md:text-3xl font-medium w-full md:w-2/3 md:m-auto px-1">
          ¿Quieres duplicar la cotización?
        </H1>
        <div className="max-w-2xl w-full px-4 md:w-3/5 md:px-0 m-auto">
          <p className="text-center text-base font-medium mt-8 mb-4">
            Puedes generar una versión diferente de la propuesta actual y
            realizar los ajustes que necesites sin perder la información
            ingresada.
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-center my-4 px-4">
          <Button
            type="button"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            primary
            onClick={() => {
              duplicate();
            }}
          >
            Si, duplicar
          </Button>
        </div>
      </div>
    </Modal>
  );
}

/**
 * PropsTypes
 */
DuplicateQuote.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func,
  draft: PropTypes.shape({
    policyRisks: {
      amparos: PropTypes.arrayOf({}),
    },
  }),
  quoteNumber: PropTypes.string,
  draftCount: PropTypes.number,
};

/**
 * Default props
 */
DuplicateQuote.defaultProps = {
  setShowModal: undefined,
  draft: {
    policyRisks: {
      amparos: [],
    },
  },
  quoteNumber: '0',
  draftCount: 0,
};
