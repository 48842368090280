/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { SvgIcon } from '@material-ui/core';
import ModalWrapper from '../../../basics/modal/ModalWrapper';
import './ModalOnerous.scss';
import Input from '../../../basics/form/Input';
import TextArea from '../../../basics/form/TextArea';
import Select from '../../../basics/form/Select';
import Button from '../../../basics/form/Button';
import { OnerousRegisterOptions } from '../../../../../utils/constants/emit_constants';
import ModalSorry from '../ModalSorry';
import emitServices from '../../../../../services/emit';

import { ReactComponent as CautionBrownIcon } from '../../../../../assets/icons/caution-brown.svg';
import { ReactComponent as AlertIcon } from '../../../../../assets/icons/alert-custom.svg';

import { validateDocument } from '../../../../../utils/helpers/stringValidations';

const ModalOnerous = ({
  roleSelection,
  showModal,
  setContinueFormRoleInfo,
  setCloseModal,
  beneficiaryType,
  setLoading,
}) => {
  const [openModalSorry, setOpenModalSorry] = React.useState(false);
  const [infoSearched, setInfoSearched] = React.useState(
    roleSelection?.info?.businessName
  );
  const {
    control,
    register,
    errors,
    formState,
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      documentNumber: roleSelection.info.documentNumber,
      businessName: roleSelection.info.businessName,
      risk: roleSelection.info.risk,
      insuredValue: roleSelection.info.insuredValue,
      equipmentDetails: roleSelection.info.equipmentDetails,
    },
  });

  const searchInfoPeople = () => {
    const values = getValues();
    setLoading(true);
    emitServices.user
      .search({
        document_number: values.documentNumber,
        document_type: '37',
      })
      .then((response) => {
        const { userRestriction, roleData } = response.data.data;
        setLoading(false);
        if (userRestriction) {
          setOpenModalSorry(userRestriction);
        } else {
          setInfoSearched(roleData);
          setValue('businessName', roleData.businessName);
        }
      })
      .catch(() => {
        setInfoSearched(true);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleContinue = () => {
    if (!infoSearched) {
      searchInfoPeople();
    } else {
      const formValues = getValues();
      formValues.address = formValues?.risk?.address;
      setContinueFormRoleInfo(formValues);
    }
  };

  const roles = useSelector((state) => state.emitInfo?.data?.roles);

  const options = useSelector(
    (state) => state.emitInfo?.data?.quote_data?.risks
  )
    ?.map((riskItem, i) => {
      const riskAddress = riskItem.address;
      const riskCity = riskItem.locationInfo.nombreMunicipio;
      const address = `${riskAddress}, ${riskCity}`;
      const valueBuild = riskItem.insuredValue.VlaEdificio;
      const valueEquipment = riskItem.insuredValue.VlaEquipEle;
      const state = riskItem?.locationInfo?.nombreDepartamento;
      const floorNumber = riskItem?.locationInfo?.floorNumber;

      const beneficiaryBuildingValue =
        beneficiaryType === 'beneficiaryBuilding'
          ? `, Valor del Edificio: $${new Intl.NumberFormat('de-DE').format(
              valueBuild
            )}`
          : '';
      const name = `${address}${beneficiaryBuildingValue}`;
      return {
        id: riskAddress,
        name,
        address,
        city: riskCity,
        state,
        floorNumber,
        code: i,
        valueBuild,
        valueEquipment,
      };
    })
    .filter((item) =>
      beneficiaryType === 'beneficiaryBuilding'
        ? item.valueBuild &&
          !roles.some(
            (role) =>
              role.type === 'beneficiaryBuilding' &&
              role?.info?.risk?.code === item.code
          )
        : item.valueEquipment
    );

  const [maxInsuredValue, setMaxInsuredValue] = React.useState(1);
  const risk = watch('risk');
  const documentNumber = watch('documentNumber');
  const insuredValue = watch('insuredValue');

  const calcMaxInsuredValue = () => {
    if (!risk) {
      return null;
    }

    if (beneficiaryType === 'beneficiaryBuilding') {
      return risk.valueBuild;
    }

    const quotedValue = risk.valueEquipment;
    let maxValue = quotedValue;
    if (roles.find((role) => role?.info?.address === risk?.address)) {
      const requiredRoles = roles.filter(
        (role) =>
          role.type === beneficiaryType && role?.idCard !== roleSelection.idCard
      );
      const acumulatedValue = requiredRoles.reduce(
        (acc, role) => acc + parseInt(role?.info?.insuredValue, 10) || 0,
        0
      );
      maxValue = quotedValue - acumulatedValue;
    }
    return maxValue;
  };

  const handleEndClickModalSorry = () => {
    setOpenModalSorry(false);
    setCloseModal(true);
  };

  const handleAdjustModalClick = () => {
    setOpenModalSorry(false);
  };

  useEffect(() => {
    const maxValue = calcMaxInsuredValue();
    setMaxInsuredValue(maxValue);
  }, [risk, documentNumber]);

  const setInsuredValueRules = () => {
    register('insuredValue', {
      required: 'Es requerido',
      min: {
        value: 1,
        message: 'El valor es inferior al minimo permitido',
      },
      max: {
        value: maxInsuredValue,
        message: 'El valor es superior al ingresado en cotización',
      },
      maxLength: {
        value: 10,
        message: 'El valor no debe exceder los 10 dígitos',
      },
    });
  };

  const handleInsuredValueChange = (e) => {
    const value = e.target.value
      .split('.')
      .join('')
      .replace(/\$/g, '')
      .replace(/\s/g, '');
    trigger();
    if (value.length <= 10) {
      setInsuredValueRules();
      setValue('insuredValue', value, { shouldValidate: true });
    }
  };

  useEffect(() => {
    setInsuredValueRules();
    trigger();
  }, [maxInsuredValue]);

  useEffect(() => {
    setValue('insuredValue', roleSelection.info.insuredValue, {
      shouldValidate: true,
    });
  }, []);

  useEffect(() => {
    if (beneficiaryType === 'beneficiaryBuilding') {
      setValue('insuredValue', risk?.valueBuild, {
        shouldValidate: true,
      });
    }
  }, [risk]);

  useEffect(() => {
    trigger();
  }, []);

  const nextMaxInsuredValue = Math.max(
    maxInsuredValue - Number(insuredValue || 0),
    0
  );
  const isFirstMachinaryOnerous = maxInsuredValue === risk?.valueEquipment;

  const showFirstMaxLabel =
    risk && isFirstMachinaryOnerous && errors?.insuredValue?.type !== 'max';

  const showMaxLabelError = errors?.insuredValue?.type === 'max';

  const showNextMaxLabel =
    (maxInsuredValue || maxInsuredValue === 0) && !isFirstMachinaryOnerous;

  const showLastMaxLabel = risk && maxInsuredValue <= 0;

  const showFirstMaxLabelError =
    risk?.valueEquipment &&
    (isFirstMachinaryOnerous ? showMaxLabelError : true);

  const [docNumberValidationError, setDocNumberValidationError] = useState('');
  const docNumberValidationRules = {
    validate: (docNumber) => {
      const error = validateDocument(docNumber, '37');
      setDocNumberValidationError(error?.message);
      return !error;
    },
  };

  const ClassLabelError = showMaxLabelError
    ? 'text-red-500'
    : 'text-color-blue';
  return (
    <ModalWrapper
      showModal={showModal}
      setShowModal={setCloseModal}
      isModalForm
    >
      {openModalSorry && (
        <ModalSorry
          showModal={openModalSorry}
          onEndClick={handleEndClickModalSorry}
          onAdjustClick={handleAdjustModalClick}
        />
      )}
      <div className="dark-blue py-8 md:pb-12 px-3 md:max-w-3xl mx-auto ">
        <h3 className="text-center text-2xl mb-8 leading-7">
          {beneficiaryType === 'beneficiaryMachinery'
            ? `Información del
          beneficiario oneroso para equipo electrónico - maquinaria`
            : `Información del beneficiario oneroso del edificio`}
        </h3>
        <hr className="mb-3" />
        <p className="text-center mb-8 text-sm text-accent">
          Los campos con (*) son obligatorios
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-6">
          <Input placeholder="Tipo de identificación" disabled value="NIT" />
          <Input
            placeholder="Número de NIT*"
            disabled={
              roleSelection.info.documentNumber ||
              infoSearched?.documentNumber ||
              infoSearched
            }
            type="number"
            name="documentNumber"
            ref={register({
              ...OnerousRegisterOptions.documentNumber,
              ...docNumberValidationRules,
            })}
            controlled={false}
            errorMessage={
              errors.documentNumber &&
              (errors.documentNumber.message || docNumberValidationError)
            }
          />
        </div>
        {infoSearched && (
          <div className="mb-8 md:mb-16">
            <div className="mb-8">
              <Input
                className="mb-2"
                placeholder="Razón social*"
                type="text"
                name="businessName"
                ref={register(OnerousRegisterOptions.businessName)}
                controlled={false}
                errorMessage={
                  errors.businessName ? errors.businessName.message : ''
                }
                disabled={
                  roleSelection.info.businessName || infoSearched?.businessName
                }
              />
              <p className={`${errors.risk ? 'mb-6' : ''} text-sm text-black`}>
                {beneficiaryType === 'beneficiaryMachinery'
                  ? `Selecciona el riesgo
              en el que se encuentra ubicada la maquinaria o equipo electrónico`
                  : `Selecciona el riesgo que requiere el endoso sobre el edificio`}
                <span className="text-accent">*</span>
              </p>
              <Controller
                key="controller-documentType-infoPeople"
                as={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Select
                    label="Selecciona el riesgo que requiere el endoso sobre el edificio*"
                    placeholder="Seleccione el riesgo"
                    options={options}
                    optionKey="code"
                    optionLabel="name"
                    errorMessage={errors.risk && errors.risk.message}
                    disabled={roleSelection.info.risk}
                  />
                }
                name="risk"
                control={control}
                rules={OnerousRegisterOptions.risk}
                valueDefault={roleSelection.info.risk}
              />
            </div>
            {beneficiaryType === 'beneficiaryMachinery' && (
              <>
                <div>
                  <Input
                    name="insuredValue"
                    placeholder="Valor asegurado*"
                    type="currency"
                    value={insuredValue}
                    controlled
                    onChange={handleInsuredValueChange}
                    errorMessage={
                      errors.insuredValue && errors.insuredValue?.message
                    }
                  />
                  {showFirstMaxLabel && (
                    <p className="text-xs font-semibold text-gray-400">
                      {`Máximo $ ${new Intl.NumberFormat('de-DE').format(
                        maxInsuredValue
                      )}`}
                    </p>
                  )}
                  {showLastMaxLabel ? (
                    <p
                      className="text-xs font-semibold"
                      style={{ color: '#8C6945' }}
                    >
                      <SvgIcon fontSize="small" className="mr-1">
                        <CautionBrownIcon />
                      </SvgIcon>
                      Llegaste al monto máximo ingresado en cotización para
                      endosos
                    </p>
                  ) : (
                    showFirstMaxLabelError && (
                      <p
                        className={clsx(
                          'text-xs font-semibold m-0 mb-6 md:mt-0',
                          ClassLabelError
                        )}
                      >
                        <SvgIcon
                          style={{ fontSize: 15 }}
                          viewBox="0 0 13 13"
                          className="mr-1"
                        >
                          <AlertIcon />
                        </SvgIcon>
                        {`Valor ingresado equipo electrónico - maquinaria en cotización $${new Intl.NumberFormat(
                          'de-DE'
                        ).format(risk?.valueEquipment)}`}
                        {showNextMaxLabel &&
                          ` / Valor permitido para próximo endoso $ ${new Intl.NumberFormat(
                            'de-DE'
                          ).format(nextMaxInsuredValue)}`}
                      </p>
                    )
                  )}
                </div>
                <div className={`mb-6 ${errors.equipmentDetails && 'mt-6'}`}>
                  <TextArea
                    name="equipmentDetails"
                    type="text"
                    className="mt-3  h-32"
                    customLabel="Detalle de la maquinaria o equipo electrónico*"
                    controlled={false}
                    placeholder="Ingresa los detalles"
                    ref={register(OnerousRegisterOptions.equipmentDetails)}
                    errorMessage={
                      errors.equipmentDetails
                        ? errors.equipmentDetails.message
                        : ''
                    }
                  />
                  <p className="text-gray-400 text-sm">
                    Ejemplo: Marca, tipo, color, año de fabricación...
                  </p>
                </div>
              </>
            )}
          </div>
        )}

        <div className="md:flex md:justify-center">
          <Button
            className="m-0 my-2 w-full mr-4 md:w-32"
            onClick={setCloseModal}
          >
            Cancelar
          </Button>
          <Button
            className="w-full md:w-32"
            primary
            disabled={infoSearched ? !formState.isValid : errors.documentNumber}
            onClick={handleContinue}
          >
            {infoSearched ? 'Guardar' : 'Consultar'}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

ModalOnerous.propTypes = {
  roleSelection: PropTypes.object,
  showModal: PropTypes.bool.isRequired,
  setContinueFormRoleInfo: PropTypes.func.isRequired,
  setCloseModal: PropTypes.func.isRequired,
  beneficiaryType: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
};

ModalOnerous.defaultProps = {
  roleSelection: { info: {} },
};

export default ModalOnerous;
