import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import logo from '../../assets/images/logos/principal.png';
import unauthorizedImg from '../../assets/images/unauthorized.svg';

const Header = styled.header`
  background-color: var(--liberty-yellow);
  color: ${(props) => (props.primary ? 'var(--liberty-blue)' : 'initial')};
  padding: 20px;
`;
const Content = styled.div`
  background-color: var(--gray-100);
  padding: 20px;
  height: 100vh;
`;
const Button = styled.button`
  background-color: var(--medium-teal);
  padding: 10px;
  border-radius: 5px;
  margin: 10px auto;
  width: 80%;
  max-width: 200px;
`;

const Unauthorized = () => {
  const actualDate = moment();
  const actualYear = actualDate.format('YYYY');
  return (
    <>
      <Header>
        <img alt="logo" src={logo} />
      </Header>
      <Content>
        <div className="bg-white rounded py-4 flex flex-col max-h-16">
          <img
            src={unauthorizedImg}
            className="m-auto w-full max-w-md"
            alt="Liberty Seguros - Unauthorized"
          />
          <h3 className="text-blue text-center m-auto font-semibold mb-3 p-2">
            No tienes permiso para acceder a esta página
          </h3>
          <p className="text-gray text-lg font-normal w-2/3 m-auto text-center">
            Debes iniciar sesión desde la oficina en línea
          </p>
          <Button>
            <a
              href="https://oficinaenlinea.libertycolombia.com"
              className="text-white"
            >
              Ir a oficina en línea
            </a>
          </Button>
        </div>
        <p className="text-center mt-4 border-t border-gray-300 p-4 text-gray-500">
          {`Liberty ${actualYear}. Todos los derechos reservados`}
        </p>
      </Content>
    </>
  );
};

export default Unauthorized;
