import React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import RouteValidator from 'navigation/RouteValidator';
import mainStore from './store';
import 'animate.css';

export default function App() {
  return (
    <Provider store={mainStore}>
      <Router>
        <Switch>
          <Route exact path="/" component={RouteValidator} />
        </Switch>
      </Router>
    </Provider>
  );
}
