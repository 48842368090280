import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import arrowDown from 'assets/icons/arrow-down.svg';
import caution from 'assets/icons/caution.svg';

/**
 * Select container
 */
const Container = styled.div`
  border-color: var(--gray-300);
  color: var(--gray-600);
  width: 100%;
  min-height: 2.8rem;

  &.error {
    border-color: red;
  }

  .error-label {
    position: absolute;
    top: -20px;
    color: red;
    width: initial;
  }

  .drop-down-header:focus {
    outline: none;
  }

  .option-selected ~ label {
    top: 0.25rem;
    font-size: 12px;
    color: var(--gray-400);
  }

  #list button.active,
  #list button:hover {
    background-color: var(--light-teal);
    font-weight: 500;
  }

  .disabled {
    color: var(--gray-400);
    pointer-events: none;
  }
`;

/**
 * Placeholder
 */
const Label = styled.label`
  left: 1rem;
  top: 0.75rem;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  width: calc(100% - 3rem);
`;

/**
 * Arrow down icon
 */
const Image = styled.img`
  position: absolute;
  right: 1rem;
  top: 1.3rem;
`;
const Span = styled.span`
  display: block;
  width: 360px;
`;

const List = styled.div`
  max-height: 168px;
`;

/**
 * Select component
 * @param {*} props
 */
const Select = (props) => {
  const {
    disabled,
    placeholder,
    options,
    valueDefault,
    onChange = (e) => e,
    optionKey,
    optionLabel,
    errorMessage,
    onValueChange = (e) => e,
  } = props;

  const [optionSelected, setOptionSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setOptionSelected(valueDefault);
  }, [valueDefault]);

  /**
   * Show list
   */
  const toggling = () => {
    setIsOpen(!isOpen);
  };

  /**
   * Call function props
   * @param {*} option
   */
  const onOptionClicked = (option) => () => {
    setIsOpen(false);
    setOptionSelected(option);
    onChange(option);
    onValueChange(option);
  };

  const list = options.map((option) => (
    <button
      key={option[optionKey]}
      type="button"
      className={`text-left list-none p-4 ${
        optionSelected === option ? 'active' : ''
      }`}
      onClick={onOptionClicked(option)}
    >
      {option[optionLabel]}
    </button>
  ));

  return (
    <React.Fragment key="select">
      <Container
        data-testid="custom-select"
        className={`h-4 relative box-border border border-solid rounded text-base ${
          errorMessage && 'error bg-red-100'
        }`}
      >
        {errorMessage && (
          <div className="error-label flex text-xs">
            <img className="w-4 mr-1" src={caution} alt="icon caution" />
            <Span>{errorMessage}</Span>
          </div>
        )}
        <button
          data-testid="button-toggling"
          type="button"
          className={`drop-down-header w-full mt-3 pt-2 px-4 bg-transparent text-left ${
            optionSelected ? 'option-selected' : ''
          } ${disabled ? 'disabled' : ''} ${!isOpen ? 'h-8' : 'h-4'}`}
          disabled={disabled}
          onClick={toggling}
        >
          {optionSelected?.[optionLabel]}
          <Image src={arrowDown} alt="arrow" />
        </button>
        <Label className="absolute pointer-events-none text-base truncate">
          {placeholder}
        </Label>
        {isOpen && (
          <List
            id="list"
            className={`flex flex-col absolute w-full z-50 border shadow-lg bg-white overflow-auto ${
              optionSelected ? 'my-0' : 'my-2'
            }`}
          >
            {list}
          </List>
        )}
      </Container>
    </React.Fragment>
  );
};

export default Select;

/**
 * Option proptypes
 */
const Option = PropTypes.shape({
  /**
   * Label
   */
  label: PropTypes.string,
  /**
   * Value
   */
  value: PropTypes.string,
});

/**
 * PropsTypes
 */
Select.propTypes = {
  /**
   * Value default
   * @param {{}} valueDefault
   */
  valueDefault: Option,
  /**
   * Disabled
   * @param {bool} disabled
   */
  disabled: PropTypes.bool,
  /**
   * Placeholder
   * @param {string} placeholder
   */
  placeholder: PropTypes.string,
  /**
   * Options
   * @param {array} options
   */
  options: PropTypes.arrayOf(Option),
  /**
   * Action call
   * @param {func} onChange
   */
  onChange: PropTypes.func,
  /**
   * Key
   * @param {string} optionKey
   */
  optionKey: PropTypes.string,
  /**
   * Label
   * @param {string} optionLabel
   */
  optionLabel: PropTypes.string,
  /**
   * Name
   * @param {string} errorMessage
   */
  errorMessage: PropTypes.string,
  /**
   * Name
   * @param {func} onValueChange
   */
  onValueChange: PropTypes.func,
};

/**
 * Default props
 */
Select.defaultProps = {
  /**
   * Default value
   * @param {{}} valueDefault
   */
  valueDefault: undefined,
  /**
   * Disabled
   * @param {bool} disabled
   */
  disabled: false,
  /**
   * Placeholder
   * @param {string} placeholder
   */
  placeholder: '',
  /**
   * Options
   * @param {array} options
   */
  options: [],
  /**
   * Action call
   * @param {func} onChange
   */
  onChange: () => {
    // do nothing
  },
  /**
   * Key
   * @param {string} optionKey
   */
  optionKey: '',
  /**
   * Label
   * @param {string} optionLabel
   */
  optionLabel: '',
  /**
   * errorMessage
   * @param {string} errorMessage
   */
  errorMessage: '',
  /**
   * errorMessage
   * @param {string} onValueChange
   */
  onValueChange: () => {
    // do nothing
  },
};
