import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import close from 'assets/icons/close.svg';

const ModalWrapper = ({
  showModal,
  setShowModal,
  showButtonClose,
  children,
  size,
  isModalForm,
  className,
}) => {
  const handleShowModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  return createPortal(
    <>
      {showModal && (
        <>
          <div className="modalContainer-background fixed inset-0 z-40" />
          <div
            className={`fixed inset-0 z-50 overflow-x-hidden overflow-y-auto ${
              !isModalForm && 'md:flex md:justify-center md:items-center'
            } ${className}`}
          >
            <div
              className={`bg-white rounded-sm shadow-lg relative max-w-${size} w-11/12 mx-auto my-3 animate__animated animate__fadeIn`}
            >
              {showButtonClose && (
                <button
                  type="button"
                  className="absolute right-0 top-0 pr-4 pt-4 outline-none focus:outline-none"
                  onClick={handleShowModal}
                >
                  <img className="w-4" src={close} alt="close" />
                </button>
              )}
              {children}
            </div>
          </div>
        </>
      )}
    </>,
    document.getElementById('ModalWrapperPortal ')
  );
};

export default ModalWrapper;

ModalWrapper.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  showButtonClose: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  size: PropTypes.string,
  className: PropTypes.string,
};

ModalWrapper.defaultProps = {
  showModal: false,
  setShowModal: undefined,
  showButtonClose: true,
  size: '6xl',
  className: '',
};
