/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */

import React, { useEffect, useState } from 'react';
// libraries
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { fetchOpenlCapital, updateFocusedDraft } from 'reducers/openlCapital';
import {
  updateDraft,
  createRisk,
  removeRisk,
  fetchQuote,
  updateDraftCount,
  constraintTableUpdate,
  createUpdateBusiness,
} from 'reducers/quoteInfo';
// components
import ConfirmationDeleteRisk from 'layouts/modals/confirmationDeleteRisk';
import ModalEmit from 'components/emit_components/collections/ModalEmit';
import ModalValidateDate from 'components/emit_components/collections/modals/ModalValidateDate';
import AlertConfirm from 'layouts/modals/AlertsConfirm';
import AdditionalCoverage from 'components/AdditionalCoverage';
import Card from 'components/Card';
import DashedCard from 'components/DashedCard';
import Tooltip from 'components/Tooltip';
import Button from 'components/Button';
import moment from 'moment';
import momentTZ from 'moment-timezone';


import 'moment/locale/es';
import DuplicateQuote from 'layouts/modals/DuplicateQuote';
// utils
import { formatPrice } from 'utils/helpers/normalizeValues';
import {
  getRequestCapitalCalculation,
  // getTemplateRequest,
  getValuesRisks,
  getValuesPropertiesRisks,
  getValuesPropertiesRisksSIG,
  getValuesAmparos,
} from 'utils/helpers/businessCapital';
import generateCluasesData from 'utils/helpers/clauses';
import {
  groupObjectsByKey,
  isArrayEmpty,
  isObjectEmpty,
} from 'utils/helpers/object';
import pdfService from 'utils/helpers/pdfService';
// HOTFIX BLOCKED PARAMS ITCON
import { filterRisksAmparos } from 'utils/helpers/filterRecords';
import { filterVariableIndex } from 'utils/helpers/filterVariableIndex';
import { filterUnselectedCoverages } from 'utils/helpers/filterUnselectedCoverages';

// assets
import imgMap from 'assets/images/map.png';
import trash from 'assets/icons/delete.svg';
import plus from 'assets/icons/plus.svg';
import duplicate from 'assets/icons/duplicate.svg';
import mail from 'assets/icons/mail.svg';
import pdf from 'assets/icons/pdf.svg';
import arrowDown from 'assets/icons/arrow-down.svg';
import checkCircle from 'assets/icons/check-circle.svg';
import pdfConfirmIcon from 'assets/icons/pdf_confirm.svg';
import {
  filterRestrictedSubCoverages,
  filterRestrictedPDFCoverages,
  filterRestrictedCoveragesData,
} from 'utils/helpers/activityRestrictionFilters';

// reducers
import { callPdfQuote, getPdfQuote, updateBodyPdf } from 'reducers/pdfQuote';
import { fetchOpenlPricing } from 'reducers/openlPricing';
import {
  fetchCoordinates,
  creaeteNewRisk,
  updateRiskDraft,
  SaveDuplicateDraft,
} from 'reducers/coordinates';
import initEmit from 'emit_store/actions/emitInfo';
import { v4 as uuidv4 } from 'uuid';
import { deleteRiskByIdQuote } from 'services/quotes';
import openlServices from '../../services/openl_logs';
import TEMPLATE_SIG from 'utils/constants/templateSIG';

momentTZ.tz.setDefault('America/Bogota');
const isProdEnv = process.env.REACT_APP_IS_PROD === 'true';

const Slider = styled.input`
  -webkit-appearance: none;
  border-radius: 5px;
  background-color: #f0fbfc;
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    box-sizing: content-box;
    border: 3px solid
      ${(props) =>
    props.valPoliza === 'true' ? 'var(--error)' : 'var(--teal)'};
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: ${(props) =>
    props.valPoliza === 'true' ? '#FFF4F5' : 'var(--teal)'};
    cursor: ew-resize;
    margin-top: -4px;
  }

  &::-moz-range-thumb {
    box-sizing: content-box;
    border: 3px solid
      ${(props) =>
    props.valPoliza === 'true' ? 'var(--error)' : 'var(--teal)'};
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: ${(props) =>
    props.valPoliza === 'true' ? '#FFF4F5' : 'var(--teal)'};
    cursor: ew-resize;
    margin-top: -4px;
  }

  &::-ms-thumb {
    box-sizing: content-box;
    border: 3px solid
      ${(props) =>
    props.valPoliza === 'true' ? 'var(--error)' : 'var(--teal)'};
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: ${(props) =>
    props.valPoliza === 'true' ? '#FFF4F5' : 'var(--teal)'};
    cursor: ew-resize;
    margin-top: -4px;
  }

  &::-webkit-slider-runnable-track {
    height: 3px;
    border-color: transparent;
    color: transparent;
    border-radius: 5px;
  }

  &::-webkit-slider-runnable-track {
    background: linear-gradient(
      90deg,
      ${(props) =>
    props.valPoliza === 'true' ? 'var(--error)' : 'var(--teal)'}
        ${(props) => props.porcentajePoliza}%,
      rgba(26, 20, 70, 0.23) 1%
    );
  }

  &::-moz-range-track {
    height: 3px;
    background-color: rgba(26, 20, 70, 0.23);
  }
  &::-moz-range-progress {
    background-color: ${(props) =>
    props.valPoliza === 'true' ? 'var(--error)' : 'var(--teal)'};
  }
  &:focus {
    outline: none;
  }
  &::-ms-track {
    height: 3px;
    cursor: pointer;
    border-color: transparent;
    color: transparent;
    background-color: transparent;
  }
  &::-ms-fill-lower {
    background-color: #5082e0;
  }
  &::-ms-tooltip {
    display: none;
  }
`;

const Tab = styled.button`
  height: 64px;
  width: 206px;

  &.active {
    border-radius: 3px 3px 0 0;
    background-color: var(--white);
    box-shadow: 0 0 4px 0 rgba(52, 55, 65, 0.25);
    border-bottom: 3px solid var(--medium-teal);
  }

  &:focus {
    outline: none;
  }

  .version {
    top: 0.5rem;
    right: 0.5rem;
  }
`;

const Risks = (props) => {
  const {
    setDraft,
    draftIndex,
    setDraftIndex,
    riskIndex,
    setRiskIndex,
    setRisk,
    setEmitRoute,
  } = props;
  const [deleteModal, setDeleteModal] = useState(false);
  const [indexRiskToDelete, setIndexRiskToDelete] = useState(null);
  const [detailCapitalOpen, setDetailCapitalOpen] = useState(false);
  const [deductibles, setDeductibles] = useState(null);
  const [showDuplicateTooltip, setShowDuplicateTooltip] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAlertPdf, setShowAlertPdf] = useState(false);
  const [showConfirmPdf, setShowConfirmPdf] = useState(false);

  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };

  const selectedBusiness = useSelector(
    (state) => state.quoteInfo.data.business
  );
  const versionCodi = useSelector(
    (state) =>
      state.openlConfig?.data?.results &&
      state.openlConfig.data.results[6][0][1][0].versionCodi
  );
  const coordinates = useSelector((state) => state.coordinates.data?.value);
  const pdfData = useSelector((state) => state.pdfQuote);
  const fetchQuoteLoading = useSelector((state) => state.quoteInfo.loading);
  const openlPricing = useSelector(
    (state) =>
      state.openlPricing?.data?.results && state.openlPricing.data.results[1]
  );
  const drafts = useSelector((state) => state.quoteInfo.data.drafts);
  const quoteNumber = useSelector((state) => state.quoteInfo.data.quoteNumber);
  const compDate = useSelector((state) => state.quoteInfo.data.compDate);
  const quoteInfoDate = useSelector((state) => state.quoteInfo.data.Date);
  const takerDataRedux = useSelector((state) =>
    state.quoteInfo.data.taker ? state.quoteInfo.data.taker : []
  );
  const draft = drafts[draftIndex];
  const dispatch = useDispatch();
  const history = useHistory();
  const openlCapitalData = useSelector((state) =>
    state.openlCapital.data[draftIndex]
      ? state.openlCapital.data[draftIndex].results[0][1]
      : []
  );
  const templatePricingRisks = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[10][0][1]
      : []
  );
  const templatePricingPropertiesRisks = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[11][0][1]
      : []
  );
  const templatePricingPropertiesRisksSIG = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[12][0][1]
      : []
  );
  const templatePricingAmparos = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[13][0][1]
      : []
  );
  const categories = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[0][0][1]
      : []
  );
  const transportTypeList = useSelector((state) => {
    if (!state.openlConfig.data.results) {
      return [];
    }
    const found = state.openlConfig.data.results[4][0][1].find(
      (amparo) => amparo.results[0][0].codigoAmparo === '8894'
    );
    return found || [];
  });

  const getCalculoPricing = async () => {
    if (compDate) {
      // ITCON change const
      let dataRequest;
      const counter = compDate.split('#', 2);
      // eslint-disable-next-line
      const dateFormat = moment(parseInt(counter[1])).format('DD/MM/YYYY');

      if (dateFormat === moment().format('DD/MM/YYYY')) {
        dataRequest = moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
      } else {
        // eslint-disable-next-line
        dataRequest = moment(parseInt(counter[1])).format(
          'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
        );
      }
      let json = {
        runtimeContext: {
          currentDate: dataRequest,
          requestDate: '2020-10-20T00:00:00.000Z',
        },
        poliza: {
          idTransaccion: '11849032',
          producto: '10024',
          danosTotalPrima: '0',
          gastosExpedicion: '0',
          totalPrima: '0',
          riesgos: [],
        },
      };

      if (
        !isObjectEmpty(draft) &&
        !isArrayEmpty(templatePricingRisks) &&
        !isObjectEmpty(selectedBusiness)
      ) {
        draft.risks.forEach((risk, index) => {
          const res = getValuesRisks(risk, templatePricingRisks);
          res.propiedadesRiesgo = [
            ...getValuesPropertiesRisks(
              {
                ...risk,
                business: selectedBusiness,
              },
              templatePricingPropertiesRisks
            ),
            ...getValuesPropertiesRisksSIG(
              coordinates,
              templatePricingPropertiesRisksSIG
            ),
          ];
          res.amparos = [];
          res.amparos = getValuesAmparos({
            policyRisks: draft.policyRisks,
            risk,
            indexRisk: index,
            templateAmparos: templatePricingAmparos,
          });
          json.poliza.riesgos.push(res);
        });

        json = filterRisksAmparos(json);
        json = filterVariableIndex(json);
        json = filterUnselectedCoverages(json, draft);
        json = filterRestrictedSubCoverages(json, draft);
        dispatch(fetchOpenlPricing(json, customHeaders));
      }
    }
  };

  const values = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[6][0][1][0]
      : []
  );
  const openlCoveragesSpecs = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[4][0][1]
      : []
  );

  /* useEffect(() => {
    const dataDraft = drafts.map(() => ({
      risks: drafts[draftIndex]?.risks.map(() => ({})),
    }));
    dispatch(SaveDuplicateDraft({ dataDraft }));
  }, []); */

  /* useEffect(() => {
    const arr = drafts[draftIndex]?.risks.map((x) => ({}));
    dispatch(updateRiskDraft({ draftIndex, arr }));
  }, []); */

  useEffect(() => {
    const request = getRequestCapitalCalculation(
      {
        capitalMaximo: values.cotizacionNegocio,
        businessCode: selectedBusiness.answerCode,
        draft: drafts[draftIndex],
        templateCapital: drafts[draftIndex].risks,
        templateCapital2: drafts[draftIndex].policyRisks,
        idComponent: 'subItemCoverage',
      },
      openlCoveragesSpecs
    );
    dispatch(fetchOpenlCapital(request, customHeaders, draftIndex));
  }, []);

  useEffect(() => {
    dispatch(
      updateDraftCount({
        draftCount: drafts.length,
      })
    );
  }, [drafts.length]);

  const quoteSaveReload = () => {
    dispatch(fetchQuote(null, customHeaders));
    if (sessionStorage.getItem('openlLogsRequestResponse') !== null) {
      const dataOpenL = JSON.parse(
        sessionStorage.getItem('openlLogsRequestResponse')
      );
      const { drafts: draftsData } = dataOpenL;
      const dataDraft = draftsData[draftIndex];
      dataOpenL.drafts = dataDraft;
      openlServices.createOpenlLogs(dataOpenL);
    }
  };
  const callOpenlCapital = () => {
    const request = getRequestCapitalCalculation(
      {
        capitalMaximo: drafts[draftIndex].capitalMaximo,
        businessCode: selectedBusiness.answerCode,
        draft: drafts[draftIndex],
        templateCapital: drafts[draftIndex].risks,
        templateCapital2: drafts[draftIndex].policyRisks,
        idComponent: 'subItemCoverage',
      },
      openlCoveragesSpecs
    );
    dispatch(fetchOpenlCapital(request, customHeaders, draftIndex));
  };
  const getValueByCode = (properties, code) => {
    return properties.find((prop) => prop.codigo == code)?.valor;
  };
  const getPdf = async () => {
    // START GENERATE PDF
    dispatch(getPdfQuote());
    const deductiblesRisk = drafts[draftIndex].risks[0].deductibles;
    const listDeductibles = [];
    for (const keyDeducicle of Object.keys(deductiblesRisk)) {
      if (keyDeducicle !== 'cod-3491') {
        const shemaDeductible = {
          name: `${deductiblesRisk[
            keyDeducicle
          ][0].idPdfRiesgo.toUpperCase()}: `,
          value: deductiblesRisk[keyDeducicle][0].desDeducible,
        };
        listDeductibles.unshift(shemaDeductible);
      } else {
        const shemaDeductible = {
          name: `DEDUCIBLE PARA LOS DEMÁS AMPAROS: `,
          value: deductiblesRisk[keyDeducicle][0].desDeducible,
        };
        listDeductibles.push(shemaDeductible);
      }
    }
    for (const deductible of deductibles) {
      if (deductible.amparoId) {
        const shemaDeductible = {
          name: `${deductible.amparoName.toUpperCase()}: `,
          value: deductible.desDeducible,
        };
        listDeductibles.push(shemaDeductible);
      }
    }

    const stopperBusiness = drafts[draftIndex].policyRisks.amparos[1];
    const RC = drafts[draftIndex].policyRisks.amparos[2];
    const mercancyTransport = drafts[draftIndex].policyRisks.amparos[3];
    const transportMoney = drafts[draftIndex].policyRisks.amparos[4];
    let RCProductVar = null;

    const assistanceTotal = draft.risks.find((riskElement) => {
      const covAsistenciaLibertyPYME = riskElement.amparos.find(
        (cov) => cov.codigoAmparo === '8882'
      );
      if (!covAsistenciaLibertyPYME) return false;

      return covAsistenciaLibertyPYME.selected == 1;
    });

    const assistanceIncluded = assistanceTotal ? 'INCLUIDO' : null;
    if (RC.selected === '1') {
      if (RC.propiedadesAmparo[9].selected === '1') {
        RCProductVar = '15% Evento / 30% Vigencia';
      }
    }
    let mercancyTransportName = null;
    let lucroValue = null;
    let expensesValue = null;
    if (mercancyTransport.selected === '1') {
      const mercancyTransportCode = String(
        getValueByCode(mercancyTransport.propiedadesAmparo, 7997)
      );
      const mercancyTransportItem = transportTypeList.results[3][0][1][0].results[2][0][1].find(
        (item) => item.codigo === mercancyTransportCode
      );
      mercancyTransportName = mercancyTransportItem?.respuesta;

      if (mercancyTransport.propiedadesAmparo[2].selected === '1') {
        expensesValue = '5%';
      }
      if (mercancyTransport.propiedadesAmparo[3].selected === '1') {
        lucroValue = '5%';
      }
    }
    const fraud = drafts[draftIndex].policyRisks.amparos[0];
    let nameTaker;
    if (takerDataRedux.selectedOption.value === '37') {
      nameTaker = takerDataRedux.RazSocial;
    } else {
      nameTaker = `${takerDataRedux.PrimNombre} ${takerDataRedux.PrimApellido}`;
    }
    const coveragesData = [
      {
        codigoAmparo: '8900',
        name: 'Incendio e impacto directo de rayo',
        value: `$${formatPrice(openlCapitalData.sumaTotalIncendio)}`,
      },
      {
        codigoAmparo: '8905',
        name: 'Explosión',
        value: `$${formatPrice(openlCapitalData.sumaTotalExplocion)}`,
      },
      {
        codigoAmparo: '8921',
        name: 'Daños por agua',
        value: `$${formatPrice(openlCapitalData.sumaTotalDanos)}`,
      },
      {
        codigoAmparo: '8940',
        name: 'Anegación',
        value: `$${formatPrice(openlCapitalData.sumaTotalAnegacion)}`,
      },
      {
        codigoAmparo: '8945',
        name: 'Huracán, vientos fuertes, granizo, impacto y humo',
        value: `$${formatPrice(openlCapitalData.sumaTotalHuracan)}`,
      },
      {
        codigoAmparo: '8950',
        name:
          'Actos mal intencionados de terceros, huelga, motín conmoción civil o popular, asonada y terrorismo',
        value: `$${formatPrice(openlCapitalData.sumaTotalActos)}`,
      },
      {
        codigoAmparo: '8857',
        name: 'Terremoto, maremoto o tsunami, temblor o erupción volcánica',
        value: `$${formatPrice(openlCapitalData.sumaTotalTerremoto)}`,
      },
      {
        codigoAmparo: '8862',
        name: 'Sustracción con violencia',
        value: `$${formatPrice(openlCapitalData.sumaTotalSustraccionCon)}`,
      },
      {
        codigoAmparo: '8866',
        name: 'Sustracción sin violencia',
        value: `$${formatPrice(openlCapitalData.sumaTotalSustraccionSin)}`,
      },
      {
        codigoAmparo: '8869',
        name: 'Daño interno',
        value: `$${formatPrice(openlCapitalData.sumaTotalDanoInterno)}`,
      },
      {
        codigoAmparo: '8871',
        name: 'Cobertura fuera de predios para bienes asegurados',
        value: `$${formatPrice(openlCapitalData.sumaTotalCobertura)}`,
      },
      {
        codigoAmparo: '8874',
        name: 'Bienes refrigerados',
        value: `$${formatPrice(openlCapitalData.sumaTotalBienes)}`,
      },
      {
        codigoAmparo: '8876',
        name: 'Robo a clientes',
        value: `$${formatPrice(openlCapitalData.sumaTotalRobos)}`,
      },
      {
        codigoAmparo: '8877',
        name: 'Dinero en local',
        value: `$${formatPrice(openlCapitalData.sumaTotalDinero)}`,
      },
      {
        codigoAmparo: '8879',
        name: 'Gastos de arrendamiento o arrendamiento dejado de percibir',
        value: `$${formatPrice(openlCapitalData.sumaTotalGastos)}`,
      },
      fraud.selected === '1'
        ? {
          codigoAmparo: '8880',
          name: 'Fraude de empleados',
          value: `$${formatPrice(openlCapitalData.sumaTotalFraude)}`,
        }
        : {
          codigoAmparo: '',
          name: null,
          value: null,
        },
      stopperBusiness.selected === '1'
        ? {
          codigoAmparo: '8881',
          name: 'Gastos por parálisis del negocio',
          value: `$${formatPrice(openlCapitalData.sumaTotalParalisis)}`,
        }
        : {
          codigoAmparo: '',
          name: null,
          value: null,
        },
      {
        codigoAmparo: '8882',
        name: 'Asistencia en PYME',
        value: assistanceIncluded,
      },
    ];

    const clausesData = await generateCluasesData(
      draft,
      coveragesData,
      RC,
      selectedBusiness,
      mercancyTransportName,
      transportMoney.propiedadesAmparo[0].valor
    );
    let body = {
      quoteNumber,
      paymentData: {
        prima: `$${formatPrice(openlPricing.primaNeta)}`,
        iva: `$${formatPrice(openlPricing.iva)}`,
        total: `$${formatPrice(openlPricing.totalNegocio)}`,
      },
      ...pdfData.requestBody,
      business: {
        code: selectedBusiness.answerCode,
        name: selectedBusiness.name,
      },
      taker: {
        docType: takerDataRedux.selectedOption.value,
        docNumber: takerDataRedux.NumIdentificacion,
        name: nameTaker,
      },
      clausesData,
      renderCivilLiability: RC.selected == '1',
      renderAutomaticTransport: mercancyTransport.selected == '1',
      renderFraud: fraud.selected == '1',
      renderMoneyTransport: transportMoney.selected == '1',
      renderStopperBusiness: stopperBusiness.selected == '1',
      assistanceTotal,
      coveragesData: filterRestrictedCoveragesData(coveragesData, draft.risks),
      civilLiability: [
        {
          name: 'Amparo básico daños y perjuicios a terceros',
          value: `$${formatPrice(RC.valorRiesgo)}`,
        },
        {
          name: 'Daños y perjuicios a terceros',
          value: '100% Evento / 100% Vigencia',
        },
        {
          name: 'Gastos médicos primarios',
          value: '10% Evento / 10% Vigencia, máximo por persona 5 smmlv',
        },
        {
          name: 'Gastos de defensa judicial en proceso civil',
          value: '5% Evento / 10% Vigencia',
        },
        {
          name: 'Contratistas y subcontratistas al servicio del asegurado',
          value: '50% Evento /50% Vigencia',
        },
        {
          name: 'Responsabilidad civil cruzada',
          value: '50% Evento /50% Vigencia',
        },
        {
          name: 'Responsabilidad civil patronal',
          value: '50% Evento /50% Vigencia',
        },
        {
          name: 'Responsabilidad civil vehículos propios y no propios',
          value: '50% Evento /50% Vigencia',
        },
        {
          name: 'Bienes bajo cuidado tenencia o control',
          value: '50% Evento /50% Vigencia',
        },
        {
          name: 'Responsabilidad civil productos',
          value: RCProductVar,
        },
      ],
      automaticTransport: [
        {
          name: 'Tipo de mercancía',
          value: mercancyTransportName,
        },
        {
          name: 'Límite Máximo por Despacho',
          value: `$${formatPrice(
            getValueByCode(mercancyTransport.propiedadesAmparo, 8016)
          )}`,
        },
        {
          name: 'Presupuesto',
          value: `$${formatPrice(
            getValueByCode(mercancyTransport.propiedadesAmparo, 7809)
          )}`,
        },
        {
          name: 'Guerra',
          value: 'INCLUIDO',
        },
        {
          name: 'Huelga',
          value: 'INCLUIDO',
        },
        {
          name: 'Gastos adicionales',
          value: expensesValue,
        },
        {
          name: 'Lucro cesante',
          value: lucroValue,
        },
      ],
      moneyTransport: [
        {
          name: 'Límite Máximo por Despacho',
          value: `$${formatPrice(transportMoney.propiedadesAmparo[0].valor)}`,
        },
        {
          name: 'Número de viajes al mes',
          value: String(transportMoney.propiedadesAmparo[1].valor),
        },
      ],
      deductiblesData: listDeductibles,
      version: {
        number: versionCodi,
      },
      risks: draft.risks,
    };
    const sessionData = JSON.parse(sessionStorage.getItem('info'));
    const tokenAuthorization = sessionData.token;
    const headers = {
      authorization: `Bearer ${tokenAuthorization}`,
      authorizedip: sessionData.ip,
    };
    body = filterRestrictedPDFCoverages(body, drafts[draftIndex]);
    try {
      const { data } = await pdfService.callService('createPdf', body, headers);
      dispatch(callPdfQuote(null, data.status));
      const policy = quoteNumber;
      const link = data.pdf;
      const linkSource = `data:application/pdf;base64,${link}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = `PDF_cotizacion_Low_Touch_PYME${policy}`;
      downloadLink.click();
    } catch (e) {
      dispatch(callPdfQuote(null, 201));
      setShowAlertPdf(true);
    }
    quoteSaveReload();
  };

  const timestampDate = useSelector((state) => state.quoteInfo.data.Date);
  const bodyPdf = useSelector((state) => state.pdfQuote.requestBody);

  moment.lang('es');
  const timeService = new Date(Number(timestampDate));
  const startDate = moment(timeService);
  const startMonth = String(startDate.format('MMM')).replace(/\b\w/g, (l) =>
    l.toUpperCase()
  );
  const startFormatMonth = startMonth.slice(0, 3);
  const startDay = Number(startDate.format('DD'));
  const startYear = Number(startDate.format('YYYY'));

  const endDate = moment(timeService).add(30, 'days');
  const endMonth = String(endDate.format('MMM')).replace(/\b\w/g, (l) =>
    l.toUpperCase()
  );
  const endFormatMonth = endMonth.slice(0, 3);
  const endDay = Number(endDate.format('DD'));
  const endYear = Number(endDate.format('YYYY'));

  const dateInfo = {
    start: {
      day: startDay,
      month: startFormatMonth,
      year: startYear,
    },
    end: {
      day: endDay,
      month: endFormatMonth,
      year: endYear,
    },
    duration: 30,
  };

  useEffect(() => {
    getCalculoPricing();
  }, [draft, templatePricingRisks, selectedBusiness, coordinates]);

  const coverages = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[4][0][1].filter((row) => {
        if (row.results[0][0].tipoAmparo === '2') {
          return true;
        }
        return false;
      })
      : []
  );

  const selectRisk = (riskSelected, riskSelectedIndex) => {
    setRisk(riskSelected);
    setRiskIndex(riskSelectedIndex);
    history.push('/risk');
  };

  const createRiskAndNavigate = () => {
    dispatch(creaeteNewRisk({ draftIndex }));
    setRiskIndex(draft.risks.length);
    dispatch(
      createRisk({
        draftIndex,
        risk: {
          draftRiskId: uuidv4(),
          completed: false,
          createdAt: momentTZ().format(),
          valEnsCompleted: false,
          ValoAsegur: {},
          Mapa: {
            lat: 0,
            lng: 0
          },
          VariableIndex: {
            selected: '0',
            code: null,
            value: null,
          },
          featureSIGTwo: {
            ...TEMPLATE_SIG
          },
          featureSIG: {
            ...TEMPLATE_SIG
          },
          constraintTable: {
            restricted: false,
            bodyRequest: {
              runtimeContext: {
                currentDate: '2021-05-19T20:44:20.335Z',
                requestDate: '2021-05-19T20:44:20.335Z',
              },
              codigoActividad: null,
              variableSig: {},
            },
            responseService: {
              results: [
                ['MATRIZ', ['[Lorg.openl.generated.beans.Amparo;', []]],
              ],
              columnNames: ['Description', 'Value'],
              rowNames: ['MATRIZ'],
            },
          },
        },
      })
    );
    setRisk({ completed: false, valEnsCompleted: false, ValoAsegur: {} });
    history.push('/risk');
  };

  const removeRiskRedux = async () => {
    try {
      const { InNewRiskItemsTable = false, risks } = drafts[draftIndex];
      const { draftRiskId } = risks[indexRiskToDelete];
      if (InNewRiskItemsTable) {
        const response = await deleteRiskByIdQuote(draftRiskId);
        if (response.status !== 200) return;
      }
      dispatch(removeRisk({ draftIndex, riskIndex: indexRiskToDelete }));
      setRiskIndex(riskIndex - 1);
      const request = getRequestCapitalCalculation(
        {
          capitalMaximo: values.cotizacionNegocio,
          businessCode: selectedBusiness.answerCode,
          draft: drafts[draftIndex],
          templateCapital: drafts[draftIndex].risks,
          templateCapital2: drafts[draftIndex].policyRisks,
          idComponent: 'ValoAsegur',
        },
        openlCoveragesSpecs
      );
      dispatch(fetchOpenlCapital(request, customHeaders, draftIndex));
      getCalculoPricing();
      setIndexRiskToDelete(null);
      setDeleteModal(false);
      setDetailCapitalOpen(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error.toString());
    }
  };

  const handleDeleteRisk = (index) => {
    setDeleteModal(true);
    setIndexRiskToDelete(index);
  };

  const handleOnCancelDeleteRisk = () => {
    setIndexRiskToDelete(null);
    setDeleteModal(false);
  };

  const getCoverageName = (codigo) => {
    let coverageName = 'demas eventos';
    coverages.every((coverage) => {
      let result = coverage.results[1][0][1].find(
        (amparo) => amparo.codigoAmparoHijo === codigo
      );
      if (!result) {
        result = coverage.results[4][0][1].find(
          (amparo) => amparo.codigoAmparoHijo === codigo
        );
      }
      coverageName = result ? result.partida : coverageName;
      return !result;
    });
    return coverageName;
  };

  const filterDeductibles = () => {
    const deductiblesFilter = [];
    const newDeductibles = [];
    const { amparos = [] } = draft?.policyRisks || {};
    amparos.map((coverage) => {
      if (coverage.selected === '1') {
        deductiblesFilter.push(coverage);
        Array.prototype.push.apply(
          deductiblesFilter,
          coverage.propiedadesAmparo
        );
      }
      return null;
    });
    const data = groupObjectsByKey(deductiblesFilter, 'codDeducible');
    if (!isObjectEmpty(data)) {
      Object.entries(data).forEach(([key, value]) => {
        if (value[0].codDeducible) {
          let save = true;
          const VlaResponCiviel = deductiblesFilter.find(
            (coverage) => coverage.id === 'VlaResponCiviel'
          );
          if (
            value[0].id === 'VlaResponCiviel' &&
            VlaResponCiviel.selected &&
            VlaResponCiviel.selected === '0'
          ) {
            save = false;
          }
          if (save) {
            newDeductibles.push({
              codigo: key,
              amparoId: value[0].id,
              amparoName: getCoverageName(value[0].codigo),
              desDeducible:
                key === 'cod-431'
                  ? 'No aplica deducible'
                  : value[0].desDeducible,
            });
          }
        }
      });
    }
    setDeductibles(newDeductibles);
  };
  useEffect(() => {
    filterDeductibles();
    dispatch(
      updateBodyPdf({
        ...bodyPdf,
        dateInfo,
      })
    );
  }, []);

  useEffect(() => {
    if (openlPricing) {
      const {
        danosTotalPrima,
        gastosExpedicionTotal,
        totalPrima,
        totalNegocio,
        sumatoriaGastosParalisis,
      } = openlPricing;
      dispatch(
        updateDraft({
          draftIndex,
          draft: {
            danosTotalPrima,
            gastosExpedicionTotal,
            totalPrima,
            totalNegocio,
            sumatoriaGastosParalisis,
          },
        })
      );
    }
  }, [openlPricing?.totalNegocio]);

  useEffect(() => {
    if (!isObjectEmpty(openlCapitalData)) {
      const {
        capitalMaximo,
        cantidadRiesgos,
        valorPorPoliza,
        valPoliza,
        porcentajePoliza,
        // riesgosPoliza,
      } = openlCapitalData;
      const riesgosPoliza = drafts[draftIndex].policyRisks.amparos
        ? drafts[draftIndex].policyRisks
        : openlCapitalData.riesgosPoliza;
      dispatch(
        updateDraft({
          draftIndex,
          draft: {
            capitalMaximo,
            cantidadRiesgos,
            valorPorPoliza,
            valPoliza,
            porcentajePoliza,
            policyRisks: riesgosPoliza,
          },
        })
      );
    }
  }, [openlCapitalData]);

  const quoteSave = async () => {
    if (sessionStorage.getItem('openlLogsRequestResponse') !== null) {
      const dataOpenL = JSON.parse(
        sessionStorage.getItem('openlLogsRequestResponse')
      );
      const { drafts: draftsData } = dataOpenL;
      const dataDraft = draftsData[draftIndex];
      dataOpenL.drafts = dataDraft;
      openlServices.createOpenlLogs(dataOpenL);
    }

    dispatch(fetchQuote(null, customHeaders));
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  useEffect(() => {
    const request = getRequestCapitalCalculation(
      {
        capitalMaximo: drafts[draftIndex].capitalMaximo,
        businessCode: selectedBusiness.answerCode,
        draft: drafts[draftIndex],
        templateCapital: drafts[draftIndex].risks,
        templateCapital2: drafts[draftIndex].policyRisks,
        idComponent: 'subItemCoverage',
      },
      openlCoveragesSpecs
    );
    dispatch(fetchOpenlCapital(request, customHeaders, draftIndex));
  }, []);

  useEffect(() => {
    dispatch(updateFocusedDraft(draftIndex));
    callOpenlCapital();
  }, [draftIndex]);

 /*  useEffect(() => {
    drafts.forEach((draftItem) => {
      (draftItem?.risks).forEach((riskItem) => {
        const { lng = 0, lat = 0 } = riskItem?.Mapa
        if (lng === 0 || lat === 0) return;
        dispatch(
          fetchCoordinates(
            {
              path: 'PymeCoordinates',
              method: 'get',
              parameters: {
                Lon: lng,
                Lat: lat,
                f: 'pjson',
              },
              draftIndex,
              riskIndex,
            },
            customHeaders
          )
        );
      });
    });
  }, []); */

  useEffect(() => {
    draft.risks.forEach((x, index) => {
      if (!x.constraintTable) {
        dispatch(
          constraintTableUpdate({
            draftIndex,
            riskIndex: index,
            constraintTable: {
              restricted: false,
              bodyRequest: {
                runtimeContext: {
                  currentDate: '2021-05-19T20:44:20.335Z',
                  requestDate: '2021-05-19T20:44:20.335Z',
                },
                codigoActividad: null,
                variableSig: {},
              },
              responseService: {
                results: [
                  ['MATRIZ', ['[Lorg.openl.generated.beans.Amparo;', []]],
                ],
                columnNames: ['Description', 'Value'],
                rowNames: ['MATRIZ'],
              },
            },
          })
        );
      }
    });
  }, []);

  // ----- Emit Components -----
  const emitInfo = useSelector((state) => state.emitInfo);

  const [showInitEmitModal, setShowInitEmitModal] = useState(false);
  const [showDeclaracionModal, setShowDeclaracionModal] = useState(false);
  const [showModalValidateDate, setShowModalValidateDate] = useState(false);

  const messageInitEmision = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[16][0][1][0].iniEmision.split('\n\n')
      : []
  );
  const modalDeclaracion = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[16][0][1][0].declaracion
      : []
  );

  const handleInitEmisionContinue = () => {
    setShowInitEmitModal(false);
    setShowDeclaracionModal(true);
  };

  const handleDeclacionContinue = () => {
    sessionStorage.removeItem('openlLogsRequestResponse');
    sessionStorage.removeItem('quoteInfo');
    sessionStorage.removeItem('pricingRequest');
    sessionStorage.removeItem('pricingResponse');
    dispatch(fetchQuote(null, customHeaders, null, true));
    setShowDeclaracionModal(false);
  };

  useEffect(() => {
    if (emitInfo.isReadyEmission) {
      dispatch(initEmit(draftIndex));
    }
  }, [emitInfo.isReadyEmission]);

  useEffect(() => {
    if (!isObjectEmpty(emitInfo.data)) {
      setEmitRoute();
    }
  }, [emitInfo.data]);

  useEffect(() => {
    if (selectedBusiness.respuestaIaxis2 === undefined) {
      let selectedSubcategory;
      let isSelected = false;
      for (const category of categories) {
        if (isSelected) break;
        const subcategories = category.results[1][0][1];
        for (const subcategory of subcategories) {
          if (subcategory.respuestaIaxis === selectedBusiness.answerCode) {
            selectedSubcategory = subcategory;
            isSelected = true;
            break;
          }
        }
      }
      const {
        respuestaIaxis: answerCode,
        descripcionActividad: name,
        ubicacion,
        respuestaIaxis2,
      } = selectedSubcategory;
      const imageName = ubicacion.replace('png', 'svg');
      dispatch(
        createUpdateBusiness({
          business: { answerCode, name, imageName, respuestaIaxis2 },
        })
      );
    }
  }, []);

  const handleInitEmit = () => {
    if (sessionStorage.getItem('openlLogsRequestResponse') !== null) {
      const dataOpenL = JSON.parse(
        sessionStorage.getItem('openlLogsRequestResponse')
      );
      const { drafts: draftsData } = dataOpenL;
      const dataDraft = draftsData[draftIndex];
      dataOpenL.drafts = dataDraft;
      openlServices.createOpenlLogs(dataOpenL);
    }

    if (new Date(2021, 10, 22) > new Date(quoteInfoDate)) {
      setShowModalValidateDate(true);
    } else {
      setShowInitEmitModal(true);
    }
  };

  return (
    <React.Fragment key="risks">
      <div className="w-full bg-gray-200 overflow-x-auto	">
        <div className="w-full md:w-c-container flex m-auto">
          {drafts &&
            drafts.map((draftOption, index) => (
              <Tab
                key={index}
                className={`relative text-left px-4 ${draftIndex === index ? 'active' : ''
                  }`}
                type="button"
                onClick={async (e) => {
                  e.preventDefault();
                  setDraft(draftOption);
                  setDraftIndex(index);
                  setRiskIndex(0);
                  dispatch(updateFocusedDraft(index));
                }}
              >
                <div className="flex">
                  <div className="w-3/5 truncate">
                    <span className="text-blue text-xs font-medium">Prima</span>
                    <br />
                    {openlPricing && (
                      <span className="text-blue text-lg font-medium">
                        {`$ ${formatPrice(drafts[index]?.totalNegocio)}`}
                      </span>
                    )}
                  </div>
                  <div className="w-1/5 relative">
                    <div className="absolute inset-0 m-auto w-px h-4 border border-solid border-gray-200" />
                  </div>
                  <div className="w-1/5 relative">
                    {draftIndex === index && (
                      <img
                        src={duplicate}
                        alt="duplicate"
                        className="absolute inset-0 m-auto"
                      />
                    )}
                  </div>
                </div>
                <span className="absolute version text-blue text-xs font-medium	">
                  {`V${index + 1}`}
                </span>
              </Tab>
            ))}
        </div>
      </div>
      <div className="flex justify-center">
        <ConfirmationDeleteRisk
          showModal={deleteModal}
          setShowModal={setDeleteModal}
          onClick={removeRiskRedux}
          onCancel={handleOnCancelDeleteRisk}
        />
        {!isArrayEmpty(draft.risks) && (
          <div className="md:w-c-container">
            <div className="flex flex-col items-center px-4 text-center">
              <h3 className="mt-6 mb-3 dark-blue">Cotización</h3>
              <p>
                {`Recuerda que puedes asegurar máximo ${values.numeroRiesgos
                  } riesgos y la suma del valor
            asegurado de los riesgos no debe superar los $${formatPrice(
                    values.cotizacionNegocio
                  )}`}
              </p>
              <p>
                Tipo de negocio seleccionado:
                <span className="ml-2 medium-teal">
                  {selectedBusiness.name}
                </span>
              </p>
            </div>
            <Tooltip
              content={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <div className="p-4">
                  <p className="flex flex-col md:flex-row text-sm text-white leading-tight">
                    <span>Suma de valores asegurados:</span>
                    <div className="flex justify-center">
                      <span className="text-teal font-medium mx-1">{` (${draft.risks.length}) `}</span>
                      {draft.valorPorPoliza && (
                        <span className="font-medium">
                          {`$${formatPrice(draft.valorPorPoliza)}`}
                        </span>
                      )}
                    </div>
                  </p>
                  <Slider
                    type="range"
                    min="1"
                    max="100"
                    value={draft.porcentajePoliza}
                    step="1"
                    className="w-full block mx-auto mt-2"
                    porcentajePoliza={draft.porcentajePoliza}
                    valPoliza={draft.valorPorPoliza}
                  />
                </div>
              }
              position="bottom"
              defaultVisible
              widthContent
            >
              <div className="bg-gray-100 md:bg-white p-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
                {draft.risks.map((risk, index) => (
                  <div key={index}>
                    <Card>
                      <div
                        role="presentation"
                        className="flex flex-row md:flex-col cursor-pointer"
                        onClick={() => {
                          selectRisk(risk, index);
                        }}
                      >
                        <div className="relative w-20 md:w-full md:h-24">
                          {selectedBusiness?.imageName && (
                            <figure className="risk-icon shadow-md absolute bg-lightTeal w-10 p-2 rounded-full flex align-center justify-center">
                              <img
                                className="m-0 p-0 w-full"
                                src={require(`assets/icons/activities/${selectedBusiness.imageName}`)}
                                alt="Liberty Seguros - Alt"
                              />
                            </figure>
                          )}
                          <img
                            src={imgMap}
                            alt="img map"
                            className="object-cover h-14 w-full h-full"
                          />
                        </div>
                        <div className="pl-10 md:pl-6 p-6 relative w-full">
                          <p className="font-medium text-sm md:text-base">
                            {risk?.NomRiesgo}
                          </p>
                          {risk.DireccionEei && risk.CiudadDep && (
                            <p className="text-xs min-h-40 overflow-ellipsis">{`${risk.DireccionEei} ${risk.CiudadDep.nombreMunicipio}`}</p>
                          )}
                          {risk.sumaTotalRiesgo && (
                            <p className="font-medium text-sm mt-3">
                              {`$${formatPrice(risk.sumaTotalRiesgo)}`}
                            </p>
                          )}
                          {draft.risks.length > 1 && (
                            <img
                              role="presentation"
                              className="h-4 icon-delete-risk cursor-pointer"
                              src={trash}
                              alt="Trash - Alt"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteRisk(index);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
                {draft.risks.length < parseInt(values.numeroRiesgos, 10) &&
                  draft.valorPorPoliza <
                  parseInt(values.cotizacionNegocio, 10) && (
                    <DashedCard>
                      <div
                        role="presentation"
                        onClick={createRiskAndNavigate}
                        className="h-32 md:h-full p-4 flex flex-row md:flex-col cursor-pointer justify-center items-center"
                      >
                        <div className="m-2 w-8 h-8 p-2 bg-teal rounded">
                          <img src={plus} alt="plus icon" />
                        </div>
                        <p className="text-base font-medium text-blue">
                          Agregar otro riesgo
                        </p>
                      </div>
                    </DashedCard>
                  )}
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="mb-32 md:mb-20 mt-20 md:mt-8 bg-tealBackground w-full m-0 p-4 md:p-0 mx-auto flex justify-center">
        <div className="md:w-c-container mx-auto">
          <div className="p-0 md:p-4">
            <p className="text-base md:w-auto md:h-auto sm:text-2xl visible text-center font-bold w-full leading-tight mt-12 md:mt-16 mb-2 text-blue">
              Coberturas adicionales para tu negocio
            </p>
            <p className="text-center pb-4">
              Contrata coberturas adicionales de acuerdo a las necesidades de tu
              cliente, ten encuenta que las siguientes coberturas aplican para
              todo el negocio
            </p>
            <div className="flex justify-center w-full">
              <div className="w-full md:w-2/3">
                {coverages.map((coverage) => (
                  <AdditionalCoverage
                    key={coverage.results[0][0].codigoAmparo}
                    draftIndex={draftIndex}
                    draft={draft}
                    coverage={coverage}
                    getCalculoPricing={getCalculoPricing}
                    filterDeductibles={filterDeductibles}
                    callOpenlCapital={callOpenlCapital}
                    quoteSave={quoteSave}
                  />
                ))}
              </div>
            </div>
          </div>
          {deductibles && (
            <div className="grid gap-2 gap-x-8 grid-cols-1 md:grid-cols-2 my-6 m-4">
              {deductibles.map((item) => {
                if (item.amparoId) {
                  return (
                    <p className="text-sm" key={item.codigo}>
                      <span className="font-bold text-gray-600 mr-1">
                        {`Deducible para ${item.amparoName}: `}
                      </span>
                      <br className="md:hidden" />
                      <span className="text-gray-500 lowercase">
                        {item.desDeducible}
                      </span>
                    </p>
                  );
                }
                return null;
              })}
              {deductibles[0] && (
                <p className="text-sm" key={deductibles[0].codigo}>
                  <span className="font-bold text-gray-600 mr-1">
                    {`Deducible para ${deductibles[0].amparoName}: `}
                  </span>
                  <br className="md:hidden" />
                  <span className="text-gray-500 lowercase">
                    {deductibles[0].desDeducible}
                  </span>
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="fixed bottom-0 w-full bg-white z-10">
        <div className="w-full flex flex-col items-center space-y-1 pt-4">
          <div className="px-4 flex justify-between items-center md:w-c-container w-full">
            <div
              className="flex space-x-4 items-center cursor-pointer"
              role="presentation"
              onClick={() => setDetailCapitalOpen(!detailCapitalOpen)}
            >
              {openlPricing && (
                <div>
                  <h4 className="hidden md:flex dark-blue m-0">
                    Total prima:
                    <span className="ml-2">
                      {`$ ${formatPrice(openlPricing.totalNegocio)}`}
                    </span>
                  </h4>
                  <h4 className="md:hidden dark-blue m-0 flex flex-col">
                    <span className="text-sm">Total prima:</span>
                    <span>{`$ ${formatPrice(openlPricing.totalNegocio)}`}</span>
                  </h4>
                </div>
              )}
              <img src={arrowDown} alt="Alt - Arrow Down" className="h-4 w-4" />
            </div>
            <div className="flex items-center">
              <div className="flex space-x-2 md:space-x-4">
                <div className="relative rounded-full bg-gray-200 md:bg-white">
                  <div className="absolute pl-4">
                    <Tooltip
                      position="top"
                      defaultVisible={showDuplicateTooltip}
                      content={
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <span className="text-sm font-medium text-white leading-tight">
                          Duplicar la propuesta
                        </span>
                      }
                    />
                  </div>
                  {!(showConfirmPdf || pdfData.loading) && (
                    <button
                      type="button"
                      className="hover:bg-gray-200 rounded-full p-2"
                      onMouseEnter={() => setShowDuplicateTooltip(true)}
                      onMouseLeave={() => setShowDuplicateTooltip(false)}
                      onClick={() => setShowModal(true)}
                      disabled={
                        drafts.length >= Number(values.versionesPermitidas)
                      }
                    >
                      <img
                        src={duplicate}
                        alt="Alt - Duplicate"
                        className="h-5 md:h-6"
                      />
                    </button>
                  )}
                </div>
                {!(showConfirmPdf || pdfData.loading) && (
                  <>
                    <div className="rounded-full bg-gray-200 md:bg-white p-2 cursor-pointer">
                      <img
                        src={pdf}
                        onClick={() => {
                          setShowConfirmPdf(true);
                        }}
                        alt="Alt - PDF"
                        className="h-5 md:h-6"
                      />
                    </div>
                    <div className="hidden rounded-full bg-gray-200 md:bg-white p-2">
                      <img src={mail} alt="Alt - Mail" className="h-5 md:h-6" />
                    </div>
                    <div className="hidden rounded-full bg-gray-200 md:bg-white p-2">
                      <img src={mail} alt="Alt - Mail" className="h-5 md:h-6" />
                    </div>
                  </>
                )}
              </div>
              <div className="hidden md:flex">
                <Button
                  className="mx-0"
                  disabled={fetchQuoteLoading}
                  onClick={() => {
                    quoteSave();
                  }}
                >
                  Guardar y salir
                </Button>
                {!isProdEnv ? (
                  <Button className="mx-0" primary onClick={handleInitEmit}>
                    Iniciar emisión
                  </Button>
                ) : null}
              </div>
              <div
                role="presentation"
                className="bg-gray-100 rounded-full p-4 hidden md:block cursor-pointer"
                onClick={() => setDetailCapitalOpen(!detailCapitalOpen)}
              >
                <img
                  src={arrowDown}
                  alt="Alt - Arrow Down"
                  className="h-4 w-4"
                />
              </div>
            </div>
          </div>
          {detailCapitalOpen && openlPricing && (
            <div className="w-full bg-gray-100 flex justify-center">
              <div className="md:w-c-container w-full p-4">
                <p className="dark-blue font-medium">Detalle de la prima:</p>
                <div className="md:overflow-hidden overflow-x-scroll overflow-y-hidden flex md:grid md:grid-cols-5 md:gap-2 w-full">
                  {openlPricing.riesgos.map((item, index) => (
                    <div className="relative flex items-center justify-center space-x-2 bg-gray-100 p-4 px-8">
                      <img
                        src={checkCircle}
                        alt="Alt - Duplicate"
                        className="h-4"
                      />
                      <div>
                        <p className="text-sm dark-blue font-medium">
                          {draft.risks[index].NomRiesgo}
                        </p>
                        <p className="text-sm dark-blue font-medium">
                          {`$${formatPrice(item.primaConIva)}`}
                        </p>
                      </div>
                      {index + 1 < draft.risks.length && (
                        <div className="w-1 border-r-2 border-teal h-8 absolute right-0" />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="flex md:hidden space-x-2 w-full px-4">
            <Button
              className="mx-0"
              disabled={fetchQuoteLoading}
              onClick={() => {
                quoteSave();
              }}
            >
              Guardar y salir
            </Button>
            {!isProdEnv ? (
              <Button className="mx-0" primary onClick={handleInitEmit}>
                Iniciar emisión
              </Button>
            ) : null}
          </div>
        </div>
      </div>
      {showAlertPdf && (
        <AlertConfirm
          showModal={showAlertPdf}
          setShowModal={setShowAlertPdf}
          quoteNumber={quoteNumber}
          title={<>Ups, lo sentimos</>}
          subtitle="Tenemos un problema técnico en este momento"
          desciption={
            <p className="mt-4">
              Por favor, intenta nuevamente a través del botón{' '}
              <strong>Reintentar</strong>. Si el error persiste, por favor{' '}
              <br />
              retoma la cotización en unos minutos desde{' '}
              <strong>Mis Negocios</strong>
            </p>
          }
          cancelButton="Mis Negocios"
          butonContinue="Reintentar"
          handleButtonContinue={() => setShowAlertPdf(false)}
          handleButtonCancel={() => window.location.reload()}
        />
      )}
      {showConfirmPdf && (
        <AlertConfirm
          showModal={showConfirmPdf}
          setShowModal={setShowConfirmPdf}
          title={
            <>
              ¡Felicidades! <br />
              La cotización esta lista
            </>
          }
          cancelButton="Ajustar coberturas"
          butonContinue="Descargar PDF"
          icon={pdfConfirmIcon}
          handleButtonCancel={() => setShowConfirmPdf(false)}
          handleButtonContinue={() => {
            setShowConfirmPdf(false);
            getPdf();
          }}
        />
      )}
      {showModal && (
        <DuplicateQuote
          showModal={showModal}
          setShowModal={setShowModal}
          draft={draft}
          quoteNumber={quoteNumber}
          draftCount={drafts.length}
          quoteSaveReload={quoteSaveReload}
        />
      )}
      {messageInitEmision.length && showInitEmitModal && (
        <ModalEmit
          modalContent={{
            type: 'initEmision',
            title: messageInitEmision[0],
            bodyNode: (
              <>
                {messageInitEmision[1]} <br /> <br /> {messageInitEmision[2]}
              </>
            ),
          }}
          showModal={showInitEmitModal}
          setShowModal={setShowInitEmitModal}
          handleContinue={handleInitEmisionContinue}
        />
      )}
      {modalDeclaracion.length && showDeclaracionModal && (
        <ModalEmit
          modalContent={{
            type: 'declaracion',
            body: modalDeclaracion,
          }}
          showModal={showDeclaracionModal}
          setShowModal={setShowDeclaracionModal}
          handleContinue={handleDeclacionContinue}
        />
      )}
      {showModalValidateDate && (
        <ModalValidateDate
          showModal={showModalValidateDate}
          setShowModal={setShowModalValidateDate}
        />
      )}
    </React.Fragment>
  );
};

export default Risks;

/**
 * PropsTypes
 */
Risks.propTypes = {
  /**
   * Current Draft
   */
  setDraft: PropTypes.func,
  /**
   * Index of current Draft
   */
  setDraftIndex: PropTypes.func,

  /**
   * Index of current Draft
   */
  draftIndex: PropTypes.number.isRequired,
  /**
   * Index of current risk
   */
  riskIndex: PropTypes.number.isRequired,
  /**
   * Sets the risk index to draft
   */
  setRiskIndex: PropTypes.func,
  /**
   * Sets the risk to draft
   */
  setRisk: PropTypes.func,
  /**
   * Set the emit route
   */
  setEmitRoute: PropTypes.func,
};

Risks.defaultProps = {
  setDraft: PropTypes.func,
  setDraftIndex: PropTypes.func,
  setRiskIndex: PropTypes.func,
  setRisk: PropTypes.func,
  setEmitRoute: PropTypes.func,
};
