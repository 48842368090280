import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.label`
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked ~ span {
    background-color: ${(props) =>
      props.dontModify ? 'var(--gray-300)' : 'var(--medium-teal)'};
  }
  span:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 12px;
    ${(props) => (props.dontModify ? '' : 'border: 1px solid white')};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background-color: ${(props) =>
    props.dontModify ? 'var(--gray-300)' : 'white'};
  border: 1px solid
    ${(props) => (props.checked ? 'var(--medium-teal)' : 'var(--gray-300)')};
  ${(props) => props.dontModify && 'border: 1px solid var(--gray-300)'};
  &:after {
    content: '';
    position: absolute;
  }
`;

const Checkbox = ({ checkedProp, changeProp, dontModify, idCheck, label }) => {
  return (
    <Container checked={checkedProp} dontModify={dontModify}>
      <input
        type="checkbox"
        checked={checkedProp}
        onChange={() => changeProp(checkedProp)}
        disabled={dontModify}
        id={idCheck}
      />
      <Checkmark checked={checkedProp} dontModify={dontModify} />
      <label className="text-xs" htmlFor={idCheck}>
        {label}
      </label>
    </Container>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  checkedProp: PropTypes.bool,
  dontModify: PropTypes.bool,
  changeProp: PropTypes.func,
  idCheck: PropTypes.string,
  label: PropTypes.string,
};

Checkbox.defaultProps = {
  checkedProp: PropTypes.bool,
  dontModify: PropTypes.bool,
  changeProp: PropTypes.func,
  idCheck: PropTypes.string,
  label: PropTypes.string,
};
