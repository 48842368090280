import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import Tooltip from 'components/Tooltip';
import { useFormContext } from 'react-hook-form';
import { updateVariableIndex } from 'reducers/quoteInfo';
import { toJSONValidation } from 'utils/helpers/reactFormValidations';
import useWindowDimensions from 'utils/helpers/resolution';
import AlertIcon from 'assets/icons/alert.svg';

const Text = styled.p`
  min-width: 150px;
  width: 100%;
  @media (min-width: 768px) {
    max-width: 250px;
    width: 250px;
  }
`;
const QuestionMark = styled.div`
  align-items: center;
  background-color: var(--gray-200);
  border-radius: 50%;
  color: var(--liberty-blue);
  display: flex;
  display: inline-block;
  font-size: 8px;
  font-weight: bold;
  justify-content: center;
  line-height: 1;
  margin-left: 10px;
  padding: 2px 4px;
  &::before {
    content: '?';
  }
`;
const VariableIndex = ({ data, refData, draftIndex, riskIndex, code }) => {
  const { register, errors, trigger } = useFormContext();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [isError, setIsError] = useState(false);
  const returnService = useSelector((state) => state.quoteInfo.data);
  const ValuesEnsure =
    returnService.drafts[draftIndex].risks[riskIndex].ValoAsegur;

  const handleChecked = (check) => {
    setChecked(check);
  };
  const { width } = useWindowDimensions();
  const updateVariableIndexFn = (
    selected,
    draftIndexValue,
    riskIndexValue,
    codeValue,
    value
  ) => {
    dispatch(
      updateVariableIndex({
        selected,
        draftIndexValue,
        riskIndexValue,
        codeValue,
        value,
      })
    );
  };
  useEffect(() => {
    if (returnService.drafts[draftIndex].risks[riskIndex].VariableIndex) {
      const VariableIndexRedux =
        returnService.drafts[draftIndex].risks[riskIndex].VariableIndex;
      if (VariableIndexRedux.selected !== '0') {
        setChecked(true);
      }
    }
  }, []);

  useEffect(() => {
    if (!('VlaEdificio' in ValuesEnsure)) {
      updateVariableIndexFn(
        '0',
        draftIndex,
        riskIndex,
        code,
        returnService.drafts[draftIndex].risks[riskIndex].VariableIndex.value
      );
      if (checked) {
        setIsError(false);
        setChecked(false);
      }
    } else if (isError) {
      setIsError(false);
    }
  }, [ValuesEnsure]);

  const handleOnValueEnsure = (e) => {
    if (!('VlaEdificio' in ValuesEnsure)) {
      setIsError(true);
      return;
    }
    setIsError(false);
    handleChecked(!checked);
    let selected = '1';
    if (checked) {
      selected = '0';
    }
    updateVariableIndexFn(
      selected,
      draftIndex,
      riskIndex,
      code,
      returnService.drafts[draftIndex].risks[riskIndex].VariableIndex.value
    );
  };

  if (Object.keys(ValuesEnsure).length > 0) {
    return (
      <div className="w-full">
        <h3 className="text-lg font-semibold text-blue text-center my-8">
          {data.descripcion}
        </h3>
        <div className="shadow-xl bg-gray-100 md:shadow-none md:w-full flex justify-between m-0 md:p-0 md:m-0">
          <div className="flex justify-start items-center w-6/12 md:w-4/5 flex-no-wrap py-8 px-4">
            <Checkbox
              checkedProp={checked}
              changeProp={handleOnValueEnsure}
              dontModify={false}
            />
            {width < 768 ? (
              <Tooltip
                position="top"
                left="15px"
                content={
                  data.literales && (
                    <p className="text-sm text-white leading-tight">
                      {data.literales}
                    </p>
                  )
                }
              >
                <Text
                  className="font-semibold text-gray-600 text-sm tracking-normal leading-4 cursor-pointer select-none py-4"
                  onClick={handleOnValueEnsure}
                >
                  {data.descripcion}
                </Text>
              </Tooltip>
            ) : (
              <div className="flex justify-start items-center">
                <Text
                  className="font-semibold text-gray-600 text-sm tracking-normal leading-4 cursor-pointer select-none py-4"
                  onClick={handleOnValueEnsure}
                >
                  {data.descripcion}
                </Text>
                <Tooltip
                  position="top"
                  left="15px"
                  content={
                    data.literales && (
                      <p className="text-sm text-white leading-tight">
                        {data.literales}
                      </p>
                    )
                  }
                >
                  <QuestionMark />
                </Tooltip>
              </div>
            )}
            {isError ? (
              <div className="flex items-start justify-start p-0 mt-2 ">
                <img
                  src={AlertIcon}
                  className="mr-2 mt-1 md:-space-y-5"
                  alt="Liberty Seguros - Icono"
                />
                <p className="text-lmRed text-xs md:-space-y-5">
                  Debe Ingresar un valor a Edificio
                </p>
              </div>
            ) : null}
          </div>
          <div className="w-6/12 bg-gray-150 md:bg-gray-100 m-0 py-8 pr-6 md:max-w-190 md:w-2/5 flex justify-center items-center md:justify-end">
            {checked && 'VlaEdificio' in ValuesEnsure && (
              <Input
                key={data.id}
                placeholder="%"
                type="number"
                name={data.id}
                onChange={(e) => {
                  trigger(data.id);
                  let selected = '0';
                  if (checked) {
                    selected = '1';
                  }
                  updateVariableIndexFn(
                    selected,
                    draftIndex,
                    riskIndex,
                    code,
                    e.target.value
                  );
                }}
                ref={register(toJSONValidation(refData))}
                className="bg-white"
                controlled={false}
                errorMessage={errors[data.id] && errors[data.id].message}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

export default VariableIndex;

VariableIndex.propTypes = {
  data: PropTypes.object.isRequired,
  code: PropTypes.string.isRequired,
  refData: PropTypes.array.isRequired,
  draftIndex: PropTypes.string.isRequired,
  riskIndex: PropTypes.string.isRequired,
};
