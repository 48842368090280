/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import Proptypes from 'prop-types';
import Button from '../../../basics/form/Button';
import ModalWrapper from '../../../basics/modal/ModalWrapper';
import BellCircleIcon from '../../../../../assets/icons/bell-circle.svg';
import RedPointIcon from '../../../../../assets/icons/red-point.svg';
import TimeIcon from '../../../../../assets/icons/time.svg';

const ModalSQS = ({ showModal }) => {
  return (
    <ModalWrapper
      size="xl"
      showButtonClose
      showModal={showModal}
      setShowModal={() => window.location.reload()}
    >
      <div className="text-center bg-gray-100 py-12 px-6">
        <div>
          <img src={TimeIcon} alt="" className="w-20 m-auto mb-6" />
        </div>
        <h2 className="text-2xl mb-3 dark-blue font-medium leading-tight">
          Tu póliza está siendo emitida
        </h2>
        <p className="text-sm text-gray-600 mx-0 md:mx-24 mb-3">
          Cuando finalice el proceso te comunicaremos a través de la zona de
          notificaciones
        </p>
        <div className="mb-3 text-center">
          <div className="relative inline-block">
            <img
              className="w-5 absolute top-0 right-0 -mr-1 -mt-1"
              src={RedPointIcon}
              alt=""
            />
            <img className="w-10" src={BellCircleIcon} alt="" />
          </div>
        </div>
        <div className="flex flex-col px-4 sm:flex-row sm:gap-4 m-auto justify-center">
          <Button
            primary
            className="w-full sm:m-0 sm:w-48 md:m-0"
            onClick={() => window.location.reload()}
          >
            Ir a mis negocios
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

ModalSQS.propTypes = {
  showModal: Proptypes.bool.isRequired,
};

export default ModalSQS;
