/* eslint-disable no-restricted-syntax */
import { updateReadyEmission } from 'emit_store/reducers/emitInfo';
import { HEADERS_JSON } from 'utils/constants/DefaultBodies';
import { isObjectEmpty } from 'utils/helpers/object';
import { v4 as uuidv4 } from 'uuid';
import momentTZ from 'moment-timezone';
import TEMPLATE_SIG from 'utils/constants/templateSIG';
import { fetchCoordinatesSuccessWhitIndexRisk } from './coordinates';
momentTZ.tz.setDefault('America/Bogota');

const urlQuote = process.env.REACT_APP_URL_QUOTE;
const urlRestriction = process.env.REACT_APP_URL_ACTIVITY;

const initialState = {
  loading: false,
  data: {
    step: null,
    draftCount: 1,
    quoteNumber: '0',
    broker: '',
    business: {},
    taker: {},
    drafts: [
      {
        risks: [
          {
            draftRiskId: uuidv4(),
            deductibles: [],
            completed: false,
            Mapa: {
              lat: 0,
              lng: 0
            },
            createdAt: momentTZ().format(),
            valEnsCompleted: false,
            ValoAsegur: {},
            VariableIndex: {
              selected: '0',
              code: null,
              value: null,
            },
            featureSIG: {
              ...TEMPLATE_SIG
            },
            featureSIGTwo: {
              ...TEMPLATE_SIG
            },
            constraintTable: {
              restricted: false,
              bodyRequest: {
                runtimeContext: {
                  currentDate: '2021-05-19T20:44:20.335Z',
                  requestDate: '2021-05-19T20:44:20.335Z',
                },
                codigoActividad: null,
                variableSig: {},
              },
              responseService: {
                results: [
                  ['MATRIZ', ['[Lorg.openl.generated.beans.Amparo;', []]],
                ],
                columnNames: ['Description', 'Value'],
                rowNames: ['MATRIZ'],
              },
            },
          },
        ],
        policyRisks: {},
      },
    ],
  },
  error: null,
};
const initialRisk = {
  draftRiskId: uuidv4(),
  deductibles: [],
  completed: false,
  valEnsCompleted: false,
  ValoAsegur: {},
  VariableIndex: {
    selected: '0',
    code: null,
    value: null,
  },
  featureSIG: {
    ...TEMPLATE_SIG
  },
  featureSIGTwo: {
    ...TEMPLATE_SIG
  },
  Mapa: {
    lat: 0,
    lng: 0
  },
  constraintTable: {
    restricted: false,
    bodyRequest: {
      runtimeContext: {
        currentDate: '2021-05-19T20:44:20.335Z',
        requestDate: '2021-05-19T20:44:20.335Z',
      },
      codigoActividad: null,
      variableSig: {},
    },
    responseService: {
      results: [['MATRIZ', ['[Lorg.openl.generated.beans.Amparo;', []]]],
      columnNames: ['Description', 'Value'],
      rowNames: ['MATRIZ'],
    },
  },
};

export const addRespversionCodi = (payload) => ({
  type: 'SET_RESPVERSIONCODI',
  payload,
});

export const setRestrictedRisk = (payload) => ({
  type: 'SET_RESTRICTED_RISK',
  payload,
});
/**
 * Starts the request for Quote info on dynamoDB
 */
export const fetchQuoteStart = () => ({
  type: 'FETCH_QUOTE_START',
  error: false,
});

/**
 * Successfull response of Quote info on DynamoDB
 */
export const fetchQuoteSuccess = (payload) => ({
  type: 'FETCH_QUOTE_SUCCESS',
  payload,
});

export const LoadingfetchQuote = () => ({
  type: 'LOADING_QUOTE_SUCCESS',
});

export const FalseLoadingfetchQuote = () => ({
  type: 'FALSE_LOADING_QUOTE_SUCCESS',
});

export const fetchdashboardSuccess = (payload) => ({
  type: 'FETCH_DASHBOARD_SUCCESS',
  payload,
});

/**
 * Failed response of Quote info on DynamoDB
 */
export const fetchQuoteError = (payload) => ({
  type: 'FETCH_QUOTE_ERROR',
  payload,
  error: true,
});

/**
 * Stores json fragment for selected step
 */
export const updateStep = (payload) => ({
  type: 'UPDATE_STEP',
  payload,
});

export const updateDraftCount = (payload) => ({
  type: 'UPDATE_DRAFT_COUNT',
  payload,
});
/**
 * Stores json fragment for selected broker
 */
export const brokerUpdate = (payload) => ({
  type: 'BROKER_UPDATE',
  payload,
});

/**
 * Stores json fragment for selected business
 */
export const createUpdateBusiness = (payload) => ({
  type: 'CREATE_UPDATE_BUSINESS',
  payload,
});

/**
 * Stores json fragment for selected taker
 */
export const createUpdateTaker = (payload) => ({
  type: 'CREATE_UPDATE_TAKER',
  payload,
});

export const takerRemove = (payload) => ({
  type: 'TAKER_REMOVE',
  payload,
});

/**
 * Stores json fragment for draft
 */
export const createDraft = (payload) => ({
  type: 'CREATE_DRAFT',
  payload,
});

/**
 * Updates info for draft
 */
export const updateDraft = (payload) => ({
  type: 'UPDATE_DRAFT',
  payload,
});

/**
 * Stores json fragment for risk
 */
export const createRisk = (payload) => ({
  type: 'CREATE_RISK',
  payload,
});

/**
 * Updates info for risk
 */
export const updateRisk = (payload) => ({
  type: 'UPDATE_RISK',
  payload,
});

export const resetRisk = (payload) => ({
  type: 'RESET_RISK',
  payload,
});

/**
 * Updates deductibles for risk
 */
export const updateDeductiblesRisk = (payload) => ({
  type: 'UPDATE_DEDUCTIBLES_RISK',
  payload,
});

export const removeRisk = (payload) => ({
  type: 'REMOVE_RISK',
  payload,
});

/**
 * Stores json fragment for values to insure for risk
 */
export const createUpdateValuesInsure = (payload) => ({
  type: 'CREATE_UPDATE_VALUES_INSURE',
  payload,
});

/**
 * Updates coverage check (amparo)
 */
export const updateCoverageCheck = (payload) => ({
  type: 'UPDATE_COVERAGE_CHECK',
  payload,
});

export const updateSubitemCheck = (payload) => ({
  type: 'UPDATE_SUBITEM_CHECK',
  payload,
});

/**
 * add sub items
 */
export const validateSubItems = (payload) => ({
  type: 'VALIDATE_SUB_ITEM',
  payload,
});
/*
 * update values for 8876 and 8877
 */
export const updateValues = (payload) => ({
  type: 'UPDATE_COVERAGE_VALUES',
  payload,
});

export const validateValueSubItems = (payload) => ({
  type: 'VALIDATE_VALUE_SUBITEM',
  payload,
});

/**
 * Updates coverage check and content (amparo)
 */
export const updateCoverage = (payload) => ({
  type: 'UPDATE_COVERAGE',
  payload,
});

/**
 * Updates coverage check and content (amparo)
 */
export const updatePolicyCoverage = (payload) => ({
  type: 'UPDATE_POLICY_COVERAGE',
  payload,
});
/**
 * Update variable index
 */
export const updateVariableIndex = (payload) => ({
  type: 'UPDATE_VARIABLE_INDEX',
  payload,
});

/**
 * Update coverage property for policy coverage
 */
export const updatePolicyCoverageProp = (payload) => ({
  type: 'UPDATE_POLICY_COVERAGE_PROP',
  payload,
});

export const riskValueRemoveFromTotalCoverage = (payload) => ({
  type: 'RISK_VALUE_REMOVE_FROM_TOTAL_COVERAGE',
  payload,
});

/**
 * Sig features update
 * @param {*} payload
 */
export const sigUpdate = (payload) => ({
  type: 'SIG_UPDATE',
  payload,
});

/**
 * Update coverage property for policy coverage
 */
export const fetchPolicyRisks = (payload) => ({
  type: 'SIG_RESTRICTION',
  payload,
});

/**
 * Sig features update
 * @param {*} payload
 */
export const constraintTableUpdate = (payload) => ({
  type: 'CONSTRAINT_TABLE_UPDATE',
  payload,
});

/**
 * change restricte status to false
 */
export const restrictedFalse = (payload) => ({
  type: 'RESTRICTED_FALSE',
  payload,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_RESTRICTED_RISK': {
      const { draftIndex, riskIndex, restricted } = action.payload;
      if (!state.data.drafts[draftIndex].risks[riskIndex]) return state;

      const risk = state.data.drafts[draftIndex].risks[riskIndex];

      const tmpConstraintTable = risk.constraintTable
        ? { ...risk.constraintTable }
        : {};

      state.data.drafts[draftIndex].risks[riskIndex] = {
        ...risk,
        constraintTable: {
          ...tmpConstraintTable,
          restricted,
        },
      };

      return state;
    }
    case 'FETCH_QUOTE_START': {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case 'FETCH_QUOTE_SUCCESS': {
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    }
    case 'FETCH_QUOTE_ERROR': {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case 'UPDATE_STEP':
    case 'UPDATE_DRAFT_COUNT':
    case 'BROKER_UPDATE': {
      const stepFragment = {
        data: { ...state.data, ...action.payload },
      };
      return {
        ...state,
        ...stepFragment,
      };
    }
    case 'CREATE_UPDATE_BUSINESS': {
      const bussinessFragment = {
        data: { ...state.data, ...action.payload },
      };
      return {
        ...state,
        ...bussinessFragment,
      };
    }
    case 'CREATE_UPDATE_TAKER': {
      return {
        ...state,
        data: {
          ...state.data,
          taker: {
            ...action.payload,
          },
        },
      };
    }
    case 'SAVE_DATA_IN_EMISSION':
      // eslint-disable-next-line no-case-declarations
      const draftsTemp = state.data.drafts;
      for (let index = 0; index < action.payload.split('#')[2]; index++) {
        draftsTemp.push(state.data.drafts[0]);
      }
      return {
        ...state,
        data: {
          ...state.data,
          quoteNumber: action.payload.split('#')[0],
          drafts: draftsTemp,
        },
      };
    case 'TAKER_REMOVE': {
      state.data.taker = {};
      return state;
    }
    case 'CREATE_DRAFT': {
      const { payload } = action;
      const drafNew = {
        ...payload,
        risks: payload.risks.map((risk) => {
          let newRisk = { ...risk };
          newRisk.draftRiskId = uuidv4();
          newRisk.createdAt = momentTZ().format();
          if (newRisk?.draftVersionId) delete newRisk.draftVersionId;
          return newRisk;
        })
      };
      const newDrafts = [...state.data.drafts, drafNew];
      return {
        ...state,
        data: {
          ...state.data,
          drafts: newDrafts
        }
      }
    }
    case 'UPDATE_DRAFT': {

      const draft = {
        ...state.data.drafts[action.payload.draftIndex],
        ...action.payload.draft,
      };
      state.data.drafts.splice(action.payload.draftIndex, 1, draft);

      return state;
    }
    case 'CREATE_RISK': {
      state.data.drafts[action.payload.draftIndex].risks.push(
        action.payload.risk
      );
      return state;
    }
    case 'UPDATE_RISK': {
      const { draftIndex, riskIndex, risk } = action.payload;

      const newRisk = {
        ...state.data.drafts[draftIndex].risks[riskIndex],
        ...risk,
      };

      state.data.drafts[draftIndex].risks.splice(riskIndex, 1, newRisk);
      const newData = { ...state.data };
      return { ...state, data: newData };
    }
    case 'RESET_RISK': {
      state.data.drafts[action.payload.draftIndex].risks[
        action.payload.riskIndex
      ] = {
        ...initialRisk,
      };
      return state;
    }

    case 'UPDATE_DEDUCTIBLES_RISK': {
      /* eslint-disable no-param-reassign */
      state.data.drafts[action.payload.draftIndex].risks[
        action.payload.riskIndex
      ].deductibles = action.payload.data;
      return state;
    }
    case 'REMOVE_RISK': {
      state.data.drafts[action.payload.draftIndex].risks.splice(
        action.payload.riskIndex,
        1
      );
      return state;
    }
    case 'CREATE_UPDATE_VALUES_INSURE': {
      const { draftIndex, riskIndex } = action.payload;
      const valuesInsurePayload = action.payload.risk.ValoAsegur;
      const draft = state.data.drafts[draftIndex];
      const risk = draft.risks[riskIndex];
      Object.keys(valuesInsurePayload).forEach((key1) => {
        if (!isObjectEmpty(risk.ValoAsegur)) {
          Object.keys(risk.ValoAsegur).forEach((key2) => {
            if (
              key1 === key2 &&
              (valuesInsurePayload[key1] === '0' ||
                valuesInsurePayload[key1] === '')
            ) {
              delete risk.ValoAsegur[key2];
              draft.risks.splice(riskIndex, 1, risk);
            } else {
              const valuesInsureFragment = {
                ValoAsegur: {
                  ...risk.ValoAsegur,
                  ...valuesInsurePayload,
                },
              };
              const newRisk = { ...risk, ...valuesInsureFragment };
              draft.risks.splice(riskIndex, 1, newRisk);
            }
          });
        } else if (
          isObjectEmpty(risk.ValoAsegur) &&
          valuesInsurePayload[key1] !== '0'
        ) {
          const valuesInsureFragment = {
            ValoAsegur: {
              ...risk.ValoAsegur,
              ...valuesInsurePayload,
            },
          };
          const newRisk = { ...risk, ...valuesInsureFragment };
          draft.risks.splice(riskIndex, 1, newRisk);
        }
      });

      return state;
    }
    case 'UPDATE_COVERAGE_CHECK': {
      const { draftIndex, riskIndex } = action.payload;
      const draft = state.data.drafts[draftIndex];
      const risk = draft.risks[riskIndex];
      const coveragePayload = action.payload.coverage;

      risk.amparos.forEach((amparo) => {
        if (amparo.codigoAmparo === coveragePayload.codigoAmparo) {
          const newCoverage = amparo;
          if (newCoverage.selected === 1) {
            newCoverage.selected = 0;
          } else {
            newCoverage.selected = 1;
          }
        }
      });
      return state;
    }
    case 'UPDATE_SUBITEM_CHECK': {
      const {
        draftIndex,
        riskIndex,
        dependencia,
        codigoAmparo,
        selected,
      } = action.payload;
      const draft = state.data.drafts[draftIndex];
      const risk = draft.risks[riskIndex];
      risk.amparos.forEach((amparo) => {
        if (amparo.codigoAmparo === dependencia) {
          const fatherItem = amparo;
          fatherItem.propiedadesAmparo.forEach((children) => {
            if (children.codigo === codigoAmparo) {
              const childrenItem = children;
              childrenItem.selected = selected ? 1 : 0;
            }
          });
          if (fatherItem.codigoAmparo === '8866') {
            const childerItemNotValue = fatherItem.propiedadesAmparo.find(
              (item) => item.valor === 0 && item.selected !== 0
            );
            fatherItem.propiedadesAmparo.forEach((children) => {
              if (children.codigo === codigoAmparo) {
                const childrenItem = children;
                if (
                  (childrenItem.selected === 1 &&
                    childrenItem.valor === 0 &&
                    !childerItemNotValue) ||
                  (childrenItem.selected === 0 &&
                    childrenItem.valor !== 0 &&
                    childerItemNotValue)
                ) {
                  fatherItem.selected = 0;
                }
              }
            });
          }
        }
      });
      return state;
    }
    case 'VALIDATE_SUB_ITEM': {
      /* eslint-disable no-param-reassign */
      const {
        checked,
        draftIndex,
        riskIndex,
        codigoAmparo,
        dependencia,
        openLPropertySubItemIndex,
      } = action.payload;
      const draft = state.data.drafts[draftIndex];
      const risk = draft.risks[riskIndex];
      const coverages = risk.amparos;
      const coverage = coverages.find(
        (cov) => dependencia === cov.codigoAmparo
      );

      const { propiedadesAmparo } = coverage;
      const coveragePropertyIndex = propiedadesAmparo.findIndex(
        (propAmparo) => codigoAmparo === propAmparo.codigo
      );
      if (checked && coveragePropertyIndex > -1) {
        propiedadesAmparo[coveragePropertyIndex] = openLPropertySubItemIndex;
      } else if (checked) {
        propiedadesAmparo.push(openLPropertySubItemIndex);
      } else {
        propiedadesAmparo.splice(coveragePropertyIndex, 1);
      }
      return state;
    }
    case 'UPDATE_COVERAGE_VALUES': {
      const { codeValue, valueItem, draftIndex, riskIndex } = action.payload;
      const draft = state.data.drafts[draftIndex];
      const risk = draft.risks[riskIndex];
      const coverages = risk.amparos;
      const coverage = coverages.find((cov) => codeValue === cov.codigoAmparo);
      coverage.valorRiesgo = valueItem;
      return state;
    }
    case 'VALIDATE_VALUE_SUBITEM': {
      const {
        codigoAmparo,
        dependencia,
        draftIndex,
        riskIndex,
        value,
      } = action.payload;
      const draft = state.data.drafts[draftIndex];
      const risk = draft.risks[riskIndex];
      const coverages = risk.amparos;

      if (coverages) {
        const coverage = coverages.find(
          (cov) => dependencia === cov.codigoAmparo
        );
        const { propiedadesAmparo } = coverage;
        const subcoverage = propiedadesAmparo.find(
          (propAmparo) => codigoAmparo === propAmparo.codigo
        );
        if (subcoverage) {
          subcoverage.valor = value;
        }
      }

      return state;
    }

    case 'UPDATE_COVERAGE': {
      const { draftIndex, riskIndex } = action.payload;
      const draft = state.data.drafts[draftIndex];
      const risk = draft.risks[riskIndex];
      const coverages = risk.amparos;
      const coveragePayload = action.payload.coverage;

      const coverage = coverages.find(
        (amparo) => amparo.codigoAmparo === coveragePayload.codigoAmparo
      );

      const coverageIndex = coverages.findIndex(
        (amparo) => amparo.codigoAmparo === coveragePayload.codigoAmparo
      );

      if (coverage.selected === '1') {
        coverage.selected = '0';
      } else {
        coverage.selected = '1';
      }

      const newCoverage = { ...coverage, ...coveragePayload };
      coverages.splice(coverageIndex, 1, newCoverage);

      return state;
    }
    case 'UPDATE_POLICY_COVERAGE': {
      const { draftIndex } = action.payload;
      const drafts = [...state.data.drafts];
      let draft = Object.assign({}, drafts[draftIndex]);
      const policyCoverages = draft.policyRisks.amparos.map((coverage) => coverage);
      const policyCoveragePayload = action.payload.policyCoverage;

      const policyCoverage = policyCoverages.find(
        (amparo) => amparo.codigoAmparo === policyCoveragePayload.codigoAmparo
      );

      const policyCoverageIndex = policyCoverages.findIndex(
        (amparo) => amparo.codigoAmparo === policyCoveragePayload.codigoAmparo
      );

      let newPolicyCoverage;
      if (policyCoverage.codigoAmparo === '8884') {
        const { propiedadesAmparo } = policyCoverage;

        const subAmparosNews = [];
        for (const subAmparo of propiedadesAmparo) {
          if (subAmparo.codigo !== '8893') {
            const seletedValue = +policyCoveragePayload.selected === 1 ? 1 : 0;
            const newAmparo = { ...subAmparo, selected: seletedValue };
            subAmparosNews.push(newAmparo);
          } else {
            subAmparosNews.push(subAmparo);
          }
        }
        newPolicyCoverage = {
          ...policyCoverage,
          ...policyCoveragePayload,
          propiedadesAmparo: subAmparosNews,
        };
      } else if (policyCoverage.codigoAmparo === '8880') {
        const { propiedadesAmparo } = policyCoverage;

        const subAmparosNews = [];
        for (const subAmparo of propiedadesAmparo) {
          if (subAmparo.codigo === '8021') {
            const seletedValue = +policyCoveragePayload.selected === 1 ? 1 : 0;
            const newAmparo = {
              ...subAmparo,
              selected: seletedValue,
              valor: +policyCoveragePayload.valorRiesgo,
            };
            subAmparosNews.push(newAmparo);
          } else {
            subAmparosNews.push(subAmparo);
          }
        }
        newPolicyCoverage = {
          ...policyCoverage,
          ...policyCoveragePayload,
          propiedadesAmparo: subAmparosNews,
        };
      } else {
        newPolicyCoverage = {
          ...policyCoverage,
          ...policyCoveragePayload,
        };
      }

      policyCoverages.splice(policyCoverageIndex, 1, newPolicyCoverage);
      draft = {
        ...draft,
        policyRisks: {
          ...draft.policyRisks,
          amparos: policyCoverages
        }
      }

      drafts.splice(draftIndex, 1, draft);
      return {
        ...state, data: {
          ...state.data,
          drafts
        }
      };
    }
    case 'UPDATE_VARIABLE_INDEX': {
      /* eslint-disable no-param-reassign */
      const {
        selected,
        draftIndexValue,
        riskIndexValue,
        codeValue,
        value,
      } = action.payload;
      state.data.drafts[draftIndexValue].risks[riskIndexValue].VariableIndex = {
        selected,
        code: codeValue,
        value,
      };
      return state;
    }
    case 'UPDATE_POLICY_COVERAGE_PROP': {
      const { draftIndex } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          drafts: state.data.drafts.map((draft, index) => {
            if (index === draftIndex) {
              const policyCoverages = draft.policyRisks.amparos.map(
                (coverage) => ({
                  ...coverage,
                })
              );
              const policyCoveragePropPayload = action.payload.coverageProp;

              const policyCoverage = policyCoverages.find(
                (amparo) => amparo.codigoAmparo === action.payload.codigoAmparo
              );

              const policyCoverageIndex = policyCoverages.findIndex(
                (amparo) => amparo.codigoAmparo === action.payload.codigoAmparo
              );

              const coverageProp = policyCoverage.propiedadesAmparo.find(
                (propiedad) =>
                  propiedad.codigo === policyCoveragePropPayload.codigo
              );

              const coveragePropIndex = policyCoverage.propiedadesAmparo.findIndex(
                (propiedad) =>
                  propiedad.codigo === policyCoveragePropPayload.codigo
              );

              const newPolicyCoverageProp = {
                ...coverageProp,
                ...policyCoveragePropPayload,
              };

              policyCoverage.propiedadesAmparo.splice(
                coveragePropIndex,
                1,
                newPolicyCoverageProp
              );
              policyCoverages[policyCoverageIndex] = { ...policyCoverage };
              return {
                ...draft,
                policyRisks: { ...draft.policyRisks, amparos: policyCoverages },
              };
            }
            return draft;
          }),
        },
      };
    }
    case 'RISK_VALUE_REMOVE_FROM_TOTAL_COVERAGE': {
      const { draftIndex, riskIndex } = action.payload;
      const draft = state.data.drafts[draftIndex];
      draft.valorPorPoliza =
        Number(draft.valorPorPoliza) -
        Number(
          draft.risks[riskIndex].sumaTotalRiesgo
            ? draft.risks[riskIndex].sumaTotalRiesgo
            : 0
        );
      return state;
    }
    case 'SIG_UPDATE': {
      const { draftIndex, riskIndex, featureSIGTwo: featureSIG } = action.payload;
      const draft = state.data.drafts[draftIndex];
      const risk = draft.risks[riskIndex];
      risk.featureSIG = featureSIG;
      risk.featureSIGTwo = featureSIG;
      return state;
    }
    case 'CONSTRAINT_TABLE_UPDATE': {
      const { draftIndex, riskIndex, constraintTable } = action.payload;
      const draft = state.data.drafts[draftIndex];
      const risk = draft.risks[riskIndex];
      risk.constraintTable = constraintTable;
      return state;
    }
    case 'SIG_RESTRICTION': {
      const { draftIndex, data, riskIndex } = action.payload;
      const draft = state.data.drafts[draftIndex];
      let risk = draft.risks[riskIndex];
      if (!risk.constraintTable) {
        risk = {
          ...risk,
          constraintTable: { restricted: false, responseService: data },
        };
        return state;
      }
      risk.constraintTable.responseService = data;
      return state;
    }
    case 'RESTRICTED_FALSE': {
      const { draftIndex, riskIndex } = action.payload;
      const draft = state.data.drafts[draftIndex];
      const risk = draft.risks[riskIndex];
      if (risk.constraintTable) {
        risk.constraintTable.restricted = false;
        return state;
      }
      return state;
    }
    case 'SET_RESPVERSIONCODI': {
      // answer for emission policy to axis 4777
      const { RespversionCodi = '605970' } = action.payload;
      let data = state.data;
      data.RespversionCodi = RespversionCodi;
      return state;
    }
    default: {
      return state;
    }
  }
}

export const sigRequestData = (sigV) => async (
  dispatch,
  getState,
  { axios }
) => {
  const {
    featureSIGNew,
    activity,
    customHeaders,
    riskIndex,
    draftIndex,
    floorsNumberRisk = null,
  } = sigV;
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };
  const response = await axios.post(
    `${urlRestriction}`,
    {
      runtimeContext: {
        currentDate: '2021-05-19T20:44:20.335Z',
        requestDate: '2021-05-19T20:44:20.335Z',
      },
      numerosPisos: floorsNumberRisk || '',
      codigoActividad: activity,
      variableSig: featureSIGNew,
    },
    {
      headers,
    }
  );
  dispatch(fetchPolicyRisks({ data: response.data, riskIndex, draftIndex }));
};

const LIMIT_VALUES_SARLAFT = {
  LIMIT_VALUE_NET_PREMIUM: 6960000,
  LIMIT_VALUE_INSURED: 156600000,
};

const validExceptionSarlaft = (openlPricing) => {
  try {
    if (!openlPricing) throw new Error('Undefined openlPricing');
    const { primaNeta, valorAseguradoSarlaft } = openlPricing;
    return !!(
      primaNeta <= LIMIT_VALUES_SARLAFT.LIMIT_VALUE_NET_PREMIUM &&
      valorAseguradoSarlaft <= LIMIT_VALUES_SARLAFT.LIMIT_VALUE_INSURED
    );
  } catch (error) {
    return false;
  }
};

export const fetchQuote = (
  type,
  customHeaders,
  quoteInfo,
  isReadyEmission = false
) => async (dispatch, getState, { axios }) => {
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };
  dispatch(fetchQuoteStart());
  switch (type) {
    case 'getQuote':
      try {
        const { data } = await axios.post(`${urlQuote}/getQuote`, quoteInfo, {
          headers,
        });

        data.drafts.forEach(({ policyRisks = {} }) => {
          const { amparos: coverages = [] } = policyRisks;
          // find coverage transporte Automático 8894
          const coverage = coverages.find(({ codigoAmparo: code = '' }) => code == '8894');
          if (coverage) {
            const { propiedadesAmparo = [] } = coverage;
            const existCoverage = propiedadesAmparo.some(({ codigo = "" }) => codigo = "8016");
            // default value if coverage 8894 does not have coverage with code 8016 in the properties
            if (!existCoverage) {
              propiedadesAmparo.push({
                "codigo": "8016",
                "valor": 0
              })
            }
          }
        });
        // Before updating the risk quote information in the quote reducer, you must update the coordinate reducer in redux.
        data.drafts.forEach((draft, indexDraft) => {
          const { risks = [] } = draft;
          risks.forEach((risk, indexRisk) => {
            const { featureSIG = TEMPLATE_SIG } = risk;
            dispatch(fetchCoordinatesSuccessWhitIndexRisk({
              res: {
                features: [
                  {
                    attributes: featureSIG
                  }
                ]
              },
              draftIndex: indexDraft,
              riskIndex: indexRisk
            }));
          });
        });

        dispatch(fetchQuoteSuccess(data));
      } catch (e) {
        console.log(e);
        dispatch(fetchQuoteError(e));
      }
      break;
    case 'takerCall':
      try {
        const quoteInfoBody = { quoteInfo: getState().quoteInfo.data };
        await axios.post(`${urlQuote}/createQuote`, quoteInfoBody, {
          headers,
        });
      } catch (e) {
        dispatch(fetchQuoteError(e));
      }
      break;
    default:
      try {
        if (isReadyEmission) dispatch(LoadingfetchQuote());
        const quoteInfoBody = { quoteInfo: { ...getState().quoteInfo.data } };
        const openlPricing = getState().openlPricing.data?.results?.[1] || null;
        quoteInfoBody.quoteInfo.exceptionSarlaft = validExceptionSarlaft(
          openlPricing
        );

        const { data } = await axios.post(
          `${urlQuote}/createQuote`,
          quoteInfoBody,
          { headers }
        );
        dispatch(fetchQuoteSuccess(data.Attributes));
      } catch (e) {
        console.error(e);
        dispatch(fetchQuoteError(e));
      } finally {
        dispatch(updateReadyEmission(isReadyEmission));
        if (!isReadyEmission) dispatch(FalseLoadingfetchQuote());
      }
      break;
  }
};
