/* eslint-disable func-names */
export const formatWord = (str) => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

export const titleCase = (str) =>
  str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());

export const replaceHashtagAddress = (address) => {
  const regex = /[#]/g;
  return address.replace(regex, 'N ');
};

export const replaceAddressSpecialChars = (address) => {
  const regex = /["#°|¿?]/g;
  return address.replace(regex, ' ');
};

export const extractValueFromSplit = (str, separator, position) => {
  const arrayResult = str.split(separator);
  return arrayResult[position];
};

export const formatPrice = (amount) => {
  return parseInt(amount, 10).toLocaleString('es-CO');
};

export const formatToDispatch = (amount) => {
  const str = String(amount);
  const strFormat = str
    .replace(/\./gi, '')
    .replace(/\s/g, '')
    .replace(/\$/g, '');
  return strFormat;
};

export const jsonUpperCase = (obj) => {
  Object.keys(obj).forEach(function (key) {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].toUpperCase();
    }
  });
  return obj;
};
