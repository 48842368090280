import React from 'react';
import PropTypes from 'prop-types';
import RadioButtonItem from 'components/RadioButtonItem';
import styled from 'styled-components';

const ItemContainer = styled.div`
  border: 1px solid var(--gray-300);
  /* with: calc(100% - 31px); */
  margin: 0;
  position: relative;
  padding: 0 16px;
  height: 51px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;

  &.selected {
    border: 1px solid var(--teal);
    background-color: var(--light-teal);
  }

  &.error {
    border-color: red;
  }


  @media (min-width: 768px) {
    margin: 0;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
`;

const PInfoDetail = styled.p`
  font-size: 13px;
  font-weight: 500;
  height: 78px;
  min-height: 100%;
  text-align: center;
  width: 199px;
`;

const ImageIconUbication = styled.img`
  width: 65px !important;
  height: 65px !important;
  object-fit: contain;
`;

const InfoDetail = styled.div`
  padding: 23px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray-300);
  border-top: none;
  background-color: white;
  gap: 18px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
  }
  &.selected {
    border: 1px solid var(--teal);
    background-color: var(--light-teal);
  }

  &.error {
    border-color: red;
  }
`;
const Container = styled.div`
  width: 33.333%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RadioButtonComponent = (props) => {
  const {
    description,
    constructionSelect,
    setConstructionType,
    onChange,
    errorMessage,
    literales,
    ubicacion,
  } = props;

  return (
    <Container
      onClick={() => {
        setConstructionType(description);
        onChange();
      }}
    >
      <ItemContainer
        className={
          (description === constructionSelect && 'selected') ||
          (errorMessage && 'error bg-red-100 ')
        }
      >
        <RadioButtonItem
          select={description === constructionSelect}
          left="30px"
          width="17px"
          height="17px"
          bottom="15px"
          direction="left"
          right=""
        />
        <span className="ml-16 text-sm color-gray-600">{description}</span>
      </ItemContainer>

      <InfoDetail
        className={
          (description === constructionSelect && 'selected') ||
          (errorMessage && 'error bg-red-100 ')
        }
      >
        <ImageIconUbication
          src={require(`assets/icons/${ubicacion}`)}
          alt="icon"
          title="icon"
        />
        <PInfoDetail>{literales}</PInfoDetail>
      </InfoDetail>
    </Container>
  );
};

export default RadioButtonComponent;

RadioButtonComponent.propTypes = {
  setConstructionType: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  constructionSelect: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
};
