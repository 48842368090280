const urlPerson = process.env.REACT_APP_URL_PERSON;

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const fetchPersonStart = () => ({
  type: 'FETCH_PERSON_START',
  error: false,
});

export const fetchPersonSuccess = (payload) => ({
  type: 'FETCH_PERSON_SUCCESS',
  payload,
});

export const fetchPersonError = (payload) => ({
  type: 'FETCH_PERSON_ERROR',
  payload,
  error: true,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_PERSON_START': {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case 'FETCH_PERSON_SUCCESS': {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case 'FETCH_PERSON_ERROR': {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case 'REMOVE_PERSON': {
      return initialState;
    }
    default: {
      return state;
    }
  }
}

export const removePerson = () => ({
  type: 'REMOVE_PERSON',
});

export const fetchPerson = (payload, customHeaders) => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(fetchPersonStart());
  try {
    const { data } = await axios.post(`${urlPerson}/request`, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...customHeaders,
      },
    });
    dispatch(fetchPersonSuccess(data));
  } catch (e) {
    dispatch(fetchPersonError(e));
  }
};
