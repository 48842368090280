import React from 'react';
import PropTypes from 'prop-types';

export default function Card({ children }) {
  return (
    <div className="rounded overflow-hidden shadow-md bg-white">{children}</div>
  );
}

Card.propTypes = {
  children: PropTypes.object.isRequired,
};
