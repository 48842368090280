/* eslint-disable no-return-assign */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Star from 'assets/icons/star.svg';
import RadioButtonItem from 'components/RadioButtonItem';

const FigureStar = styled.figure`
  position: absolute;
  top: 6px;
  right: 6px;
`;

/**
 * FavoriteCard component
 * @param {*} props
 */
const FavoriteCard = (props) => {
  const {
    respuestaIaxis2,
    code,
    selected,
    desc,
    literal,
    imageName,
    activitySelected,
  } = props;
  const [subCategories, setSubCategories] = useState([]);
  const categories = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[0][0][1]
      : []
  );
  useEffect(() => {
    const virtualSubCategories = [];
    categories.map((item) =>
      item.results[1][0][1].map((item2) => virtualSubCategories.push(item2))
    );
    setSubCategories(virtualSubCategories);
  }, [categories[1]]);
  return (
    <div
      role="presentation"
      onClick={() => {
        let imageNameSvg = '';
        subCategories.forEach(
          (item) =>
            item.respuestaIaxis === code && (imageNameSvg = item.ubicacion)
        );
        activitySelected(code, desc, imageNameSvg, respuestaIaxis2);
      }}
      className={`cursor-pointer w-full my-1 bg-white rounded flex overflow-hidden md:mr-3 md:w-48 md:flex-col TestFavoriteCard ${
        selected && 'border-solid border border-mediumTeal bg-lightTeal'
      }`}
    >
      <figure className="w-28 overflow-hidden md:w-full bg-yellow h-auto">
        <img
          src={require(`assets/images/activities/${imageName}`)}
          className="w-full object-cover h-full"
          alt="Liberty Seguros - Alt"
        />
      </figure>
      <div className="flex flex-col justify-center align-center relative w-full h-32 p-4">
        <h5 className="text-lg text-gray-600 my-0">{desc}</h5>
        <span className="text-xs text-gray-600 my-0 text-opacity-50 pr-4">
          {literal}
        </span>
        <FigureStar>
          <img src={Star} alt="Liberty Seguros - Alt" />
        </FigureStar>
        <RadioButtonItem
          select={selected}
          width="20px"
          height="20px"
          direction="right"
          bottom="10px"
          right="10px"
          left=""
        />
      </div>
    </div>
  );
};

export default FavoriteCard;

FavoriteCard.propTypes = {
  respuestaIaxis2: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  code: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  literal: PropTypes.string.isRequired,
  imageName: PropTypes.string.isRequired,
  activitySelected: PropTypes.func.isRequired,
};
