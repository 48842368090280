/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useSortBy,
} from 'react-table';
import styled from 'styled-components';
import useWindowDimensions from '../../utils/helpers/resolution';
import iconExpandMore from '../../assets/icons/expand-more.svg';
import iconExpandLess from '../../assets/icons/expand-less.svg';
import sortIcon from '../../assets/icons/more.svg';
import TableFooter from './TableFooter';

const TitleTh = styled.th`
  height: 19px;
  width: 44px;
  color: var(--gray-600);
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: start;
  padding-left: 1%;
  background-color: var(--white);
  img {
    width: 10px;
    margin: 10px;
  }
  @media (max-width: 700px) {
    background-color: var(--light-teal);
    font-size: 15px;
    height: 18px;
    line-height: 18px;
  }
`;

const DivTd = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CustomTable = ({ columns, data, filter }) => {
  const dimensions = useWindowDimensions();
  const [desktop, setDesktop] = useState(true);

  useMemo(() => {
    const { width } = dimensions;

    if (width < 701) {
      setDesktop(false);
    } else {
      setDesktop(true);
    }
  }, [dimensions]);
  let colorBool = false;
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    headerGroups,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setGlobalFilter(filter.length !== 1 ? filter : '');
  }, [filter, data]);

  const getIcon = (isSorted, isSortedDesc) => {
    if (isSorted) {
      if (isSortedDesc) return iconExpandMore;
      return iconExpandLess;
    }
    return sortIcon;
  };

  return (
    <>
      <div className="mb-4 shadow-md overflow-x-auto overflow-x-hiden border-b border-gray-200 bg-white">
        {/* eslint-disable react/jsx-props-no-spreading */}
        <table {...getTableProps()} className=" min-w-full">
          <thead className="bg-white-50">
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(
                    ({
                      getHeaderProps,
                      getSortByToggleProps,
                      render,
                      sticky = false,
                      sorter = true,
                      hide = false,
                      isSorted,
                      isSortedDesc,
                    }) =>
                      !hide && (
                        <TitleTh
                          scope="col"
                          className={`px-1 py-6 ${sticky && 'sticky left-0'}`}
                          {...getHeaderProps(
                            getSortByToggleProps({
                              title: undefined,
                            })
                          )}
                        >
                          <span
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: `${!desktop ? 'flex-start' : ''}`,
                            }}
                          >
                            {render('Header')}
                            {sorter && (
                              <img
                                src={getIcon(isSorted, isSortedDesc)}
                                alt="icon-sort"
                                className="align-middle"
                                height="10px"
                                width="10px"
                              />
                            )}
                          </span>
                        </TitleTh>
                      )
                  )}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()} data-todo-x-max="2" className="my-10">
            {page.map((row) => {
              prepareRow(row);
              colorBool = !colorBool;

              return (
                <tr
                  {...row.getRowProps()}
                  style={{
                    backgroundColor: `${
                      desktop && colorBool ? 'var(--gray-100)' : 'white'
                    }`,
                    borderBottom: `${
                      !desktop ? '1px solid var(--gray-200)' : 'none'
                    }`,
                  }}
                >
                  {columns.map(
                    (column, index) =>
                      !column.hide && (
                        <td
                          key={index}
                          className={clsx(
                            column.sticky && 'sticky left-0 bg-white'
                          )}
                        >
                          <DivTd
                            className="px-2 py-4 "
                            style={{
                              width: `${desktop ? '150px' : '120px'}`,
                            }}
                          >
                            {column.render && (
                              <column.render row={row?.original} />
                            )}
                          </DivTd>
                        </td>
                      )
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <TableFooter
        {...{
          canPreviousPage,
          canNextPage,
          pageCount,
          gotoPage,
          nextPage,
          previousPage,
          setPageSize,
          pageIndex,
          pageSize,
          desktop,
        }}
      />
    </>
  );
};

export default CustomTable;

CustomTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  filter: PropTypes.string,
  desktop: PropTypes.bool.isRequired,
};

CustomTable.defaultProps = {
  filter: '',
};
