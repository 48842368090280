import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import warning from 'assets/icons/warning.svg';

const BannerStyle = styled.div`
  background-color: var(--error);
`;

const Content = styled.div`
  min-height: 56px;
`;

const Banner = () => {
  const refBanner = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(refBanner.current ? refBanner.current.offsetHeight : 0);
  }, []);

  return (
    <div style={{ height }}>
      <BannerStyle
        ref={refBanner}
        className="w-full fixed z-50 text-white p-2 md:p-0"
      >
        <div className="md:w-c-container m-auto">
          <Content className="flex justify-center">
            <img className="w-5 mr-2" src={warning} alt="icon warning" />
            <span className="self-center text-center">
              La suma de valores asegurados supera las póliticas de cotización,
              para cotizar este negocio debes hacerlo escribiendo a
              <strong> solicitudes@libertycolombia.com</strong>
            </span>
          </Content>
        </div>
      </BannerStyle>
    </div>
  );
};

export default Banner;
