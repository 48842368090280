import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import * as reducers from './reducers';
import * as services from './services';
import * as emitState from './emit_store/reducers';

const isProdEnv = process.env.REACT_APP_IS_PROD === 'true';
const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
export const store = createStore(
  combineReducers({ ...reducers, ...emitState }),
  isProdEnv
    ? applyMiddleware(thunk.withExtraArgument(services))
    : composeEnhancers(applyMiddleware(thunk.withExtraArgument(services)))
);

export default store;
