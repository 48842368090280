/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Button from 'components/Button';

const AlertsConfirm = ({
  showModal,
  setShowModal,
  title,
  subtitle,
  desciption,
  cancelButton,
  butonContinue,
  quoteNumber,
  handleButtonCancel,
  handleButtonContinue,
  icon,
}) => {
  return (
    <Modal showModal={showModal} setShowModal={setShowModal} lg={false}>
      <div className="m-6 flex flex-col items-center justify-center px-6 md:px-20 space-y-3 md:space-y-6">
        {icon ? <img src={icon} alt="icon" /> : null}
        <h3 className="mt-4 font-semibold text-center dark-blue">{title}</h3>
        <div className="w-full mt-4 text-center">
          {subtitle ? <h6>{subtitle}</h6> : null}
          {desciption ? <>{desciption}</> : null}
          {quoteNumber ? (
            <p className="mt-4"> Cotización: {quoteNumber} </p>
          ) : null}
        </div>
        <div className="flex flex-col md:flex-row w-full justify-center">
          <Button className="sm:px-10" onClick={handleButtonCancel}>
            {cancelButton}
          </Button>
          <Button className="sm:px-12" primary onClick={handleButtonContinue}>
            {butonContinue}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AlertsConfirm;

AlertsConfirm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  cancelButton: PropTypes.string.isRequired,
  butonContinue: PropTypes.string.isRequired,
  handleButtonContinue: PropTypes.func.isRequired,
  handleButtonCancel: PropTypes.func.isRequired,
};
