import React from 'react';
import Proptypes from 'prop-types';
import Button from '../../../basics/form/Button';
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/big-trash.svg';
import ModalWrapper from '../../../basics/modal/ModalWrapper';

const ModalRemoveRol = ({ showModal, setRemoveRole, setCloseModal }) => {
  return (
    <ModalWrapper size="xl" setShowModal={setCloseModal} showModal={showModal}>
      <div className="text-center bg-gray-100 pt-12 pb-12 mt-32 md:mt-0 ">
        <TrashIcon className="w-16 h-16 sm:w-20 sm:h-20 m-auto mb-4" />
        <h2 className="text-gray-900 font-medium mb-8 sm:mb-12 text-2xl ">
          ¿Desea eliminar el rol?
        </h2>
        <div className="flex flex-col px-4 sm:flex-row  sm:gap-4 m-auto justify-center">
          <Button
            className="w-full sm:m-0 sm:w-32 md:m-0 md:w-32"
            onClick={setCloseModal}
          >
            Cancelar
          </Button>
          <Button
            className="w-full sm:m-0 sm:w-32 md:m-0 md:w-32"
            primary
            onClick={setRemoveRole}
          >
            Aceptar
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

ModalRemoveRol.propTypes = {
  setRemoveRole: Proptypes.func.isRequired,
  setCloseModal: Proptypes.func.isRequired,
  showModal: Proptypes.bool.isRequired,
};

export default ModalRemoveRol;
