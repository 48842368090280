import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Navigation from 'layouts/modals/Navigation';
import NavigationTabs from 'components/NavigationTabs';
import Drafts from 'layouts/tabs/Drafts';
import Business from 'layouts/tabs/Business';
import Taker from 'layouts/tabs/Taker';
import Loader from 'components/Loader';
import { updateTabQuote } from 'reducers/activeQuoteTab';
import { isObjectEmpty } from 'utils/helpers/object';

export default function Quote() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const quoteInfo = useSelector((state) => {
    return state.quoteInfo.data;
  });

  const { drafts } = quoteInfo;

  const loading = useSelector(
    ({ openlConfig, coordinates, openlCapital }) =>
      !!openlConfig.loading || !!coordinates.loading || !!openlCapital.loading
  );

  const callPdfService = useSelector(
    (state) => state.pdfQuote.loading === true
  );

  const tabs = [
    {
      id: '1',
      label: 'Negocio',
      content: (
        <div className="tab-content">
          <Business />
        </div>
      ),
    },
    {
      id: '2',
      label: 'Tomador',
      content: (
        <div className="tab-content">
          <Taker currentTab={currentTab} setCurrentTab={setCurrentTab} />
        </div>
      ),
    },
    {
      id: '3',
      label: 'Cotizacion',
      content: (
        <div>
          <Drafts
            drafts={drafts}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        </div>
      ),
    },
  ];

  const activeTab = useSelector((state) => {
    return state.activeQuoteTab;
  });

  useEffect(() => {
    if (activeTab) {
      setCurrentTab(activeTab.index);
    }
  }, [activeTab]);

  useEffect(() => {
    const { taker, step } = quoteInfo;
    if (step) {
      if (!isObjectEmpty(taker) && step === 3) {
        dispatch(updateTabQuote({ index: 2 }));
      } else if (!isObjectEmpty(taker) && step === 2) {
        dispatch(updateTabQuote({ index: 1 }));
      } else {
        dispatch(updateTabQuote({ index: 0 }));
      }
    }
  }, []);

  return (
    <div>
      {(loading || callPdfService) && <Loader />}
      <NavigationTabs
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      <Navigation showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
}
