import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import ModalWrapper from '../../../basics/modal/ModalWrapper';
import Button from '../../../basics/form/Button';
import Input from '../../../basics/form/Input';
import Select from '../../../basics/form/Select';
import LegalPerson from '../../forms/LegalPerson';
import NaturalPerson from '../../forms/NaturalPerson';
import ModalSorry from '../ModalSorry';

import {
  NaturalPersonRegisterOptions,
  optionsIdentitiesTaker,
  optionsIdentitiesInsuredBeneficiary,
  NaturalPersonSchema,
  LegalPersonSchema,
} from '../../../../../utils/constants/emit_constants';
import RolesChecks from '../../cards/RolesChecks';
import emitServices from '../../../../../services/emit';

import { validateDocument } from '../../../../../utils/helpers/stringValidations';

const ModalInfoPeople = ({
  roleSelection,
  showModal,
  handleContinueFormRoleInfo,
  setCloseModal,
  handleUpdateRoleObject,
  isMaximumTakers,
  isMaximumInsured,
  isMaximumBeneficiaries,
  setLoading,
}) => {
  const rolesInfo = useSelector((state) => state.emitInfo.data.roles);
  const indexOfTaker = rolesInfo.findIndex(role => role.isTaker === true);
  const { exceptionSarlaft } = useSelector(
    (state) => state.emitInfo.data?.quote_data
  );
  const [isLegalPerson, setIsLegalPerson] = useState(false);
  const [isNaturalPerson, setIsNaturalPerson] = useState(false);
  const [isPersonValidated, setIsPersonValidated] = useState(false);
  const [openModalSorry, setOpenModalSorry] = useState(false);
  const [PersonRoleSchema, setPersonRoleSchema] = useState({});
  const [clickAddBeneficiarie, setClickAddBeneficiarie] = useState(false);

  const [isModalForm, setIsModalForm] = useState(false);

  const isSomeRoleSelected =
    roleSelection.isTaker ||
    roleSelection.isInsured ||
    roleSelection.isBeneficiary;

  const {
    control,
    register,
    errors,
    watch,
    formState,
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      documentType: roleSelection.info.documentType,
      documentNumber: roleSelection.info.documentNumber,
    },
  });


  const documentType = watch('documentType');
  const [docNumberValidationError, setDocNumberValidationError] = useState('');
  const docNumberValidationRules = {
    validate: (docNumber) => {
      let error = validateDocument(docNumber, documentType?.code);
      const roles = rolesInfo.filter(
        (role) => role.idCard !== roleSelection.idCard && role.isCompleteInfo
      );
      const insuredRole = roles.filter((role) => role.isInsured);
      const beneficiaryRoles = roles.filter((role) => role.isBeneficiary);

      const isInsuredAlreadyExists = insuredRole.some(
        (role) =>
          role.info.documentNumber === docNumber &&
          role.isInsured === roleSelection.isInsured
      );

      const isBeneficiaryAlreadyExists = beneficiaryRoles.some(
        (role) =>
          role.info.documentNumber === docNumber &&
          role.isBeneficiary === roleSelection.isBeneficiary
      );

      if (isInsuredAlreadyExists || isBeneficiaryAlreadyExists) {
        error = {
          message: 'Este número de identificación ya ha sido registrado',
        };
      }

      setDocNumberValidationError(error?.message);
      return !error;
    },
  };

  useEffect(() => {
    trigger();
  }, [documentType]);

  useEffect(() => {
    trigger();
  }, [roleSelection.isInsured, roleSelection.isBeneficiary]);

  const validateTypePerson = (roleData) => {
    if (roleData.documentType.code === '37') {
      setPersonRoleSchema(roleData);
      setIsLegalPerson(true);
    } else {
      setPersonRoleSchema(roleData);
      setIsNaturalPerson(true);
    }
    setIsPersonValidated(true);
    setIsModalForm(true);
  };

  const handleContinue = () => {
    setIsPersonValidated(false);
    setIsModalForm(false);
    if (
      Object.keys(roleSelection.info).length > 0 &&
      roleSelection.info.documentNumber === getValues().documentNumber &&
      roleSelection.info.documentType.code === getValues().documentType.code &&
      roleSelection.isCompleteInfo
    ) {
      validateTypePerson(roleSelection.info);
    } else {
      setLoading(true);
      const { documentNumber } = getValues();
      const { idCard } = roleSelection;
      emitServices.user
        .search({
          document_number: documentNumber,
          document_type: documentType.code,
        })
        .then((response) => {
          let { roleData, hasHabeasData } = response.data.data;
          const { userRestriction, sarlaft } = response.data.data;
          handleUpdateRoleObject(idCard, 'IsNewPersonIaxis', true);
          handleUpdateRoleObject(idCard, 'hasHabeasData', hasHabeasData);
          if (userRestriction) {
            setOpenModalSorry(userRestriction);
            return;
          }
          if (sarlaft) {
            handleUpdateRoleObject(idCard, 'isSarlaft', sarlaft);
          }
          roleData.documentNumber = documentNumber;
          if (roleData.documentType.code === '37')
            roleData = { ...roleSelection.info, ...roleData };
          validateTypePerson(roleData);
        })
        .catch(() => {
          if (getValues().documentType.code === '37') {
            LegalPersonSchema.documentType = getValues().documentType;
            LegalPersonSchema.documentNumber = getValues().documentNumber;           
            validateTypePerson(LegalPersonSchema);
            handleUpdateRoleObject(idCard, 'IsNewPersonIaxis', false);
            handleUpdateRoleObject(idCard, 'info', { documentType: { code: documentType.code, name: documentType.name } });
          } else {
            NaturalPersonSchema.documentType = getValues().documentType;
            NaturalPersonSchema.documentNumber = getValues().documentNumber;
            validateTypePerson(NaturalPersonSchema);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handlEndClickModalSorry = () => {
    setOpenModalSorry(false);
    setCloseModal(true);
  };

  const handleAdjustClickModalSorry = () => {
    setOpenModalSorry(false);
  };

  const optionsIdentities =
    roleSelection.type === 'taker'
      ? optionsIdentitiesTaker
      : optionsIdentitiesInsuredBeneficiary;
  return (
    <ModalWrapper
      showModal={showModal}
      setShowModal={setCloseModal}
      isModalForm={isModalForm}
    >
      <div className="text-center dark-blue py-8 md:pb-20">
        <ModalSorry
          showModal={openModalSorry}
          onEndClick={handlEndClickModalSorry}
          onAdjustClick={handleAdjustClickModalSorry}
        />
        <div className="px-3 md:max-w-3xl mx-auto">
          <h1 className="text-2xl mb-2 leading-tight">
            Información de las personas
          </h1>
          <p className="text-xs mx-5 mb-6 leading-tight">
            Ingresa la información de la persona y selecciona el rol que tendrá
            dentro de la póliza
          </p>
          <hr />
          <div className="flex justify-evenly my-6 md:mx-40">
            <RolesChecks
              role={roleSelection}
              setToggleCheck={handleUpdateRoleObject}
              isMaximumTakers={isMaximumTakers}
              isMaximumInsured={isMaximumInsured}
              isMaximumBeneficiaries={isMaximumBeneficiaries}
              isInsuredAlreadyExists={false}
              isBeneficiaryAlreadyExists={false}
            />
          </div>
        </div>

        {!isPersonValidated ? (
          <div className="text-center dark-blue py-8 px-3 md:max-w-3xl mx-auto">
            <div className="text-left grid grid-cols-1 md:grid-cols-2 gap-5 mb-6">
              <Controller
                key="controller-documentType-infoPeople"
                as={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Select
                    placeholder="Tipo de identificación"
                    options={optionsIdentities}
                    optionKey="code"
                    optionLabel="name"
                    errorMessage={
                      errors.documentType && errors.documentType.message
                    }
                  />
                }
                name="documentType"
                control={control}
                rules={NaturalPersonRegisterOptions.documentType}
                valueDefault={roleSelection.info.documentType}
              />

              <Input
                placeholder="Número de identificación"
                type="number"
                name="documentNumber"
                className="mt-4 md:mt-0"
                ref={register({
                  ...NaturalPersonRegisterOptions.documentNumber,
                  ...docNumberValidationRules,
                  // ...nitValidationRules,
                })}
                controlled={false}
                errorMessage={
                  errors.documentNumber &&
                  (errors.documentNumber.message || docNumberValidationError)
                }
              />
            </div>
            <Button className="mr-4" onClick={setCloseModal}>
              Cancelar
            </Button>
            <Button
              primary
              disabled={!formState.isValid || !isSomeRoleSelected}
              onClick={handleContinue}
            >
              Continuar
            </Button>
          </div>
        ) : (
          <>
            {isLegalPerson && (
              <LegalPerson
                roleSchema={PersonRoleSchema}
                setContinueFormRoleInfo={handleContinueFormRoleInfo}
                setCloseModal={setCloseModal}
                roleSelection={roleSelection}
                exceptionSarlaft={exceptionSarlaft}
                isLegalPerson={isLegalPerson}
              />
            )}
            {isNaturalPerson && (
              <NaturalPerson
                roleSchema={PersonRoleSchema}
                setContinueFormRoleInfo={handleContinueFormRoleInfo}
                setCloseModal={setCloseModal}
                roleSelection={roleSelection}
                exceptionSarlaft={exceptionSarlaft}
                typeOfIdentification={rolesInfo[indexOfTaker]?.info?.documentType?.code}
              />
            )}
          </>
        )}
      </div>
    </ModalWrapper>
  );
};

ModalInfoPeople.propTypes = {
  roleSelection: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleContinueFormRoleInfo: PropTypes.func.isRequired,
  setCloseModal: PropTypes.func.isRequired,
  handleUpdateRoleObject: PropTypes.func.isRequired,
  isMaximumTakers: PropTypes.bool.isRequired,
  isMaximumInsured: PropTypes.bool.isRequired,
  isMaximumBeneficiaries: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default ModalInfoPeople;
