import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Controller,
  useForm,
  FormProvider,
  useFieldArray,
} from 'react-hook-form';
import { Row, Col } from 'react-grid-system';
import styled from 'styled-components';

// Libs
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';

// Components
import FileUpload from 'components/emit_components/basics/Sarlaft/FileUpload';
import Modal from 'components/Modal';
import RestrictedPerson from 'layouts/modals/RestrictedPerson';
import { isObjectEmpty } from 'utils/helpers/object';
import emitServices from '../../../../services/emit';
import Input from '../../basics/form/Input';
import Select from '../../basics/form/Select';
import Button from '../../basics/form/Button';
import Loader from '../../../Loader';
import FormRepresentativeLegal from './beneficiary/FormRepresentativeLegal';
import FormBeneficiaries from './beneficiary/Formbeneficiaries';

// Constants
import {
  LegalPersonRegisterOptions,
  optionsIdentities,
  optionsAddress,
  FILE_TYPES,
} from '../../../../utils/constants/emit_constants';
import {
  departments,
  cities,
} from '../../../../utils/constants/emit_constants/departments&cities';

// Helpers
import { validationRules } from '../../../../utils/helpers/stringValidations';
import {
  parseDataBeneficiary,
  deleteDataBeneficiary,
} from '../../../../utils/helpers/parseAddBeneficiaryData';

// Assets
import caution from '../../../../assets/icons/caution.svg';

// Styles
const HelperText = styled.span`
  color: var(--gray-400);
`;

const BeneficiaryDiv = styled.div`
  margin: 2.5rem auto;
  box-shadow: 3px 6px 22px #00000029;
  width: 966px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1200px) {
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
  }
`;

const ErrorText = styled.span`
  color: red;
  display: flex;
  font-size: 0.75rem;
`;

const TextFieldCustom = withStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: '#fff',
      border: '1px solid #C0BFC0',
      borderColor: 'var(--gray-300)',
      color: 'var(--gray-600)',
      background: '#ffffff',
      minHeight: '3rem',
      borderRadius: '5px',
      fontSize: '1rem',

      '& .MuiAutocomplete-popupIndicator': {
        '& path': {
          d:
            "path('M 12.018 14.88 L 19.577 7.47 l 1.204 1.124 L 12.032 17.243 L 3.298 8.649 L 4.486 7.477 Z')",
        },
      },

      '&.Mui-focused fieldset': {
        backgroundColor: '#fff',
      },

      '&.Mui-error': {
        backgroundColor: 'rgba(255, 245, 245, var(--bg-opacity))',
        borderColor: 'red',
      },
    },

    '& .MuiFilledInput-underline:after, & .MuiFilledInput-underline:before': {
      border: 'none',
    },

    '& .MuiFormLabel-root.Mui-focused': {
      color: 'var(--gray-600)',
    },

    '& .MuiFormLabel-root.Mui-error': {
      color: 'var(--gray-600)',
    },
  },
})(TextField);

const LegalPerson = ({
  roleSchema,
  setContinueFormRoleInfo,
  setCloseModal,
  roleSelection,
  exceptionSarlaft,
  isLegalPerson,
}) => {
  const {
    control,
    register,
    errors,
    setValue,
    getValues,
    formState,
    trigger,
    watch,
    setError,
    clearErrors,
    reset,
    unregister,
  } = useForm({
    mode: 'all',
    defaultValues: roleSchema,
    shouldUnregister: true,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'beneficiarie', // Nombre del array en el formulario
  });

  const [legalPersonIsBeneficiarie, setLegalPersonIsBeneficiarie] = useState(
    roleSelection?.info?.isLegalRepresentativeBeneficiarie
      ? roleSelection?.info?.isLegalRepresentativeBeneficiarie
      : {
          option: 'No',
          code: 'No',
        }
  );

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSaveForm = () => {
    setContinueFormRoleInfo(getValues());
  };

  useEffect(() => {
    register('department', LegalPersonRegisterOptions.department);
    register('city', LegalPersonRegisterOptions.city);
    register('documentType');
    trigger();
  }, []);

  const documentType = watch('documentType')?.code;
  const documentNumber = watch('documentNumber');
  const keyFile = exceptionSarlaft ? 'habeasData' : 'sarlaftFile';
  const fileTaker = watch(keyFile);
  const fileTakerCertificate = watch('certificateFile');
  const showFileTaker =
    roleSelection.isTaker &&
    (!roleSelection.hasHabeasData || !exceptionSarlaft);
  const IsNewPersonIaxis = roleSelection?.IsNewPersonIaxis;
  const isJuridicPerson =
    roleSelection?.info?.documentType?.name === 'NIT' ||
    roleSelection?.info?.documentType?.code === '37';
  const showFileCertificate =
    showFileTaker && isJuridicPerson && !IsNewPersonIaxis;
  const { isSarlaft } = roleSelection;

  const requireBeneficiaries =
    fields.length === 0 &&
    legalPersonIsBeneficiarie.code !== 'Si' &&
    showFileCertificate;

  useEffect(() => {
    if (!isSarlaft) {
      register(keyFile, {
        required: {
          value: showFileTaker,
          message: `Archivo de ${
            exceptionSarlaft ? 'habeas data' : 'sarlaft '
          } requerido`,
        },
      });
      trigger();
    }
    if (showFileCertificate) {
      register('certificateFile', {
        required: {
          value: showFileTaker,
          message: `Archivo de Certificado de Existencia y Representación Legal requerido`,
        },
      });
      trigger();
    }
  }, [showFileTaker, showFileCertificate]);

  const handleFileChange = (value, typeFile) => {
    const key = typeFile === FILE_TYPES.CERL ? 'certificateFile' : keyFile;
    setValue(key, value, {
      shouldValidate: true,
    });
    trigger();
  };

  const handleSearchPerson = async (
    indexBeneficiarie,
    isBeneficiarie = false
  ) => {
    setLoading(true);
    emitServices.user
      .search({
        document_number: isBeneficiarie
          ? getValues(
              `beneficiarie[${indexBeneficiarie}].documentNumberBeneficiary`
            )
          : getValues('documentNumberLegalRepresentative'),
        document_type: isBeneficiarie
          ? getValues(
              `beneficiarie[${indexBeneficiarie}].documentTypeBeneficiary`
            ).code
          : getValues('documentTypeLegalRepresentative').code,
        needClientRetry: false,
      })
      .then((response) => {
        const { roleData, userRestriction } = response.data.data;
        if (userRestriction) {
          setShowModal(true);
          if (isBeneficiarie) {
            setValue(
              `beneficiarie[${indexBeneficiarie}].documentNumberBeneficiary`,
              ''
            );
          } else {
            setValue('documentNumberLegalRepresentative', '');
          }
          trigger();
          return;
        }
        if (isBeneficiarie) {
          if (roleData.documentType.code === '37') {
            setValue(
              `beneficiarie[${indexBeneficiarie}].businessNameBeneficiary`,
              roleData.businessName
            );
          } else {
            setValue(
              `beneficiarie[${indexBeneficiarie}].firstNameBeneficiary`,
              roleData.firstName
            );
            setValue(
              `beneficiarie[${indexBeneficiarie}].secondNameBeneficiary`,
              roleData.secondName
            );
            setValue(
              `beneficiarie[${indexBeneficiarie}].surnameBeneficiary`,
              roleData.surname
            );
            setValue(
              `beneficiarie[${indexBeneficiarie}].secondSurnameBeneficiary`,
              roleData.secondSurname
            );
          }
          setValue(
            `beneficiarie[${indexBeneficiarie}].documentTypeBeneficiary`,
            roleData.documentType
          );
          setValue(
            `beneficiarie[${indexBeneficiarie}].documentNumberBeneficiary`,
            roleData.documentNumber
          );
          trigger();
        } else {
          setValue('firstNameLegalRepresentative', roleData.firstName);
          setValue('secondNameLegalRepresentative', roleData.secondName);
          setValue('surnameLegalRepresentative', roleData.surname);
          setValue('secondSurnameLegalRepresentative', roleData.secondSurname);
          setValue('documentTypeLegalRepresentative', roleData.documentType);
          setValue(
            'documentNumberLegalRepresentative',
            roleData.documentNumber
          );
          trigger();
        }
      })
      .catch(() => {})
      .finally(() => {
        trigger();
        setLoading(false);
      });
  };

  const finishProcess = () => {
    setShowModal(false);
  };
 
  const fileIsRequired =
    ((showFileTaker && !isSarlaft) || (fileTaker && showFileTaker)) &&
    (!roleSelection.hasHabeasData || !exceptionSarlaft);
  return (
    <FormProvider
      {...{
        control,
        register,
        trigger,
        errors,
        formState,
        setValue,
        watch,
        getValues,
        clearErrors,
        setError,
        reset,
        unregister,
      }}
    >
      {loading && <Loader />}
      <Modal showModal={showModal} setShowModal={setShowModal} lg={false}>
        <RestrictedPerson
          setShowModal={setShowModal}
          finishProcess={finishProcess}
        />
      </Modal>
      <div className="text-left dark-blue mb-2 mt-4 animate__animated animate__fadeInUp">
        <div className="flex items-center flex-col px-3 md:max-w-3xl mx-auto">
          <Row className="m-auto space-y-8 px-3 md:max-w-3xl mx-auto">
            <Col
              sm={12}
              className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-6"
            >
              <Controller
                key="controller-documentType-LegalPerson"
                as={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Select
                    placeholder="Tipo de identificación"
                    options={optionsIdentities}
                    optionKey="name"
                    optionLabel="name"
                    onChange={() => trigger('documentType')}
                    errorMessage={
                      errors.documentType && errors.documentType.message
                    }
                    disabled={!!roleSchema.documentType?.code}
                  />
                }
                name="documentType"
                control={control}
                rules={LegalPersonRegisterOptions.documentType}
                valueDefault={roleSchema.documentType}
              />

              <Input
                placeholder="Número de identificación"
                type="number"
                name="documentNumber"
                ref={register(LegalPersonRegisterOptions.documentNumber)}
                controlled={false}
                errorMessage={
                  errors.documentNumber && errors.documentNumber.message
                }
                disabled={!!roleSchema.documentNumber}
              />
            </Col>
            <Col
              lg={12}
              className="flex flex-col space-y-8  lg:space-y-0 lg:flex-row lg:space-x-6"
            >
              <Input
                placeholder="Razón social*"
                type="text"
                name="businessName"
                inputClass="w-100"
                controlled={false}
                errorMessage={
                  errors.businessName ? errors.businessName.message : ''
                }
                disabled={!!roleSchema.businessName}
                ref={register(LegalPersonRegisterOptions.businessName)}
              />
            </Col>
            <Col lg={6} className="flex flex-col">
              <Controller
                key="controller-email"
                render={() => (
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Input
                    placeholder="Correo electrónico*"
                    type="email"
                    name="email"
                    inputClass=""
                    controlled
                    errorMessage={errors.email ? errors.email.message : ''}
                    disabled={false}
                    onChange={(e) => {
                      const { value } = e.target;
                      setValue('email', value.toLowerCase());
                      trigger('email');
                    }}
                    value={getValues().email}
                  />
                )}
                name="email"
                control={control}
                rules={validationRules}
              />

              <HelperText className="text-xs mt-2">
                Ejemplo: nombre@mail.com
              </HelperText>
            </Col>

            <Col lg={6}>
              {!isObjectEmpty(getValues()) && (
                <div className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-3">
                  <div className="relative w-full">
                    {errors.department && (
                      <ErrorText className="absolute top-0 -mt-5">
                        {errors.department.message && (
                          <img
                            className="w-4 mr-1"
                            src={caution}
                            alt="icon caution"
                          />
                        )}
                        <span className="truncate">
                          {errors.department.message}
                        </span>
                      </ErrorText>
                    )}
                    <Autocomplete
                      value={getValues().department}
                      onChange={(event, newValue) => {
                        setValue('department', newValue, {
                          shouldValidate: true,
                        });
                        setValue('city', '', {
                          shouldValidate: true,
                        });
                        trigger();
                      }}
                      options={departments}
                      getOptionLabel={(option) => option.name}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextFieldCustom
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={errors.department}
                          label="Departamento*"
                          variant="filled"
                          size="small"
                        />
                      )}
                    />
                  </div>
                  <div className="relative w-full">
                    {errors.city && (
                      <ErrorText className="absolute top-0 -mt-5">
                        {errors.city.message && (
                          <img
                            className="w-4 mr-1"
                            src={caution}
                            alt="icon caution"
                          />
                        )}
                        <span className="truncate">{errors.city.message}</span>
                      </ErrorText>
                    )}
                    <Autocomplete
                      value={getValues().city}
                      onChange={(event, newValue) => {
                        setValue('city', newValue, {
                          shouldValidate: true,
                        });
                        trigger();
                      }}
                      options={cities[getValues().department?.code] || []}
                      getOptionLabel={(option) => option.name}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextFieldCustom
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={errors.city}
                          label="Ciudad*"
                          variant="filled"
                          size="small"
                        />
                      )}
                    />
                  </div>
                </div>
              )}
            </Col>

            <Col
              sm={12}
              className="flex flex-col space-y-8  lg:space-y-0 lg:flex-row lg:space-x-6"
            >
              <Input
                placeholder="Teléfono Móvil*"
                type="number"
                name="phoneNumber"
                inputClass="w-100"
                controlled={false}
                errorMessage={
                  errors.phoneNumber ? errors.phoneNumber.message : ''
                }
                ref={register(LegalPersonRegisterOptions.phoneNumber)}
              />
              <Controller
                key="controller-addressType-LegalPerson"
                as={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Select
                    placeholder="Tipo de dirección*"
                    options={optionsAddress}
                    optionKey="code"
                    optionLabel="name"
                    errorMessage={
                      errors.addressType ? errors.addressType.message : ''
                    }
                  />
                }
                name="addressType"
                control={control}
                rules={LegalPersonRegisterOptions.addressType}
                valueDefault={
                  roleSchema.addressType.code ? roleSchema.addressType : ''
                }
              />
            </Col>
            <Col sm={12} className="flex flex-col ">
              <Input
                placeholder="Dirección*"
                type="text"
                name="address"
                inputClass="w-100"
                controlled={false}
                errorMessage={errors.address ? errors.address.message : ''}
                ref={register(LegalPersonRegisterOptions.address)}
              />
              <HelperText className="text-xs mt-2">
                Ejemplo: calle 1234 b No 24 a 54 b
              </HelperText>
            </Col>
          </Row>
        </div>
        <Row>
          <Col sm={12}>
            {fileIsRequired && (
              <>
                <FileUpload
                  documentType={documentType}
                  documentNumber={documentNumber}
                  onChange={handleFileChange}
                  value={fileTaker || ''}
                  typeFile={
                    exceptionSarlaft
                      ? FILE_TYPES.HABEAS_DATA
                      : FILE_TYPES.SARLAFT
                  }
                />

                <p className="text-center text-red-600">
                  {exceptionSarlaft
                    ? errors?.habeasData?.message
                    : errors?.sarlaftFile?.message}
                </p>
              </>
            )}
          </Col>
          {showFileCertificate && (
            <Col sm={12}>
              <>
                <FileUpload
                  documentType={documentType}
                  documentNumber={documentNumber}
                  onChange={handleFileChange}
                  value={fileTakerCertificate || ''}
                  typeFile={FILE_TYPES.CERL}
                />
                <p className="text-center text-red-600">
                  {errors?.certificateFile?.message}
                </p>
              </>
            </Col>
          )}
        </Row>
        {showFileCertificate && (
          <BeneficiaryDiv>
            <FormRepresentativeLegal
              roleInfo={roleSelection}
              handleSearchPerson={handleSearchPerson}
              legalPersonIsBeneficiarie={legalPersonIsBeneficiarie}
              setLegalPersonIsBeneficiarie={setLegalPersonIsBeneficiarie}
              clearDocumentNumber={showModal}
            />
            <hr />
            <FormBeneficiaries
              roleInfo={roleSelection}
              handleSearchPerson={handleSearchPerson}
              legalPersonIsBeneficiarie={legalPersonIsBeneficiarie}
              isLegalPerson={isLegalPerson}
              clearDocumentNumber={showModal}
              fields={fields}
              append={append}
              remove={remove}
            />
          </BeneficiaryDiv>
        )}

        <div className="flex items-center flex-col px-3 md:max-w-3xl mx-auto">
          <div className="flex justify-center">
            <Button className="mr-4" onClick={setCloseModal}>
              Cancelar
            </Button>
            <Button
              primary
              disabled={!formState.isValid || requireBeneficiaries}
              onClick={handleSaveForm}
            >
              Guardar
            </Button>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

LegalPerson.propTypes = {
  roleSchema: PropTypes.object.isRequired,
  setContinueFormRoleInfo: PropTypes.func.isRequired,
  setCloseModal: PropTypes.func.isRequired,
  isSarlaft: PropTypes.bool.isRequired,
  roleSelection: PropTypes.object.isRequired,
  exceptionSarlaft: PropTypes.bool.isRequired,
  isLegalPerson: PropTypes.bool.isRequired,
};

export default LegalPerson;
