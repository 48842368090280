import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import { createUpdateValuesInsure } from 'reducers/quoteInfo';
// components
import DashedCard from 'components/DashedCard';
import Tooltip from 'components/Tooltip';
import Card from 'components/Card';
import Input from 'components/Input';
// helpers
import { getValidations } from 'utils/helpers/reactFormValidations';
import { formatPrice } from 'utils/helpers/normalizeValues';
// assets
import plus from 'assets/icons/plus.svg';
import link from 'assets/icons/link.svg';
import circleClose from 'assets/icons/circle-close.svg';

export default function ValuesEnsureCard({
  valueEnsure,
  currentRisk,
  capitalCalculation,
  draftIndex,
  riskIndex,
  idComponent,
  type,
}) {
  const { control, errors, setValue, trigger, getValues, setError, clearErrors } = useFormContext();
  const dispatch = useDispatch();
  const { valoresAsegurar, literales, ubicacion, descripcion } = valueEnsure;
  const [isSelected, setIsSelected] = useState(false);
  const name = `${idComponent}.${valueEnsure.id}`;
  useEffect(() => {
    if (
      currentRisk &&
      currentRisk[idComponent] &&
      currentRisk[idComponent][valueEnsure.id]
    ) {
      setIsSelected(true);
    }
  }, [currentRisk, idComponent]);

  const removeValueInsure = () => {
    dispatch(
      createUpdateValuesInsure({
        draftIndex,
        riskIndex,
        risk: {
          [idComponent]: {
            [valueEnsure.id]: '0',
          },
        },
      })
    );
    setIsSelected(false);
    capitalCalculation();
  };

  const handleCustomOnMouseOut = async (e) => {
    e.persist();
    if (
      (getValues(name) === 0 && getValues(name) !== null) ||
      getValues(name) === currentRisk[idComponent][valueEnsure.id]
    ) {
      return;
    }

    dispatch(async (handlerDispatch) => {
      handlerDispatch(
        createUpdateValuesInsure({
          draftIndex,
          riskIndex,
          risk: {
            [idComponent]: {
              [valueEnsure.id]: getValues(name),
            },
          },
        })
      );
    });
    capitalCalculation();
  };

  return isSelected ? (
    <Card key={`card-${name}`}>
      <div
        className="min-w-220 min-h-220 p-4 pt-6 h-full flex flex-col justify-between items-center"
        onMouseLeave={handleCustomOnMouseOut}
      >
        <div className="flex items-center justify-between w-full">
          <div className="min-h-50">
            <p className="text-base font-medium text-blue mr-2">
              {valoresAsegurar}
            </p>
            {descripcion && (
              <p className="text-xs text-gray-500">{descripcion}</p>
            )}
          </div>
          <img
            src={require(`assets/icons/values-ensure/${ubicacion}`)}
            alt="img-icon-values"
          />
        </div>
        <div className="w-full">
          <Controller
            name={name}
            control={control}
            render={({ value }) => (
              // eslint-disable-next-line
              <Input
                placeholder="Valor"
                inputClass={valueEnsure.inputClass}
                errorMessage={
                  errors[idComponent] &&
                  errors[idComponent][valueEnsure.id] &&
                  errors[idComponent][valueEnsure.id].message
                }
                customOnBlur={handleCustomOnMouseOut}
                customLabel={`Valor máximo $${formatPrice(
                  parseInt(valueEnsure.validacionMenorIgualQue, 10)
                )}`}
                positionLabel="bottom"
                type={valueEnsure.tipo}
                value={value}
                onValueChange={async (values) => {
                  const newValue = values.floatValue;
                  if ((!newValue) || (newValue <= 0)) {
                    setError(name, {
                      type: "required",
                      message: "El valor debe ser mayor a 0",
                      shouldFocus: true
                    });
                  } else {
                    setValue(name, newValue, {
                      shouldValidate: true
                    });
                    trigger(name);
                  }
                }}
              />
            )}
            rules={getValidations({
              ...valueEnsure,
              validacionMenorIgualQueMsjError: `Valor maximo $${formatPrice(
                valueEnsure.validacionMenorIgualQue
              )}`,
            })}
          />
        </div>
        <p
          role="presentation"
          onClick={removeValueInsure}
          className="cursor-pointer text-xs font-medium text-blue bg-lightTeal flex rounded px-2 py-1"
        >
          No lo quiero
          <img src={circleClose} alt="close icon" className="ml-1" />
        </p>
      </div>
    </Card>
  ) : (
    <DashedCard type={type}>
      <div
        onClick={() => setIsSelected(true)}
        role="presentation"
        className="cursor-pointer relative min-w-220 min-h-220 p-8 flex flex-col justify-center items-center"
      >
        <div className="w-8 h-8 p-2 bg-teal mb-1 rounded">
          <img src={plus} alt="plus icon" />
        </div>
        <p className="text-base font-medium text-blue">{`Asegurar ${valoresAsegurar}`}</p>
        <div className="flex bg-teal p-1 rounded items-center absolute bottom-10">
          <Tooltip
            content={
              literales && (
                <p className="text-sm text-white leading-tight">{literales}</p>
              )
            }
          >
            <div className="flex">
              <p className="text-xs text-blue font-medium mr-1">
                Ver lo que puedes asegurar
              </p>
              <img src={link} alt="plus icon" />
            </div>
          </Tooltip>
        </div>
      </div>
    </DashedCard>
  );
}

ValuesEnsureCard.propTypes = {
  valueEnsure: PropTypes.object.isRequired,
  capitalCalculation: PropTypes.func.isRequired,
  draftIndex: PropTypes.number.isRequired,
  riskIndex: PropTypes.number.isRequired,
  idComponent: PropTypes.string.isRequired,
  type: PropTypes.string,
  currentRisk: PropTypes.object,
};

ValuesEnsureCard.defaultProps = {
  type: 'info',
  currentRisk: {},
};
