import * as yup from 'yup';

const verifyDigit = (nit) => {
  const verificationDigit = nit.substring(nit.length - 1, nit.length);
  const nitWithoutDigit = nit.substring(0, nit.length - 1);

  if (
    parseInt(nitWithoutDigit, 10) < 800000000 ||
    parseInt(nitWithoutDigit, 10) > 999999999
  ) {
    return false;
  }

  const vpri = [null, 3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71];
  let x = 0;
  let y = 0;
  const z = nitWithoutDigit.length;

  for (let i = 0; i < z; i += 1) {
    y = nitWithoutDigit.substr(i, 1);
    x += y * vpri[z - i];
  }

  y = x % 11;
  const resultDigit = y > 1 ? 11 - y : y;

  return Number(verificationDigit) === Number(resultDigit);
};

const NITSchema = yup
  .number('Solo se admiten valores numéricos')
  .min(700000000, 'Valor mínimo de NIT: 700000000')
  .max(9999999999, 'Valor máximo para NIT: 9999999999')
  .test('verify-digit', 'Dígito de verificación inválido', (value) =>
    verifyDigit(String(value))
  );

const CCSchema = yup
  .number('Solo se admiten valores numéricos')
  .test(
    'minChars',
    'Debe tener al menos 3 carácteres',
    (val) => String(val).length >= 3
  )
  .test(
    'maxChars',
    'Debe máximo 10 carácteres',
    (val) => String(val).length <= 10
  );

const CESchema = yup
  .number('Solo se admiten valores numéricos')
  .test(
    'minChars',
    'Debe tener al menos 3 carácteres',
    (val) => String(val).length >= 3
  )
  .test(
    'maxChars',
    'Debe máximo 18 carácteres',
    (val) => String(val).length <= 18
  );

const RCSchema = yup
  .number('Solo se admiten valores numéricos')
  .test(
    'minChars',
    'Debe tener al menos 3 carácteres',
    (val) => String(val).length >= 3
  )
  .test(
    'maxChars',
    'Debe máximo 20 carácteres',
    (val) => String(val).length <= 20
  );

export const validateDocument = (number, type) => {
  try {
    switch (type) {
      case '36':
      case '44':
      case '40':
      case '34':
      case '38':
      case '48':
        CCSchema.validateSync(number);
        break;
      case '33':
        CESchema.validateSync(number);
        break;
      case '35':
        RCSchema.validateSync(number);
        break;
      case '37':
        NITSchema.validateSync(number);
        break;
      default:
        break;
    }
    return false;
  } catch (error) {
    return error;
  }
};

export default verifyDigit;

export const validationRules = {
  required: 'Este campo es requerido',
  validate: {
    emailFormat: (value) => {
      // Validación de formato de correo electrónico
      if (
        !/^[A-Za-z0-9.~!ñÑ#$%&'()*+/\=?^_`{|}-]+@([A-Za-z0-9.-]+)+[A-Za-z]{2,}$/.test(
          value
        )
      ) {
        return 'Ingresa el @ o caracteres válidos “!”,“#”,“$”,“%”,“&”,“”,“’”,“*”,“+”,“-”,“/”,“=“,”?”,“^”,“_”,“`”,“{“,“|”,“}”';
      }

      if (value.charAt(0) === '.') {
        return 'El primer carácter no puede ser un punto (.)';
      }
      // Validación de no tener dos puntos consecutivos (..)
      if (value.includes('..')) {
        return 'No se permiten dos puntos consecutivos (..)';
      }

      // Validación de no tener un punto ó - antes del @
      if (
        (value.indexOf('@') > 0 &&
          value
            .substring(value.indexOf('@') - 1, value.indexOf('@'))
            .includes('.')) ||
        (value.indexOf('@') > 0 &&
          value
            .substring(value.indexOf('@') - 1, value.indexOf('@'))
            .includes('-'))
      ) {
        if (
          (value.includes('@outlook') || value.includes('@hotmail')) &&
          value.indexOf('@') > 0 &&
          value
            .substring(value.indexOf('@') - 1, value.indexOf('@'))
            .includes('-')
        ) {
          return true;
        }
        if (value.includes('@outlook') || value.includes('@hotmail')) {
          return 'Outlook/Hotmail no permite un punto antes del @';
        }

        return 'No se permite un punto ó un - antes del @';
      }
      if (value.includes('ñ') || value.includes('Ñ')) {
        return 'No se permite la tecla ñ';
      }
      const afterAt = value.split('@')[1];
      if (afterAt && afterAt.length > 255) {
        return 'Después del @ no puede tener más de 255 caracteres';
      }
      if (afterAt) {
        const domain = afterAt.split('.')[0];
        if (
          domain &&
          (domain.charAt(0) === '-' || domain.charAt(domain.length - 1) === '.')
        ) {
          return 'El dominio no puede comenzar con "-" ni terminar con "." o terminar con "-"';
        }
      }
      // Validación de dominio con al menos 2 caracteres
      if (afterAt) {
        const domain = afterAt.split('.')[1];
        if (domain !== undefined) {
          if (domain.length < 2) {
            return 'El dominio debe tener al menos 2 caracteres';
          }
        }
      }

      const beforeAt = value.split('@')[0];
      // Validación para Outlook/Hotmail
      if (value.includes('@outlook') || value.includes('@hotmail')) {
        // Debe iniciar con letra
        if (!/^[a-z]/.test(beforeAt)) {
          return 'Outlook/Hotmail debe iniciar con letra y no permite iniciar con mayusculas';
        }
        // No permite caracteres especiales a excepción de guión bajo, punto y guión normal
        if (!/^[a-zA-Z0-9_.-]+$/.test(beforeAt)) {
          return 'Outlook/Hotmail no permite caracteres especiales, excepto _, . y -';
        }
        // No permite terminar con punto
        if (beforeAt.endsWith('.')) {
          return 'Outlook/Hotmail no permite terminar con punto';
        }
      }

      // Validación para Yahoo
      if (value.includes('@yahoo')) {
        // Debe iniciar con letra
        if (!/^[a-z]/.test(beforeAt)) {
          return 'Yahoo debe iniciar con letra y no permite iniciar con mayusculas';
        }
        // No permite caracteres especiales a excepción de guión bajo y punto
        if (!/^[a-zA-Z0-9_.]+$/.test(beforeAt)) {
          return 'Yahoo no permite caracteres especiales, excepto _ y .';
        }
        // Debe terminar con letra o número
        if (!/[a-zA-Z0-9]$/.test(beforeAt)) {
          return 'Yahoo debe terminar con letra o número';
        }
        // No permite más de un punto o guion bajo seguidos
        if (beforeAt.includes('..') || beforeAt.includes('__')) {
          return 'Yahoo no permite más de un punto o guion bajo seguidos';
        }
      }

      // Validación para Gmail
      if (value.includes('@gmail')) {
        // El primer carácter debe ser una letra o número
        if (!/^[a-z0-9]/.test(beforeAt.charAt(0))) {
          return 'Gmail debe comenzar con letra o número y no permite iniciar con mayusculas';
        }
        // No permite caracteres especiales, excepto punto
        if (!/^[a-zA-Z0-9.]+$/.test(beforeAt)) {
          return 'Gmail no permite caracteres especiales, excepto punto';
        }
        // No permite iniciar con punto
        if (beforeAt.charAt(0) === '.') {
          return 'Gmail no permite iniciar con punto';
        }
        // No permite puntos al final
        if (beforeAt.endsWith('.')) {
          return 'Gmail no permite terminar con punto';
        }
        // No permite guion bajo en ninguna posición
        if (beforeAt.includes('_')) {
          return 'Gmail no permite guion bajo en ninguna posición';
        }
      }

      return true;
    },
    emailLength: (value) => {
      // Validación de longitud antes del @
      const parts = value.split('@');
      if (parts.length !== 2 || parts[0].length > 64) {
        return 'El correo electrónico debe tener maximo 64 caracteres antes del @';
      }
      return true;
    },
    // Puedes agregar más validaciones personalizadas aquí
  },
};
