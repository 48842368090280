/* eslint-disable camelcase */
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { client, clientRetry } from 'services/axios';
import moment from 'moment-timezone';

import { getBarcode } from 'services/emit/getBarcode';
import { addBeneficiary } from 'services/emit/addBeneficiary';
import { HEADERS_JSON } from '../../utils/constants/DefaultBodies';
import {
  CURRENT_TIMEZONE,
  LegalPersonSchema,
  NaturalPersonSchema,
} from '../../utils/constants/emit_constants';
import {
  fetchEmitInfoStart,
  fetchEmitInfoSuccess,
  fetchEmitInfoError,
  updateRolesEmitInfo,
  updateVigencyDateEmitInfo,
  updateCurrentTabEmitInfo,
  updatePolicyNumberCaratula,
  updateProcessNumberCaratula,
  updatePolicyBarcode,
  updateAddBeneficiary,
  updatePolicyNumberCertificado,
  readySQS,
  setLoading,
} from '../reducers/emitInfo';

import { parseRequestBeneficiary } from '../../utils/helpers/parseAddBeneficiaryData';

const urlEmit = process.env.REACT_APP_URL_EMIT;

export const updateRoleObject = (roleIdSelected, key, value) => (
  dispatch,
  getState
) => {
  const { roles } = getState().emitInfo.data;
  const indexRole = roles.findIndex((role) => role.idCard === roleIdSelected);
  if (indexRole !== -1) {
    const newRole = roles[indexRole];
    if (key === 'info') value = { ...newRole.info, ...value };
    newRole[key] = value;
    const { info } = newRole;
    const canHaveSomeRole = ['beneficiaryBuilding', 'beneficiaryMachinery'];
    const indexExistRole = roles.findIndex(
      ({ idCard, info: { documentNumber, documentType }, type }) =>
        documentNumber === info.documentNumber &&
        documentType?.code === info.documentType?.code &&
        idCard !== roleIdSelected &&
        (canHaveSomeRole.includes(type) ? type === newRole.type : true)
    );

    if (indexExistRole !== -1) {
      const roleExist = roles[indexExistRole];
      const { isBeneficiary, isInsured, isTaker } = roleExist;
      const { sarlaftFile, habeasData } = roleExist.info;
      if (isBeneficiary) newRole.isBeneficiary = true;
      if (isInsured) newRole.isInsured = true;
      if (isTaker) newRole.isTaker = true;
      if (sarlaftFile) newRole.info.sarlaftFile = sarlaftFile;
      if (habeasData) newRole.info.habeasData = habeasData;
      roles[indexRole] = Object.assign(roleExist, newRole);
      roles.splice(indexExistRole, 1);
    }
    if (key === 'isTaker' && value === false) {
      newRole.isSarlaft = newRole.isSarlaft === true;
      delete newRole?.isHabeasData;
      delete newRole?.info?.habeasData;
      delete newRole?.info?.sarlaftFile;
    }
    dispatch(updateRolesEmitInfo({ roles: [...roles] }));
  }
};

export const createNewRole = (newRole) => (dispatch, getState) => {
  const { roles } = getState().emitInfo.data;
  dispatch(updateRolesEmitInfo({ roles: roles.concat([newRole]) }));
};

export const deleteRole = (roleIdSelected) => (dispatch, getState) => {
  const { roles } = getState().emitInfo.data;
  const newRoles = roles.filter((role) => role.idCard !== roleIdSelected);
  dispatch(updateRolesEmitInfo({ roles: newRoles }));
};

export const updateVigencyDate = (roleIdSelected) => (dispatch) => {
  dispatch(
    updateVigencyDateEmitInfo({
      insurability_statement_start_date: roleIdSelected,
    })
  );
};

export default (draftCurrent) => async (dispatch, getState) => {
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };
  const {
    compBrokerId,
    compDate,
    business,
    taker,
    quoteNumber,
    drafts,
    Date: quoteDate,
    broker,
    exceptionSarlaft
  } = getState().quoteInfo.data;
  const { totalNegocio } = drafts[draftCurrent];

  const formatRoleInfo = (documentType) => {
    let info = {
      documentType: {
        name: taker.selectedOption.label,
        code: taker.selectedOption.value,
      },
      documentNumber: taker.NumIdentificacion,
    };
    if (documentType === '37') {
      info = {
        ...LegalPersonSchema,
        ...info,
        businessName: taker.RazSocial,
      };
    } else {
      info = {
        ...NaturalPersonSchema,
        ...info,
        firstName: taker.PrimNombre,
        surname: taker.PrimApellido,
      };
    }
    return info;
  };

  const roleInfo = [
    {
      idCard: `${uuidv4()}`,
      isSelectCard: true,
      isTaker: true,
      isInsured: false,
      isBeneficiary: false,
      isNotValidRole: false,
      type: 'taker',
      business_name: business.name,
      isCompleteInfo: false,
      info: formatRoleInfo(taker.selectedOption.value),
    },
  ];

  const risks = drafts[draftCurrent].risks.map((risk) => {
    const {
      ValoAsegur,
      DireccionEei,
      CiudadDep,
      NumPiso: { descripcion },
    } = risk;
    return {
      insuredValue: ValoAsegur,
      address: DireccionEei,
      locationInfo: { ...CiudadDep, floorNumber: descripcion },
    };
  });

  const requestPayload = {
    data: {
      emission_id: `${compDate}#${draftCurrent}`,
      quote_id: compDate,
      vigency_start_date: Date.now(),
      quote_data: {
        compBrokerId,
        compDate,
        draftCurrent,
        primeValue: String(totalNegocio),
        quoteNumber,
        risks,
        quoteDate,
        exceptionSarlaft,
      },
      business,
      broker_id: broker,
      roles: roleInfo,
      currentRoleId: '',
      step: 0,
    },
  };

  dispatch(fetchEmitInfoStart());
  try {
    const { data } = await axios.post(
      `${urlEmit}/emission/create`,
      requestPayload,
      {
        headers,
      }
    );
    dispatch(fetchEmitInfoSuccess(data.data));
  } catch (e) {
    dispatch(fetchEmitInfoError(e));
  }
};

export const saveDataInEmision = (payload) => ({
  type: 'SAVE_DATA_IN_EMISSION',
  payload,
});

export const requestGetEmitInfo = (emissionId) => async (dispatch) => {
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };

  const requestPayload = {
    data: {
      emission_id: emissionId,
    },
  };

  dispatch(fetchEmitInfoStart());
  try {
    const { data } = await axios.post(
      `${urlEmit}/emission/show`,
      requestPayload,
      {
        headers,
      }
    );
    dispatch(saveDataInEmision(data.data.emission_id));
    dispatch(fetchEmitInfoSuccess(data.data));
  } catch (e) {
    dispatch(fetchEmitInfoError(e));
  }
};

export const requestUpdateRoles = () => async (dispatch, getState) => {
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };

  const emissionId = getState().emitInfo.data.emission_id;
  const { roles } = getState().emitInfo.data;

  const requestPayload = {
    data: {
      emission_id: emissionId,
      roles,
    },
  };

  dispatch(fetchEmitInfoStart());
  try {
    const { data } = await axios.post(
      `${urlEmit}/emission/roles/update`,
      requestPayload,
      {
        headers,
      }
    );
    dispatch(updateRolesEmitInfo(data.data));
  } catch (e) {
    dispatch(fetchEmitInfoError(e));
  }
};

export const requestUpdateVigencyDate = () => async (dispatch, getState) => {
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };

  const emissionId = getState().emitInfo.data.emission_id;
  const vigencyDate = Number(
    getState().emitInfo.data.insurability_statement_start_date
  );

  const requestPayload = {
    data: {
      emission_id: emissionId,
      insurability_statement_start_date: vigencyDate,
    },
  };

  dispatch(fetchEmitInfoStart());
  try {
    const { data } = await axios.post(
      `${urlEmit}/emission/vigency-date/update`,
      requestPayload,
      {
        headers,
      }
    );
    dispatch(updateVigencyDateEmitInfo(data.data));
  } catch (e) {
    dispatch(fetchEmitInfoError(e));
  }
};

export const updateCurrentTab = (currentStep) => async (dispatch, getState) => {
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };

  const emissionId = getState().emitInfo.data.emission_id;

  const requestPayload = {
    data: {
      emission_id: emissionId,
      step: currentStep,
    },
  };

  try {
    const { data } = await axios.post(
      `${urlEmit}/emission/step/update`,
      requestPayload,
      {
        headers,
      }
    );
    dispatch(updateCurrentTabEmitInfo({ step: data.data.step }));
  } catch (e) {
    dispatch(fetchEmitInfoError(e));
  }
};

export const updateEmissionDate = (emissionDate) => async (
  dispatch,
  getState
) => {
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };

  const emissionId = getState().emitInfo.data.emission_id;

  const requestPayload = {
    data: {
      emission_id: emissionId,
      emission_date: emissionDate,
    },
  };

  try {
    await axios.post(
      `${urlEmit}/emission/emission-date/update`,
      requestPayload,
      {
        headers,
      }
    );
  } catch (e) {
    dispatch(fetchEmitInfoError(e));
  }
};

export const createBeneficiaries = () => async (dispatch, getState) => {
  const vigencyDate = Number(
    getState().emitInfo.data.insurability_statement_start_date
  );
  const time = new Date(vigencyDate);

  const year = time.getFullYear();
  const month = String(time.getMonth() + 1).padStart(2, '0'); // Sumar 1 porque los meses van de 0 a 11
  const day = String(time.getDate()).padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;
  const emissionDataBeneficiary = getState().emitInfo.data.roles;
  const payloadBeneficiary = parseRequestBeneficiary(
    emissionDataBeneficiary,
    formattedDate
  );

  try {
    const responseAddBeneficiary = await addBeneficiary(payloadBeneficiary);
    dispatch(updateAddBeneficiary(responseAddBeneficiary));
  } catch (e) {
    dispatch(fetchEmitInfoError(e));
  }
};

export const generateBarcode = () => async (dispatch, getState) => {
  const emitInfoData = getState()?.emitInfo?.data;
  const { emission_id, policy_number_caratula: npoliza } = emitInfoData;
  const indexOfTaker = emitInfoData.roles.findIndex(
    (role) => role.isTaker === true
  );
  const takerInfo = emitInfoData.roles[indexOfTaker];
  try {
    dispatch(setLoading(true));
    const barcode = await getBarcode({
      npoliza,
      emission_id,
    });
    if (
      !takerInfo.IsNewPersonIaxis ||
      'isLegalRepresentativeBeneficiarie' in takerInfo.info
    ) {
      dispatch(createBeneficiaries());
    }
    dispatch(setLoading(false));
    dispatch(updatePolicyBarcode(barcode));
    let emission_date = moment().tz(CURRENT_TIMEZONE).format();
    emission_date = Date.parse(emission_date);
    dispatch(updateEmissionDate(emission_date));
    dispatch(updateCurrentTab(1));
  } catch (e) {
    dispatch(fetchEmitInfoError(e));
  }
};

export const closePolicy = () => async (dispatch, getState) => {
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };

  const emitInfoData = getState()?.emitInfo?.data;
  const {
    emission_id,
    policy_number_caratula: npoliza,
    process_number_caratula: sprocess,
    process_number_certificado: sprocess_certificado,
  } = emitInfoData;

  const requestPayload = {
    npoliza,
    sprocess,
    sprocess_certificado,
    emission_id,
  };

  try {
    await clientRetry.post(`${urlEmit}/emission/close-policy`, requestPayload, {
      headers,
    });

    dispatch(generateBarcode());
  } catch (e) {
    dispatch(fetchEmitInfoError(e));
  }
};

export const movedFiles = () => async (dispatch, getState) => {
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };

  const emission_id = getState()?.emitInfo?.data.emission_id;
  const requestPayload = { emission_id };

  try {
    await axios.post(`${urlEmit}/emission/movedFiles`, requestPayload, {
      headers,
    });
    dispatch(closePolicy());
  } catch (e) {
    dispatch(fetchEmitInfoError(e));
  }
};

export const createPolicy = () => async (dispatch, getState) => {
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };

  const emissionId = getState().emitInfo.data.emission_id;
  const requestPayload = {
    data: {
      emission_id: emissionId,
    },
  };

  dispatch(fetchEmitInfoStart());
  try {
    const { data } = await clientRetry.post(
      `${urlEmit}/emission/createPolicy`,
      requestPayload,
      {
        headers,
      }
    );
    if ('caratula_response' in data && data.caratula_response.numeroPoliza) {
      dispatch(
        updatePolicyNumberCaratula(String(data.caratula_response.numeroPoliza))
      );
    }
    // Almacenar tambien numero de proceso del servicio de caratula
    if (data?.caratula_response?.consecutivoProceso) {
      dispatch(
        updateProcessNumberCaratula(
          String(data.caratula_response.consecutivoProceso)
        )
      );
    }
    if (
      data.certificate_response?.data?.crearPolizaIAXISRs?.emisionPoliza
        ?.resultadoPolizaHome?.codigoProcesoCargue
    ) {
      dispatch(
        updatePolicyNumberCertificado(
          String(
            data.certificate_response?.data?.crearPolizaIAXISRs?.emisionPoliza
              ?.resultadoPolizaHome?.codigoProcesoCargue
          )
        )
      );
    }
    if (!(data.statusPolicy === 'MOVEDFILES_COMPLETE')) {
      dispatch(movedFiles());
    } else {
      dispatch(closePolicy());
    }
  } catch (e) {
    dispatch(fetchEmitInfoError(e));
  }
};

export const emitPolicySQS = () => async (dispatch, getState) => {
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };

  const emission_id = getState()?.emitInfo?.data.emission_id;
  const requestPayload = { emission_id };

  try {
    await axios.post(`${urlEmit}/emission/policy/queue`, requestPayload, {
      headers,
    });
    dispatch(readySQS());
  } catch (e) {
    dispatch(fetchEmitInfoError(e));
  }
};
