import React from 'react';
import Proptypes from 'prop-types';
import Button from '../../../basics/form/Button';
import ModalWrapper from '../../../basics/modal/ModalWrapper';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

const CustomP = styled.p`
  font-size: 10px
`;

const ModalErrorCreatePolicy = ({ showModal, onMyBussiness, onRetry, isErrorOpenL = false, showButtonBussines = false }) => {

  const errorClosePolicy = useSelector((state) => state.emitInfo?.error?.message === "Request failed with status code 503")


  return (
    <ModalWrapper size="xl" showButtonClose={false} showModal={showModal}>
      <div className="text-center bg-gray-100 py-12 px-6">
        <h2 className="text-2xl mb-3 dark-blue font-medium leading-tight">
          Lo sentimos
        </h2>
        <p className="text-sm text-gray-600 mb-5">
          Tenemos un problema técnico en este momento
        </p>
        {
          isErrorOpenL ? (<p className="text-sm text-gray-600 mb-3 mx-0 md:mx-24">
            {`Por favor, dale al botón para reintentar agregar esta `}
            <span className="font-medium medium-teal"> cobertura</span>
          </p>) : (<p className="text-sm text-gray-600 mb-3 mx-0 md:mx-24">
            {`Por favor, retoma la cotización en unos minutos desde `}
            <span className="font-medium medium-teal">Mis negocios</span>
          </p>)
        }

        <div className="flex flex-col px-4 sm:flex-row  sm:gap-4 m-auto justify-center">

          {
            !showButtonBussines ? (
              <Button
                className="w-full sm:m-0 sm:w-48 md:m-0"
                primary
                onClick={onRetry}
              >
                Reintentar
              </Button>) : (<Button
                className="w-full sm:m-0 sm:w-48 md:m-0"
                onClick={onMyBussiness}
              >
                Mis Negocios
              </Button>
            )
          }
        </div>
      </div>
      <div className="bg-gray-200 w-full px-12 py-4 sm:px-24 sm:py-6">
        <p className="text-gray-900 text-xs font-normal m-0 text-center">
          {`Para más información comunícate desde Bogotá a la línea `}
          <span className="medium-teal">307 7050</span>
          {` o desde cualquier lugar del país `}
          <span className="medium-teal">018000 113390</span>
        </p>
        {
          errorClosePolicy && (<CustomP className="text-gray-900 text-xs font-normal m-0 text-center"> {`Error de carga certificado o caratula`}</CustomP>)
        }

      </div>
    </ModalWrapper>
  );
};

ModalErrorCreatePolicy.propTypes = {
  showModal: Proptypes.bool.isRequired,
  onMyBussiness: Proptypes.func.isRequired,
  onRetry: Proptypes.func.isRequired,
  isErrorOpenL: Proptypes.bool.isRequired,
  showButtonBussines: Proptypes.bool.isRequired,
};

export default ModalErrorCreatePolicy;
