const initialState = {
  loading: false,
  error: null,
  requestBody: {},
};

export const getPdfQuote = (payload) => ({
  type: 'GET_PDF_QUOTE',
  loading: true,
  error: null,
  payload,
});

export const getPdfQuoteSuccess = (payload) => ({
  type: 'GET_PDF_QUOTE_SUCCESS',
  payload,
});
export const getPdfQuoteError = (payload) => ({
  type: 'GET_PDF_QUOTE_ERROR',
  payload,
  error: true,
});
export const updateBodyPdf = (payload) => ({
  type: 'UPDATE_BODY',
  payload,
  error: true,
});
export const updateBusiness = (payload) => ({
  type: 'UPDATE_BUSINESS',
  payload,
});
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_PDF_QUOTE': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'GET_PDF_QUOTE_SUCCESS': {
      return {
        ...state,
        loading: false,
      };
    }
    case 'GET_PDF_QUOTE_ERROR': {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case 'UPDATE_BODY': {
      return {
        ...state,
        requestBody: action.payload,
      };
    }
    case 'UPDATE_BUSINESS': {
      return {
        ...state,
        business: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const callPdfQuote = (err, status) => async (dispatch) => {
  if (status === 201) {
    return dispatch(getPdfQuoteSuccess());
  }
  return dispatch(getPdfQuoteError(err));
};
