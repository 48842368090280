import axios from 'axios';

import { HEADERS_JSON } from 'utils/constants/DefaultBodies';

const urlQuote = process.env.REACT_APP_URL_QUOTE;

/**
 * Verifies if url starts with the base or adds the indicated path
 * @param {string} url
 */
const readUrl = (url = '') =>
  url.startsWith('http://') || url.startsWith('https://')
    ? url
    : `${urlQuote}/${url}`;

const getHeaders = () => {
  const { token, ip } = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${token}`,
    authorizedip: ip,
  };
  return customHeaders;
};
// delete risk by ID from risk items table
export const deleteRiskByIdQuote = async (draftRiskId) =>
  axios.post(
    readUrl('deleteRisk'),
    { draftRiskId },
    {
      headers: { ...HEADERS_JSON.headers, ...getHeaders() },
    }
  );
