/* eslint-disable no-restricted-syntax */
const CLAUSES = [
  {
    codigoAmparo: '8900',
    actividad: 0,
    items: [
      {
        codigo: 0,
      },
    ],
  },
  {
    codigoAmparo: '8901',
    actividad: 0,
    items: [
      {
        codigo: 8901,
      },
    ],
  },
  {
    codigoAmparo: '8950',
    actividad: 0,
    items: [
      {
        codigo: '0',
      },
    ],
  },
  {
    codigoAmparo: '8869',
    actividad: 0,
    items: [
      {
        codigo: 0,
      },
    ],
  },
  {
    codigoAmparo: '8876',
    actividad: 0,
    items: [
      {
        codigo: 0,
      },
    ],
  },
  {
    codigoAmparo: '8901',
    actividad: 0,
    items: [
      {
        codigo: 6127,
      },
    ],
  },
  {
    codigoAmparo: '8880',
    actividad: 0,
    items: [
      {
        codigo: 0,
      },
    ],
  },
  {
    codigoAmparo: '8882',
    actividad: 0,
    items: [
      {
        codigo: 0,
      },
    ],
  },
  {
    codigoAmparo: '8884',
    actividad: 0,
    items: [
      {
        codigo: 0,
      },
    ],
  },
  {
    codigoAmparo: '8884',
    actividad: '',
    items: [
      {
        codigo: 0,
      },
    ],
  },
  {
    codigoAmparo: '8894',
    actividad: 0,
    items: [
      {
        codigo: 0,
      },
    ],
  },
  {
    codigoAmparo: '8899',
    actividad: 0,
    items: [
      {
        codigo: 0,
      },
    ],
  },
];

export default CLAUSES;
