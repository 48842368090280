import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-top: -20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-aling: left;
  @media (min-width: 768px) {
    margin-right: 10px;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  input:checked ~ span {
    background-color: ${(props) =>
      props.dontModify ? 'var(--gray-300)' : 'var(--medium-teal)'};
  }
  input:checked ~ span:after {
    display: block;
  }
  span:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: 1px solid white;
    /* ${(props) => (props.checked ? 'white' : 'var(--gray-300)')}; */
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 2px;
  background-color: ${(props) =>
    props.dontModify ? 'var(--gray-300)' : 'white'};
  border: 1px solid
    ${(props) => (props.checked ? 'var(--medium-teal)' : 'var(--gray-300)')};
  ${(props) => props.dontModify && 'border: 1px solid var(--gray-300)'};
  &:after {
    content: '';
    position: absolute;
  }
`;

const Checkbox = (props) => {
  const { checkedProp, changeProp, dontModify } = props;
  return (
    <Container checked={checkedProp} dontModify={dontModify}>
      <input
        type="checkbox"
        checked={checkedProp}
        onChange={() => changeProp(checkedProp)}
        disabled={dontModify}
      />
      <Checkmark checked={checkedProp} dontModify={dontModify} />
    </Container>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  checkedProp: PropTypes.bool,
  dontModify: PropTypes.bool,
  changeProp: PropTypes.func,
};

Checkbox.defaultProps = {
  checkedProp: PropTypes.bool,
  dontModify: PropTypes.bool,
  changeProp: PropTypes.func,
};
