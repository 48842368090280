import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  updatePolicyCoverage,
  updatePolicyCoverageProp
} from 'reducers/quoteInfo';
import CoveragModal from 'layouts/modals/CoverageModal';
import Loader from 'components/Loader';
import Button from 'components/Button';
import {
  formatPrice,
  extractValueFromSplit,
} from 'utils/helpers/normalizeValues';

import addCircle from 'assets/icons/add-circle.svg';
import deleteCircle from 'assets/icons/delete-circle.svg';
import edit from 'assets/icons/edit.svg';

import Modal from "../components/Modal"

import ModalErrorService from './emit_components/collections/modals/ModalErrorCreatePolicy';

const ImgContainer = styled.div`
  box-sizing: border-box;
  height: 51px;
  width: 51px;
  border: 1px solid #99e5ea;
  border-radius: 50%;
`;

/**
 *
 */
const Plus = styled.img`
  position: absolute;
  top: -5px;
  right: -5px;
`;

const DeleteCircle = styled.button`
  right: 1rem;
  top: 1rem;
`;

const Values = styled.div`
  span {
    border-right: 1px solid var(--teal);
    padding-right: 0.5rem;
  }
  // @media (min-width: 768px) {
  span:only-child {
    border-right: 0;
  }
  // }
`;

const AdditionalCoverage = (props) => {
  const {
    draftIndex,
    draft,
    coverage,
    getCalculoPricing,
    filterDeductibles,
    callOpenlCapital,
    quoteSave
  } = props;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { abreModal, codigoAmparo } = coverage.results[0][0];
  const [showButtonBussines, setShowButtonBussines] = useState(false);
  const [clics, setClics] = useState(0);

  const policyCoverage = useSelector((state) =>
    state.quoteInfo.data.drafts[draftIndex].policyRisks.amparos
      ? state.quoteInfo.data.drafts[draftIndex].policyRisks.amparos.find(
        (coverageFromRedux) => coverageFromRedux.codigoAmparo === codigoAmparo
      )
      : []
  );

  const errrorOpenL = useSelector((state) =>
    state.openlPricing.error !== null || state.openlCapital.error !== null
  );


  const retryOpenLServices = () => {
    if (clics <= 3 && errrorOpenL) {
      getCalculoPricing();
      callOpenlCapital();
      setClics(prevClics => prevClics + 1);
    } else {
      setShowButtonBussines(true);
    }
  }


  useEffect(() => {
    getCalculoPricing();
    callOpenlCapital();
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [policyCoverage]);

  const coverageUpdate = async (newPolicyCoverage) => {
    setLoading(true);

    dispatch(
      updatePolicyCoverage({
        draftIndex,
        policyCoverage: {
          codigoAmparo: policyCoverage.codigoAmparo,
          ...newPolicyCoverage,
        },
      })
    );
    filterDeductibles();
  };

  const policyCoveragePropUpdate = (amparoPadre, coverageProp) => {
    dispatch(
      updatePolicyCoverageProp({
        draftIndex,
        codigoAmparo: amparoPadre,
        coverageProp,
      })
    );
  };

  const saveCoverageProperties = () => {

    coverage.results[3][0][1].forEach((field) => {
      const { amparoPadre, codigoPregunta, tipo, id } = field.results[0][0];
      const fieldValues = data?.[id];
      const values = {
        filter: fieldValues?.codigo,
        currency: fieldValues,
        number: fieldValues,
      };
      const value = values[tipo] || 0;
      const newPolicyCoverage = {
        selected: '1',
      };

      coverageUpdate(newPolicyCoverage);
      const coverageProp = {
        codigo: codigoPregunta,
        valor: Number(value),
      };
      policyCoveragePropUpdate(amparoPadre, coverageProp);
    });
  };




  useEffect(() => {
    if (
      (data && policyCoverage.codigoAmparo === '8884') ||
      (data && policyCoverage.codigoAmparo === '8880')
    ) {
      const newPolicyCoverage = {
        selected: '1',
        valorRiesgo: data[coverage.results[3][0][1][0].results[0][0].id],
      };
      coverageUpdate(newPolicyCoverage);
    } else if (data) {
      saveCoverageProperties();
    }
  }, [data]);


  const searchFilterOption = (field, value) => {
    const selectedOption = field.results[2][0][1].find(
      (option) => option.codigo === value.toString()
    );
    return selectedOption.respuesta || null;
  };

  const getValueByType = (field) => {
    let result = null;
    if ((policyCoverage.codigoAmparo === '8884') || policyCoverage.codigoAmparo === '8880') {
      result = `$ ${formatPrice(policyCoverage.valorRiesgo)}`;
    } else {
      const subCoverage = policyCoverage?.propiedadesAmparo.find(
        (amparo) => amparo?.codigo === field?.results[0][0].codigoPregunta
      );
      switch (field.results[0][0].tipo) {
        case 'filter':
          result = searchFilterOption(field, subCoverage?.valor);
          break;
        case 'currency':
          result = `$ ${formatPrice(subCoverage?.valor)}`;
          break;
        case 'number':
          result = subCoverage?.valor;
          break;
        default:
          return null;
      }
    }

    return result;
  };


  return (
    <div
      className={`rounded shadow flex flex-col lg:flex-row p-4 mb-3  ${policyCoverage.selected === '0' || policyCoverage.selected === 0
        ? 'bg-lightTeal'
        : 'bg-white'
        }`}
    >
      {loading && <Loader />}
      <div className="w-full lg:w-1/12">
        <ImgContainer
          className={`m-auto relative ${policyCoverage.selected === '0' || policyCoverage.selected === 0
            ? 'bg-white'
            : 'bg-lightTeal'
            }`}
        >
          <img
            src={require(`assets/icons/activities/${coverage.results[0][0].ubicacion}`)}
            alt="Liberty Seguros - Alt"
            className="main absolute inset-0 m-auto"
          />
          {(policyCoverage.selected === '1' ||
            policyCoverage.selected === 1) && (
              <Plus
                src={addCircle}
                alt="Liberty seguros - PYME"
                className="h-5 w-5"
              />
            )}
        </ImgContainer>
      </div>
      <div className="w-full lg:w-9/12 lg:pl-4">
        <h6 className="font-medium text-base text-center lg:text-left">
          {coverage.results[0][0].garantia}
        </h6>
        <p className="text-xs text-opacity-75">
          {coverage.results[0][0].literales}
        </p>
        {abreModal === '1' &&
          (policyCoverage.selected === '1' ||
            policyCoverage.selected === 1) && (
            <button
              onClick={() => {
                setShowModal(true);
              }}
              type="button"
              className="bg-lightTeal rounded border border-solid border-teal text-sm p-2 text-blue max-w-full flex items-center my-2"
            >
              <Values className="flex flex-col">
                {coverage.results[3][0][1].map((field, index) => (
                  <span
                    key={field.results[0][0].codigoPregunta}
                    className="text-left"
                  >
                    <b>
                      {`${extractValueFromSplit(
                        coverage.results[0][0].valoresContratados,
                        '|',
                        index
                      )}: `}
                    </b>
                    {getValueByType(field)}
                  </span>
                ))}
              </Values>
              <img src={edit} alt="Liberty Seguros - Alt" className="m-auto" />
            </button>
          )}
      </div>
      <div className="w-full lg:w-2/12 relative flex justify-center lg:block">
        {policyCoverage.selected === '0' || policyCoverage.selected === 0 ? (
          <Button
            primary
            type="button"
            onClick={() => {
              if (abreModal === '1') {
                localStorage.setItem('codigoAmparo', policyCoverage.codigoAmparo);
                setShowModal(true);
              } else {
                localStorage.setItem('codigoAmparo', policyCoverage.codigoAmparo);
                coverageUpdate({
                  selected: '1',
                });
              }
            }}
          >
            Contratar
          </Button>
        ) : (
          <DeleteCircle
            type="button"
            className="lg:absolute pt-4 lg:p-0"
            onClick={() => {
              const newPolicyCoverage = {
                selected: '0',
              };
              if (policyCoverage.codigoAmparo === '8884') {
                newPolicyCoverage.valorRiesgo = '0';
                const coverageProp = {
                  codigo: '8893',
                  selected: '1',
                };
                policyCoveragePropUpdate(
                  policyCoverage.codigoAmparo,
                  coverageProp
                );
              } else {
                policyCoverage.propiedadesAmparo.map((subcoverage) => {
                  const coverageProp = {
                    codigo: subcoverage.codigo,
                  };
                  policyCoveragePropUpdate(
                    policyCoverage.codigoAmparo,
                    coverageProp
                  );
                  return null;
                });
              }
              coverageUpdate(newPolicyCoverage);
            }}
          >
            <img src={deleteCircle} alt="Liberty seguros - PYME" />
          </DeleteCircle>
        )}
      </div>
      {showModal && !errrorOpenL && (
        <CoveragModal
          showModal={showModal}
          setShowModal={setShowModal}
          setData={setData}
          draft={draft}
          policyCoverage={policyCoverage}
          coverage={coverage}
          policyCoveragePropUpdate={policyCoveragePropUpdate}
          errrorOpenL={errrorOpenL}
        />
      )}
      {
        errrorOpenL && (
          <ModalErrorService
            showModal={errrorOpenL}
            onMyBussiness={() => {
              const newPolicyCoverage = {
                selected: '0',
                valorRiesgo: 0,
              };
              dispatch(
                updatePolicyCoverage({
                  draftIndex,
                  policyCoverage: {
                    codigoAmparo: localStorage.getItem('codigoAmparo'),
                    ...newPolicyCoverage,
                  },
                })
              )
              localStorage.removeItem('codigoAmparo');
              setTimeout(() => {
                quoteSave();
              }, 2500);

            }}
            onRetry={retryOpenLServices}
            isErrorOpenL={errrorOpenL}
            showButtonBussines={showButtonBussines}
          />
        )
      }

    </div>
  );
};

export default AdditionalCoverage;

/**
 * PropsTypes
 */
AdditionalCoverage.propTypes = {
  /**
   * Draft
   * @param {arrayOf} coverage
   */
  draft: PropTypes.shape({
    policyRisks: {
      amparos: PropTypes.arrayOf({}),
    },
  }),
  /**
   * Coverage
   * @param {Object} coverage
   */
  coverage: PropTypes.shape({
    results: PropTypes.arrayOf({}),
  }),
  /**
   * Index of current Draft
   */
  draftIndex: PropTypes.number.isRequired,
  getCalculoPricing: PropTypes.func.isRequired,
  filterDeductibles: PropTypes.func.isRequired,
  quoteSave: PropTypes.func.isRequired
};

/**
 * Default props
 */
AdditionalCoverage.defaultProps = {
  /**
   * Draft
   * @param {arrayOf} coverage
   */
  draft: {
    policyRisks: {
      amparos: [],
    },
  },
  /**
   * Coverage
   * @param {Object} coverage
   */
  coverage: null,
};
