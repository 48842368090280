import styled from 'styled-components';

export default styled.button`
  box-sizing: border-box;
  padding: 8px 16px;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid var(--gray-400);
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.21);
  :hover {
    background: var(--teal-background);
    color: var(--gray-600);
  }
  :disabled {
    background: var(--gray-300);
    color: var(--gray-600);
    box-shadow: none;
    cursor: default;
  }
`;
