/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  updateRisk,
  resetRisk,
  fetchQuote,
  updateDeductiblesRisk,
  riskValueRemoveFromTotalCoverage,
  constraintTableUpdate,
  sigUpdate,
  sigRequestData,
  setRestrictedRisk,
  restrictedFalse,
  // eslint-disable-next-line import/named
} from 'reducers/quoteInfo';
import { openlCapitalRemove } from 'reducers/openlCapital';

import Address from 'components/Address';
import Back from 'components/Back';
import ConstructionTypes from 'components/ConstructionsTypes';
import Button from 'components/Button';
import Coverage from 'components/Coverage';
import Input from 'components/Input';
import Filter from 'components/Filter';
import Select from 'components/Select';
import Map from 'components/Map';
import Number from 'components/Number';
import FormAlert from 'components/FormAlert';
import TotalQuoteFooter from 'components/TotalQuoteFooter';
import VariableIndex from 'components/VariableIndex';
import ValuesEnsure from 'layouts/risk-form/ValuesEnsure';
import {
  toJSONValidation,
  addRulesValidation,
} from 'utils/helpers/reactFormValidations';
import {
  MAP_COUNTRY_ACCURACY,
  MAP_ALERT_MESSAGES,
} from 'utils/constants/MapOptions';
import { formatPrice } from 'utils/helpers/normalizeValues';
import { groupObjectsByKey } from 'utils/helpers/object';
import { fetchCoordinates } from 'reducers/coordinates';

import TEMPLATE_SIG from 'utils/constants/templateSIG';
import alertIcon from 'assets/icons/Icon-System-Info-16px.svg';
import RestrictionUbication from '../modals/RestrictionUbication';

import { ButtonContinue } from './components/ButtonContinue';

import './styles/Risk.scss';

export default function Risk(props) {
  const { draftIndex, riskIndex, setCurrentTab, currentTab } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const drafts = useSelector((state) => state.quoteInfo.data.drafts);
  const currentDraft = drafts[draftIndex];
  const currentRisk = currentDraft.risks[riskIndex];
  const methods = useForm({
    reValidateMode: 'all',
    mode: 'onSubmit',
    defaultValues: currentRisk,
  });

  const {
    control,
    handleSubmit,
    register,
    trigger,
    errors,
    clearErrors,
    formState,
    setValue,
    getValues,
    setError,
    watch,
  } = methods;

  const numPisoWatch = watch('NumeroPisos');
  const selectedBusiness = useSelector(
    (state) => state.quoteInfo.data.business
  );

  const [floorNumber, setFloorNumber] = useState('');
  const [constructionSelect, setConstructionSelect] = useState(
    (currentRisk.TipoConstru && currentRisk.TipoConstru.descripcion) || ''
  );
  const [showModal, setShowModal] = useState(false);
  const [filledAddress, setFilledAddress] = useState('');
  const [locatedAddress, setLocatedAddress] = useState(
    currentRisk?.DireccionEei || ''
  );
  const [cityProvince, setCityProvince] = useState('');
  const [notFoundAddress, setNotFoundAddress] = useState(false);
  const [deductibles, setDeductibles] = useState(false);
  const [maxDeductibles, setMaxDeductibles] = useState(false);
  // status for modals
  const [exit, setExit] = useState(false);

  const firstDivref = useRef(null);
  const secondtDivref = useRef(null);

  const arr = [];
  const arrEnd = [];
  const objNew = {};
  let responsiveSIG = [];
  let featureSIGNew;

  const fields = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[8][0][1]
      : []
  );

  const answers = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[2][0][1]
      : []
  );

  const values = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[6][0][1][0]
      : []
  );

  const cities = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[7][0][1]
      : []
  );

  const years = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[2][0][1][0].results[1][0][1]
      : []
  );

  const coverages = useSelector((state) => state.openlConfig.coverages);

  const childCoverages = useSelector(
    (state) => state.openlConfig.childCoverages
  );

  const validateCoverages = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[9][0][1]
      : []
  );

  const constTypes = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[2][0][1][1].results[1][0][1]
      : []
  );

  const rentExpenses = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[9][0][1][14].results
      : []
  );
  const coveragesList = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[4][0][1]
      : []
  );
  const rentExpensesInfo = coveragesList.find(
    (amparo) => amparo.results[0][0].codigoAmparo === '8879'
  );
  const lengthRentExpenses = rentExpensesInfo.results[3][0][1][0].results[0][0];

  const numPisosInm = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[2][0][1][3].results[1][0][1]
      : []
  );

  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));

  const featureSIG = useSelector((state) =>
    state.coordinates.data
      ? state.coordinates.data.value.features[0].attributes
      : {}
  );

  const featureSIGTwo = useSelector((state) =>
    // state.coordinates.drafts[draftIndex]
    state.coordinates.data
      ? state.coordinates.drafts[draftIndex]?.risks[riskIndex]
      : { ...TEMPLATE_SIG }
  );

  const activity = useSelector(
    (state) => state.quoteInfo.data.business.answerCode
  );

  const constraintTable = useSelector(
    (state) =>
      state.quoteInfo.data.drafts[draftIndex]?.risks[riskIndex].constraintTable
  );

  const constraintTableResponseService = useSelector(
    (state) =>
      state.quoteInfo.data.drafts[draftIndex]?.risks[riskIndex].constraintTable
        ?.responseService?.results[0][1][1]
  );

  const funcMap = {
    GET_CITY: cities,
    GET_YEARS: years,
    GET_NUMPISOSINM: numPisosInm,
    FLOOR_NUMBER_HOOK: floorNumber,
    SET_FLOOR_NUMBER: setFloorNumber,
  };

  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };

  const quoteSave = () => {
    if (!constraintTable?.restricted) {
      dispatch(
        updateRisk({
          risk: {
            completed: false,
            valEnsCompleted: true,
            featureSIG: featureSIGTwo,
            ...getValues(),
          },
          draftIndex,
          riskIndex,
        })
      );
      dispatch(fetchQuote(null, customHeaders));
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setExit(true);
    }
  };

  const quoteSaveReload = () => {
    dispatch(
      updateRisk({
        risk: {
          completed: false,
          valEnsCompleted: true,
          featureSIGTwo,
          featureSIG: featureSIGTwo,
          ...getValues(),
        },
        draftIndex,
        riskIndex,
      })
    );
    dispatch(fetchQuote(null, customHeaders));
  };

  const addRisk = (data) => {
    if (!constraintTable?.restricted) {
      if (
        currentDraft.valorPorPoliza <= parseInt(values.cotizacionNegocio, 10)
      ) {
        dispatch(
          updateRisk({
            risk: {
              completed: true,
              valEnsCompleted: true,
              featureSIG,
              ...data,
            },
            draftIndex,
            riskIndex,
          })
        );
        dispatch(fetchQuote(null, customHeaders));
        history.push('/');
      }
    } else {
      setExit(true);
    }
  };

  const updateDeductibles = (data) => {
    dispatch(updateDeductiblesRisk({ draftIndex, riskIndex, data }));
  };

  useEffect(() => {
    quoteSaveReload();
  }, []);

  useEffect(() => {
    if ('amparos' in currentRisk && currentRisk.amparos.length > 0) {
      const data = groupObjectsByKey(currentRisk.amparos, 'codDeducible');
      if (
        constraintTableResponseService &&
        constraintTableResponseService.length >= 1
      ) {
        responsiveSIG = constraintTableResponseService.map(
          (x) => x.codigoAmparo
        );
        Object.entries(data).forEach((resComplete) => {
          const [f, v] = resComplete;
          v.forEach((x) => arr.push({ f, cod: x, res: resComplete }));
        });
        arr.forEach((x) => {
          if (responsiveSIG.indexOf(x?.cod?.codigoAmparo) === -1) {
            arrEnd.push(x);
          }
        });
        if (arrEnd.length >= 1) {
          arrEnd.forEach((element) => {
            const k = `${element?.f}`;
            if (objNew[k]) {
              objNew[k].push(element.cod);
            } else {
              objNew[k] = [element.cod];
            }
          });
        }
        setDeductibles(objNew);
        const max = Object.keys(objNew).reduce((a, b) =>
          objNew[a].length > objNew[b].length ? a : b
        );
        setMaxDeductibles(max);
        updateDeductibles(objNew);
      } else {
        setDeductibles(data);
        const max = Object.keys(data).reduce((a, b) =>
          data[a].length > data[b].length ? a : b
        );
        updateDeductibles(data);
        setMaxDeductibles(max);
      }
    }
  }, [currentRisk.amparos]);

  useEffect(() => {
    if (!drafts[draftIndex].risks[riskIndex].valEnsCompleted) {
      dispatch(
        riskValueRemoveFromTotalCoverage({
          draftIndex,
          riskIndex,
        })
      );
      dispatch(
        resetRisk({
          draftIndex,
          riskIndex,
        })
      );
      dispatch(openlCapitalRemove());
    }
  }, []);

  const removableCoveragesByActivity = useSelector((state) => {
    if (
      !state.quoteInfo.data.drafts[draftIndex].risks[riskIndex].constraintTable
    )
      return null;
    if (
      !state.quoteInfo.data.drafts[draftIndex].risks[riskIndex].constraintTable
        .responseService.results
    )
      return null;

    return state.quoteInfo.data.drafts[draftIndex].risks[riskIndex]
      .constraintTable.responseService.results[0][1][1];
  });

  useEffect(() => {
    if (!removableCoveragesByActivity) return;
    const isRestrictedRisk = removableCoveragesByActivity.some(
      (covRestricted) =>
        covRestricted.codigoAmparo === '8900' ||
        covRestricted.codigoAmparo === '8940'
    );
    if (isRestrictedRisk) {
      dispatch(setRestrictedRisk({ draftIndex, riskIndex, restricted: true }));
    } else {
      dispatch(setRestrictedRisk({ draftIndex, riskIndex, restricted: false }));
    }
  }, [removableCoveragesByActivity]);

  useEffect(() => {
    featureSIGNew = {};
    Object.entries(!featureSIGTwo ? {} : featureSIGTwo).forEach((x) => {
      const [f, v] = x;
      if (f.toLocaleLowerCase().split('_')[0] === 'amenaza') {
        featureSIGNew[f.toLocaleLowerCase()] = v;
      }
    });
    if (Object.keys(featureSIGNew).length === 0) featureSIGNew = TEMPLATE_SIG;
    if (constraintTable) {
      dispatch(
        constraintTableUpdate({
          draftIndex,
          riskIndex,
          constraintTable: {
            ...constraintTable,
            bodyRequest: {
              ...constraintTable.bodyRequest,
              codigoActividad: activity,
              // variableSig: featureSIGNew,
              variableSig:
                Object.keys(featureSIGNew).length === 0
                  ? TEMPLATE_SIG
                  : featureSIGNew,
            },
          },
        })
      );
    }
    dispatch(
      sigRequestData({
        floorsNumberRisk: getValues('NumPiso')?.descripcion,
        featureSIGNew,
        activity,
        customHeaders,
        draftIndex,
        riskIndex,
      })
    );
    dispatch(
      sigUpdate({
        draftIndex,
        riskIndex,
        featureSIGTwo
      })
    );
  }, [draftIndex, riskIndex, featureSIGTwo, numPisoWatch]);

  useEffect(() => {
    if (!constraintTable) return;
    setExit(constraintTable?.restricted);
  }, [constraintTable]);

  useEffect(() => {
    const formValues = getValues();
    if (formValues?.CiudadDep?.descripcion) {
      setCityProvince(formValues?.CiudadDep?.descripcion);
      if (!formValues?.Mapa) return;
      dispatch(
        fetchCoordinates(
          {
            path: 'PymeCoordinates',
            method: 'get',
            parameters: {
              Lon: formValues?.Mapa?.lng,
              Lat: formValues?.Mapa?.lat,
              f: 'pjson',
            },
            draftIndex,
            riskIndex,
          },
          customHeaders
        )
      );
    }
  }, []);

  useEffect(() => {
    if (!constraintTable) return;
    const validations = Object.keys(errors);

    const firstValidation = validations.some(
      (validation) =>
        validation === 'DireccionEei' ||
        validation === 'CiudadDep' ||
        validation === 'NumPiso'
    );
    const secondValidation = validations.some(
      (validation) =>
        validation === 'TipoConstru' ||
        validation === 'AnoConstruc' ||
        validation === 'NumeroPisos'
    );
    if (firstValidation) {
      firstDivref.current.focus();
    } else if (secondValidation) {
      secondtDivref.current.focus();
    }
  }, [errors]);

  const handleForm = async (data, e) => {
    e.stopPropagation();
    if (data) addRisk(data);
  };

  const handleKeyPress = (event) => {
    if (event?.key === 'Enter') {
      if (
        Object.keys(errors).length === 0 &&
        (getValues('DireccionEei') !== '' ||
          getValues('CiudadDep') !== undefined ||
          getValues('NumPiso') !== null ||
          getValues('TipoConstru') !== null ||
          getValues('AnoConstruc') !== undefined ||
          getValues('NumeroPisos') !== undefined)
      ) {
        event.preventDefault();
      } else {
        setError('DireccionEei', {
          message: 'Por favor ingrese la Dirección del Riesgo',
        });
        setError('CiudadDep', {
          message: 'Por favor ingrese la Ciudad y Municipio del Riesgo',
        });
        setError('NumPiso', { message: 'Seleccione # piso' });
        setError('TipoConstru', {
          message: 'Por favor seleccione el tipo de construcción',
        });
        setError('AnoConstruc', {
          message: 'Por favor seleccione el año de construcción',
        });
        setError('NumeroPisos', {
          message: 'Por favor ingrese el número de pisos del inmueble',
        });
      }
    }
  };

  // Agrega un event listener cuando el componente se monta
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <>
      <FormProvider
        {...{
          control,
          register,
          trigger,
          errors,
          clearErrors,
          formState,
          setValue,
          setError,
          getValues,
        }}
      >
        <div className="md:w-c-container m-auto hidden md:block">
          <Back onClick={() => setCurrentTab(currentTab - 1)} />
        </div>
        <form
          name="riskForm"
          className="flex flex-col items-center"
          onSubmit={handleSubmit(handleForm)}
        >
          <p className="text-2xl sm:text-4xl text-center font-medium mt-8 sm:mt-0 mb-2 text-blue">
            Datos del riesgo
          </p>
          <p className="text-sm sm:text-base text-center font-regular sm:w-auto w-11/12 text-gray-600">
            {`Recuerda que la suma del valor asegurado de los riesgos no debe superar los $${formatPrice(
              values.cotizacionNegocio
            )}`}
          </p>
          <p className="text-sm sm:text-base text-center font-regular sm:w-auto w-11/12 mb-6 mx-20 text-gray-600">
            Tipo de negocio seleccionado:
            <span className="ml-2 medium-teal">{selectedBusiness.name}</span>
          </p>
          <div className="info-text">
            <img src={alertIcon} alt="alert" title="alert" />
            <p className="font-bold">
              Ten en cuenta que si deseas asegurar más de un piso, deberás crear
              un nuevo riesgo en esta póliza
            </p>
          </div>
          <div className="flex justify-center w-full sm:w-c-container">
            <div className="w-full md:w-2/3 px-6 sm:px-2 p-2 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-5">
              {fields.map((field) => {
                const rules = addRulesValidation(
                  field.results[0][0].id,
                  getValues,
                  setError,
                  clearErrors,
                  field.results[1][0][1]
                );
                switch (field.results[0][0].tipo) {
                  case 'text':
                    return (
                      <div ref={firstDivref} tabIndex="0">
                        <Input
                          key={field.results[0][0].id}
                          placeholder={field.results[0][0].descripcion}
                          type={field.results[0][0].tipo}
                          name={field.results[0][0].id}
                          value={funcMap[field.results[0][0].value]}
                          onChange={() => {
                            trigger(field.results[0][0].id);
                          }}
                          ref={register(
                            toJSONValidation(field.results[1][0][1])
                          )}
                          inputClass={field.results[0][0].inputClass}
                          controlled={false}
                          errorMessage={
                            errors[field.results[0][0].id] &&
                            errors[field.results[0][0].id].message
                          }
                        />
                      </div>
                    );
                  case 'number':
                    return (
                      <Controller
                        key={`controller-${field.results[0][0].id}`}
                        name={field.results[0][0].id}
                        control={control}
                        render={() => (
                          <Number
                            key={field.results[0][0].id}
                            placeholder={field.results[0][0].descripcion}
                            type={field.results[0][0].tipo}
                            name={field.results[0][0].id}
                            onValueChange={(value) => {
                              setValue(field.results[0][0].id, value);
                              trigger(field.results[0][0].id);
                              setValue(field.results[0][0].id, {
                                codigo:
                                  answers[3].results[1][0][1][0]
                                    .codigoPreguntaDet,
                                valor: value,
                              });
                            }}
                            inputClass={field.results[0][0].inputClass}
                            controlled
                            errorMessage={
                              errors[field.results[0][0].id] &&
                              errors[field.results[0][0].id].message
                            }
                          />
                        )}
                        defaultValue={null}
                        rules={toJSONValidation(field.results[1][0][1])}
                        defaultInputValue={
                          currentRisk &&
                          currentRisk[field.results[0][0].id] &&
                          currentRisk[field.results[0][0].id].valor
                        }
                      />
                    );
                  case 'filter':
                    return (
                      <Controller
                        key={`controller-${field.results[0][0].id}`}
                        name={field.results[0][0].id}
                        control={control}
                        render={() => (
                          <Filter
                            name={field.results[0][0].id}
                            placeholder={field.results[0][0].descripcion}
                            options={funcMap[field.results[0][0].options]}
                            onValueChange={(value) => {
                              setValue(field.results[0][0].id, value);
                              trigger(field.results[0][0].id);
                              setCityProvince(
                                `${value.nombreMunicipio}, ${value.nombreDepartamento}`
                              );
                            }}
                            optionKey={field.results[0][0].optionKey}
                            optionLabel={field.results[0][0].optionLabel}
                            inputClass={field.results[0][0].inputClass}
                            controlled
                            errorMessage={
                              errors[field.results[0][0].id] &&
                              errors[field.results[0][0].id].message
                            }
                            defaultInputValue={
                              currentRisk &&
                              currentRisk[field.results[0][0].id] &&
                              currentRisk[field.results[0][0].id].descripcion
                            }
                          />
                        )}
                        rules={toJSONValidation(field.results[1][0][1])}
                      />
                    );
                  case 'select':
                    return (
                      <div className="flex space-x-1 grid-cols-2">
                        <div className="w-2 mt-3">
                          {' '}
                          <span className="text-red-600	"> * </span>{' '}
                        </div>
                        <Controller
                          key={`controller-${field.results[0][0].id}`}
                          name={field.results[0][0].id}
                          control={control}
                          render={() => (
                            <Select
                              name={field.results[0][0].id}
                              placeholder={field.results[0][0].descripcion}
                              options={funcMap[field.results[0][0].options]}
                              onChange={(value) => {
                                clearErrors(field.results[0][0].id);
                                setValue(field.results[0][0].id, value);
                                trigger(field.results[0][0].id);
                              }}
                              optionKey={field.results[0][0].optionKey}
                              optionLabel={field.results[0][0].optionLabel}
                              errorMessage={
                                errors[field.results[0][0].id] &&
                                errors[field.results[0][0].id].message
                              }
                              valueDefault={currentRisk[field.results[0][0].id]}
                              risks
                            />
                          )}
                          rules={toJSONValidation(rules)}
                        />
                      </div>
                    );
                  case 'address':
                    return (
                      <Address
                        key={field.results[0][0].id}
                        fields={field.results[2][0][1]}
                        name={field.results[0][0].id}
                        setFilledAddress={(e) => {
                          setFilledAddress(e.target.value);
                        }}
                        locatedAddress={locatedAddress}
                        currentRisk={currentRisk}
                      >
                        {notFoundAddress && (
                          <FormAlert
                            isError
                            content={MAP_ALERT_MESSAGES.addressNotFound}
                          />
                        )}
                      </Address>
                    );
                  case 'map':
                    return (
                      <div key="map" className="md:col-span-2">
                        <Controller
                          key={`controller-${field.results[0][0].id}`}
                          name={field.results[0][0].id}
                          control={control}
                          render={() => (
                            <Map
                              filledAddress={
                                filledAddress !== '' && cityProvince !== ''
                                  ? `${filledAddress}, ${cityProvince}, ${MAP_COUNTRY_ACCURACY.country}`
                                  : ''
                              }
                              onValueChange={(value) => {
                                setNotFoundAddress(false);
                                dispatch(
                                  restrictedFalse({ draftIndex, riskIndex })
                                );
                                setValue(field.results[0][0].id, value);

                                dispatch(
                                  fetchCoordinates(
                                    {
                                      path: 'PymeCoordinates',
                                      method: 'get',
                                      parameters: {
                                        Lon: value.lng,
                                        Lat: value.lat,
                                        f: 'pjson',
                                      },
                                      draftIndex,
                                      riskIndex,
                                    },
                                    customHeaders
                                  )
                                );
                              }}
                              setLocatedAddress={(value) => {
                                if (value === MAP_ALERT_MESSAGES.unnamedRoad) {
                                  setLocatedAddress(cityProvince.split(',')[0]);
                                }
                                setNotFoundAddress(false);
                              }}
                              showZeroResults={(istrue) => {
                                if (istrue) setNotFoundAddress(true);
                              }}
                              draftIndex={draftIndex}
                              riskIndex={riskIndex}
                            />
                          )}
                          defaultValue={null}
                        />
                      </div>
                    );
                  case 'construction_type':
                    return (
                      <div
                        key="construction-type"
                        className="md:col-span-2"
                        ref={secondtDivref}
                        tabIndex="0"
                      >
                        <Controller
                          key={`controller-${field.results[0][0].id}`}
                          name={field.results[0][0].id}
                          control={control}
                          render={() => (
                            <ConstructionTypes
                              key={field.results[0][0].id}
                              constructions={constTypes}
                              setConstructionType={setConstructionSelect}
                              constructionSelect={constructionSelect}
                              showModal={showModal}
                              setShowModal={setShowModal}
                              onChange={(value) => {
                                setValue(field.results[0][0].id, value);
                                trigger(field.results[0][0].id);
                              }}
                              onValueChange={() => {
                                clearErrors(field.results[0][0].id);
                              }}
                              errorMessage={
                                errors[field.results[0][0].id] &&
                                errors[field.results[0][0].id].message
                              }
                            />
                          )}
                          defaultValue={null}
                          rules={toJSONValidation(field.results[1][0][1])}
                        />
                      </div>
                    );
                  case 'values_to_insure':
                    return (
                      <div key="values-to-insure" className="md:col-span-2">
                        <ValuesEnsure
                          idComponent={field.results[0][0].id}
                          key={field.results[0][0].id}
                          currentRisk={currentRisk}
                          trigger={trigger}
                          draftIndex={draftIndex}
                          riskIndex={riskIndex}
                          errorMessage={
                            errors[field.results[0][0].id] &&
                            errors[field.results[0][0].id].message
                          }
                          onValueChange={(data) => {
                            clearErrors(field.results[0][0].id);
                          }}
                        />
                      </div>
                    );
                  case 'coverage_and_assistance':
                    return (
                      <div
                        className="md:col-span-2"
                        key={field.results[0][0].id}
                      >
                        <Coverage
                          coverages={
                            removableCoveragesByActivity
                              ? coverages.filter((coverage) => {
                                let pass = true;
                                removableCoveragesByActivity.forEach(
                                  (covRemove) => {
                                    if (
                                      covRemove.codigoAmparo ===
                                      coverage.results[0][0].codigoAmparo
                                    ) {
                                      pass = false;
                                    }
                                  }
                                );
                                return pass;
                              })
                              : coverages
                          }
                          childCoverages={childCoverages}
                          draftIndex={draftIndex}
                          riskIndex={riskIndex}
                          validateCoverages={validateCoverages}
                          rentExpenses={rentExpenses}
                          lengthRentExpenses={lengthRentExpenses}
                        />
                      </div>
                    );
                  case 'variable_index':
                    return (
                      <div
                        className="md:col-span-2"
                        key={field.results[0][0].id}
                      >
                        <VariableIndex
                          data={field.results[0][0]}
                          key={field.results[0][0].id}
                          name={field.results[0][0].id}
                          code={field.results[0][0].preguntaForm}
                          refData={field.results[2][0][1][0].results[1][0][1]}
                          draftIndex={draftIndex}
                          riskIndex={riskIndex}
                        />
                      </div>
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </div>
          {deductibles && (
            <div className="grid gap-2 gap-x-8 grid-cols-1 sm:grid-cols-2 my-6 m-4">
              {Object.keys(deductibles).map((key) => {
                if (String(key) === String(maxDeductibles)) {
                  return (
                    <p key={key} className="text-sm flex flex-col sm:flex-row">
                      <span className="font-bold text-gray-600 mr-1">
                        Deducibles para los demás amparos:
                      </span>
                      <span className="text-gray-500 lowercase">
                        {deductibles[key][0].desDeducible}
                      </span>
                    </p>
                  );
                }
                return deductibles[key].map((item, index) => (
                  <p key={index} className="text-sm flex flex-col sm:flex-row">
                    <span className="font-bold text-gray-600 mr-1">
                      Deducible para
                      <span className="lowercase"> {item.idDeducible}:</span>
                    </span>
                    <span className="text-gray-500 lowercase">
                      {item.desDeducible}
                    </span>
                  </p>
                ));
              })}
            </div>
          )}
          <div className="flex flex-col md:flex-row justify-center my-4 px-4">
            <Button type="button" onClick={quoteSave}>
              Guardar y Salir
            </Button>
            <ButtonContinue
              onClick={quoteSaveReload}
              currentRisk={currentRisk}
            />
            <Button
              type="button"
              onClick={() => {
                history.push('/');
              }}
            >
              Cancelar
            </Button>
          </div>
        </form>
        <TotalQuoteFooter draft={currentDraft} riskIndex={riskIndex} />
      </FormProvider>
      <RestrictionUbication
        showModal={exit}
        setShowModal={setExit}
        type="gps2"
        title="Lo sentimos"
        cancelButton="Terminar"
        butonContinue="Ajustar Ubicacion"
        content="No es posible continuar con la cotización por la ubicación en donde se encuentra el riesgo."
      />
    </>
  );
}

/**
 * PropsTypes
 */
Risk.propTypes = {
  /**
   * Set current tab
   */
  setCurrentTab: PropTypes.func,
  /**
   * Current tab
   */
  currentTab: PropTypes.number,
  /**
   * Draft index selected
   */
  draftIndex: PropTypes.number.isRequired,
  /**
   * Risk index selected
   */
  riskIndex: PropTypes.number.isRequired,
};

/**
 * Default props
 */
Risk.defaultProps = {
  setCurrentTab: undefined,
  currentTab: 1,
};
