import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import search from 'assets/icons/search.svg';
import PropTypes from 'prop-types';
import QuestionTooltip from './QuestionTooltip';
import useWindowDimensions from '../../utils/helpers/resolution';
import 'assets/styles/scss/components/QuoteIFilters.scss';

const isProdEnv = process.env.REACT_APP_IS_PROD === 'true';

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: auto;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.25);
  padding: 0 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 768px) {
    box-shadow: none;
    margin: 0;
    max-width: 78%;
    min-width: 550px;
    width: 50%;
  }
`;
const TabWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  justify-content: center;
  width: auto;
  display: flex;
  flex-wrap: nowrap;
  flex: 0 0 30%;
  padding: 0;
  border-bottom: 3px solid
    ${(props) => (props.active ? 'var(--medium-teal)' : 'transparent')};
  padding: 10px 20px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  p {
    color: ${(props) =>
      props.active ? 'var(--liberty-blue)' : 'var(--gray-600)'};
    font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  }
  :nth-child(2) {
    p {
      min-width: 135px;
    }
  }

  .counter-container {
    background-color: ${(props) =>
      props.active ? 'var(--medium-teal)' : 'var(--gray-200)'};
    color: ${(props) => (props.active ? 'white' : 'var(--gray-400)')};
  }

  @media (min-width: 768px) {
    flex: 0 0 100px;
    :nth-child(2) {
      p {
        min-width: 160px;
      }
    }
  }
`;
const Tab = ({ onClick, title, length, index, actualTab }) => (
  <TabWrapper onClick={() => onClick(index)} active={index === actualTab}>
    <p>{title}</p>
    <span className="counter-container ml-2 rounded-md px-1 font-medium text-xs">
      {length}
    </span>
  </TabWrapper>
);

Tab.propTypes = {
  onClick: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired,
  actualTab: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  length: PropTypes.any.isRequired,
};

const QuoteIssuanceFilters = ({
  setFilter,
  actualTab,
  tabs,
  onTabChange = () => {
    // do nothing
  },
}) => {
  const dimensions = useWindowDimensions();
  const [desktop, setDesktop] = useState(true);

  useEffect(() => {
    const { width } = dimensions;

    if (width < 701) {
      setDesktop(false);
    } else {
      setDesktop(true);
    }
  }, [dimensions]);

  return (
    <div className="flex align-center flex-col-filter">
      <TabsContainer>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            index={tab.name}
            actualTab={actualTab}
            onClick={onTabChange}
            title={tab.label}
            length={tab.count ? tab.count : 0}
          />
        ))}
      </TabsContainer>
      <div className="FiltersContainer-search with-search-filter flex align-center justify-center bg-gray-100 md:bg-white md:m-0 md:justify-items-end">
        <div
          style={{
            marginTop: '10px',
            marginBottom: `${desktop ? '10px' : '0px'}`,
          }}
          className="flex gap-2 relative w-full sm:w-3/5  mx-6 md:w-full"
        >
          <div
            className={`flex flex-row rounded border border-solid border-gray-200 w-full ${
              desktop ? '' : 'shadow-2xl'
            }`}
          >
            <input
              /* eslint-disable jsx-a11y/no-autofocus */
              autoFocus
              className="flex-auto p-2 rounded-l-sm outline-none"
              onChange={(e) => setFilter(e.target.value)}
              placeholder="Búsca por Nombre, identificación..."
            />
            <button
              type="button"
              className="w-12 rounded border-none bg-white"
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <img className="inline" src={search} alt="buscar" />
            </button>
          </div>
          {!isProdEnv && (
            <QuestionTooltip
              right="-1px"
              left="auto"
              content={`Recuerda que puedes hacer la búsqueda con los siguientes factores: ID Cotización, 
              Nombres o Apellidos, Razón social, Número de identificación`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default QuoteIssuanceFilters;

QuoteIssuanceFilters.propTypes = {
  setFilter: PropTypes.func.isRequired,
  actualTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
};
