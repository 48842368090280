/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormContext, Controller } from 'react-hook-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Checkbox from 'components/Checkbox';
import Tooltip from 'components/Tooltip';
import InputLimit from 'components/InputLimit';
import RentExpense from 'layouts/modals/RentExpense';
import { isActive } from 'utils';
import useWindowDimensions from 'utils/helpers/resolution';
import {
  updateCoverageCheck,
  updateValues,
  updateCoverage,
  updateSubitemCheck,
} from 'reducers/quoteInfo';
import { formatPrice } from 'utils/helpers/normalizeValues';
import AlertIcon from 'assets/icons/alert.svg';
import EditIcon from 'assets/icons/edit.svg';
import arrowDown from 'assets/icons/arrow-blue.svg';
import SubCoveragesItem from 'components/SubCoverageItem';
import Input from 'components/Input';
import { getValidations } from 'utils/helpers/reactFormValidations';
import { getRequestCapitalCalculation } from 'utils/helpers/businessCapital';
import { fetchOpenlCapital } from 'reducers/openlCapital';
import CONST_SCV from '../utils/constants/scv';
import percentaje from '../utils/helpers/percentaje';

const Text = styled.p`
  min-width: 150px;
  width: 100%;
`;
const QuestionMark = styled.div`
  align-items: center;
  background-color: var(--gray-200);
  border-radius: 50%;
  color: var(--liberty-blue);
  display: flex;
  display: inline-block;
  font-size: 8px;
  font-weight: bold;
  justify-content: center;
  line-height: 1;
  margin-left: 10px;
  padding: 2px 4px;
  &::before {
    content: '?';
  }
`;
const Content = styled.div`
  background-color: var(--light-teal);
  border-radius: 3px;
  border: 1px solid var(--teal);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin: 0 auto;
  padding: 3px;
  // width: 90%;
  .box {
    display: flex;
    flex-direction: column;
    width: 80%;
    &-item {
      color: var(--liberty-blue);
      display: flex;
      flex-direction: column;
      padding: 3px;
      &-title {
        font-weight: bold;
      }
      &-value {
        font-weight: normal;
      }
    }
  }
  figure {
    border-left: 1px solid var(--teal);
    height: 100%;
    margin: auto;
    padding: 25px 0 25px 5px;
    img {
      margin: auto;
      padding: 0;
    }
  }
  @media (min-width: 768px) {
    // margin-left: -40%;
    padding: 0;
    // width: 115%;
    .box {
      flex-direction: row;
      width: 100%;
      &-item {
        padding: 3px 15px;
        display: flex;
        align-items: flex-start;
        &:first-child {
          border-right: 1px solid var(--teal);
          min-width: 120px;
        }
        &:nth-child(2) {
          border: none;
          max-width: 90px;
        }
        span {
          margin: 0;
          padding: 0;
        }
      }
    }
    figure {
      align-items: center;
      border-left: none;
      display: flex;
      justify-content: center;
      margin: auto;
      padding: 0 5px;
      img {
        margin: auto;
        padding: 0;
      }
    }
  }
`;
const Accordeon = styled.div`
  width: 100%;
  visibility: ${(props) => (props.show ? 'visible' : 'collapse')};
  height: ${(props) => (props.show ? 'auto' : '0px')};
  tansition: 0.5s;
  padding: 0 0 15px 0;
`;
Accordeon.displayName = 'AccordeonBox';
const BoxItems = styled.div`
  width: 95%;
  margin: auto;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 1px 2px #34374140;
`;
const HeaderBoxItem = styled.div`
  width: 100%;
  margin: auto;
  border-radius: 5px 5px 0 0;
  background-color: var(--medium-teal);
  box-shadow: 0px 1px 2px #34374140;
  position: relative;
  p {
    color: white;
    text-align: center;
    width: 100%;
    font-size: 14px;
    padding: 5px 0;
  }
`;
const TriangleStyle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  margin: 0;
  left: 70px;
  right: 0;
  border-top: ${(props) => props.position === 'top' && '9px solid'}
    var(--medium-teal);
  top: ${(props) => props.position === 'top' && '-9px'};
  border-bottom: ${(props) => props.position === 'bottom' && '9px solid'}
    var(--medium-teal);
  transform: rotate(180deg);
`;
const CoverageItem = (props) => {
  const {
    item,
    childCoverageItems,
    draftIndex,
    riskIndex,
    dependencies,
    abductionWithViolence,
    rentExpenses,
    lengthRentExpenses,
    dependenciesWithViolence,
  } = props;
  const { control, errors, setValue, trigger } = useFormContext();
  const result = item.results[0][0];
  const aloneItem = result;
  const initialCheck = result.check == '1';
  const protectionType = result.tipoAmparo == '1';
  const codeItem = result.codigoAmparo;
  const dispatch = useDispatch();
  const returnService = useSelector((state) => state.quoteInfo.data);
  const draft = returnService.drafts[draftIndex];
  const risk = draft.risks[riskIndex];
  const openlCapitalData = useSelector((state) =>
    state.openlCapital.data[draftIndex]
      ? state.openlCapital.data[draftIndex].results
      : []
  );
  const arrayRisksData = useSelector((state) =>
    state.quoteInfo.data.drafts
      ? state.quoteInfo.data.drafts[draftIndex].risks[riskIndex].amparos
      : []
  );
  const valoAsegurState = useSelector((state) =>
    state.quoteInfo.data.drafts[draftIndex].risks[riskIndex]
      ? state.quoteInfo.data.drafts[draftIndex].risks[riskIndex].ValoAsegur
      : []
  );
  const { width } = useWindowDimensions();
  const dontModify = initialCheck;
  const searchDependencies = [];
  const arrayResponse = [];
  const [errorMessage, setErrorMessage] = useState(false);
  const [checked, setChecked] = useState(initialCheck);
  const [canonValue, setCanonValue] = useState('0');
  const [monthValue, setMonthValue] = useState('0');
  const [subItemsBox, setSubItemsBox] = useState(false);
  const [monthSelect, setMonthSelect] = useState({});
  const [ValueRentExpense, setValueRentExpense] = useState(false);
  const [saveRentExpense, setSaveRentExpense] = useState(false);
  const [subItems, setSubitems] = useState([]);
  const handleChecked = (check) => {
    setChecked(check);
  };

  const expectById = (coverages, expectedId) => {
    if (!coverages) return null;
    return coverages.find((covItem) => covItem.codigoAmparo === expectedId);
  };

  useEffect(() => {
    if (openlCapitalData.length > 0) {
      if (arrayRisksData) {
        if (
          valoAsegurState.VlaContenido == undefined &&
          valoAsegurState.VlaEquipEle == undefined &&
          valoAsegurState.VlaMercancia == undefined
        ) {
          const covSusConViol = expectById(arrayRisksData, '8862');
          if (covSusConViol) {
            if (result.codigoAmparo == '8862' && covSusConViol.selected == 1) {
              dispatch(
                updateCoverageCheck({
                  draftIndex,
                  riskIndex,
                  coverage: {
                    codigoAmparo: '8862',
                    selected: '0',
                  },
                })
              );
              handleChecked(false);
            }
          }

          const covSusSinViol = expectById(arrayRisksData, '8866');
          if (covSusSinViol) {
            if (result.codigoAmparo == '8866' && covSusSinViol.selected == 1) {
              dispatch(
                updateCoverageCheck({
                  draftIndex,
                  riskIndex,
                  coverage: {
                    codigoAmparo: '8866',
                    selected: '0',
                  },
                })
              );
              handleChecked(false);
            }
          }

          const covRoboAClientes = expectById(arrayRisksData, '8876');
          if (covRoboAClientes) {
            if (
              result.codigoAmparo == '8876' &&
              covRoboAClientes.selected == 1
            ) {
              dispatch(
                updateCoverageCheck({
                  draftIndex,
                  riskIndex,
                  coverage: {
                    codigoAmparo: '8876',
                    selected: '0',
                  },
                })
              );
              handleChecked(false);
            }
          }

          const dineroLocal = expectById(arrayRisksData, '8877');
          if (dineroLocal) {
            if (result.codigoAmparo == '8877' && dineroLocal.selected == 1) {
              dispatch(
                updateCoverageCheck({
                  draftIndex,
                  riskIndex,
                  coverage: {
                    codigoAmparo: '8877',
                    selected: '0',
                  },
                })
              );
              handleChecked(false);
            }
          }
        }
        if (
          valoAsegurState.VlaEquipEle == undefined &&
          valoAsegurState.VlaContenido == undefined
        ) {
          const covCobFueraDePred = expectById(arrayRisksData, '8871');
          if (covCobFueraDePred) {
            if (
              result.codigoAmparo == '8871' &&
              covCobFueraDePred.selected == 1
            ) {
              dispatch(
                updateCoverageCheck({
                  draftIndex,
                  riskIndex,
                  coverage: {
                    codigoAmparo: '8871',
                    selected: '0',
                  },
                })
              );
              handleChecked(false);
            }
          }
          const covSusSinViol = expectById(arrayRisksData, '8866');
          if (covSusSinViol) {
            if (result.codigoAmparo == '8866' && covSusSinViol.selected == 1) {
              dispatch(
                updateCoverageCheck({
                  draftIndex,
                  riskIndex,
                  coverage: {
                    codigoAmparo: '8866',
                    selected: '0',
                  },
                })
              );
              handleChecked(false);
            }
          }
        }
        if (valoAsegurState.VlaMercancia == undefined) {
          const covBienesRefri = expectById(arrayRisksData, '8874');
          if (covBienesRefri) {
            if (result.codigoAmparo == '8874' && covBienesRefri.selected == 1) {
              dispatch(
                updateCoverageCheck({
                  draftIndex,
                  riskIndex,
                  coverage: {
                    codigoAmparo: '8874',
                    selected: '0',
                  },
                })
              );
              handleChecked(false);
            }
          }
        }
        if (valoAsegurState.VlaEquipEle == undefined) {
          const covDanoInterno = expectById(arrayRisksData, '8869');
          if (covDanoInterno) {
            if (result.codigoAmparo == '8869' && covDanoInterno.selected == 1) {
              dispatch(
                updateCoverageCheck({
                  draftIndex,
                  riskIndex,
                  coverage: {
                    codigoAmparo: '8869',
                    selected: '0',
                  },
                })
              );
              handleChecked(false);
            }
          }
        }
      }
    }
  }, [
    valoAsegurState.VlaContenido,
    valoAsegurState.VlaMercancia,
    valoAsegurState.VlaEquipEle,
    valoAsegurState.VlaEdificio,
    arrayRisksData,
  ]);

  const amparos = risk?.amparos;

  useEffect(() => {
    if (amparos) {
      const amparo = amparos.find(
        ({ codigoAmparo }) => `${codigoAmparo}` === `${codeItem}`
      );
      if (amparo) {
        const showAmparo = isActive(amparo);
        setChecked(showAmparo);
      }

      const covGastosArrendamiento = amparos.find(
        (cov) => cov.codigoAmparo === '8879'
      );
      if (covGastosArrendamiento) {
        const valueCanonRedux =
          covGastosArrendamiento.propiedadesAmparo[0].valor;
        const numberMonthRedux =
          covGastosArrendamiento.propiedadesAmparo[1].valor;
        setCanonValue(valueCanonRedux);
        setMonthValue(numberMonthRedux);
        setMonthSelect({
          value: Number(numberMonthRedux),
        });
      }
    }
  }, [amparos]);

  const assistanceOrLeasingExpenses = (code, valueChecked) => {
    if (valueChecked) {
      dispatch(
        updateCoverageCheck({
          draftIndex,
          riskIndex,
          coverage: {
            codigoAmparo: String(code),
            selected: '1',
          },
        })
      );
    } else {
      dispatch(
        updateCoverageCheck({
          draftIndex,
          riskIndex,
          coverage: {
            codigoAmparo: String(code),
            selected: '0',
          },
        })
      );
    }
    handleChecked(valueChecked);
  };

  const searchDependence = (arrayInfo, code) => {
    arrayInfo.map((dependence) => {
      if (code == dependence.codigoAmparo) {
        dependence.propiedadesAmparo.map((propertyItem) => {
          switch (propertyItem.id) {
            case 'VlaEdificio':
            case 'VlaContenido':
            case 'VlaEquipEle':
            case 'VlaMercancia':
              if (!searchDependencies.find((i) => i == propertyItem.id)) {
                searchDependencies.push(propertyItem.id);
              }
              break;
            default:
              return null;
          }
          return searchDependencies;
        });
      }
      return searchDependencies;
    });
  };
  const validateUncheck = (dependenciesWithViolenceArr, code, valueChecked) => {
    const arrayValidator = [];
    risk.amparos.map((itemService) => {
      dependenciesWithViolenceArr.map((itemDependencie) => {
        if (
          itemService.codigoAmparo == itemDependencie &&
          itemService.selected == '1'
        ) {
          arrayValidator.push(itemService);
        }
      });
    });
    if (arrayValidator.length > 0) {
      arrayValidator.forEach((coverageItem) => {
        const { codigoAmparo } = coverageItem;
        dispatch(
          updateCoverageCheck({
            draftIndex,
            riskIndex,
            coverage: {
              codigoAmparo,
              selected: '0',
            },
          })
        );
      });
    }

    handleChecked(valueChecked);
    dispatch(
      updateCoverageCheck({
        draftIndex,
        riskIndex,
        coverage: {
          codigoAmparo: code,
          selected: '0',
        },
      })
    );
    return null;
  };
  const validateDependencieViolence = (valueItem, codeValue, valueChecked) => {
    if (codeValue == '8879') {
      handleChecked(valueChecked);
      if (!valueChecked) {
        dispatch(
          updateCoverage({
            draftIndex,
            riskIndex,
            coverage: {
              codigoAmparo: codeValue,
              selected: 0,
              propiedadesAmparo: [
                {
                  codigoAmparoCap: rentExpenses[1][0][1][0].codigoAmparoCap,
                  codigo: rentExpenses[1][0][1][0].codigo,
                  id: rentExpenses[1][0][1][0].id,
                  valor: 0,
                },
                {
                  codigo: rentExpenses[1][0][1][1].codigo,
                  codigoAmparoCap: rentExpenses[1][0][1][1].codigoAmparoCap,
                  id: rentExpenses[1][0][1][1].id,
                  valor: 0,
                },
              ],
            },
          })
        );
      }
    }
    if (codeValue == '8862') {
      let itemViolence = expectById(risk.amparos, '8862');
      if (!itemViolence) itemViolence = { selected: '0' };
      if (itemViolence.selected == '0') {
        if (Object.keys(valueItem).length > 0) {
          const ValoAsegurArray = Object.keys(valueItem);
          ValoAsegurArray.forEach((ValoAsegurItem) => {
            const validateItems = searchDependencies.find(
              (searchDependenciesItem) =>
                searchDependenciesItem == ValoAsegurItem
            );
            if (validateItems) {
              arrayResponse.push(validateItems);
            } else {
              setErrorMessage(true);
            }
          });
          if (arrayResponse.length > 0) {
            if (abductionWithViolence) {
              const itemRequired = risk.amparos[7].selected;
              if (itemRequired == '1') {
                setErrorMessage(false);
                dispatch(
                  updateCoverageCheck({
                    draftIndex,
                    riskIndex,
                    coverage: {
                      codigoAmparo: codeValue,
                      selected: '1',
                    },
                  })
                );
                handleChecked(valueChecked);
              } else {
                setErrorMessage(true);
              }
            } else {
              dispatch(
                updateCoverageCheck({
                  draftIndex,
                  riskIndex,
                  coverage: {
                    codigoAmparo: codeValue,
                    selected: '1',
                  },
                })
              );
              setErrorMessage(false);
              handleChecked(valueChecked);
            }
          } else if (abductionWithViolence) {
            const itemCViolence = expectById(risk.amparos, '8862');
            const itemRequired = itemCViolence ? itemCViolence.selected : '0';
            if (itemRequired == '1') {
              setErrorMessage(false);
              dispatch(
                updateCoverageCheck({
                  draftIndex,
                  riskIndex,
                  coverage: {
                    codigoAmparo: codeValue,
                    selected: '1',
                  },
                })
              );
              handleChecked(valueChecked);
            } else {
              setErrorMessage(true);
            }
          }
        } else {
          setErrorMessage(true);
        }
      } else {
        return validateUncheck(
          dependenciesWithViolence,
          codeValue,
          valueChecked
        );
      }

      return null;
    }
    if (Object.keys(valueItem).length > 0) {
      const ValoAsegurArray = Object.keys(valueItem);
      ValoAsegurArray.forEach((ValoAsegurItem) => {
        const validateItems = searchDependencies.find(
          (searchDependenciesItem) => searchDependenciesItem == ValoAsegurItem
        );
        if (validateItems) {
          arrayResponse.push(validateItems);
        } else {
          setErrorMessage(true);
        }
      });
      if (arrayResponse.length > 0) {
        if (abductionWithViolence) {
          const itemViolence = expectById(risk.amparos, '8862');
          const itemRequired = itemViolence ? itemViolence.selected : '0';

          if (itemRequired == '1') {
            setErrorMessage(false);
            dispatch(
              updateCoverageCheck({
                draftIndex,
                riskIndex,
                coverage: {
                  codigoAmparo: codeValue,
                  selected: '1',
                },
              })
            );
            handleChecked(valueChecked);
          } else {
            setErrorMessage(true);
          }
        } else {
          dispatch(
            updateCoverageCheck({
              draftIndex,
              riskIndex,
              coverage: {
                codigoAmparo: codeValue,
                selected: '1',
              },
            })
          );
          setErrorMessage(false);
          handleChecked(valueChecked);
        }
      } else if (abductionWithViolence) {
        const itemViolence = expectById(risk.amparos, '8862');
        const itemRequired = itemViolence ? itemViolence.selected : '0';
        if (codeValue == '8866' || codeValue == '8871') {
          const ValoAseguroCheckedRequired =
            valueItem.VlaContenido !== undefined ||
            valueItem.VlaEquipEle !== undefined;
          if (ValoAseguroCheckedRequired == true && itemRequired == '1') {
            setErrorMessage(false);
            dispatch(
              updateCoverageCheck({
                draftIndex,
                riskIndex,
                coverage: {
                  codigoAmparo: codeValue,
                  selected: '1',
                },
              })
            );
            handleChecked(valueChecked);
          } else {
            setErrorMessage(true);
          }
        }
        if (
          itemRequired == '1' &&
          codeValue !== '8866' &&
          codeValue !== '8871'
        ) {
          setErrorMessage(false);
          dispatch(
            updateCoverageCheck({
              draftIndex,
              riskIndex,
              coverage: {
                codigoAmparo: codeValue,
                selected: '1',
              },
            })
          );
          handleChecked(valueChecked);
        } else {
          setErrorMessage(true);
        }
      }
    } else {
      setErrorMessage(true);
    }
  };

  const subCoverageSelected = (selected) => {
    subItems.forEach((subI) => {
      const { dependencia, codigoAmparo } = subI.results[0][0];
      dispatch(
        updateSubitemCheck({
          draftIndex,
          riskIndex,
          dependencia,
          codigoAmparo,
          selected,
        })
      );
    });
  };

  const validateDependencies = (itemAlone, valueChecked) => {
    const codeValue = String(itemAlone.codigoAmparo);
    const { ValoAsegur } = risk;
    if (codeValue == '8882') {
      return assistanceOrLeasingExpenses(itemAlone.codigoAmparo, valueChecked);
    }
    searchDependence(dependencies, codeValue);
    validateDependencieViolence(ValoAsegur, codeValue, valueChecked);
    if (valueChecked) {
      subCoverageSelected(valueChecked);
    }
  };
  const updateValuesItems = (codeValue, value) => {
    const valueItem = Number(value);
    if (codeValue === '8876' || codeValue === '8877') {
      dispatch(updateValues({ codeValue, valueItem, draftIndex, riskIndex }));
    }
  };
  const subCoveragesValidate = () => {
    const it = childCoverageItems.filter((subCov) => {
      let value = null;
      switch (subCov.results[0][0].garantia) {
        case 'Edificio':
          if (valoAsegurState.VlaEdificio) {
            value = subCov;
          }
          break;
        case 'Contenidos':
          if (valoAsegurState.VlaContenido) {
            value = subCov;
          }
          break;
        case 'Equipo electrico -  maquinaria':
          if (valoAsegurState.VlaEquipEle) {
            value = subCov;
          }
          break;
        case 'Mercancías fijas':
          if (valoAsegurState.VlaMercancia) {
            value = subCov;
          }
          break;
        default:
          return value;
      }
      return value;
    });
    setSubitems(it);
  };

  const basicData = useSelector((state) =>
    state.openlConfig && state.openlConfig.data.results
      ? state.openlConfig.data.results[6][0][1][0]
      : []
  );

  const selectedBusiness = useSelector(
    (state) => state.quoteInfo.data.business
  );
  const drafts = useSelector((state) =>
    state.openlConfig && state.openlConfig.data.results
      ? state.quoteInfo.data.drafts
      : []
  );

  const coveragesList = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[4][0][1]
      : []
  );

  const sessionInfoObj = sessionStorage.getItem('info')
    ? JSON.parse(sessionStorage.getItem('info'))
    : {};

  const currentDraft = drafts[draftIndex];
  const coverageRedux = currentDraft.risks[riskIndex].amparos;
  const coverage = coverageRedux?.find((cov) => cov.codigoAmparo === codeItem);

  const capitalCalculation = () => {
    const request = getRequestCapitalCalculation(
      {
        capitalMaximo: basicData.cotizacionNegocio,
        businessCode: selectedBusiness.answerCode,
        draft: drafts[draftIndex],
        templateCapital: drafts[draftIndex].risks,
        templateCapital2: drafts[draftIndex].policyRisks,
        idComponent: 'subItemCoverage',
      },
      coveragesList
    );
    const customHeaders = {
      authorization: `Bearer ${sessionInfoObj.token}`,
      authorizedip: sessionInfoObj.ip,
    };
    dispatch(fetchOpenlCapital(request, customHeaders, draftIndex));
  };

  const hasChildren = subItems.length > 0;
  useEffect(() => {
    subCoveragesValidate();
  }, [
    valoAsegurState.VlaContenido,
    valoAsegurState.VlaMercancia,
    valoAsegurState.VlaEquipEle,
    valoAsegurState.VlaEdificio,
  ]);

  useEffect(() => {
    if (coverage?.selected === 0) {
      setChecked(false);
    }
  }, [coverage && coverage.selected]);
  const [inputsForcedToBeTriggered, setInputsForcedToBeTriggered] = useState(
    []
  );
  useEffect(() => {
    async function triggerValidations(ids) {
      await trigger(ids);
      setInputsForcedToBeTriggered([]);
    }
    if (inputsForcedToBeTriggered.length > 0) {
      triggerValidations(inputsForcedToBeTriggered);
    }
  }, [risk.amparos]);
  const timerRef = useRef(null);

  useEffect(() => {
    if (!ValueRentExpense && saveRentExpense) {
      validateDependencies(aloneItem, true);
    }
  }, [ValueRentExpense, saveRentExpense]);

  const coverageContractation = () => {
    capitalCalculation();
    if (result.codigoAmparo === '8879' && !checked) {
      setValueRentExpense(!ValueRentExpense);
    } else {
      validateDependencies(aloneItem, !checked);
    }
  };

  const subCoverageValidate = () => {
    const { propiedadesAmparo } = coverage;
    const subcoverageLength = propiedadesAmparo.filter(
      (subcoverage) => subcoverage.selected === 1
    ).length;
    if (subcoverageLength === 0) {
      coverageContractation();
    }
  };

  if (protectionType) {
    const subItemsBoxRotate = subItemsBox ? 'rotate-180' : 'rotate-0';
    return (
      <div className="border-b border-gray-200 md:my-2 rounded-3xl">
        <div className="w-full flex justify-start items-center px-6 py-4">
          {/* <TdPersonalized> */}
          {/* <div className="flex justify-start items-center w-full py-3 flex-no-wrap m-0"> */}
          <Checkbox
            checkedProp={checked}
            changeProp={() => {
              coverageContractation();
            }}
            dontModify={dontModify}
          />
          {width < 768 ? (
            <Tooltip
              content={
                result.literales && (
                  <p className="text-sm text-white leading-tight">
                    {result.literales}
                  </p>
                )
              }
            >
              <Text className="font-semibold text-gray-600 text-sm tracking-normal leading-4 cursor-pointer select-none">
                {result.garantia}
              </Text>
            </Tooltip>
          ) : (
            <div className="flex items-center">
              <Text className="font-semibold text-gray-600 text-sm tracking-normal leading-4 cursor-pointer select-none">
                {result.garantia}
              </Text>
              <Tooltip
                position="top"
                left="15px"
                content={
                  result.literales && (
                    <p className="text-sm text-white leading-tight">
                      {result.literales}
                    </p>
                  )
                }
              >
                <QuestionMark />
              </Tooltip>
            </div>
          )}
          {/* </div> */}
          {errorMessage && result.preguntasAmparo !== '1' && (
            <div className="flex items-start justify-start p-0 mt-2 ">
              <img
                src={AlertIcon}
                className="mr-2 mt-1 md:-space-y-5"
                alt="Liberty Seguros - Icono"
              />
              <p className="text-lmRed text-xs md:-space-y-5">{result.error}</p>
            </div>
          )}
          {/* </TdPersonalized> */}
          {/* <td className="border-red-600"> */}
          {result.muestraCampoCapital == '1' &&
            risk.amparos &&
            checked &&
            risk.amparos.map((itemValue) => {
              const { validacionMinMax } = itemValue;
              return (
                itemValue.codigoAmparo == result.codigoAmparo && (
                  <div
                    key={itemValue.codigoAmparo}
                    className="flex-1 p-0 flex flex-col items-end md:flex-row md:justify-end md:items-center pr-2 md:pr-0"
                  >
                    <img
                      src={arrowDown}
                      width="15"
                      className={`accordion cursor-pointer m-2 p-0 md:invisible transform transition  delay-75 ${subItemsBoxRotate}`}
                      alt="Liberty Seguros - Icono desplegar"
                      onClick={() => setSubItemsBox(!subItemsBox)}
                    />
                    {hasChildren ? (
                      <>
                        <InputLimit
                          onValueChange={(values) => {
                            if (
                              CONST_SCV.SCV_CODE.toString() ===
                              itemValue.codigoAmparo
                            ) {
                              const coverageMatch = coveragesList.find(
                                (coveItem) =>
                                  coveItem.results[0][0].codigoAmparo ===
                                  CONST_SCV.LOCAL_MONEY_CODE.toString()
                              );
                              let porcentajeDefecto = null;
                              let valorMaximo = null;

                              if (coverageMatch) {
                                porcentajeDefecto = parseFloat(
                                  coverageMatch.results[0][0].porcentajeDefecto
                                );
                                valorMaximo = parseFloat(
                                  coverageMatch.results[0][0].valorMaximo
                                );
                              }
                              setValue(
                                `pi${CONST_SCV.LOCAL_MONEY_CODE}`,
                                porcentajeDefecto !== null &&
                                  valorMaximo !== null
                                  ? percentaje.percentajeFn(
                                      porcentajeDefecto,
                                      valorMaximo,
                                      values.value
                                    )
                                  : values.value,
                                {
                                  shouldValidate: true,
                                  shouldDirty: true,
                                }
                              );
                            }
                          }}
                          value={String(itemValue.valorRiesgo)}
                        />
                        <img
                          src={arrowDown}
                          className={`accordion cursor-pointer m-0 p-0 invisible md:visible transform transition delay-75 ${subItemsBoxRotate}`}
                          alt="Liberty Seguros - Icono desplegar"
                          onClick={() => setSubItemsBox(!subItemsBox)}
                        />
                      </>
                    ) : (
                      <>
                        <div style={{ width: '200px' }}>
                          <Controller
                            name={`pi${itemValue.codigoAmparo}`}
                            control={control}
                            as={
                              // eslint-disable-next-line react/jsx-wrap-multilines
                              <Input
                                className="bg-white"
                                placeholder="Valor"
                                errorMessage={
                                  errors[`pi${itemValue.codigoAmparo}`] &&
                                  errors[`pi${itemValue.codigoAmparo}`].message
                                }
                                positionLabel="top"
                                type="currency_dynamic"
                              />
                            }
                            value={itemValue.valorRiesgo}
                            defaultValue={itemValue.valorRiesgo}
                            onValueChange={(values) => {
                              clearTimeout(timerRef.current);
                              timerRef.current = setTimeout(() => {
                                setValue(
                                  `pi${itemValue.codigoAmparo}`,
                                  values.value,
                                  {
                                    shouldValidate: true,
                                    shouldDirty: true,
                                  }
                                );
                                updateValuesItems(
                                  itemValue.codigoAmparo,
                                  values.value
                                );
                              });
                            }}
                            rules={getValidations({
                              validacionMinMax,
                              required: true,
                            })}
                            customOnBlur={() => {
                              setInputsForcedToBeTriggered([
                                ...inputsForcedToBeTriggered,
                                `pi${itemValue.codigoAmparo}`,
                              ]);
                              capitalCalculation();
                            }}
                          />
                        </div>
                        <img
                          src={arrowDown}
                          className="m-0 p-0 invisible"
                          alt="Liberty Seguros - Icono desplegar"
                        />
                      </>
                    )}
                  </div>
                )
              );
            })}
          {result.preguntasAmparo == '1' && checked && (
            <Content>
              <button
                className="box focus:outline-none active:outline-none"
                type="button"
                onClick={() => setValueRentExpense(!ValueRentExpense)}
              >
                <div className="box">
                  <div className="box-item">
                    <span className="box-item-title">Canon: </span>
                    <span className="box-item-value">
                      {`$${formatPrice(canonValue)}`}
                    </span>
                  </div>
                  <div className="box-item">
                    <span className="box-item-title">Meses: </span>
                    <span className="box-item-value">{monthValue}</span>
                  </div>
                </div>
              </button>
              <button
                className="box focus:outline-none active:outline-none"
                type="button"
                onClick={() => setValueRentExpense(!ValueRentExpense)}
              >
                <figure>
                  <img src={EditIcon} alt="Liberty Seguros - Editar" />
                </figure>
              </button>
            </Content>
          )}
          {ValueRentExpense && (
            <RentExpense
              showModal={ValueRentExpense}
              setShowModal={setValueRentExpense}
              // saveRentExpense={saveRentExpense}
              setSaveRentExpense={setSaveRentExpense}
              setMonthValue={setMonthValue}
              setCanonValue={setCanonValue}
              rentExpenses={rentExpenses}
              draftIndex={draftIndex}
              riskIndex={riskIndex}
              canonValue={canonValue}
              monthValue={monthValue}
              monthSelect={monthSelect}
              setMonthSelect={setMonthSelect}
              code={result.codigoAmparo}
              lengthRentExpenses={lengthRentExpenses}
            />
          )}
          {/* </td> */}
        </div>
        {checked && hasChildren && (
          <Accordeon show={subItemsBox}>
            <BoxItems>
              <HeaderBoxItem>
                <TriangleStyle position="top" />
                <p>Items de amparo</p>
              </HeaderBoxItem>
              {subItems.map((subI) => {
                const shelterCode = subI.results[0][0].codigoAmparo;
                let shelterInfo = {
                  valor: 0,
                };
                if (arrayRisksData) {
                  arrayRisksData.map((item2) => {
                    item2.propiedadesAmparo.map((item3) => {
                      if (item3.codigo === shelterCode) {
                        shelterInfo = item3;
                      }
                    });
                  });
                }
                return (
                  <div
                    key={subI.results[0][0].codigoAmparo}
                    className="w-full flex justify-start items-center pl-4 md:pl-24 py-3 flex-no-wrap m-0 border-b border-gray-200"
                  >
                    <SubCoveragesItem
                      item={subI}
                      control={control}
                      Controller={Controller}
                      errors={errors}
                      setValue={setValue}
                      shelterInfo={shelterInfo}
                      draftIndex={draftIndex}
                      riskIndex={riskIndex}
                      subCoverageValidate={subCoverageValidate}
                    />
                  </div>
                );
              })}
            </BoxItems>
          </Accordeon>
        )}
      </div>
    );
  }
  return null;
};

export default CoverageItem;

CoverageItem.propTypes = {
  item: PropTypes.object.isRequired,
  childCoverageItems: PropTypes.array.isRequired,
  abductionWithViolence: PropTypes.bool.isRequired,
  dependencies: PropTypes.array.isRequired,
  dependenciesWithViolence: PropTypes.array.isRequired,
  rentExpenses: PropTypes.array.isRequired,
  draftIndex: PropTypes.string.isRequired,
  riskIndex: PropTypes.string.isRequired,
  lengthRentExpenses: PropTypes.array.isRequired,
};
