import { HEADERS_JSON } from 'utils/constants/DefaultBodies';

const urlAuth = process.env.REACT_APP_URL_AUTH;

const initialState = {
  loading: false,
  data: {},
  error: null,
};

export const fetchSessionInfoStart = () => ({
  type: 'FETCH_SESSION_INFO_START',
  error: false,
});

export const fetchSessionInfoSuccess = (payload) => ({
  type: 'FETCH_SESSION_INFO_SUCCESS',
  payload,
});

export const fetchSessionInfoError = (payload) => ({
  type: 'FETCH_SESSION_INFO_ERROR',
  payload,
  error: true,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_SESSION_INFO_START': {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case 'FETCH_SESSION_INFO_SUCCESS': {
      return {
        ...StaticRange,
        loading: false,
        data: action.payload,
      };
    }
    case 'FETCH_SESSION_INFO_ERROR': {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const fetchSessionInfo = (body) => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(fetchSessionInfoStart());
  try {
    const { data } = await axios.post(
      `${urlAuth}/getToken`,
      body,
      HEADERS_JSON
    );
    dispatch(fetchSessionInfoSuccess(data));
  } catch (e) {
    dispatch(fetchSessionInfoError(e));
  }
};
