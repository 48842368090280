export const filterRestrictedSubCoverages = (json, currentDraft) => {
  const toRemove = [];
  currentDraft.risks.forEach((risk, index) => {
    if (
      risk.constraintTable &&
      risk.constraintTable.responseService &&
      risk.constraintTable.responseService.results[0][1][1]
    ) {
      risk.constraintTable.responseService.results[0][1][1].forEach(
        (covSig) => {
          toRemove.push({ index, value: covSig });
        }
      );
    }
  });

  json.poliza.riesgos.forEach((risk, index) => {
    const filterList = risk.amparos.filter((coverage) => {
      return !toRemove.some(
        (matchCov) =>
          matchCov.value.codigoAmparo === coverage.codigoAmparoPadre &&
          index === matchCov.index
      );
    });
    risk.amparos = filterList;
  });

  return json;
};
export const filterRestrictedPDFCoverages = (body, currentDraft) => {
  const toRemove = [];
  currentDraft.risks.forEach((risk, index) => {
    if (
      risk.constraintTable &&
      risk.constraintTable.responseService &&
      risk.constraintTable.responseService.results[0][1][1]
    ) {
      risk.constraintTable.responseService.results[0][1][1].forEach(
        (covSig) => {
          toRemove.push({ index, value: covSig });
        }
      );
    }
  });

  body.risks.forEach((risk, index) => {
    const filterList = risk.amparos.filter((coverage) => {
      return !toRemove.some(
        (matchCov) =>
          matchCov.value.codigoAmparo === coverage.codigoAmparo &&
          index === matchCov.index
      );
    });
    risk.amparos = filterList;
  });

  return body;
};

export const filterRestrictedCoveragesData = (coverages, risks) => {
  let restrictionCounter = [];
  risks.forEach((risk) => {
    risk.constraintTable.responseService.results[0][1][1].forEach(
      (restriction) => {
        const tmpMatch = restrictionCounter.findIndex(
          (rCounter) => rCounter.id === restriction.codigoAmparo
        );
        if (tmpMatch >= 0) {
          restrictionCounter[tmpMatch].count += 1;
        } else {
          restrictionCounter.push({
            id: restriction.codigoAmparo,
            count: 1,
          });
        }
      }
    );
  });
  restrictionCounter = restrictionCounter.filter((rCounter) => {
    return rCounter.count === risks.length;
  });
  return coverages.filter((cov) => {
    return !restrictionCounter.some((rest) => cov.codigoAmparo === rest.id);
  });
};
