/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { barcodeIsValid } from 'utils';
import { getBarcode } from 'services/emit/getBarcode';
import pdfService from 'utils/helpers/pdfService';
import FileDownload from 'js-file-download';
import { updatePolicyBarcode } from 'emit_store/reducers/emitInfo';
import Button from '../../basics/form/Button';
import checkFill from '../../../../assets/icons/check-fill.svg';
import policyIssued from '../../../../assets/images/policy-issued.png';
import SelectDownload from '../../basics/form/SelectDownload';
import Loader from '../../../Loader';

const Confimation = ({ quoteNumber, policyNumber, handleMyBusiness }) => {
  const emitInfo = useSelector((state) => state.emitInfo?.data);
  const pdfData = useSelector((state) => state.pdfQuote);
  const dispatch = useDispatch();
  const [loadingBase, setLoading] = React.useState(false);
  const loadingEmitInfo = useSelector((state) => state.emitInfo?.loading);
  const barcode = useSelector((state) => state.emitInfo?.data?.barcode);
  const loading = loadingBase || loadingEmitInfo;

  const getPdf = async ({ emissionId, compBrokerId, optionsTypesPDF }) => {
    if (!barcodeIsValid(barcode)) {
      const { emission_id, policy_number_caratula: npoliza } = emitInfo;
      const code = await getBarcode({
        emission_id: emission_id || emissionId,
        npoliza,
      });
      dispatch(updatePolicyBarcode(barcode));
      if (!code) return;
    }
    const body = {
      compDate: `${emissionId.split('#')[0]}#${emissionId.split('#')[1]}`,
      compBrokerId,
      emission_id: emissionId,
      pdfData,
      optionsTypesPDF,
      quoteNumber,
    };

    const sessionData = JSON.parse(sessionStorage.getItem('info'));
    const tokenAuthorization = sessionData.token;
    const headers = {
      token: tokenAuthorization,
      ip: sessionData.ip,
      authorization: `Bearer ${tokenAuthorization}`,
      authorizedip: sessionData.ip,
    };

    try {
      setLoading(true);
      const { data } = await pdfService.callServiceEmit(
        '/emit/createPdf',
        body,
        headers
      );
      const { file, file_name } = data;
      FileDownload(Buffer.from(file, 'base64'), file_name);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onChangeOptions = (option) => {
    const { emission_id, broker_id } = emitInfo;
    getPdf({
      emissionId: emission_id,
      compBrokerId: `${broker_id}#${quoteNumber}`,
      optionsTypesPDF: option,
    });
  };

  return (
    <div className="text-center mb-5">
      <div className="mt-8 sm:mt-10 dark-blue">
        <h1 className="text-xl sm:text-3xl font-semibold mb-2">
          Tu póliza ha sido emitida
        </h1>
        <p className="text-xs sm:text-base">
          {`La póliza ha sido registrada bajo el número ${policyNumber}, descarga la póliza y los detalles a continuación`}
        </p>
        <div>
          <img src={policyIssued} alt="" className="inline-block" />
        </div>
        <div className="relative mb-8">
          <img
            src={checkFill}
            alt=""
            className="absolute top-0 -mt-4 center-icon"
          />
        </div>
        <SelectDownload onChangeOptions={(option) => onChangeOptions(option)} />
        <div>
          <Button primary onClick={handleMyBusiness}>
            Ir a mis negocios
          </Button>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

Confimation.propTypes = {
  quoteNumber: PropTypes.string.isRequired,
  policyNumber: PropTypes.string.isRequired,
  handleMyBusiness: PropTypes.func.isRequired,
};

export default Confimation;
