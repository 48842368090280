import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../basics/form/Checkbox';

const RolesChecks = ({
  role,
  setToggleCheck,
  isMaximumTakers,
  isMaximumInsured,
  isMaximumBeneficiaries,
  isInsuredAlreadyExists,
  isBeneficiaryAlreadyExists,
  handleValidateIdentity,
}) => {
  const hasTakerSelect =
    role.isTaker === true &&
    role.isInsured === false &&
    role.isBeneficiary === false;

  const hasInsuredSelect =
    role.isTaker === false &&
    role.isInsured === true &&
    role.isBeneficiary === false;

  const hasBeneficiarySelect =
    role.isTaker === false &&
    role.isInsured === false &&
    role.isBeneficiary === true;

  const hasOneSelect =
    hasTakerSelect || hasInsuredSelect || hasBeneficiarySelect;

  return (
    <>
      <Checkbox
        checkedProp={role.isTaker}
        changeProp={() => {
          if (!(hasOneSelect && role.isTaker)) {
            const { idCard, info, isTaker } = role;
            setToggleCheck(idCard, 'isTaker', !isTaker);
            if (handleValidateIdentity && !isTaker) {
              const payload = {
                currentRoleId: idCard,
                documentType: info.documentType.code,
                documentNumber: info.documentNumber,
                name: info?.surname || 'PersJurid',
              };
              handleValidateIdentity(payload);
            } else {
              setToggleCheck(idCard, 'isNotValidRole', false);
            }
          }
        }}
        dontModify={isMaximumTakers && !role.isTaker}
        idCheck={`taker-${role.idCard}`}
        label="Tomador"
      />
      <Checkbox
        checkedProp={role.isInsured}
        changeProp={() => {
          if (!(hasOneSelect && role.isInsured)) {
            setToggleCheck(role.idCard, 'isInsured', !role.isInsured);
          }
        }}
        dontModify={
          (isMaximumInsured && !role.isInsured) || isInsuredAlreadyExists
        }
        idCheck={`insured-${role.idCard}`}
        label="Asegurado"
      />
      <Checkbox
        checkedProp={role.isBeneficiary}
        changeProp={() => {
          if (!(hasOneSelect && role.isBeneficiary)) {
            setToggleCheck(
              role.idCard,
              'isBeneficiary',
              !role.isBeneficiary,
              true
            );
          }
        }}
        dontModify={
          (isMaximumBeneficiaries && !role.isBeneficiary) ||
          isBeneficiaryAlreadyExists
        }
        idCheck={`beneficiary-${role.idCard}`}
        label="Beneficiario"
      />
    </>
  );
};

RolesChecks.propTypes = {
  role: PropTypes.object.isRequired,
  setToggleCheck: PropTypes.func.isRequired,
  isMaximumTakers: PropTypes.bool.isRequired,
  isMaximumInsured: PropTypes.bool.isRequired,
  isMaximumBeneficiaries: PropTypes.bool.isRequired,
  isInsuredAlreadyExists: PropTypes.bool.isRequired,
  isBeneficiaryAlreadyExists: PropTypes.bool.isRequired,
};

export default RolesChecks;
