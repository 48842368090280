/* eslint-disable no-unused-vars */
import React, { useMemo, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Filter from 'components/Filter';
import Input from 'components/Input';
import Currency from 'components/Currency';
import Select from 'components/Select';
import Tooltip from 'components/Tooltip';
import {
  addRulesValidation,
  toJSONValidation,
} from 'utils/helpers/reactFormValidations';
import addCircle from 'assets/icons/add-circle.svg';
import checkmark from 'assets/icons/checkmark-circle.svg';
/**
 * H1
 */
const H1 = styled.h1`
  color: var(--liberty-blue);
  @media (min-width: 500px) {
    min-width: 500px;
  }
`;

/**
 * p
 */
const P = styled.p`
  max-width: 576px;
`;

/**
 *
 */
const ImageContainer = styled.div`
  height: 90px;
  width: 90px;
  border: 1px solid var(--teal);
  background-color: var(--light-teal);
  border-radius: 50%;

  img.main {
    min-width: 50px;
  }
`;

/**
 *
 */
const Plus = styled.img`
  position: absolute;
  top: -5px;
  right: -15px;
`;

const Text = styled.p`
  min-width: 150px;
  width: 100%;
`;

const QuestionMark = styled.div`
  align-items: center;
  background-color: var(--teal-background);
  border-radius: 50%;
  color: var(--liberty-blue);
  display: flex;
  display: inline-block;
  font-size: 8px;
  font-weight: bold;
  justify-content: center;
  line-height: 1;
  margin-left: 10px;
  padding: 2px 4px;
  &::before {
    content: '?';
  }
`;

const Accordion = styled.div`
  input[type='checkbox'] {
    display: none;
  }
  .lbl-toggle {
    display: block;
    text-align: center;
    color: var(--dark-teal);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 13.4px;
    cursor: pointer;
    border-radius: 7px;
    transition: all 0.25s ease-out;
  }

  .lbl-toggle::after {
    background-image: url(${require(`assets/icons/arrow-down.svg`)});
    background-repeat: no-repeat;
    width: 16px;
    height: 10px;
    content: ' ';
    display: inline-block;
    vertical-align: middle;
    margin-left: 0.25rem;
    transform: translateY(-2px);
    transition: transform 0.2s ease-out;
  }
  .toggle:checked + .lbl-toggle::after {
    transform: rotate(180deg) translateX(0px);
  }
  .collapsible-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.25s ease-in-out;
  }
  .toggle:checked + .lbl-toggle + .collapsible-content {
    max-height: 350px;
  }
  .toggle:checked + .lbl-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .collapsible-content .content-inner {
  }
  .collapsible-content p {
    margin-bottom: 0;
  }
`;
export default function CoverageModal(props) {
  const {
    showModal,
    setShowModal,
    setData,
    policyCoverage,
    coverage,
    policyCoveragePropUpdate,
  } = props;

  const defaultValues = {};

  const searchFilterOption = (field, value) => {
    const selectedOption = field.results[2][0][1].find(
      (option) => option.codigo === value.toString()
    );
    return selectedOption || null;
  };

  if (policyCoverage.codigoAmparo === '8884') {
    defaultValues[coverage.results[3][0][1][0].results[0][0].id] =
      policyCoverage.valorRiesgo;
  } else if (policyCoverage.codigoAmparo === '8880') {
    defaultValues[coverage?.results[3][0][1][0]?.results[0][0]?.id] =
      policyCoverage.valorRiesgo;
  } else {
    const fields = coverage.results[3][0][1];
    fields.forEach((field) => {
      const subCoverage = policyCoverage?.propiedadesAmparo.find(
        (amparo) => amparo.codigo === field.results[0][0].codigoPregunta
      );
      switch (field.results[0][0].tipo) {
        case 'filter':
          defaultValues[field.results[0][0].id] = searchFilterOption(
            field,
            subCoverage?.valor
          );
          break;
        case 'currency':
        case 'number':
          defaultValues[field.results[0][0].id] = subCoverage?.valor.toString();
          break;
        default:
          break;
      }
    });
  }

  const onSubmit = (data) => {
    setShowModal(false);
    setData(data);
  };

  const getCoverageSelectedProp = useCallback(
    (subcoverage) => {
      const { selected } = policyCoverage.propiedadesAmparo.find(
        (option) => option.codigo === subcoverage.codigoAmparoHijo
      );
      return selected.toString() === '1';
    },
    [policyCoverage]
  );

  const addCoverage = (subcoverage) => {
    const { selected } = policyCoverage.propiedadesAmparo.find(
      (option) => option.codigo === subcoverage.codigoAmparoHijo
    );
    const { amparoPadre, codigoAmparoHijo } = subcoverage;
    const coverageProp = {
      codigo: codigoAmparoHijo,
      selected: selected === '1' ? '0' : '1',
    };
    policyCoveragePropUpdate(amparoPadre, coverageProp);
  };

  const {
    control,
    handleSubmit,
    register,
    trigger,
    errors,
    setValue,
    formState,
    clearErrors,
    getValues,
    setError,
  } = useForm({ defaultValues, mode: 'all' });

  const newCoverages = useMemo(
    () =>
      coverage.results[4][0][1].map((subcoverage) => {
        subcoverage.isSelected = getCoverageSelectedProp(subcoverage);
        return subcoverage;
      }),
    [coverage, getCoverageSelectedProp]
  );

  return (
    Object.keys(defaultValues).length !== 0 && (
      <Modal showModal={showModal} setShowModal={setShowModal} lg={false}>
        <form
          name="coverageForm"
          className="flex flex-col items-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col m-auto items-center">
            <div className="relative w-20 h-20 m-auto bg-lightTeal rounded-full border border-solid border-teal">
              <img
                src={require(`assets/icons/activities/${coverage.results[0][0].ubicacion}`)}
                alt="Liberty Seguros - Alt"
                className="w-12	absolute inset-0 m-auto"
              />
              <Plus src={addCircle} alt="" />
            </div>
            <H1 className="text-center text-2xl md:text-3xl font-medium w-full md:w-2/3 px-1 mt-4">
              {coverage.results[0][0].garantia}
            </H1>
            <div className="w-full px-4 md:w-3/5 md:px-0">
              <P className="text-center text-base font-medium mt-8 mb-4">
                {coverage.results[0][0].literalesModal}
              </P>
            </div>

            {coverage.results[2][0][1].length > 0 && (
              <React.Fragment key="accordion">
                <Accordion className="w-full">
                  <div className="wrap-collabsible">
                    <input
                      id="collapsible"
                      className="toggle"
                      type="checkbox"
                    />
                    <label htmlFor="collapsible" className="lbl-toggle mb-4">
                      {coverage.results[0][0].panelLabel}
                    </label>
                    <div className="collapsible-content">
                      <div className="content-inner w-full px-4 md:w-3/5 md:px-0 m-auto">
                        <div className="text-left leading-5">
                          {coverage.results[2][0][1].map((subcoverage) => (
                            <div
                              key={subcoverage.codigoInformativo}
                              className="flex items-baseline"
                            >
                              <img src={checkmark} alt="" className="mr-2" />
                              <span className="text-sm text-gray-600">
                                {subcoverage.descripcion}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr className="h-px w-full border-solid divide-gray-200 mt-4" />
                    </div>
                  </div>
                </Accordion>
              </React.Fragment>
            )}

            <div className="w-full px-4 md:w-3/5 md:px-0 grid grid-cols-1 gap-5 my-4">
              {coverage.results[3][0][1].map((field) => {
                const {
                  id,
                  tipo,
                  descripcionPregunta,
                  inputClass,
                } = field.results[0][0];
                let validations = field.results[1][0][1];

                switch (tipo) {
                  case 'number':
                    return (
                      <Input
                        key={id}
                        placeholder={descripcionPregunta}
                        type={tipo}
                        name={id}
                        onChange={(e) => {
                          trigger(id);
                        }}
                        ref={register(toJSONValidation(validations))}
                        inputClass={inputClass}
                        controlled={false}
                        errorMessage={errors[id] && errors[id].message}
                        defaultValue={0}
                      />
                    );
                  case 'filter':
                    return (
                      <Controller
                        key={`controller-${id}`}
                        render={({ value }) => (
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <Filter
                            name={id}
                            placeholder={descripcionPregunta}
                            options={field.results[2][0][1]}
                            onValueChange={(option) => {
                              setValue(id, option);
                              trigger(id);
                            }}
                            optionKey="codigoPreguntaDet"
                            optionLabel="respuesta"
                            inputClass={inputClass}
                            controlled
                            errorMessage={errors[id] && errors[id].message}
                            defaultInputValue={
                              id &&
                              defaultValues[id] &&
                              defaultValues[id].respuesta
                            }
                          />
                        )}
                        name={id}
                        control={control}
                        rules={toJSONValidation(validations)}
                      />
                    );
                  case 'select':
                    return (
                      <Controller
                        key={`controller-${id}`}
                        render={({ value }) => (
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <Select
                            name={id}
                            placeholder={descripcionPregunta}
                            options={field.results[2][0][1]}
                            onValueChange={(values) => {
                              setValue(id, values);
                              trigger(id);
                            }}
                            optionKey="codigoPreguntaDet"
                            optionLabel="respuesta"
                            errorMessage={errors[id] && errors[id].message}
                            defaultValue={null}
                          />
                        )}
                        name={id}
                        control={control}
                        rules={toJSONValidation(validations)}
                      />
                    );
                  case 'currency': {
                    const validationCurrency = addRulesValidation(
                      id,
                      getValues,
                      setError,
                      clearErrors,
                      validations
                    );
                    return (
                      <Controller
                        key={`controller-${id}`}
                        render={({ value }) => (
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <Currency
                            key={id}
                            placeholder={descripcionPregunta}
                            type={tipo}
                            name={id}
                            onValueChange={(values) => {
                              setValue(id, values.value);
                              trigger(id);
                              if (id == 'PresupuAnual') trigger('DineroLimite');
                            }}
                            controlled
                            inputClass={inputClass}
                            errorMessage={errors[id] && errors[id].message}
                            defaultInputValue={
                              id && defaultValues[id] ? defaultValues[id] : ''
                            }
                          />
                        )}
                        name={id}
                        control={control}
                        rules={toJSONValidation(validationCurrency)}
                      />
                    );
                  }
                  default:
                    return null;
                }
              })}
            </div>

            <div className="w-full bg-tealBackground">
              <div className="w-full px-4 md:w-3/5 md:px-0 grid grid-cols-1 gap-5 mx-auto my-4">
                {coverage.results[4][0][1].length > 0 && (
                  <h6 className="text-blue font-medium text-base">
                    ¿Tu negocio necesita adicionalmente?
                  </h6>
                )}

                <div className="text-left leading-5">
                  {newCoverages.map((subcoverage) => (
                    <div
                      key={subcoverage.codigoAmparoHijo}
                      className="flex justify-start items-center w-full py-3 flex-no-wrap"
                    >
                      <Checkbox
                        checkedProp={subcoverage.isSelected}
                        changeProp={() => {
                          addCoverage(subcoverage);
                        }}
                        dontModify={false}
                      />
                      {/* <Text className="font-semibold text-gray-600 text-sm tracking-normal leading-4 cursor-pointer select-none">
                        {subcoverage.partida}
                      </Text>
                      <QuestionMark /> */}
                      <div className="flex items-center w-full">
                        <Text className="font-semibold text-gray-600 text-sm tracking-normal leading-4 cursor-pointer select-none">
                          {subcoverage.partida}
                        </Text>
                        <Tooltip
                          position="top"
                          left="15px"
                          content={
                            subcoverage.partida ? (
                              <p className="text-sm text-white leading-tight">
                                {subcoverage.partida}
                              </p>
                            ) : undefined
                          }
                        >
                          <QuestionMark />
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-col md:flex-row justify-center my-4 px-4">
                <Button primary type="submit" disabled={!formState.isValid}>
                  Continuar
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    )
  );
}

/**
 * Option proptypes
 */
const Option = PropTypes.shape();

/**
 * PropsTypes
 */
CoverageModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  setData: PropTypes.func,
  /**
   * Draft
   * @param {arrayOf} coverage
   */
  draft: PropTypes.shape({
    policyRisks: {
      amparos: PropTypes.arrayOf({}),
    },
  }),
  /**
   * PolicyCoverage
   * @param {array} coverage
   */
  policyCoverage: PropTypes.shape({
    codigoAmparo: PropTypes.string,
    valorRiesgo: PropTypes.number,
    propiedadesAmparo: PropTypes.array,
  }),
  /**
   * Coverage
   * @param {arrayOf} coverage
   */
  coverage: PropTypes.shape({
    results: PropTypes.array,
  }),
  /**
   * Subcoverage update
   */
  policyCoveragePropUpdate: PropTypes.func,
};

/**
 * Default props
 */
CoverageModal.defaultProps = {
  showModal: false,
  setShowModal: null,
  setData: null,
  /**
   * Draft
   * @param {arrayOf} coverage
   */
  draft: {
    policyRisks: {
      amparos: [],
    },
  },
  /**
   * PolicyCoverage
   * @param {arrayOf} coverage
   */
  policyCoverage: PropTypes.shape({
    codigoAmparo: '',
    valorRiesgo: 0,
    propiedadesAmparo: [],
  }),
  /**
   * Coverage
   * @param {arrayOf} coverage
   */
  coverage: {
    results: [{}],
  },
  /**
   * Subcoverage update
   */
  policyCoveragePropUpdate: null,
};
