import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import QuestionTooltip from './QuestionTooltip';

const LeftTimeLabel = ({ leftTime }) => {
  const expirationRules = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[6][0][1][0]
      : []
  );

  const {
    semaforoNaranja,
    semaforoRojo,
    semaforoNaranjaMax,
    semaforoRojoMin,
    semaforoRojoMax,
  } = useMemo(() => expirationRules, [expirationRules]);

  let background = semaforoNaranja;
  if (leftTime <= semaforoRojoMax) background = semaforoRojo;
  else if (leftTime > semaforoNaranjaMax) background = 'transparent';

  return (
    <div className="flex gap-2">
      <span
        style={{
          background: `${background}`,
          color: leftTime > semaforoNaranjaMax ? 'var(--gray-600)' : 'white',
          fontWeight: leftTime > semaforoNaranjaMax ? 400 : 600,
        }}
        className="inline-flex items-center px-2 py-1 h-4 rounded font-medium bg-red-100"
      >
        {leftTime < semaforoRojoMin ? `Vencido` : `${leftTime} días`}
      </span>
      {leftTime === 1 && (
        <QuestionTooltip
          content={`Recuerda que solo hasta hoy ${new Date().toLocaleDateString()} puedes iniciar la retoma del proceso de esta solicitud`}
        />
      )}
    </div>
  );
};

LeftTimeLabel.propTypes = {
  leftTime: PropTypes.number.isRequired,
};

export default LeftTimeLabel;
