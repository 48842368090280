import React from 'react';
import PropTypes from 'prop-types';
import dashed from '../../../../assets/images/dashed.svg';
import dashedError from '../../../../assets/images/dashed-error.svg';
import alert from '../../../../assets/icons/alert.svg';

export default function DashedCard({ children, isRequired }) {
  return (
    <div className="relative">
      {isRequired && (
        <span className="absolute top-0 -mt-5 text-lmRed flex text-xs">
          <img className="w-4 mr-1" src={alert} alt="icon caution" />
          <span className="truncate">Rol necesario para emitir la póliza</span>
        </span>
      )}
      <div
        style={{
          backgroundImage: `url(${isRequired ? dashedError : dashed})`,
        }}
        className={`h-full ${isRequired ? 'bg-red-100' : 'bg-tealBackground'}`}
      >
        {children}
      </div>
    </div>
  );
}

DashedCard.propTypes = {
  children: PropTypes.object.isRequired,
  isRequired: PropTypes.bool.isRequired,
};
