import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-grid-system';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

// Components
import Input from '../../../basics/form/Input';
import Select from '../../../basics/form/Select';
import RadioButtonComponent from '../../../basics/form/RadioButtonComponent';

// Constants
import {
  LegalPersonRegisterOptions,
  optionsIdentitiesLegalRepresentative,
} from '../../../../../utils/constants/emit_constants';

// Styles
const SpanBold = styled.span`
  font-weight: bold;
  color: #1a1446;
`;

const SpanAsterisc = styled.span`
  color: #28a3af;
`;

const FormRepresentativeLegal = ({
  roleInfo,
  handleSearchPerson,
  legalPersonIsBeneficiarie,
  setLegalPersonIsBeneficiarie,
}) => {
  const {
    register,
    trigger,
    errors,
    control,
    setValue,
    getValues,
  } = useFormContext();

  

  const [selectedOption, setSelectedOption] = useState(''); // Estado para la opción seleccionada

  // useEffect(() => {
  //   if (roleInfo.info.representativeLegal) {
  //     setValue(
  //       'isLegalRepresentativeBeneficiarie',
  //       roleInfo.representativeLegal.isLegalRepresentativeBeneficiarie
  //     );
  //     setValue(
  //       'documentTypeLegalRepresentative',
  //       roleInfo.representativeLegal.documentTypeLegalRepresentative
  //     );
  //     setValue(
  //       'documentNumberLegalRepresentative',
  //       roleInfo.representativeLegal.documentNumberLegalRepresentative
  //     );
  //     setValue(
  //       'firstNameLegalRepresentative',
  //       roleInfo.representativeLegal.firstNameLegalRepresentative
  //     );
  //     setValue(
  //       'secondNameLegalRepresentative',
  //       roleInfo.representativeLegal.secondNameLegalRepresentative
  //     );
  //     setValue(
  //       'surnameLegalRepresentative',
  //       roleInfo.representativeLegal.surnameLegalRepresentative
  //     );
  //     setValue(
  //       'secondSurnameLegalRepresentative',
  //       roleInfo.representativeLegal.secondSurnameLegalRepresentative
  //     );
  //     trigger();
  //   }
  // }, [roleInfo.representativeLegal]);

  return (
    <>
      <div className="px-3 md:max-w-3xl mx-auto widthCustom">
        <h1 className="text-2xl mb-2 leading-tight">
          Datos del Representante Legal
        </h1>
        <p className="text-xs mx-5 mb-6 leading-tight">
          ¿El <SpanBold>Representante Legal</SpanBold> es{' '}
          <SpanBold>Beneficiario Final</SpanBold>?{' '}
          <SpanAsterisc>*</SpanAsterisc>
        </p>

        <Row className="radioButtonsContainer">
          <Controller
            key="isLegalRepresentativeBeneficiarie"
            render={() => (
              <>
                <RadioButtonComponent
                  key="Si"
                  description="Si"
                  constructionSelect={
                    getValues('isLegalRepresentativeBeneficiarie')?.option
                  }
                  onChange={(option) => {
                    setValue(
                      'isLegalRepresentativeBeneficiarie',
                      { option, code: 'Si' },
                      { shouldValidate: true }
                    );
                    setLegalPersonIsBeneficiarie({
                      option,
                      code: 'Si',
                    });
                    trigger('isLegalRepresentativeBeneficiarie');
                  }}
                  errorMessage={
                    errors.isLegalRepresentativeBeneficiarie?.message || ''
                  }
                  isLegalRepresentative
                />
                <RadioButtonComponent
                  key="No"
                  description="No"
                  constructionSelect={
                    getValues('isLegalRepresentativeBeneficiarie')?.option
                  }
                  onChange={(option) => {
                    setValue(
                      'isLegalRepresentativeBeneficiarie',
                      { option, code: 'No' },
                      { shouldValidate: true }
                    );
                    setLegalPersonIsBeneficiarie({
                      option,
                      code: 'No',
                    });
                    trigger('isLegalRepresentativeBeneficiarie');
                  }}
                  errorMessage={
                    errors.isLegalRepresentativeBeneficiarie?.message || ''
                  }
                  isLegalRepresentative
                />
              </>
            )}
            name="isLegalRepresentativeBeneficiarie"
            control={control}
            rules={LegalPersonRegisterOptions.isLegalRepresentativeBeneficiarie}
          />
        </Row>
        <hr />
      </div>
      <Row className="m-auto space-y-8 px-3 md:max-w-3xl mx-auto ">
        <Col
          sm={12}
          className="flex flex-col space-y-8  lg:space-y-0 lg:flex-row lg:space-x-6 "
        >
          <Controller
            key="documentTypeLegalRepresentative"
            render={() => (
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Select
                placeholder="Tipo de identificación"
                options={optionsIdentitiesLegalRepresentative}
                optionKey="name"
                optionLabel="name"
                onChange={(option) => {
                  setValue('documentTypeLegalRepresentative', option);
                  trigger('documentTypeLegalRepresentative');
                  setValue('documentNumberLegalRepresentative', '', {
                    shouldValidate: true,
                  });
                  setValue('firstNameLegalRepresentative', '', {
                    shouldValidate: true,
                  });
                  setValue('secondNameLegalRepresentative', '', {
                    shouldValidate: true,
                  });
                  setValue('surnameLegalRepresentative', '', {
                    shouldValidate: true,
                  });
                  setValue('secondSurnameLegalRepresentative', '', {
                    shouldValidate: true,
                  });
                }}
                errorMessage={
                  errors.documentTypeLegalRepresentative &&
                  errors.documentTypeLegalRepresentative.message
                }
                disabled={false}
                valueDefault={getValues().documentTypeLegalRepresentative}
              />
            )}
            name="documentTypeLegalRepresentative"
            control={control}
            rules={LegalPersonRegisterOptions.documentTypeLegalRepresentative}
            defaultValue={
              roleInfo?.representativeLegal?.documentTypeLegalRepresentative
            }
          />
          <Controller
            key="documentNumberLegalRepresentative"
            render={() => (
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Input
                placeholder="Número de identificación*"
                type={
                  selectedOption === '40' ||
                  roleInfo?.documentTypeLegalRepresentative?.code === '40'
                    ? 'text'
                    : 'number'
                }
                name="documentNumberLegalRepresentative"
                inputClass=""
                controlled
                errorMessage={
                  errors.documentNumberLegalRepresentative &&
                  errors.documentNumberLegalRepresentative.message
                }
                disabled={false}
                onChange={(e) => {
                  const { value } = e.target;
                  setValue('documentNumberLegalRepresentative', value);
                  trigger('documentNumberLegalRepresentative');
                }}
                value={getValues().documentNumberLegalRepresentative}
                onBlur={handleSearchPerson}
              />
            )}
            name="documentNumberLegalRepresentative"
            control={control}
            rules={
              selectedOption === '40' ||
              roleInfo?.documentTypeLegalRepresentative?.code === '40'
                ? LegalPersonRegisterOptions.documentNumberLegalRepresentativePassport
                : LegalPersonRegisterOptions.documentNumberLegalRepresentative
            }
          />
        </Col>
        <Col
          sm={12}
          className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-6"
        >
          <Input
            placeholder="Primer Nombre"
            type="text"
            name="firstNameLegalRepresentative"
            ref={register(
              LegalPersonRegisterOptions.firstNameLegalRepresentative
            )}
            controlled={false}
            errorMessage={
              errors.firstNameLegalRepresentative &&
              errors.firstNameLegalRepresentative.message
            }
            disabled={false}
          />
          <Input
            placeholder="Segundo Nombre"
            type="text"
            name="secondNameLegalRepresentative"
            ref={register(
              LegalPersonRegisterOptions.secondNameLegalRepresentative
            )}
            controlled={false}
            errorMessage={
              errors.secondNameLegalRepresentative &&
              errors.secondNameLegalRepresentative.message
            }
            disabled={false}
          />
        </Col>
        <Col
          sm={12}
          className="flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-6"
        >
          <Input
            placeholder="Primer Apellido"
            type="text"
            name="surnameLegalRepresentative"
            ref={register(
              LegalPersonRegisterOptions.surnameLegalRepresentative
            )}
            controlled={false}
            errorMessage={
              errors.surnameLegalRepresentative &&
              errors.surnameLegalRepresentative.message
            }
            disabled={false}
          />
          <Input
            placeholder="Segundo Apellido"
            type="text"
            name="secondSurnameLegalRepresentative"
            ref={register(
              LegalPersonRegisterOptions.secondSurnameLegalRepresentative
            )}
            controlled={false}
            errorMessage={
              errors.secondSurnameLegalRepresentative &&
              errors.secondSurnameLegalRepresentative.message
            }
            disabled={false}
          />
        </Col>
      </Row>
      <hr />
    </>
  );
};

FormRepresentativeLegal.propTypes = {
  roleInfo: PropTypes.object,
  handleSearchPerson: PropTypes.func,
  legalPersonIsBeneficiarie: PropTypes.object,
  setLegalPersonIsBeneficiarie: PropTypes.func,
};

FormRepresentativeLegal.defaultProps = {
  roleInfo: {},
  handleSearchPerson: {},
  legalPersonIsBeneficiarie: () => {},
  setLegalPersonIsBeneficiarie: () => {},
};

export default FormRepresentativeLegal;
