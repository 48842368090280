import axios from 'axios';

import { HEADERS_JSON } from 'utils/constants/DefaultBodies';

const urlSession = process.env.REACT_APP_URL_SESSION;

/**
 * Verifies if url starts with the base or adds the indicated path
 * @param {string} url
 */
const readUrl = (url = '') =>
  url.startsWith('http://') || url.startsWith('https://')
    ? url
    : `${urlSession}/${url}`;

/**
 * Gets and update the last session
 * @param {string} url
 * @param {Object} body
 * @param {Object} headers
 */
const sessionService = (url = '', body = {}, headers = {}) => {
  return axios.post(readUrl(url), body, {
    headers: { ...HEADERS_JSON.headers, ...headers },
  });
};

export default sessionService;
