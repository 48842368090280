/* eslint-disable camelcase */
import { client, clientRetry } from 'services/axios';
import { getSessionHeaders } from 'utils/helpers/sessionData';

const userApi = process.env.REACT_APP_URL_EMIT;

const search = ({ document_type, document_number, needClientRetry = true }) => {
  if (!needClientRetry) {
    return client.post(
      `${userApi}/user/search`,
      {
        data: { document_type, document_number },
      },
      {
        headers: getSessionHeaders(),
      }
    );
  }
  return clientRetry.post(
    `${userApi}/user/search`,
    {
      data: { document_type, document_number },
    },
    {
      headers: getSessionHeaders(),
    }
  );
};

const validateIdentity = ({ documentType, documentNumber, name }) => {
  const parsedDocumentNumber =
    documentType === '37' ? documentNumber.slice(0, -1) : documentNumber;
  return client.post(
    `${userApi}/user/identity`,
    {
      data: {
        identification_type: documentType,
        identification: parsedDocumentNumber,
        last_name: name,
      },
    },
    {
      headers: getSessionHeaders(),
    }
  );
};

export default {
  search,
  validateIdentity,
};
