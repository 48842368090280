import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import iconExpandMore from '../assets/icons/expand-more.svg';
import iconExpandLess from '../assets/icons/expand-less.svg';

const UserInfo = styled.div`
  display: ${({ isDisplay }) => (!isDisplay ? 'none' : 'inline')};
`;

const Menu = styled.div`
  width: 0;
  height: 0;
  padding: 1.5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid white;
  margin-right: 8px;
`;

const Items = styled.div`
  width: '100%';
  height: 0px;
  background-color: white;
`;

const ButtonClick = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PName = styled.p`
  /* margin-left: 10px; */
  margin-bottom: 3px;
  height: 14px;
  width: 100%;
  color: #808080;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
`;

const PValue = styled.p`
  /* margin-left: 10px; */
  height: 16px;
  width: 96px;
  color: #343741;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
`;

const UserMenuItem = ({ lastSession }) => {
  const [display, setDisplay] = useState(false);
  const sessionInfo = sessionStorage.getItem('info');
  let sessionInfoObj;
  if (sessionInfo) {
    sessionInfoObj = JSON.parse(sessionInfo);
  }
  const format = 'DD/MMM/YYYY';
  const timeFormat = 'hh:mm a';

  const UserInfoArray = [
    { name: 'IP', value: sessionInfoObj && sessionInfoObj.ip },
    {
      name: 'Hora última conexión',
      value: `${moment(lastSession?.LastDate).format(timeFormat)}`,
    },
    {
      name: 'Fecha última conexión',
      value: `${moment(lastSession?.LastDate).format(format)}`,
    },
    { name: 'Fecha actual', value: moment().format(format) },
  ];

  const click = () => {
    setDisplay(!display);
  };

  return (
    <>
      <div className=" sm:flex hidden mx-2">
        <div className="mx-auto w-50 text-right">
          <div className="relative inline-block text-left">
            <div>
              <ButtonClick onClick={click}>
                <img
                  src={!display ? iconExpandMore : iconExpandLess}
                  width="15"
                  height="9"
                  alt="User Menu"
                />
              </ButtonClick>
            </div>

            <UserInfo isDisplay={display}>
              <section className="flex flex-col absolute">
                <Menu />
                <Items>
                  <div
                    className="origin-top-right p-3 absolute right-0 mt-0 w-56 shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                    style={{ zIndex: 3, maxWidth: '176px' }}
                  >
                    <div className="py-0">
                      {UserInfoArray.map((data) => (
                        <section
                          key={data.name}
                          className="text-left p-1 text-sm font-regular hover:bg-lightTeal hover:font-medium focus:outline-none"
                          style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            cursor: 'pointer',
                          }}
                        >
                          <PName>{data.name}</PName>
                          <PValue>{data.value}</PValue>
                        </section>
                      ))}
                    </div>
                  </div>
                </Items>
              </section>
            </UserInfo>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserMenuItem;

/**
 * PropsTypes
 */
UserMenuItem.propTypes = {
  /**
   * Current Draft
   */
  lastSession: PropTypes.object,
};

UserMenuItem.defaultProps = {
  /**
   * Current Draft
   */
  lastSession: {
    LastDate: '',
  },
};
