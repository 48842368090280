/* eslint-disable camelcase */
import axios from 'axios';
import { getSessionHeaders } from 'utils/helpers/sessionData';

const baseAPI = process.env.REACT_APP_URL_EMIT;

// eslint-disable-next-line camelcase
const createOpenlLogs = (payloadBeneficiary) => {
  const {
    compBrokerId,
    compDate,
    date,
    fullDate,
    log_id,
    request,
    response,
    drafts,
  } = payloadBeneficiary;
  axios.post(
    `${baseAPI}/emission/create-openl-logs-request`,
    {
      compBrokerId,
      compDate,
      date,
      fullDate,
      log_id,
      request,
      response,
      drafts,
    },
    { headers: getSessionHeaders() }
  );
};
export default {
  createOpenlLogs,
};
