import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  MemoryRouter as Router,
  Switch,
  Route,
  useHistory,
} from 'react-router-dom';
import Risk from 'layouts/tabs/Risk';
import Risks from 'layouts/tabs/Risks';

export default function Drafts(props) {
  const history = useHistory();
  const { drafts, currentTab, setCurrentTab } = props;
  const [draftIndex, setDraftIndex] = useState(drafts.length - 1);
  const [selectedRiskIndex, setSelectedRiskIndex] = useState(
    drafts[drafts.length - 1].risks.length - 1
  );
  const [draft, setDraft] = useState(drafts[drafts.length - 1]);
  const [selectedRisk, setSelectedRisk] = useState(
    drafts[drafts.length - 1].risks[drafts[drafts.length - 1].risks.length - 1]
  );

  const setEmitRoute = () => {
    history.push('/emit');
  };

  return (
    <React.Fragment key="drafts-component">
      <Router>
        <Switch>
          <Route exact path="/risks">
            <Risks
              // draft={draft}
              setDraft={setDraft}
              draftIndex={draftIndex}
              setDraftIndex={setDraftIndex}
              riskIndex={selectedRiskIndex}
              setRiskIndex={(i) => setSelectedRiskIndex(i)}
              setRisk={(risk) => setSelectedRisk(risk)}
              setEmitRoute={setEmitRoute}
            />
          </Route>
          <Route exact path="/risk">
            <Risk
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              currentRisk={selectedRisk}
              draftIndex={draftIndex}
              riskIndex={selectedRiskIndex}
            />
          </Route>
          <Route exact path="/">
            <Redirect draft={draft} />
          </Route>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export function Redirect(props) {
  const { draft } = props;
  const history = useHistory();
  useEffect(() => {
    draft.risks.forEach((risk) => {
      if (risk.completed === false) {
        history.push('/risk');
      } else {
        history.push('/risks');
      }
    });
  }, []);
  return <></>;
}

/**
 * PropTypes
 */
Drafts.propTypes = {
  /**
   * Activated validation
   * @param {array} drafts
   */
  drafts: PropTypes.array.isRequired,
  /**
   * Set current tab
   */
  setCurrentTab: PropTypes.func,
  /**
   * Current tab
   */
  currentTab: PropTypes.number,
};

Drafts.defaultProps = {
  setCurrentTab: undefined,
  currentTab: 1,
};

Redirect.propTypes = {
  draft: PropTypes.object.isRequired,
};
