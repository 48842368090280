/* eslint-disable no-unused-expressions */
import { getNestedValue, isArrayEmpty } from 'utils/helpers/object';
import coverageExceptions from './coverageException';
import CONST_SCV from '../constants/scv';
import percentaje from './percentaje';

export const getTemplateRequest = (basicTemplate, type) => {
  const template = {
    amparos: [],
  };
  basicTemplate.forEach((element) => {
    if (element.results[0][0].tipo === type) {
      template.amparos.push({
        ...element.results[0][0],
        propiedadesAmparo: element.results[1][0][1],
      });
    }
  });
  return template;
};

const mapValuesEnsure = (
  { risk, templateCapital, idComponent },
  openlCoveragesSpecs
) => {
  let copyTemplate;
  if (risk.amparos) {
    copyTemplate = { ...risk };
  } else {
    copyTemplate = { ...templateCapital };
  }

  if (risk && risk[idComponent] && risk.completed === false) {
    const coveragesToSkip = [];

    if (risk.constraintTable) {
      if (risk.constraintTable.responseService) {
        risk.constraintTable.responseService.results[0][1][1].forEach(
          (sigRestrictedItem) => {
            coveragesToSkip.push(sigRestrictedItem.codigoAmparo);
          }
        );
      }
    }

    copyTemplate.amparos &&
      copyTemplate.amparos.forEach((item) => {
        const mustUnselect = coveragesToSkip.some(
          (skipCov) => skipCov === item.codigoAmparo
        );
        const itemMatch = openlCoveragesSpecs.find(
          (coverage) =>
            coverage.results[0][0].codigoAmparo === item.codigoAmparo
        );
        if (itemMatch?.results[0][0].obligarorio === 'Obligatorio')
          item.selected = '1';

        if (mustUnselect) item.selected = '0';
        item.propiedadesAmparo.forEach((element) => {
          // eslint-disable-next-line no-param-reassign
          if (
            risk[idComponent][element.id] &&
            idComponent !== 'subItemCoverage'
          ) {
            let exceptionMatch = coverageExceptions.find(
              (covExc) =>
                covExc.id === element.codigo ||
                covExc.parentId === element.codigoAmparoCap
            );
            if (exceptionMatch) {
              element.valor = percentaje.percentajeFn(
                exceptionMatch.percentage / 100,
                100000000,
                risk[idComponent][element.id]
              );
            }
            CONST_SCV.SCV_CHILDRENS.forEach((exceptionItem) => {
              if (
                exceptionItem.childrens.some(
                  (child) => child.toString() === element.codigo
                )
              ) {
                exceptionMatch = true;
                const mustToCalc = CONST_SCV.CALC.some(
                  (codigo) => parseInt(element.codigo, 10) === codigo
                );
                let porcentajeDefecto = null;
                let valorMaximo = null;
                if (!openlCoveragesSpecs) {
                  element.valor = risk[idComponent][element.id];
                  return;
                }
                if (mustToCalc) {
                  const childMatch = openlCoveragesSpecs.find(
                    (coverage) =>
                      coverage.results[0][0].codigoAmparo === element.codigo
                  );
                  if (childMatch) {
                    porcentajeDefecto = parseFloat(
                      childMatch.results[0][0].porcentajeDefecto
                    );

                    valorMaximo = parseFloat(
                      childMatch.results[0][0].valorMaximo
                    );
                    element.valor =
                      mustToCalc &&
                      porcentajeDefecto !== null &&
                      valorMaximo !== null
                        ? percentaje.percentajeFn(
                            porcentajeDefecto,
                            valorMaximo,
                            risk[idComponent][element.id]
                          )
                        : risk[idComponent][element.id];
                  }
                } else {
                  element.valor = risk[idComponent][element.id];
                }
              }
            });
            // CALC_BY_FLAG
            // It's used to don't override excepted coverages when Valores a Asegurar changed
            if (!(idComponent === 'ValoAsegur' && exceptionMatch)) {
              element.valor = risk[idComponent][element.id];
            }
          } else if (
            !risk[idComponent][element.id] &&
            idComponent !== 'subItemCoverage' &&
            (element.id === 'VlaEdificio' ||
              element.id === 'VlaContenido' ||
              element.id === 'VlaEquipEle' ||
              element.id === 'VlaMercancia')
          ) {
            element.valor = 0;
          }
        });
      });
  }
  return copyTemplate;
};

export const getRequestCapitalCalculation = (
  {
    capitalMaximo,
    businessCode,
    draft,
    templateCapital,
    templateCapital2,
    idComponent,
  },
  openlCoveragesSpecs
) => {
  const request = {
    poliza: {
      capitalMaximo,
      actividad: businessCode,
      riesgos: [],
      riesgosPoliza: {},
    },
  };
  draft.risks.forEach((item) => {
    const map = mapValuesEnsure(
      {
        risk: item,
        templateCapital,
        idComponent,
      },
      openlCoveragesSpecs
    );
    request.poliza.riesgos.push(map);
  });

  request.poliza.riesgosPoliza.amparos = templateCapital2.amparos;
  return request;
};

export const getValuesRisks = (values, template) => {
  const json = {};
  template.forEach((item) => {
    if (item.dinamico === '1') {
      const arr = item.iDNewBusiness.split(',');
      json[item.idCalculo] = getNestedValue(values, arr);
    } else {
      json[item.idCalculo] = item.valor;
    }
  });
  return json;
};

export const getValuesPropertiesRisks = (values, template) => {
  const properties = [];
  template.forEach((item) => {
    const { codigo, valor } = item;
    if (item.dinamico === '1') {
      const arr = item.iDNewBusiness.split(',');
      properties.push({ codigo, valor: getNestedValue(values, arr) });
    } else {
      properties.push({ codigo, valor });
    }
  });
  return properties;
};

export const getValuesPropertiesRisksSIG = (values, template) => {
  const properties = [];
  template.forEach((item) => {
    const { codigo, iDNewBusiness } = item;
    properties.push({
      codigo,
      valor: values?.features[0]?.attributes[iDNewBusiness],
    });
  });
  return properties;
};

const getProperty = (risk, itemProperty, itemTemplate) => {
  const methods = {
    indice: () => {
      const scheme = itemTemplate.results[2][0][1];
      const include = itemTemplate.results[1][0][1].find(
        (item) => item.codigoIndice === itemProperty.codigo
      );
      const { codigo, valor } = scheme[0];
      const { codigo: codigo2 } = scheme[1];
      if (include && risk?.VariableIndex?.selected === '1') {
        return [
          {
            codigo,
            valor,
          },
          {
            codigo: codigo2,
            valor: risk?.VariableIndex?.value || 0,
          },
        ];
      }
      return null;
    },
  };
  const method = methods[itemTemplate?.results[0][0]?.nombreArreglo];
  if (method) return method();
  return null;
};

const getPropertiesAmparos = (risk, itemProperty, templateAmparos) => {
  let properties = [];
  templateAmparos.forEach((itemTemplate) => {
    const property = getProperty(risk, itemProperty, itemTemplate);
    properties = property ? [...properties, ...property] : properties;
  });
  return properties;
};

const getAmparos = (shelters, numRisk, risk, templateAmparos, amparos) => {
  // validar que los amparos no esten vacios
  if (!isArrayEmpty(shelters)) {
    // se recorren los amparos
    shelters.forEach((item) => {
      // validar amparos seleccionados
      if (item.selected === '1' || item.selected === 1) {
        // extraer deducibles de los amparos
        const {
          codigoAmparo: codigoAmparoPadre,
          porcentajeDeducible,
          aplicaDeducible: aplica,
          minimoDeducible: minimo,
          valorDeducible: valor,
          unidadDeducible: unidad,
          propiedadesAmparo,
          valorRiesgo,
        } = item;

        // se construye el amparo que se enviara en el request
        const ampa = {
          codigoAmparoPadre,
          numeroRiesgo: numRisk,
          valorAsegurado: valorRiesgo,
          descuentoRecargo: '0',
          deducible: {
            porcentajeDeducible,
            aplica,
            minimo,
            valor,
            unidad,
          },
        };

        // caso especial en responsabilidad civil por daños para extraer valor riesgo
        // if (codigoAmparoPadre === '8884') {
        //   ampa.valorAsegurado = item.valorRiesgo;
        // }
        // transporte de mercancias debe incluir unas propiedades de amparos especificas
        if (codigoAmparoPadre === '8894') {
          const amparosChildren = ['8016', '8017', '8018', '7997', '7809'];
          ampa.propiedadesAmparo = propiedadesAmparo.filter((itemProperty) =>
            amparosChildren.includes(itemProperty.codigo)
          );
        }
        const codeProperties = ['8876', '8879', '8899', '8880', '8881'];
        if (codeProperties.includes(codigoAmparoPadre)) {
          // arreglo de amparos que se envia con las mismas propiedades de amparos
          ampa.propiedadesAmparo = propiedadesAmparo;
          if (codigoAmparoPadre === '8879') {
            ampa.valorAsegurado = propiedadesAmparo.reduce(
              (total, ampValue) => total * Number(ampValue.valor),
              1
            );
          }
          // se agrega el amparo padre antes de los hijos
          amparos.push(ampa);
        } else {
          // se agrega el amparo padre antes de los hijos
          amparos.push(ampa);
          // se recorren las propiedades amparos que se agregan al arreglo de amparos
          propiedadesAmparo.forEach((itemProperty) => {
            // se extraen algunos atributos de las propiedades del amparo
            const amparo = {
              codigoAmparoPadre,
              codigoAmparo: itemProperty.codigo,
              numeroRiesgo: numRisk,
              valorAsegurado: itemProperty.valor,
              descuentoRecargo: '0',
            };
            // responsabilidad civil por daños las propiedades llevan deducibles
            if (codigoAmparoPadre === '8884') {
              const {
                porcentajeDeducible: porcentajeDeducible2,
                aplicaDeducible: aplica2,
                minimoDeducible: minimo2,
                valorDeducible: valor2,
                unidadDeducible: unidad2,
              } = itemProperty;
              amparo.deducible = {
                porcentajeDeducible: porcentajeDeducible2,
                aplica: aplica2,
                minimo: minimo2,
                valor: valor2,
                unidad: unidad2,
              };
              if (itemProperty.codigo === '8893') {
                // propiedades amparos que son seleccionables
                if (itemProperty.selected === '1') {
                  amparos.push(amparo);
                }
              } else {
                amparos.push(amparo);
              }
            }
            // transporte automatico de mercancias
            else if (codigoAmparoPadre === '8894') {
              if (
                itemProperty.codigo === '8897' ||
                itemProperty.codigo === '8898'
              ) {
                // propiedades amparos que son seleccionables
                if (itemProperty.selected === '1') {
                  amparos.push(amparo);
                }
              } else if (
                itemProperty.codigo === '8895' ||
                itemProperty.codigo === '8896'
              ) {
                // propiedades de amparos que van por defecto
                amparos.push(amparo);
              }
            } else if (itemProperty.valor !== 0) {
              // propiedades de amparos con casos especiales como el indice
              amparo.propiedadesAmparo = getPropertiesAmparos(
                risk,
                itemProperty,
                templateAmparos
              );
              amparos.push(amparo);
            }
          });
        }
      }
    });
  }
  return amparos;
};

export const getValuesAmparos = ({
  policyRisks,
  risk,
  indexRisk,
  templateAmparos,
}) => {
  const amparos = [];
  const numeroRiesgo = (indexRisk + 1).toString();
  // Coberturas y asistencias que aplican a este negocio (riesgo)
  getAmparos(risk.amparos, numeroRiesgo, risk, templateAmparos, amparos);
  // Coberturas adicionales para tu negocio (riesgos)
  if (numeroRiesgo === '1') {
    getAmparos(
      policyRisks?.amparos,
      numeroRiesgo,
      risk,
      templateAmparos,
      amparos
    );
  }

  return amparos;
};
