import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import NavigationTabs from 'components/NavigationTabs';
import Loader from 'components/Loader';
import Roles from './roles/Roles';
import Confirmation from './confirmation/Confirmation';

const Emit = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const openlConfig = useSelector((state) => state.openlConfig.loading);
  const emitInfo = useSelector((state) => state.emitInfo);

  const tabs = [
    {
      id: '1',
      label: 'Roles y vigencia',
      content: <Roles />,
    },
    {
      id: '2',
      label: 'Confirmación',
      content: <Confirmation />,
    },
  ];

  useEffect(() => {
    const { step } = emitInfo.data;
    setCurrentTab(step);
  }, [emitInfo.data.step]);

  return (
    <>
      {openlConfig && <Loader />}
      {emitInfo.loading && <Loader />}
      {!emitInfo.loading && (
        <NavigationTabs
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          isBackVisible={false}
        />
      )}
    </>
  );
};

export default Emit;
