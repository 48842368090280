import * as yup from 'yup';

export const LegalPersonSchema = yup.object().shape({
  documentType: yup.object().required(),
  documentNumber: yup.string().required(),
  businessName: yup.string().required(),
  phoneNumber: yup.string().required().matches(/^\d+$/, 'Teléfono celular requerido'),
  email: yup.string().required(),
  department: yup.object().required(),
  city: yup.object().required(),
  addressType: yup.object().required(),
  address: yup.string().required()
});
export const LegalPersonSchemaWithBeneficiaries = yup.object().shape({
  documentType: yup.object().required(),
  documentNumber: yup.string().required(),
  businessName: yup.string().required(),
  phoneNumber: yup.string().required().matches(/^\d+$/, 'Teléfono celular requerido'),
  email: yup.string().required(),
  department: yup.object().required(),
  city: yup.object().required(),
  addressType: yup.object().required(),
  address: yup.string().required(),
  dataRepresentativeLegal: yup.object(),
  beneficiaries: yup.array(),
  // beneficiaries: yup.boolean(),
});

export const NaturalPersonSchema = yup.object().shape({
  documentType: yup.object().required(),
  documentNumber: yup.string().required(),
  firstName: yup.string().required(),
  surname: yup.string().required(),
  gender: yup.object().required(),
  day: yup.string().required(),
  month: yup.string().required(),
  year: yup.string().required(),
  phoneNumber: yup.string().required().matches(/^\d+$/, 'Teléfono celular requerido'),
  email: yup.string().required(),
  department: yup.object().required(),
  city: yup.object().required(),
  addressType: yup.object().required(),
  address: yup.string().required(),
});

export const BeneficiaryBuildingSchema = yup.object().shape({
  businessName: yup.string().required(),
  insuredValue: yup.number().required(),
  address: yup.string().required(),
  risk: yup.object().required(),
});

export const BeneficiaryMachinerySchema = yup.object().shape({
  businessName: yup.string().required(),
  insuredValue: yup.number().required(),
  address: yup.string().required(),
  risk: yup.object().required(),
  equipmentDetails: yup.string().required(),
});
