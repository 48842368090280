import { HEADER_IPS_DNS } from 'utils/constants/publicIpProvider';

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const fetchPublicIpStart = () => ({
  type: 'FETCH_PUBLICIP_START',
  error: false,
});

export const fetchPublicIpSuccess = (payload) => ({
  type: 'FETCH_PUBLICIP_SUCCESS',
  payload,
});

export const fetchPublicIpError = (payload) => ({
  type: 'FETCH_PUBLICIP_ERROR',
  payload,
  error: true,
});

export const insertPublicIp = (payload) => ({
  type: 'INSERT_PUBLICIP',
  payload,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_PUBLICIP_START': {
      return { ...state, loading: true, error: null };
    }
    case 'FETCH_PUBLICIP_SUCCESS': {
      return { ...state, loading: false, data: action.payload };
    }
    case 'FETCH_PUBLICIP_ERROR': {
      return { ...state, loading: false, error: action.payload };
    }
    case 'INSERT_PUBLICIP': {
      return { ...state, loading: false, data: action.payload };
    }
    default: {
      return state;
    }
  }
}

export const fetchPublicIp = () => async (dispatch) => {
  dispatch(fetchPublicIpStart());
  try {
    const responseOne = await fetch(HEADER_IPS_DNS.ICANHAZIP);
    const responseTwo = await responseOne.text();
    if (responseTwo !== null || responseTwo !== undefined) {
      // return responseTwo;
      dispatch(fetchPublicIpSuccess(responseTwo));
    }
    const responseThree = await fetch(HEADER_IPS_DNS.IPIFY);
    const responseFour = await responseThree.json();
    dispatch(fetchPublicIpSuccess(responseFour.ip));
  } catch (e) {
    dispatch(fetchPublicIpError(e));
  }
};
