import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Input from 'components/Input';

/**
 * Currency component
 * @param {*} props
 */
const Currency = forwardRef((props, ref) => {
  const {
    name,
    type,
    placeholder,
    controlled,
    errorMessage,
    inputClass,
    onValueChange,
    defaultInputValue,
  } = props;

  return (
    <React.Fragment key="Currency">
      <div className="relative">
        <Input
          data-testid="custom-input"
          name={name}
          type={type}
          value={defaultInputValue}
          placeholder={placeholder}
          onValueChange={onValueChange}
          ref={ref}
          controlled={controlled}
          errorMessage={errorMessage}
          inputClass={inputClass}
        />
      </div>
    </React.Fragment>
  );
});

export default Currency;

/**
 * PropsTypes
 */
Currency.propTypes = {
  /**
   * Name
   * @param {string} name
   */
  name: PropTypes.string,
  /**
   * Type
   * @param {string} type
   */
  type: PropTypes.string,
  /**
   * Placeholder
   * @param {string} placeholder
   */
  placeholder: PropTypes.string,
  /**
   * Controlled
   * @param {bool} controlled
   */
  controlled: PropTypes.bool,
  /**
   * Name
   * @param {string} errorMessage
   */
  errorMessage: PropTypes.string,
  /**
   * Name
   * @param {string} inputClass
   */
  inputClass: PropTypes.string,
  /**
   * onValueChange
   * @param {func} onValueChange
   */
  onValueChange: PropTypes.func,
  /**
   * defaultInputValue
   * @param {func} defaultInputValue
   */
  defaultInputValue: PropTypes.string,
};

/**
 * Default props
 */
Currency.defaultProps = {
  /**
   * Name
   * @param {string} name
   */
  name: '',
  /**
   * Type
   * @param {string} type
   */
  type: '',
  /**
   * Placeholder
   * @param {string} placeholder
   */
  placeholder: '',
  /**
   * Controlled
   * @param {bool} controlled
   */
  controlled: false,
  /**
   * errorMessage
   * @param {string} errorMessage
   */
  errorMessage: '',
  /**
   * inputClass
   * @param {string} inputClass
   */
  inputClass: '',
  /**
   * onValueChange
   * @param {func} onValueChange
   */
  onValueChange: () => {
    // do nothing
  },
  /**
   * defaultInputValue
   * @param {func} defaultInputValue
   */
  defaultInputValue: '',
};
