import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Dropdown } from 'react-bootstrap';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IconButton, ClickAwayListener, Button } from '@material-ui/core';
import esLocale from 'date-fns/locale/es';
import DateFnsUtils from '@date-io/date-fns';
import NotificationTooltip from 'components/emit_components/basics/NotificationTooltip/NotificationTooltip';
import CustomTextField from './CustomTextField';
import { styles } from './Calendar.styles';
import { dateCompare } from './utils';

const useStyles = makeStyles(styles);

const isOutRange = (date, minDate, maxDate) => {
  const dateIsHigher = dateCompare(date, maxDate) === 'higher';
  const dateIsLower = dateCompare(date, minDate) === 'lower';
  return dateIsHigher || dateIsLower;
};

const CustomToolbar = ({ date }) => {
  const classes = useStyles();
  const dateMonth = date
    .toLocaleDateString('es-ES', {
      day: '2-digit',
      month: 'short',
    })
    .replace(/ /g, '/')
    .replace(/\b([a-z])/, (s, w) => w.toUpperCase());
  return (
    <div className={classes.toolbarContainer}>
      <p className={classes.yearText}>{date.getFullYear()}</p>
      <p className={classes.dayMonthText}>{dateMonth}</p>
    </div>
  );
};

CustomToolbar.propTypes = {
  date: PropTypes.instanceOf(Date),
};

CustomToolbar.defaultProps = {
  date: new Date(),
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick(e);
  };
  return (
    <div
      role="button"
      aria-hidden="true"
      className="calendar-inner d-flex justify-content-between"
      href=""
      ref={ref}
      onClick={handleClick}
    >
      {children}
    </div>
  );
});

CustomToggle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
};

const Calendar = ({ onChange, value, fullWidth, maxDays }) => {
  const classes = useStyles();
  const [auxDate, setAuxDate] = useState(new Date(Number(value)));
  const [selectedDate, setSelectedDate] = useState(value);
  const [initialDate, setInitialDate] = useState(value);
  const today = new Date();
  const todayCopy = new Date();
  const maxDateMessage = `No es posible seleccionar una fecha superior a ${maxDays} días como inicio de vigencia de tu póliza`;
  const minDateMessage =
    'No es posible seleccionar una fecha anterior a la actual como inicio de vigencia de tu póliza';
  const [isOpen, setIsOpen] = useState(false);
  const maxDate = todayCopy.setDate(today.getDate() + maxDays - 1);
  const dateOutRange = isOutRange(auxDate, today, maxDate);
  const handleDateChange = (date) => {
    setAuxDate(date);
    const dateIsHigher = dateCompare(date, maxDate) === 'higher';
    const dateIsLower = dateCompare(date, today) === 'lower';
    if (dateIsHigher || dateIsLower) date = null;
    setSelectedDate(date);
    onChange(new Date(date).getTime());
  };

  const cancel = () => {
    const auxInitialDate = new Date(parseInt(initialDate, 10));
    setSelectedDate(auxInitialDate);
    setAuxDate(auxInitialDate);
    onChange(auxInitialDate.getTime());
  };

  const handleClickAway = () => {
   
    if (dateOutRange) {
      cancel();
    } else {
      setSelectedDate(auxDate);
      setInitialDate(new Date(auxDate).getTime());
      onChange(new Date(auxDate).getTime());
    }
  };

  const handleCancelClick = () => {
    cancel();
    setIsOpen(false);
  };
  const handleOkClick = () => {  
    if (selectedDate) {  
      setIsOpen(false);
    }
  };

  const handleToggle = () => {    
    setIsOpen(!isOpen);
    if (selectedDate === null && !isOpen) {     
      setSelectedDate(today);
      setAuxDate(today);
      onChange(today);
    }
  };

  useEffect(() => {
    setSelectedDate(value);
    onChange(value);
  }, [value]);

  const customDay = (day, selected, isInCurrentMonth) => {
    const isSelected = dateCompare(day, selected) === 'equal';
    const isToday = dateCompare(day, maxDate) === 'equal';
    const dateIsHigher = dateCompare(day, maxDate) === 'higher';
    const dateIsLower = dateCompare(day, today) === 'lower';
    const selectableDay = !(dateIsHigher || dateIsLower);
    const wrapperClassName = clsx(classes.normalDay, {
      [classes.selectedDay]: isSelected,
      [classes.today]: isToday,
      [classes.notAcceptedDay]: isSelected && !selectableDay,
      [classes.notSelectableDay]: !selectableDay,
      [classes.notInThisMonthDay]: !isInCurrentMonth,
    });

    return (
      <NotificationTooltip
        disableListeners={selectableDay}
        disableHoverListener={selectableDay}
        title={dateIsHigher ? maxDateMessage : minDateMessage}
      >
        <IconButton className={wrapperClassName}>
          <span className={classes.text}>{day.getDate()}</span>
        </IconButton>
      </NotificationTooltip>
    );
  };

  return (
    <div style={{ width: fullWidth && '100%' }}>
      <Dropdown
        show={isOpen}
        onToggle={handleToggle}
        className="picker-container"
        alignRight
      >
        <Dropdown.Toggle
          as={CustomToggle}
          variant="success"
          id="dropdown-basic"
        >
          <CustomTextField
            value={
              selectedDate
                ? auxDate?.toLocaleDateString('es-ES', {
                    day: '2-digit',
                    month: 'long',
                    year: 'numeric',
                  })
                : 'Selecciona la fecha'
            }
            disabled
            placeholder="Selecciona la fecha"
            fullWidth
          />
        </Dropdown.Toggle>
        <ClickAwayListener disableReactTree onClickAway={handleClickAway}>
          <Dropdown.Menu
            show={isOpen}
            style={{
              background: 'white',
              boxShadow: '5px 28px 56px -12px rgba(0,0,0,0.44)',
              zIndex: 300,
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                fullWidth={fullWidth}
                onChange={handleDateChange}
                variant="static"
                format="dd/MM/yyyy"
                value={auxDate}
                renderDay={customDay}
                ToolbarComponent={CustomToolbar}
                minDate={today}
                maxDate={maxDate}
              />
              <div
                style={{
                  display: 'flex',
                  paddingRight: 10,
                  marginBottom: 10,
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  onClick={handleCancelClick}
                  style={{ textTransform: 'none', color: '#28A3AF' }}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={dateOutRange}
                  onClick={handleOkClick}
                  style={{
                    textTransform: 'none',
                    color: !dateOutRange && '#28A3AF',
                  }}
                >
                  Ok
                </Button>
              </div>
            </MuiPickersUtilsProvider>
          </Dropdown.Menu>
        </ClickAwayListener>
      </Dropdown>
    </div>
  );
};

Calendar.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  maxDays: PropTypes.number.isRequired,
};

Calendar.defaultProps = {
  fullWidth: false,
  value: null,
};

export default Calendar;
