/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Button from 'components/Button';
import caution from 'assets/icons/caution.svg';
import { tokenAPI } from 'services/auth/sessionInfo';

const urlOficina = process.env.REACT_APP_URL_OFICINA;

const ActionConfirm = ({
  showModal,
  setShowModal,
  title,
  cancelButton,
  butonContinue,
  //   onClick,
  //   onCancel,
  type,
}) => {
  const handleClick = async () => {
    const sessionInfo = sessionStorage.getItem('info');
    const sessionInfoObj = JSON.parse(sessionInfo);

    await tokenAPI(
      'removeToken',
      {
        ...sessionInfoObj,
        // ip: sessionIp,
      },
      {
        headers: { Authorization: `Bearer ${sessionInfoObj.token}` },
      }
    );
    sessionStorage.removeItem('info');
    window.location.replace(urlOficina);
  };

  return (
    <Modal showModal={showModal} setShowModal={setShowModal} lg={false}>
      <div className="m-6 flex flex-col items-center justify-center px-6 md:px-20 space-y-3 md:space-y-6">
        <div className="bg-">
          <img
            className="w-24"
            src={type === 'caution' && caution}
            alt="Alt - Caution"
          />
        </div>
        <h3 className="mt-4 font-semibold text-center dark-blue">{title}</h3>
        <div className="flex flex-col md:flex-row w-full">
          <Button
            className="sm:px-10"
            onClick={() => {
              setShowModal(false);
            }}
          >
            {cancelButton}
          </Button>
          <Button
            className="sm:px-12"
            primary
            onClick={() => {
              handleClick();
            }}
          >
            {butonContinue}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ActionConfirm;

ActionConfirm.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cancelButton: PropTypes.string.isRequired,
  butonContinue: PropTypes.string.isRequired,
};
