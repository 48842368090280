import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const InputLimit = ({ value, onValueChange }) => {
  return (
    <NumberFormat
      thousandSeparator="."
      decimalSeparator=","
      prefix="$ "
      className="color-gray-500 border border-gray-700 placeholder-gray-500 font-light outline-none bg-gray-200 px-1 py-4 rounded md:px-2 h-10 m-auto ml-2 md:ml-0 md:mr-6"
      value={value}
      readOnly
      onValueChange={onValueChange}
    />
  );
};

export default InputLimit;

InputLimit.propTypes = {
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
};
