/* eslint-disable no-return-assign */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import search from 'assets/icons/search.svg';
import close from 'assets/icons/close.svg';
import { formatWord } from 'utils/helpers/normalizeValues';

/**
 * Allowed activities finder
 * @param {*} props
 */

const DropdownFinder = (props) => {
  const { placeholder, listToFilter, activitySelected } = props;
  const [showFiltered, setShowFiltered] = useState(false);
  const [cleanInput, setCleanInput] = useState(false);
  const [changedValue, setChangedValue] = useState('');
  const [rowsFiltered, setRowsFiltered] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const rowsFilter = (inputValue) => {
    setRowsFiltered([]);
    const filtered = listToFilter.filter((row) => {
      const n = formatWord(row.actividad).indexOf(formatWord(inputValue));
      if (n >= 0) {
        return true;
      }
      return false;
    });
    setShowFiltered(true);
    setCleanInput(true);
    setRowsFiltered(filtered);
  };
  const categories = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[0][0][1]
      : []
  );
  useEffect(() => {
    const virtualSubCategories = [];
    categories.map((item) =>
      item.results[1][0][1].map((item2) => virtualSubCategories.push(item2))
    );
    setSubCategories(virtualSubCategories);
  }, [categories[1]]);

  return (
    <React.Fragment key="allowedsFinder">
      <div className="inline-block relative w-full sm:w-2/4 my-4">
        <div className="flex flex-row rounded border border-solid border-gray-200 shadow-md">
          <input
            className="flex-auto p-3 shadow-none rounded-l-sm outline-none"
            placeholder={placeholder}
            value={changedValue}
            onChange={(e) => {
              e.preventDefault();
              setChangedValue(e.target.value);
              if (e.target.value.length > 3) {
                rowsFilter(e.target.value);
              } else {
                setShowFiltered(false);
                setCleanInput(false);
              }
            }}
          />
          {cleanInput ? (
            <button
              type="button"
              className="w-12 rounded shadow-none border-none bg-none"
              onClick={(e) => {
                e.preventDefault();
                setChangedValue('');
                setShowFiltered(false);
                setCleanInput(false);
              }}
            >
              <img className="inline" src={close} alt="Limpiar" />
            </button>
          ) : null}
          <button
            type="button"
            className="w-12 rounded shadow-none border-none bg-none md:bg-teal"
          >
            <img className="inline" src={search} alt="Buscar" />
          </button>
        </div>
        {showFiltered ? (
          <div className="flex flex-col absolute bg-white z-50 w-full border border-solid border-gray-200 shadow-md rounded">
            {rowsFiltered.map((obj) => (
              <button
                key={obj.actividad}
                type="button"
                className="text-left p-3 text-sm font-regular hover:bg-lightTeal hover:font-medium focus:outline-none"
                onClick={() => {
                  let imageName = '';
                  let respuestaIaxis2 = '';
                  subCategories.forEach((item) => {
                    if (item.respuestaIaxis === obj.respuesta) {
                      imageName = item.ubicacion;
                      respuestaIaxis2 = item.respuestaIaxis2;
                    }
                  });
                  activitySelected(
                    obj.respuesta,
                    obj.actividad,
                    imageName,
                    respuestaIaxis2
                  );
                }}
              >
                {obj.actividad}
              </button>
            ))}
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default DropdownFinder;

/**
 * PropsTypes
 */
DropdownFinder.propTypes = {
  /**
   * Placeholder
   * @param {string} placeholder
   */
  placeholder: PropTypes.string,
  /**
   * Array of allowed activities
   * @param {array} activities
   */
  listToFilter: PropTypes.array.isRequired,
  /**
   * Function to send activity selected to parent
   * @param {array} activities
   */
  activitySelected: PropTypes.func.isRequired,
};

/**
 * Default props
 */
DropdownFinder.defaultProps = {
  placeholder: '',
};
