/* eslint-disable import/prefer-default-export */
import moment from 'moment';

const substractDate = (date) => {
  const currentDate = moment().format('MM/DD/YYYY');
  const days = moment(currentDate).diff(moment(date), 'days');
  return 30 - days;
};

export function capitalizeWords(string) {
  return string.toLowerCase().replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
}

export const emitParser = (emitProcesses) =>
  emitProcesses.map((emitProcess) => ({
    ...emitProcess,
    taker: emitProcess?.taker?.toUpperCase(),
    substractDate: substractDate(
      moment(emitProcess.quote_date).format('MM/DD/YYYY')
    ),
    quote_date_str: moment(emitProcess.quote_date).format('DD/MM/YYYY'),
  }));

export const quotesParser = (quotes) => {
  const object = [];

  if (quotes) {
    /* eslint-disable no-restricted-syntax */
    for (let i = 0; i < quotes.length; i += 1) {
      const indexObject = {};
      for (const index in quotes[i]) {
        if (index === 'taker') {
          let fullTaker = '';
          for (const jIndex in quotes[i][index]) {
            /* eslint-disable no-prototype-builtins */
            if (quotes[i][index].hasOwnProperty(jIndex)) {
              if (jIndex === 'NumIdentificacion') {
                indexObject.NumIdentificacion = quotes[i][index][jIndex];
              } else if (jIndex === 'PrimApellido' || jIndex === 'PrimNombre') {
                // eslint-disable-next-line no-loop-func
                quotes[i][index][jIndex].split(' ').map((name) => {
                  if (name !== 'null') {
                    fullTaker += `${name} `;
                  }
                  return '';
                });
              } else if (jIndex !== 'selectedOption') {
                fullTaker += quotes[i][index][jIndex];
              }
            }
          }
          indexObject.fullTaker = fullTaker;
          if (quotes[i][index].selectedOption) {
            if (quotes[i][index].selectedOption.tipoIdentificacion === '38') {
              indexObject.takerDescripcion = 'NUIP';
            } else {
              indexObject.takerDescripcion =
                quotes[i][index].selectedOption.descripcion;
            }
          }
        } else if (index === 'Date') {
          indexObject[index] = moment(quotes[i][index]).format('MM/DD/YYYY');
          indexObject[`substract${index}`] = `${substractDate(
            moment(quotes[i][index]).format('MM/DD/YYYY')
          )} días`;
        } else if (index === 'Status') {
          indexObject[index] =
            quotes[i][index] === 'Active' ? 'Cotizado' : 'Inactivo';
        } else {
          indexObject[index] = quotes[i][index];
        }
      }
      if (indexObject.substractDate !== '0 días') object.push(indexObject);
    }
  }
 /*  return object.sort((a, b) => moment(b.Date) - moment(a.Date)); */
   return object.sort((a, b) => Number(b.quoteNumber) - Number(a.quoteNumber)); 
};
