import axios from 'axios';

import { HEADERS_JSON } from 'utils/constants/DefaultBodies';

const urlQuote = process.env.REACT_APP_URL_QUOTE;

/**
 * Verifies if url starts with the base or adds the indicated path
 * @param {string} url
 */
const readUrl = (url = '') =>
  url.startsWith('http://') || url.startsWith('https://')
    ? url
    : `${urlQuote}/${url}`;

/**
 * Get Quote
 * @param {string} url
 * @param {Object} body
 * @param {Object} headers
 */
const getQuotes = (url = '', body = {}, headers = {}) =>
  axios.post(readUrl(url), body, {
    headers: { ...HEADERS_JSON.headers, ...headers },
  });

export default getQuotes;
