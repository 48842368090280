import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatPrice } from 'utils/helpers/normalizeValues';
import bank from 'assets/icons/bank.svg';
import gps from 'assets/icons/gps.svg';

const Container = styled.div`
  p,
  span {
    color: var(--liberty-blue);
  }

  .svg-red {
    filter: invert(37%) sepia(93%) saturate(7471%) hue-rotate(356deg)
      brightness(91%) contrast(135%);
  }
`;

const Triangle = styled.div`
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-top: 12px solid white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

const Slider = styled.input`
  -webkit-appearance: none;
  border-radius: 5px;
  background-color: rgba(26, 20, 70, 0.23);
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    box-sizing: content-box;
    border: 3px solid
      ${(props) =>
        props.valPoliza === 'true' ? 'var(--error)' : 'var(--liberty-blue)'};
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.valPoliza === 'true' ? '#FFF4F5' : 'var(--teal)'};
    cursor: ew-resize;
    margin-top: -4px;
  }

  &::-moz-range-thumb {
    box-sizing: content-box;
    border: 3px solid
      ${(props) =>
        props.valPoliza === 'true' ? 'var(--error)' : 'var(--liberty-blue)'};
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.valPoliza === 'true' ? '#FFF4F5' : 'var(--teal)'};
    cursor: ew-resize;
    margin-top: -4px;
  }

  &::-ms-thumb {
    box-sizing: content-box;
    border: 3px solid
      ${(props) =>
        props.valPoliza === 'true' ? 'var(--error)' : 'var(--liberty-blue)'};
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.valPoliza === 'true' ? '#FFF4F5' : 'var(--teal)'};
    cursor: ew-resize;
    margin-top: -4px;
  }

  &::-webkit-slider-runnable-track {
    height: 3px;
    border-color: transparent;
    color: transparent;
    border-radius: 5px;
  }

  &::-webkit-slider-runnable-track {
    background: linear-gradient(
      90deg,
      ${(props) =>
          props.valPoliza === 'true' ? 'var(--error)' : 'var(--liberty-blue)'}
        ${(props) => props.porcentajePoliza}%,
      rgba(26, 20, 70, 0.23) 1%
    );
  }

  &::-moz-range-track {
    height: 3px;
    background-color: rgba(26, 20, 70, 0.23);
  }
  &::-moz-range-progress {
    background-color: ${(props) =>
      props.valPoliza === 'true' ? 'var(--error)' : 'var(--liberty-blue)'};
  }
  &:focus {
    outline: none;
  }
  &::-ms-track {
    height: 3px;
    cursor: pointer;
    border-color: transparent;
    color: transparent;
    background-color: transparent;
  }
  &::-ms-fill-lower {
    background-color: #5082e0;
  }
  &::-ms-tooltip {
    display: none;
  }
`;

const TotalQuoteFooter = ({ draft, riskIndex }) => {
  const { valPoliza, valorPorPoliza, porcentajePoliza } = draft;
  return (
    <Container
      data-testid="total-quote-test"
      className={`${valPoliza === 'true' ? 'bg-red-100' : 'bg-gray-200'} `}
    >
      <div className="md:w-c-container m-auto">
        <div className="flex text-base flex-col-reverse md:flex-row">
          <div className="w:full md:w-1/3 py-6">
            <div className="flex justify-center md:justify-start">
              <img
                className={`mr-2 ${valPoliza === 'true' && 'svg-red'}`}
                src={gps}
                alt="icon gps"
              />
              <p>Riesgo actual</p>
              <div
                className={`rounded ml-2 px-1 ${
                  valPoliza === 'true' ? 'bg-red-200' : 'bg-white'
                }`}
              >
                <span className="font-bold">{riskIndex + 1}</span>
              </div>
            </div>
          </div>
          <div className="w:full md:w-1/3 py-6 relative">
            <Triangle />
            <div className="flex justify-center">
              <img
                className={`w-5 mr-2 ${valPoliza === 'true' && 'svg-red'}`}
                src={bank}
                alt="icon bank"
              />
              <p className="truncate">Total valores asegurados</p>
              <div
                className={`rounded ml-2 px-1 ${
                  valPoliza === 'true' ? 'bg-red-200' : 'bg-white'
                }`}
              >
                <span className="font-bold truncate">
                  {`$ ${valorPorPoliza ? formatPrice(valorPorPoliza) : 0}`}
                </span>
              </div>
            </div>
            <Slider
              type="range"
              min="1"
              max="100"
              value={porcentajePoliza || 0}
              step="1"
              className="w-5/6 block mx-auto mt-2"
              porcentajePoliza={porcentajePoliza || 0}
              valPoliza={valPoliza}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TotalQuoteFooter;

/**
 * PropsTypes
 */
TotalQuoteFooter.propTypes = {
  /**
   * Draft
   * @param {arrayOf} coverage
   */
  draft: PropTypes.shape({
    valPoliza: PropTypes.bool,
    valorPorPoliza: PropTypes.number,
    porcentajePoliza: PropTypes.number,
  }).isRequired,
  /**
   * Risk index selected
   */
  riskIndex: PropTypes.number.isRequired,
};
