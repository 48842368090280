import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TableFooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  max-width: 100%;
  background-color: var(--gray-100);
  @media (max-width: 501px) {
    justify-content: center;
    flex-direction: column;
    padding-right: 0;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    span {
      height: 14px;
      width: 111px;
      color: var(--gray-600);
      font-family: Helvetica;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 14px;
      margin-right: 10px;
    }
    select {
      height: 40px;
      width: 50px;
      border-radius: 3px;
      background-color: var(--white);
      align-items: center;
      margin-right: 80px;
    }
    .buttonCircle {
      height: 32px;
      width: 32px;
      background-color: var(--gray-100);
      border-radius: 100px;
      margin-right: 3px;
      margin-left: 3px;
    }
    .buttonRow {
      height: 40px;
      width: 38px;
      border-radius: 3px;
      background-color: var(--white);
      margin-right: 20px;
      margin-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      p {
        height: 23px;
        width: 13px;
      }
    }
  }
`;

const TableFooter = ({
  desktop,
  pageSize,
  setPageSize,
  previousPage,
  canPreviousPage,
  pageCount,
  pageIndex,
  canNextPage,
  nextPage,
  gotoPage,
}) => {
  return (
    <TableFooterWrapper>
      <div
        style={{
          marginLeft: `${desktop ? '0' : '22%'}`,
        }}
      >
        <span>Registros por página </span>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {/* eslint-disable no-shadow */}
          {[10, 20, 30, 40, 50].map((pageSizeItems) => (
            <option key={pageSizeItems} value={pageSizeItems}>
              {pageSizeItems}
            </option>
          ))}
        </select>
      </div>
      <div>
        <button
          className="buttonRow"
          onClick={(e) => {
            e.preventDefault();
            previousPage();
          }}
          disabled={!canPreviousPage}
          type="button"
        >
          <p>{'<'}</p>
        </button>
        {pageIndex + 2 > pageCount - 1 ? (
          <button
            className="buttonCircle"
            onClick={(e) => {
              e.preventDefault();
              gotoPage(0);
            }}
            disabled={!canPreviousPage}
            type="button"
          >
            ...
          </button>
        ) : (
          <></>
        )}
        {pageIndex - 1 >= 0 && (
          <button
            className="buttonCircle"
            onClick={(e) => {
              e.preventDefault();
              previousPage();
            }}
            disabled={!canPreviousPage}
            type="button"
          >
            {pageIndex}
          </button>
        )}
        <button
          style={{ background: 'var(--teal)' }}
          className="buttonCircle"
          disabled={!canPreviousPage}
          type="button"
        >
          {pageIndex + 1}
        </button>
        {pageCount > pageIndex + 1 && (
          <button
            className="buttonCircle"
            onClick={(e) => {
              e.preventDefault();
              nextPage();
            }}
            disabled={!canNextPage}
            type="button"
          >
            {pageIndex + 2}
          </button>
        )}
        {pageIndex < pageCount - 1 ? (
          <button
            className="buttonCircle"
            onClick={(e) => {
              e.preventDefault();
              gotoPage(pageCount - 1);
            }}
            disabled={!canNextPage}
            type="button"
          >
            ...
          </button>
        ) : (
          <></>
        )}

        <button
          className="buttonRow"
          onClick={(e) => {
            e.preventDefault();
            nextPage();
          }}
          disabled={!canNextPage}
          type="button"
        >
          <p>{'>'}</p>
        </button>
      </div>
    </TableFooterWrapper>
  );
};

export default TableFooter;

TableFooter.propTypes = {
  desktop: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  pageCount: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  nextPage: PropTypes.func.isRequired,
  gotoPage: PropTypes.func.isRequired,
};
