import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import CoverageItem from 'components/CoverageItem';

const Converage = (props) => {
  const dependenciesWithViolence = [];
  const {
    coverages,
    childCoverages,
    validateCoverages,
    draftIndex,
    riskIndex,
    rentExpenses,
    lengthRentExpenses,
  } = props;
  const returnService = useSelector((state) => state.quoteInfo.data);
  const [dependencies, setDependencies] = useState([]);
  const ValuesEnsure =
    returnService.drafts[draftIndex].risks[riskIndex].ValoAsegur;
  useEffect(() => {
    if (returnService.drafts[draftIndex].risks[riskIndex].amparos) {
      const searchDependencies =
        returnService.drafts[draftIndex].risks[riskIndex].amparos;
      setDependencies(searchDependencies);
    }
  }, [returnService.drafts[draftIndex].risks[riskIndex].amparos]);

  if (Object.keys(ValuesEnsure).length > 0) {
    return (
      <div className="w-full">
        <h3 className="text-lg font-semibold text-blue text-center my-8">
          Coberturas y asistencias que aplican a este negocio
        </h3>
        <div className="shadow-xl bg-gray-100 md:shadow-none md:bg-white">
          <table className="bg-gray-100 m-auto w-full">
            <thead className="bg-white">
              <tr>
                <div className="flex w-full">
                  <th className="bg-lightTeal w-3/5 m-0 p-6 md:w-9/12 md:bg-white md:p-0 md:px-8">
                    <p className="text-gray-600 md:text-blue md:text-xs md:font-bold md:text-left md:m-0 md:p-0">
                      Coberturas
                    </p>
                  </th>
                  <th className="bg-lightTeal w-2/5 m-0 py-2 opacity-50 md:opacity-100 md:bg-white md:p-0 md:w-3/12 flex items-center justify-center">
                    <p className="text-black md:text-blue md:text-xs md:font-bold md:text-right md:m-0 md:p-0 md:border-none md:pr-12">
                      Límites
                    </p>
                  </th>
                </div>
              </tr>
            </thead>
            <tbody>
              {coverages.map((item) => {
                if (item.results[0][0].dependencia === '8862') {
                  dependenciesWithViolence.push(
                    item.results[0][0].codigoAmparo
                  );
                }
                const currentChildCoverages = childCoverages.filter(
                  (childCoverage) => {
                    return (
                      childCoverage.results[0][0].dependencia ===
                      item.results[0][0].codigoAmparo
                    );
                  }
                );
                return (
                  <CoverageItem
                    item={item}
                    childCoverageItems={currentChildCoverages}
                    key={item.results[0][0].codigoAmparo}
                    draftIndex={draftIndex}
                    riskIndex={riskIndex}
                    validateCoverages={validateCoverages}
                    dependencies={dependencies}
                    abductionWithViolence={item.results[0][0].dependencia}
                    dependenciesWithViolence={dependenciesWithViolence}
                    rentExpenses={rentExpenses}
                    lengthRentExpenses={lengthRentExpenses}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  return <></>;
};

export default Converage;

Converage.propTypes = {
  coverages: PropTypes.array.isRequired,
  childCoverages: PropTypes.array.isRequired,
  validateCoverages: PropTypes.array.isRequired,
  draftIndex: PropTypes.string.isRequired,
  riskIndex: PropTypes.string.isRequired,
  rentExpenses: PropTypes.array.isRequired,
  lengthRentExpenses: PropTypes.array.isRequired,
};
