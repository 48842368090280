import React from 'react';
import { useSelector } from 'react-redux';
import ConfirmationTemplate from '../../../components/emit_components/templates/confirmation/Confimation';

const Confirmation = () => {
  const emitInfo = useSelector((state) => state.emitInfo);

  const handleMyBusiness = () => {
    window.location.reload();
  };

  return (
    !emitInfo.loading && (
      <ConfirmationTemplate
        quoteNumber={emitInfo.data.quote_data.quoteNumber}
        policyNumber={emitInfo.data.policy_number_caratula}
        handleMyBusiness={handleMyBusiness}
      />
    )
  );
};

export default Confirmation;
