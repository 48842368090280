import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import Button from 'components/Button';
import { isActive } from 'utils';

export const ButtonContinue = ({ onClick, currentRisk }) => {
  const { errors } = useFormContext();

  const isValid = useMemo(() => !Object.keys(errors)?.length > 0, [
    Object.keys(errors),
  ]);
  const { amparos, ValoAsegur } = currentRisk;
  const isInvalid = useMemo(() => {
    if(Object.keys(ValoAsegur).length === 0) return true;
    if (!amparos?.length) return true;
    return amparos.some((amparo) => {
      const { propiedadesAmparo = [] } = amparo;
      if (!isActive(amparo)) return false;
      return propiedadesAmparo.some(
        ({ selected, valor, id }) =>
          +selected === 1 &&
          !+valor === 0 &&
          id in ValoAsegur &&
          !ValoAsegur[id] === 0
      );
    });
  }, [amparos, ValoAsegur]);

  return (
    <Button
      primary
      type="submit"
      onClick={onClick}
      disabled={isInvalid || !isValid}
    >
      Continuar
    </Button>
  );
};

ButtonContinue.propTypes = {
  onClick: PropTypes.func.isRequired,
  currentRisk: PropTypes.object.isRequired,
};
