import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { DEFAULT_CATEGORY_CODE } from 'utils/constants/Openl';
import { updateTabQuote } from 'reducers/activeQuoteTab';
import FavoriteCard from './FavoriteCard';
import DefaultCard from './DefaultCard';
import Button from './Button';

/**
 * Subcategories component
 * @param {*} props
 */
const Subcategories = (props) => {
  const dispatch = useDispatch();
  const { subvalues, activitySelected } = props;
  const appState = useSelector((state) => state.quoteInfo.data);
  const selectCategory = appState.business ? appState.business.answerCode : '';
  return (
    <div className="bg-tealBackground w-full m-0 p-4 md:p-0 mx-auto flex justify-center">
      <div className="md:w-c-container mx-auto md:p-0 md:m-0 border-1 border-white pt-1 md:pt-8">
        {subvalues[0].codigoCategoriaDet === DEFAULT_CATEGORY_CODE ? (
          <p className="grid grid-cols-1 md:text-base mb-2 text-sm text-center">
            Pensando en las necesidades de tu cliente, hemos diseñado productos
            especiales para los siguientes negocios
          </p>
        ) : null}
        <div className="flex flex-wrap sm:flex-col md:flex-row w-full md:justify-center">
          {subvalues.map((activity) => {
            return activity.codigoCategoriaDet === DEFAULT_CATEGORY_CODE ? (
              <FavoriteCard
                key={activity.respuestaIaxis}
                respuestaIaxis2={activity.respuestaIaxis2}
                selected={activity.respuestaIaxis === selectCategory}
                desc={activity.descripcionActividad}
                literal={activity.literales}
                imageName={activity.ubicacion}
                activitySelected={activitySelected}
                code={activity.respuestaIaxis}
              />
            ) : (
              <DefaultCard
                key={activity.respuestaIaxis}
                respuestaIaxis2={activity.respuestaIaxis2}
                activity={activity.descripcionActividad}
                imageName={activity.ubicacion}
                activitySelected={activitySelected}
                code={activity.respuestaIaxis}
                select={activity.respuestaIaxis === selectCategory}
              />
            );
          })}
        </div>
        <div className="flex justify-center py-8">
          <Button
            primary
            disabled={!selectCategory}
            onClick={() => {
              dispatch(updateTabQuote({ index: 1 }));
            }}
          >
            Continuar
          </Button>
          <Button
            primary
            disabled={!selectCategory}
            onClick={() => {
              window.location.reload();
            }}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Subcategories;

/**
 * PropTypes
 */
Subcategories.propTypes = {
  subvalues: PropTypes.array.isRequired,
  activitySelected: PropTypes.func.isRequired,
};
