/* eslint-disable import/prefer-default-export */
import { HEADER_IPS_DNS, LOCALHOST } from 'utils/constants/publicIpProvider';

const getPublicIp = async() => {
    try {
        const responseOne = await fetch(HEADER_IPS_DNS.ICANHAZIP);
        const responseTwo = await responseOne.text();
        if (responseTwo !== null || responseTwo !== undefined) {
            return responseTwo.trim();
        }
        const responseThree = await fetch(HEADER_IPS_DNS.IPIFY);
        const responseFour = await responseThree.json();
        return responseFour.ip.trim();
    } catch (error) {
        return LOCALHOST;
    }
};

export { getPublicIp };