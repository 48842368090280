import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { tokenAPI } from 'services/auth/sessionInfo';
import sessionService from 'services/auth/session';
import Content from 'navigation/Content';
import { getPublicIp } from 'utils/helpers/publicIp';
import { insertPublicIp } from 'reducers/ipConfig';
import SESSION_CONST from 'utils/constants/Session';
import Unauthorized from './error/unauthorized';
import TimeOut from '../layouts/modals/TimeOut';

const RouteValidator = () => {
  const dispatch = useDispatch();
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const refer = urlSearchParams.get('refer');

  const [sessionInfo, setSessionInfo] = useState(
    sessionStorage.getItem('info')
  );
  const [exit, setExit] = useState(false);
  const [eventsExist, setEventsExist] = useState(false);
  const [error, setError] = useState(null);

  /**
   * Get token
   */

  const getTokenFromRefer = async () => {
    const resolvedIp = await getPublicIp();
    dispatch(insertPublicIp(resolvedIp));
    await tokenAPI(
      'getToken',
      { refer, ip: resolvedIp },
      { headers: { Authorization: `Bearer ${refer}` } }
    )
      .then(async (response) => {
        sessionStorage.setItem(
          'info',
          JSON.stringify({ ...response.data, ip: resolvedIp })
        );
        setSessionInfo(response.data);
      })
      .catch((err) => {
        setError(err);
      });
  };

  /**
   * Verify session token
   */
  const verifySessionIp = async () => {
    const sessionInfoObj =
      typeof sessionInfo === 'string' ? JSON.parse(sessionInfo) : sessionInfo;
    await tokenAPI(
      'verifyToken',
      {
        ...sessionInfoObj,
      },
      {
        headers: { Authorization: `Bearer ${sessionInfoObj.token}` },
      }
    )
      .then(async (response) => {
        setSessionInfo(response.data);
      })
      .catch((err) => {
        setError(err);
      });
  };

  /**
   * Session update
   */
  const sessionUpdate = () => {
    const { agente, token, ip } =
      typeof sessionInfo === 'string' ? JSON.parse(sessionInfo) : sessionInfo;
    const customHeaders = {
      authorization: `Bearer ${token}`,
      authorizedip: ip,
    };
    sessionService(
      SESSION_CONST.PATH_SESSION_UPDATE,
      {
        CustomerTypeId: 'Broker',
        Broker: agente,
      },
      customHeaders
    );
  };

  /**
   * Get token from refer or verify token of session
   */
  useEffect(() => {
    if (!sessionInfo) {
      getTokenFromRefer();
    } else {
      verifySessionIp();
      sessionUpdate();
    }
  }, []);

  /**
   * Execute interval for validate user activity
   */
  useEffect(() => {
    const id = setInterval(async () => {
      if (eventsExist) {
        try {
          const infoSession = sessionStorage.getItem('info');
          const { agente, token, ip, nombreUsuario } = JSON.parse(infoSession);
          await tokenAPI(
            'updateToken',
            {
              agente,
              nombreUsuario,
              token,
              ip,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
        } catch (err) {
          setError(true);
        }
      } /* else {
        callToTokenApi(SESSION_CONST.TOKEN_REMOVE);
        sessionStorage.removeItem('info');
        window.location.reload(false);
      } */
      setEventsExist(false);
    }, SESSION_CONST.SESSION_TIME);
    return () => clearInterval(id);
  });

  /**
   * Capture the all user events
   */
  useEffect(() => {
    function addEvent() {
      setEventsExist(true);
    }

    Object.keys(SESSION_CONST.USER_EVENTS).forEach(function eventsForEach(i) {
      window.addEventListener(SESSION_CONST.USER_EVENTS[i], addEvent);
    });
    return () => {
      Object.keys(SESSION_CONST.USER_EVENTS).forEach(function eventsForEach(i) {
        window.removeEventListener(SESSION_CONST.USER_EVENTS[i], addEvent);
      });
    };
  }, []);

  /**
   * Verify user activity
   */
  const [signoutTime] = useState(SESSION_CONST.SESSION_FRONT_ALERT);
  const logoutTimeout = useRef(null);

  const setTimeouts = () => {
    logoutTimeout.current = setTimeout(() => {
      setExit(true);
    }, signoutTime);
  };

  const clearTimeouts = () => {
    if (logoutTimeout.current) clearTimeout(logoutTimeout.current);
  };

  const resetTimeout = () => {
    clearTimeouts();
    setTimeouts();
  };

  useEffect(() => {
    const events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress',
    ];

    Object.keys(events).forEach(function eventsForEach(i) {
      window.addEventListener(events[i], resetTimeout);
    });

    setTimeouts();
    return () => {
      Object.keys(events).forEach(function eventsForEach(i) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      });
    };
  }, []);

  if (error) {
    sessionStorage.removeItem('info');
    return (
      <React.Fragment key="content">
        <Unauthorized />
      </React.Fragment>
    );
  }

  if (sessionInfo) {
    return (
      <React.Fragment key="content">
        <TimeOut
          showModal={exit}
          setShowModal={setExit}
          keepSession={() => {
            clearTimeouts();
            setTimeouts();
          }}
        />
        <Redirect to="/" />
        <Content />
      </React.Fragment>
    );
  }

  return <></>;
};

export default RouteValidator;
