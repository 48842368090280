import axios from 'axios';
import { barcodeIsValid, sleep } from 'utils';
import { HEADERS_JSON } from 'utils/constants/DefaultBodies';

const urlEmit = process.env.REACT_APP_URL_EMIT;
const MAX_RETRY = 6;
const TIMEOUT = 10000;

export const addBeneficiary = async (payloadBeneficiary) => {
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };

  const requestPayload = {
    payloadBeneficiary,
  };

  let addBeneficiaryResponse = '';
  let retry = 0;
  while (retry < MAX_RETRY) {
    try {
      const { data } = await axios.post(
        `${urlEmit}/emission/add-beneficiarys`,
        requestPayload,
        {
          headers,
        }
      );
      addBeneficiaryResponse = data;
      retry += 1;
    } catch (e) {
      retry += 1;
      if (retry >= MAX_RETRY) throw e;
    }
    if (!barcodeIsValid(addBeneficiaryResponse)) await sleep(TIMEOUT);
    else break;
  }
  return addBeneficiaryResponse;
};
