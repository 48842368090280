/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import Button from 'components/Button';
import gps2 from 'assets/icons/gps2.svg';

const RestrictionUbication = ({
  showModal,
  setShowModal,
  title,
  content,
  footerOne,
  footerTwo,
  numberOne,
  numberTwo,
  cancelButton,
  butonContinue,
  //   onClick,
  //   onCancel,
  type,
}) => {
  return (
    <Modal showModal={showModal} setShowModal={setShowModal} lg={false}>
      <div className="m-1 flex flex-col items-center justify-center px-6 md:px-5 sm:px-2 space-y-1 md:space-y-0">
        <div className="m-1 flex flex-col sm:flex-col-reverse items-center justify-center px-6 md:px-5 sm:px-2 space-y-1 md:space-y-0">
          <div className="">
            <img
              className="w-24"
              src={type === 'gps2' && gps2}
              alt="Alt - gps2"
            />
          </div>
          <div className="text-center flex justify-center items-center flex-col">
            <h3 className="mt-4 font-semibold text-center dark-blue title">
              {title}
            </h3>
            <p className="text-center md:w-3/4 sm:w-1 text-base my-4 text-gray-600 content">
              {content}
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row w-full justify-center items-center button">
          <Button
            className="sm:px-10 ter"
            onClick={() => {
              window.location.reload();
            }}
          >
            {cancelButton}
          </Button>
          <Button
            className="sm:px-10 adj"
            primary
            onClick={() => {
              setShowModal(false);
            }}
          >
            {butonContinue}
          </Button>
        </div>
      </div>
      <div className="text-center flex justify-center items-center flex-col my-0.5 bg-gray-100 px-3">
        <p className="text-center md:w-3/4 sm:w-1 text-sm my-4 text-gray-600">
          {footerOne}
          <span className="text-mediumTeal">{numberOne}</span>
          {footerTwo}
          <span className="text-mediumTeal">{numberTwo}</span>
        </p>
      </div>
    </Modal>
  );
};

export default RestrictionUbication;

RestrictionUbication.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cancelButton: PropTypes.string.isRequired,
  butonContinue: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  footerOne: PropTypes.string.isRequired,
  footerTwo: PropTypes.string.isRequired,
  numberOne: PropTypes.string.isRequired,
  numberTwo: PropTypes.string.isRequired,
};
