import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Back from 'components/Back';
import { updateStep } from 'reducers/quoteInfo';
import useWindowDimensions from 'utils/helpers/resolution';

/**
 * Tabs style component
 */
const TabsStyle = styled.div`
  background-color: var(--gray-100);
  display: flex;
  justify-content: center;
`;

/**
 * Oval style component
 */
const OvalStyle = styled.div`
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  // border: 1px solid var(--gray-300);
  ${(props) =>
    props.width > 768
      ? 'border: 1px solid var(--gray-300);'
      : 'border: 1px solid var(--light-teal);'}
  background-color: var(--gray-100);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  > p {
    font-size: 14px;
    font-weight: 600;
    color: var(--gray-300);
  }
  &.complete {
    background-color: var(--light-teal);
    ${(props) =>
      props.width > 768
        ? 'border: 1px solid var(--medium-teal);'
        : 'border: 1px solid var(--light-teal);'}
  }
  &.active {
    background-color: var(--light-teal);
    border: 2px solid var(--medium-teal);
    > p {
      font-size: 14px;
      color: var(--liberty-blue);
    }
  }
`;

/**
 * Line style component
 */
const LineStyle = styled.div`
  box-sizing: border-box;
  height: 1px;
  width: 14px;
  border: 1px solid #c0bfc0;
  margin-left: 12px;
  margin-right: 12px;
`;

/**
 * Button style component
 */
const ButtonStyle = styled.button`
  color: #808080;
  p {
    font-size: 14px;
    opacity: 0.7;
  }
  &:focus {
    outline: none;
  }
  &.complete {
    p {
      color: var(--liberty-blue);
      font-weight: 600;
    }
  }
  &.active {
    color: #1a1446;
    font-weight: bold;
    p {
      color: var(--liberty-blue);
      font-weight: 600;
      opacity: 1;
    }
  }
`;

/**
 * Triangle style component
 */
const TriangleStyle = styled.div`
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
  border-bottom: 12px solid white;
  position: absolute;
  bottom: -16px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

/**
 * Line Down Style component
 */
const LineDownStyle = styled.div`
  box-sizing: border-box;
  height: 1px;
  width: 23px;
  border: 1px solid #28a3af;
  position: absolute;
  bottom: -16px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;

export default function NavigationTabs({
  tabs,
  currentTab,
  setCurrentTab,
  isBackVisible,
}) {
  const dispatch = useDispatch();
  const drafts = useSelector((state) => {
    return state.quoteInfo.data.drafts;
  });
  const proposal = useSelector((state) => {
    sessionStorage.setItem('quoteInfo', JSON.stringify(state.quoteInfo.data));
    return state.quoteInfo.data.quoteNumber;
  });
  const { width } = useWindowDimensions();

  useEffect(() => {
    dispatch(
      updateStep({
        step: currentTab + 1,
      })
    );
  }, [currentTab]);

  return (
    <div className="w-full">
      <TabsStyle>
        <div className="w-full md:w-c-container relative flex-col flex justify-center">
          {proposal !== '0' && (
            <p className="relative p-1 text-center bg-lightYellow text-xs sm:bg-transparent sm:text-sm sm:absolute sm:left-0 sm:ml-3">
              Propuesta
              <span className="text-blue ml-1 font-medium">{proposal}</span>
              <span className="bg-mediumTeal rounded p-1 m-1 font-bold text-white text-sm">
                {`V${drafts.length}`}
              </span>
            </p>
          )}

          <div className="flex bg-white md:bg-transparent border-b-solid border-b-2 border-gray-200 md:border-none">
            <div className="w-1/5">
              {isBackVisible && (
                <Back
                  onClick={() => setCurrentTab(currentTab - 1)}
                  className={`md:invisible ${currentTab === 0 && 'invisible'}`}
                />
              )}
            </div>
            <div className="w-3/5 flex justify-center p-2 sm:p-4">
              {tabs.map((tab, i) => (
                <div key={`tab-${tab.id}`} className="flex items-center">
                  <div
                    className={` flex items-center relative ${
                      i === currentTab ? 'active' : ''
                    }`}
                  >
                    <div className="sm:flex hidden">
                      <TriangleStyle
                        className={`${i !== currentTab && 'hidden'}`}
                      />
                    </div>
                    <div className="flex sm:hidden">
                      <LineDownStyle
                        className={`${i !== currentTab && 'hidden'}`}
                      />
                    </div>

                    <ButtonStyle
                      data-testid={`test-change-tab-button-${tab.id}`}
                      /* onClick={() => {
                        setCurrentTab(i);
                      }} */
                      className={`flex items-center ${
                        i === currentTab && 'active'
                      } ${i < currentTab && 'complete'}`}
                      type="button"
                    >
                      <OvalStyle
                        className={`mx-2 ${i === currentTab && 'active'} ${
                          i < currentTab && 'complete'
                        }`}
                        width={width}
                      >
                        <p>{i + 1}</p>
                      </OvalStyle>
                      <p className="sm:flex hidden">{tab.label}</p>
                    </ButtonStyle>
                  </div>
                  {i + 1 < tabs.length && (
                    <LineStyle className="sm:flex hidden" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </TabsStyle>

      {tabs.map((tab, i) => {
        if (i === currentTab) {
          return (
            <div
              data-testid={`test-content-${tab.id}`}
              key={`content-${tab.id}`}
            >
              {tab.content}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
}

NavigationTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentTab: PropTypes.number.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  isBackVisible: PropTypes.bool,
};

NavigationTabs.defaultProps = {
  isBackVisible: true,
};
