export const dateCompare = (oneDate, twoDate) => {
  let oneTime = new Date(oneDate);
  oneTime.setHours(0, 0, 0, 0);
  oneTime = oneTime.getTime();
  let twoTime = new Date(twoDate);
  twoTime.setHours(0, 0, 0, 0);
  twoTime = twoTime.getTime();
  let result = '';
  if (oneTime === twoTime) {
    result = 'equal';
  } else if (oneTime > twoTime) {
    result = 'higher';
  } else {
    result = 'lower';
  }
  return result;
};
