import React from 'react';
import Proptypes from 'prop-types';
import Button from '../../../basics/form/Button';
import ModalWrapper from '../../../basics/modal/ModalWrapper';
import alertTriangleIcon from '../../../../../assets/icons/alert-triangle.svg';

const ModalValidateDate = ({ showModal, setShowModal }) => (
  <ModalWrapper
    size="xl"
    showButtonClose
    showModal={showModal}
    setShowModal={setShowModal}
  >
    <div className="text-center bg-gray-100 py-12 px-6">
      <div>
        <img src={alertTriangleIcon} alt="" className="w-20 m-auto mb-6" />
      </div>
      <h2 className="text-2xl mb-3 dark-blue font-medium leading-tight">
        Lo sentimos
      </h2>
      <p className="text-sm text-gray-600 mb-5 mx-0 md:mx-32">
        Se ha presentado un incoveniente al emitir tu póliza.
      </p>
      <p className="text-sm text-gray-600 mb-10 mx-0 md:mx-32">
        {`Por favor, vuelve a cotizar o solicita la emisión por `}
        <span className="font-medium medium-teal">BPM</span>
      </p>
      <div className="flex flex-col px-4 sm:flex-row sm:gap-4 m-auto justify-center">
        <Button
          className="w-full sm:m-0 sm:w-48 md:m-0"
          onClick={() => setShowModal(false)}
        >
          Cerrar
        </Button>
        <Button
          className="w-full sm:m-0 sm:w-48 md:m-0"
          onClick={() => window.location.reload()}
          primary
        >
          Ir a mis negocios
        </Button>
      </div>
    </div>
  </ModalWrapper>
);

ModalValidateDate.propTypes = {
  showModal: Proptypes.bool.isRequired,
  setShowModal: Proptypes.func.isRequired,
};

export default ModalValidateDate;
