import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const LinearProgressCustom = ({ error, success, value }) => {
  let backgroundColor = '#1a90ff';
  if (error) {
    backgroundColor = '#E92243';
  } else if (success) {
    backgroundColor = '#03AC63';
  }
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor,
    },
  }))(LinearProgress);
  return <BorderLinearProgress variant="determinate" value={value} />;
};

export default LinearProgressCustom;

LinearProgressCustom.propTypes = {
  error: PropTypes.any.isRequired,
  success: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};
