import React, { useState } from 'react';
import styled from 'styled-components';
import forwardRight from '../assets/icons/forward.svg';
import ActionConfirm from '../layouts/modals/ActionConfirm';

const CloseSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 45px;
  width: 100%;
  background-color: #99e5ea;
  :hover {
    background: #c8eff3;
  }

  .closeImg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 20%;
    height: 45px;

    img {
      height: 20px;
      width: 15px;
    }
  }
  .closeText {
    display: flex;
    width: 80%;
    height: 45px;
    align-items: center;
    justify-content: end;
    flex-direction: row;

    p {
      height: 12px;
      color: #1e1045;
      font-family: Roboto;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 12px;
    }
  }
`;
const SessionClose = () => {
  const [exit, setExit] = useState(false);

  return (
    <>
      <CloseSection
        onMouseOver={() => {
          setExit(!exit);
        }}
        onFocus={() => {
          setExit(!exit);
        }}
        className="text-left  text-sm font-regular hover:bg-lightTeal hover:font-medium focus:outline-none "
      >
        <div className="closeImg">
          <img src={forwardRight} alt="Close - img" />
        </div>
        <div className="closeText">
          <p>Cerrar sesión</p>
        </div>
      </CloseSection>
      <ActionConfirm
        showModal={exit}
        setShowModal={setExit}
        type="caution"
        title="¿Quieres cerrar sesión?"
        cancelButton="Cancelar"
        butonContinue="Sí, cerrar sesión"
      />
    </>
  );
};
export default SessionClose;
