/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useMemo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { QuotesContext } from 'context/QuotesContext';
import useWindowDimensions from '../../utils/helpers/resolution';
import { fetchQuote } from '../../reducers/quoteInfo';
import CustomTable from './CustomTable';
import LeftTimeLabel from './LeftTimeLabel';
import { quotesParser } from './utils';
import ButtonAction from './ButtonAction';
import ButtonNumber from './ButtonNumber';
import Loader from 'components/Loader';

const TitleTd = styled.section`
  height: 14px;
  width: 100%;
  color: var(--gray-600);
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: start;
  margin-left: 10px;
  flex-wrap: nowrap;
  @media (max-width: 700px) {
    height: 17px;
    margin-left: -5px;
  }
`;

const SubTitleTd = styled.section`
  height: 14px;
  width: 100%;
  color: var(--gray-400);
  font-family: Roboto;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: start;
  margin-left: 10px;
  @media (max-width: 700px) {
    height: 17px;
    margin-left: -5px;
  }
`;

const DivButtonMobile = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const QuoteIssuanceTable = ({ filter }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const dimensions = useWindowDimensions();
  const [desktop, setDesktop] = useState(true);
  const [data, setData] = useState([]);
  const { quotes } = useContext(QuotesContext);
  const resumingQuote = useSelector(state => state.quoteInfo.data.quoteNumber != '0')
  const loading = useSelector(state => state.quoteInfo.loading)

  useMemo(() => {
    const { width } = dimensions;

    if (width < 701) {
      setDesktop(false);
    } else {
      setDesktop(true);
    }
  }, [dimensions]);

  const getQuote = ({ compDate, compBrokerId }) => {
    const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
    const customHeaders = {
      authorization: `Bearer ${sessionInfoObj.token}`,
      authorizedip: sessionInfoObj.ip,
    };
    dispatch(fetchQuote('getQuote', customHeaders, { compDate, compBrokerId }));
    /*  setTimeout(() => {
       history.push('/quote');
     }, 2000); */
  };

  useEffect(() => {
    if (resumingQuote) history.push('/quote')
  }, [resumingQuote])

  useMemo(() => {
    const parsedQuotes = quotesParser(quotes);
    setData(parsedQuotes);
  }, [quotes]);

  const columns = useMemo(
    () => [
      {
        Header: 'No. de solicitud',
        accessor: 'quoteNumber',
        sticky: !desktop,
        width: 150,
        render: ({ row }) => (
          <DivButtonMobile>
            {!desktop && (
              <ButtonNumber
                className="shadow-2xl"
                onClick={() => {
                  getQuote(row);
                }}
              >
                ...
              </ButtonNumber>
            )}
            <div className="flex-col">
              <TitleTd scope="col">{row.quoteNumber}</TitleTd>
              <SubTitleTd scope="col">
                {row.draftCount === 1
                  ? `${row.draftCount} Propuesta`
                  : `${row.draftCount} Propuestas`}
              </SubTitleTd>
            </div>
          </DivButtonMobile>
        ),
      },
      {
        Header: 'Fecha',
        accessor: 'Date',
        render: ({ row }) => (
          <TitleTd
            style={{
              display: 'flex',
              alignItems: `${!desktop ? 'center' : ''}`,
              justifyContent: `${!desktop ? 'center' : ''}`,
            }}
            scope="col"
          >
            {moment(row.Date).format('DD/MM/YYYY')}
          </TitleTd>
        ),
      },
      {
        Header: 'Tomador',
        accessor: 'fullTaker',
        render: ({ row }) => (
          <p className="text-gray-600 text-sm">{row.fullTaker}</p>
        ),
      },
      {
        Header: 'Identificación',
        accessor: 'NumIdentificacion',
        render: ({ row }) => (
          <>
            <TitleTd
              style={{
                display: 'flex',
                alignItems: `${!desktop ? 'center' : ''}`,
                justifyContent: `${!desktop ? 'center' : ''}`,
              }}
              scope="col"
            >
              {row.NumIdentificacion}
            </TitleTd>
            <SubTitleTd
              style={{
                display: 'flex',
                alignItems: `${!desktop ? 'center' : ''}`,
                justifyContent: `${!desktop ? 'center' : ''}`,
              }}
              scope="col"
            >
              {row.takerDescripcion}
            </SubTitleTd>
          </>
        ),
      },

      {
        Header: 'Vence en',
        accessor: 'substractDate',
        render: ({ row }) => (
          <TitleTd
            style={{
              display: 'flex',
              alignItems: `${!desktop ? 'center' : ''}`,
              justifyContent: `${!desktop ? 'center' : ''}`,
            }}
            scope="col"
          >
            {row.substractDate ? (
              <LeftTimeLabel leftTime={parseInt(row.substractDate, 10)} />
            ) : (
              <></>
            )}
          </TitleTd>
        ),
      },
      {
        Header: 'Estado',
        sorter: false,
        render: ({ row }) => (
          <TitleTd
            style={{
              display: 'flex',
              alignItems: `${!desktop ? 'center' : ''}`,
              justifyContent: `${!desktop ? 'center' : ''}`,
            }}
            scope="col"
          >
            {row.Status}
          </TitleTd>
        ),
      },
      {
        Header: 'Acción',
        sorter: false,
        hide: !desktop,
        render: ({ row }) => (
          <div>
            <ButtonAction
              onClick={() => {
                getQuote(row);
              }}
            >
              Retomar
            </ButtonAction>
          </div>
        ),
      },
    ],
    [desktop]
  );

  return (
    <>
      {loading && <Loader />}
      <div style={{ background: 'var(--gray-100)' }}>
        <div className="sm:px-6 mt-2 md:px-8 md:py-8 lg:px-16 max-w-screen-xl mx-auto">
          <CustomTable
            columns={columns}
            data={data}
            filter={filter}
            desktop={desktop}
          />
        </div>
      </div>
    </>
  );
};

QuoteIssuanceTable.propTypes = {
  filter: PropTypes.string.isRequired,
};

export default QuoteIssuanceTable;
