import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import caution from 'assets/icons/caution.svg';
import clsx from 'clsx';

/**
 * Input default classes
 */
const inputDefaultClasses =
  'relative box-border py-2 px-2 border border-solid rounded text-base';

/**
 * Input style component
 */
const InputStyle = styled.div`
  border-color: var(--gray-300);
  color: var(--gray-600);
  width: 100%;

  &.disabled {
    background: #e6e6e6;
    border: 1px solid #919191;
  }

  &.error {
    border-color: red;
    .custom-label {
      color: red;
    }
  }

  .input:focus {
    outline: none;
  }

  label {
    width: calc(100% - 0.5rem);
    left: 0.5rem;
    top: 0.75rem;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  textarea::placeholder {
    color: var(--gray-600);
  }

  .custom-label {
    position: absolute;
    left: 0;
    top: ${(props) => props.positionLabel === 'top' && '-24px'};
    bottom: ${(props) => props.positionLabel === 'bottom' && '-22px'};
    color: gray;
    width: calc(100% - 0.5rem);
    line-height: 12px;
  }

  textarea {
    height: 93%;
  }

  textarea:not(:placeholder-shown) ~ label,
  textarea:focus ~ label,
  // input:valid ~ label,
  textarea:disabled ~ label {
    top: 0.25rem;
    font-size: 12px;
    color: var(--gray-400);
  }

  textarea[type='number']::-webkit-inner-spin-button,
  textarea[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

/**
 * Input component
 * @param {*} props
 */
const TextArea = forwardRef((props, ref) => {
  const {
    type,
    disabled,
    placeholder,
    value,
    onChange,
    onBlur,
    className,
    name,
    controlled,
    errorMessage,
    inputClass,
    customLabel,
    positionLabel,
    customOnBlur,
  } = props;

  const handleOnBlur = (e) => {
    customOnBlur(e);
    onBlur(e);
  };

  return (
    <InputStyle
      data-testid="custom-input"
      className={clsx(
        inputDefaultClasses,
        className,
        errorMessage && 'error bg-red-100',
        disabled && 'disabled'
      )}
      positionLabel={positionLabel}
      disabled={disabled}
    >
      {(customLabel || errorMessage) && (
        <div className="custom-label flex text-xs">
          {errorMessage && (
            <>
              <img className="w-4 mr-1" src={caution} alt="icon caution" />
              <span>{errorMessage}</span>
            </>
          )}
        </div>
      )}
      <div className="relative h-full">
        {customLabel && (
          <span className="text-xs text-gray-500 absolute top-0 left-0">
            {customLabel}
          </span>
        )}
        <textarea
          type={type}
          className={`w-full mt-3 input ${
            errorMessage && 'bg-red-100'
          } ${inputClass}`}
          disabled={disabled}
          value={controlled ? value : undefined}
          onChange={onChange}
          name={name}
          ref={ref}
          onBlur={handleOnBlur}
          placeholder={placeholder}
        />
      </div>
    </InputStyle>
  );
});

export default TextArea;

/**
 * PropsTypes
 */
TextArea.propTypes = {
  /**
   * Input type
   * @param {string} type
   */
  type: PropTypes.string,
  /**
   * Disabled
   * @param {bool} disabled
   */
  disabled: PropTypes.bool,
  /**
   * Placeholder
   * @param {string} placeholder
   */
  placeholder: PropTypes.string,
  /**
   * Value
   * @param {string} value
   */
  value: PropTypes.string,
  /**
   * Action call
   * @param {func} onChange
   */
  onChange: PropTypes.func,
  /**
   * On blur
   * @param {func} onBlur
   */
  onBlur: PropTypes.func,
  /**
   * className
   * @param {string} className
   */
  className: PropTypes.string,
  /**
   * Name
   * @param {string} name
   */
  name: PropTypes.string,
  /**
   * Controlled
   * @param {bool} controlled
   */
  controlled: PropTypes.bool,
  /**
   * Name
   * @param {string} errorMessage
   */
  errorMessage: PropTypes.string,
  /**
   * Name
   * @param {string} inputClass
   */
  inputClass: PropTypes.string,
  /**
   * customLabel
   * @param {string} customLabel
   */
  customLabel: PropTypes.string,
  /**
   * positionLabel
   * @param {string} positionLabel
   */
  positionLabel: PropTypes.string,
  /**
   * On blur
   * @param {func} customOnBlur
   */
  customOnBlur: PropTypes.func,
};

/**
 * Default props
 */
TextArea.defaultProps = {
  /**
   * Input type
   * @param {string} type
   */
  type: 'text',
  /**
   * Disabled
   * @param {bool} disabled
   */
  disabled: false,
  /**
   * Placeholder
   * @param {string} placeholder
   */
  placeholder: '',
  /**
   * Value
   * @param {string} value
   */
  value: '',
  /**
   * Action call
   * @param {func} onChange
   */
  onChange: () => {
    // do nothing
  },
  /**
   * On blur
   * @param {func} onBlur
   */
  onBlur: () => {
    // do nothing
  },
  /**
   * class name
   * @param {string} className
   */
  className: '',
  /**
   * name
   * @param {string} name
   */
  name: '',
  /**
   * controlled
   * @param {bool} controlled
   */
  controlled: true,
  /**
   * errorMessage
   * @param {string} errorMessage
   */
  errorMessage: '',
  /**
   * inputClass
   * @param {string} inputClass
   */
  inputClass: '',
  /**
   * inputClass
   * @param {string} customLabel
   */
  customLabel: null,
  /**
   * inputClass
   * @param {string} positionLabel
   */
  positionLabel: 'top',
  /**
   * On blur
   * @param {func} customOnBlur
   */
  customOnBlur: () => {
    // do nothing
  },
};
