const departments = [
  {
    code: '54',
    name: 'NORTE DE SANTANDER',
  },
  {
    code: '50',
    name: 'META',
  },
  {
    code: '27',
    name: 'CHOCÓ',
  },
  {
    code: '41',
    name: 'HUILA',
  },
  {
    code: '13',
    name: 'BOLÍVAR',
  },
  {
    code: '25',
    name: 'CUNDINAMARCA',
  },
  {
    code: '20',
    name: 'CESAR',
  },
  {
    code: '68',
    name: 'SANTANDER',
  },
  {
    code: '17',
    name: 'CALDAS',
  },
  {
    code: '85',
    name: 'CASANARE',
  },
  {
    code: '52',
    name: 'NARIÑO',
  },
  {
    code: '18',
    name: 'CAQUETÁ',
  },
  {
    code: '44',
    name: 'LA GUAJIRA',
  },
  {
    code: '76',
    name: 'VALLE DEL CAUCA',
  },
  {
    code: '47',
    name: 'MAGDALENA',
  },
  {
    code: '19',
    name: 'CAUCA',
  },
  {
    code: '15',
    name: 'BOYACÁ',
  },
  {
    code: '73',
    name: 'TOLIMA',
  },
  {
    code: '66',
    name: 'RISARALDA',
  },
  {
    code: '81',
    name: 'ARAUCA',
  },
  {
    code: '63',
    name: 'QUINDIO',
  },
  {
    code: '23',
    name: 'CÓRDOBA',
  },
  {
    code: '94',
    name: 'GUAINÍA',
  },
  {
    code: '11',
    name: 'BOGOTÁ D.C',
  },
  {
    code: '70',
    name: 'SUCRE',
  },
  {
    code: '95',
    name: 'GUAVIARE',
  },
  {
    code: '97',
    name: 'VAUPÉS',
  },
  {
    code: '86',
    name: 'PUTUMAYO',
  },
  {
    code: '99',
    name: 'VICHADA',
  },
  {
    code: '91',
    name: 'AMAZONAS',
  },
  {
    code: '88',
    name: 'ARCHIPIÉLAGO DE SAN ANDRÉS,...',
  },
  {
    code: '8',
    name: 'ATLÁNTICO',
  },
  {
    code: '5',
    name: 'ANTIOQUIA',
  },
];

const cities = {
  5: [
    {
      code: '2',
      name: 'ABEJORRAL',
    },
    {
      code: '4',
      name: 'ABRIAQUÍ',
    },
    {
      code: '21',
      name: 'ALEJANDRÍA',
    },
    {
      code: '30',
      name: 'AMAGÁ',
    },
    {
      code: '31',
      name: 'AMALFI',
    },
    {
      code: '34',
      name: 'ANDES',
    },
    {
      code: '36',
      name: 'ANGELÓPOLIS',
    },
    {
      code: '38',
      name: 'ANGOSTURA',
    },
    {
      code: '40',
      name: 'ANORÍ',
    },
    {
      code: '44',
      name: 'ANZA',
    },
    {
      code: '45',
      name: 'APARTADÓ',
    },
    {
      code: '51',
      name: 'ARBOLETES',
    },
    {
      code: '55',
      name: 'ARGELIA',
    },
    {
      code: '59',
      name: 'ARMENIA',
    },
    {
      code: '79',
      name: 'BARBOSA',
    },
    {
      code: '88',
      name: 'BELLO',
    },
    {
      code: '86',
      name: 'BELMIRA',
    },
    {
      code: '91',
      name: 'BETANIA',
    },
    {
      code: '93',
      name: 'BETULIA',
    },
    {
      code: '107',
      name: 'BRICEÑO',
    },
    {
      code: '113',
      name: 'BURITICÁ',
    },
    {
      code: '120',
      name: 'CÁCERES',
    },
    {
      code: '125',
      name: 'CAICEDO',
    },
    {
      code: '129',
      name: 'CALDAS',
    },
    {
      code: '134',
      name: 'CAMPAMENTO',
    },
    {
      code: '138',
      name: 'CAÑASGORDAS',
    },
    {
      code: '142',
      name: 'CARACOLÍ',
    },
    {
      code: '145',
      name: 'CARAMANTA',
    },
    {
      code: '147',
      name: 'CAREPA',
    },
    {
      code: '150',
      name: 'CAROLINA',
    },
    {
      code: '154',
      name: 'CAUCASIA',
    },
    {
      code: '172',
      name: 'CHIGORODÓ',
    },
    {
      code: '190',
      name: 'CISNEROS',
    },
    {
      code: '101',
      name: 'CIUDAD BOLÍVAR',
    },
    {
      code: '197',
      name: 'COCORNÁ',
    },
    {
      code: '206',
      name: 'CONCEPCIÓN',
    },
    {
      code: '209',
      name: 'CONCORDIA',
    },
    {
      code: '212',
      name: 'COPACABANA',
    },
    {
      code: '234',
      name: 'DABEIBA',
    },
    {
      code: '237',
      name: 'DON MATÍAS',
    },
    {
      code: '240',
      name: 'EBÉJICO',
    },
    {
      code: '250',
      name: 'EL BAGRE',
    },
    {
      code: '148',
      name: 'EL CARMEN DE VIBORAL',
    },
    {
      code: '697',
      name: 'EL SANTUARIO',
    },
    {
      code: '264',
      name: 'ENTRERRIOS',
    },
    {
      code: '266',
      name: 'ENVIGADO',
    },
    {
      code: '282',
      name: 'FREDONIA',
    },
    {
      code: '284',
      name: 'FRONTINO',
    },
    {
      code: '306',
      name: 'GIRALDO',
    },
    {
      code: '308',
      name: 'GIRARDOTA',
    },
    {
      code: '310',
      name: 'GÓMEZ PLATA',
    },
    {
      code: '313',
      name: 'GRANADA',
    },
    {
      code: '315',
      name: 'GUADALUPE',
    },
    {
      code: '318',
      name: 'GUARNE',
    },
    {
      code: '321',
      name: 'GUATAPE',
    },
    {
      code: '347',
      name: 'HELICONIA',
    },
    {
      code: '353',
      name: 'HISPANIA',
    },
    {
      code: '360',
      name: 'ITAGUI',
    },
    {
      code: '361',
      name: 'ITUANGO',
    },
    {
      code: '364',
      name: 'JARDÍN',
    },
    {
      code: '368',
      name: 'JERICÓ',
    },
    {
      code: '376',
      name: 'LA CEJA',
    },
    {
      code: '380',
      name: 'LA ESTRELLA',
    },
    {
      code: '390',
      name: 'LA PINTADA',
    },
    {
      code: '400',
      name: 'LA UNIÓN',
    },
    {
      code: '411',
      name: 'LIBORINA',
    },
    {
      code: '425',
      name: 'MACEO',
    },
    {
      code: '440',
      name: 'MARINILLA',
    },
    {
      code: '1',
      name: 'MEDELLÍN',
    },
    {
      code: '467',
      name: 'MONTEBELLO',
    },
    {
      code: '475',
      name: 'MURINDÓ',
    },
    {
      code: '480',
      name: 'MUTATÁ',
    },
    {
      code: '483',
      name: 'NARIÑO',
    },
    {
      code: '495',
      name: 'NECHÍ',
    },
    {
      code: '490',
      name: 'NECOCLÍ',
    },
    {
      code: '501',
      name: 'OLAYA',
    },
    {
      code: '541',
      name: 'PEÑOL',
    },
    {
      code: '543',
      name: 'PEQUE',
    },
    {
      code: '576',
      name: 'PUEBLORRICO',
    },
    {
      code: '579',
      name: 'PUERTO BERRÍO',
    },
    {
      code: '585',
      name: 'PUERTO NARE',
    },
    {
      code: '591',
      name: 'PUERTO TRIUNFO',
    },
    {
      code: '604',
      name: 'REMEDIOS',
    },
    {
      code: '607',
      name: 'RETIRO',
    },
    {
      code: '615',
      name: 'RIONEGRO',
    },
    {
      code: '628',
      name: 'SABANALARGA',
    },
    {
      code: '631',
      name: 'SABANETA',
    },
    {
      code: '642',
      name: 'SALGAR',
    },
    {
      code: '647',
      name: 'SAN ANDRÉS',
    },
    {
      code: '649',
      name: 'SAN CARLOS',
    },
    {
      code: '652',
      name: 'SAN FRANCISCO',
    },
    {
      code: '656',
      name: 'SAN JERÓNIMO',
    },
    {
      code: '658',
      name: 'SAN JOSÉ DE LA MONTAÑA',
    },
    {
      code: '659',
      name: 'SAN JUAN DE URABÁ',
    },
    {
      code: '660',
      name: 'SAN LUIS',
    },
    {
      code: '664',
      name: 'SAN PEDRO',
    },
    {
      code: '665',
      name: 'SAN PEDRO DE URABA',
    },
    {
      code: '667',
      name: 'SAN RAFAEL',
    },
    {
      code: '670',
      name: 'SAN ROQUE',
    },
    {
      code: '674',
      name: 'SAN VICENTE',
    },
    {
      code: '679',
      name: 'SANTA BÁRBARA',
    },
    {
      code: '686',
      name: 'SANTA ROSA DE OSOS',
    },
    {
      code: '42',
      name: 'SANTAFÉ DE ANTIOQUIA',
    },
    {
      code: '690',
      name: 'SANTO DOMINGO',
    },
    {
      code: '736',
      name: 'SEGOVIA',
    },
    {
      code: '756',
      name: 'SONSON',
    },
    {
      code: '761',
      name: 'SOPETRÁN',
    },
    {
      code: '789',
      name: 'TÁMESIS',
    },
    {
      code: '790',
      name: 'TARAZÁ',
    },
    {
      code: '792',
      name: 'TARSO',
    },
    {
      code: '809',
      name: 'TITIRIBÍ',
    },
    {
      code: '819',
      name: 'TOLEDO',
    },
    {
      code: '837',
      name: 'TURBO',
    },
    {
      code: '842',
      name: 'URAMITA',
    },
    {
      code: '847',
      name: 'URRAO',
    },
    {
      code: '854',
      name: 'VALDIVIA',
    },
    {
      code: '856',
      name: 'VALPARAÍSO',
    },
    {
      code: '858',
      name: 'VEGACHÍ',
    },
    {
      code: '861',
      name: 'VENECIA',
    },
    {
      code: '873',
      name: 'VIGÍA DEL FUERTE',
    },
    {
      code: '885',
      name: 'YALÍ',
    },
    {
      code: '887',
      name: 'YARUMAL',
    },
    {
      code: '890',
      name: 'YOLOMBÓ',
    },
    {
      code: '893',
      name: 'YONDÓ',
    },
    {
      code: '895',
      name: 'ZARAGOZA',
    },
  ],
  8: [
    {
      code: '78',
      name: 'BARANOA',
    },
    {
      code: '1',
      name: 'BARRANQUILLA',
    },
    {
      code: '137',
      name: 'CAMPO DE LA CRUZ',
    },
    {
      code: '141',
      name: 'CANDELARIA',
    },
    {
      code: '296',
      name: 'GALAPA',
    },
    {
      code: '372',
      name: 'JUAN DE ACOSTA',
    },
    {
      code: '421',
      name: 'LURUACO',
    },
    {
      code: '433',
      name: 'MALAMBO',
    },
    {
      code: '436',
      name: 'MANATÍ',
    },
    {
      code: '520',
      name: 'PALMAR DE VARELA',
    },
    {
      code: '573',
      name: 'PUERTO COLOMBIA',
    },
    {
      code: '549',
      name: 'PIOJÓ',
    },
    {
      code: '558',
      name: 'POLONUEVO',
    },
    {
      code: '560',
      name: 'PONEDERA',
    },
    {
      code: '606',
      name: 'REPELÓN',
    },
    {
      code: '634',
      name: 'SABANAGRANDE',
    },
    {
      code: '638',
      name: 'SABANALARGA',
    },
    {
      code: '675',
      name: 'SANTA LUCÍA',
    },
    {
      code: '685',
      name: 'SANTO TOMÁS',
    },
    {
      code: '758',
      name: 'SOLEDAD',
    },
    {
      code: '770',
      name: 'SUAN',
    },
    {
      code: '832',
      name: 'TUBARÁ',
    },
    {
      code: '849',
      name: 'USIACURÍ',
    },
  ],
  11: [
    {
      code: '1',
      name: 'BOGOTÁ, D.C',
    },
  ],
  13: [
    {
      code: '6',
      name: 'ACHÍ',
    },
    {
      code: '30',
      name: 'ALTOS DEL ROSARIO',
    },
    {
      code: '42',
      name: 'ARENAL',
    },
    {
      code: '52',
      name: 'ARJONA',
    },
    {
      code: '62',
      name: 'ARROYOHONDO',
    },
    {
      code: '74',
      name: 'BARRANCO DE LOBA',
    },
    {
      code: '140',
      name: 'CALAMAR',
    },
    {
      code: '160',
      name: 'CANTAGALLO',
    },
    {
      code: '1',
      name: 'CARTAGENA',
    },
    {
      code: '188',
      name: 'CICUCO',
    },
    {
      code: '222',
      name: 'CLEMENCIA',
    },
    {
      code: '212',
      name: 'CÓRDOBA',
    },
    {
      code: '244',
      name: 'EL CARMEN DE BOLÍVAR',
    },
    {
      code: '248',
      name: 'EL GUAMO',
    },
    {
      code: '268',
      name: 'EL PEÑÓN',
    },
    {
      code: '300',
      name: 'HATILLO DE LOBA',
    },
    {
      code: '430',
      name: 'MAGANGUÉ',
    },
    {
      code: '433',
      name: 'MAHATES',
    },
    {
      code: '440',
      name: 'MARGARITA',
    },
    {
      code: '442',
      name: 'MARÍA LA BAJA',
    },
    {
      code: '468',
      name: 'MOMPÓS',
    },
    {
      code: '458',
      name: 'MONTECRISTO',
    },
    {
      code: '473',
      name: 'MORALES',
    },
    {
      code: '549',
      name: 'PINILLOS',
    },
    {
      code: '580',
      name: 'REGIDOR',
    },
    {
      code: '600',
      name: 'RÍO VIEJO',
    },
    {
      code: '620',
      name: 'SAN CRISTÓBAL',
    },
    {
      code: '647',
      name: 'SAN ESTANISLAO',
    },
    {
      code: '650',
      name: 'SAN FERNANDO',
    },
    {
      code: '654',
      name: 'SAN JACINTO',
    },
    {
      code: '655',
      name: 'SAN JACINTO DEL CAUCA',
    },
    {
      code: '657',
      name: 'SAN JUAN NEPOMUCENO',
    },
    {
      code: '667',
      name: 'SAN MARTÍN DE LOBA',
    },
    {
      code: '670',
      name: 'SAN PABLO',
    },
    {
      code: '673',
      name: 'SANTA CATALINA',
    },
    {
      code: '683',
      name: 'SANTA ROSA',
    },
    {
      code: '688',
      name: 'SANTA ROSA DEL SUR',
    },
    {
      code: '744',
      name: 'SIMITÍ',
    },
    {
      code: '760',
      name: 'SOPLAVIENTO',
    },
    {
      code: '780',
      name: 'TALAIGUA NUEVO',
    },
    {
      code: '810',
      name: 'TIQUISIO',
    },
    {
      code: '836',
      name: 'TURBACO',
    },
    {
      code: '838',
      name: 'TURBANÁ',
    },
    {
      code: '873',
      name: 'VILLANUEVA',
    },
    {
      code: '894',
      name: 'ZAMBRANO',
    },
    {
      code: '490',
      name: 'NOROSI',
    },
  ],
  15: [
    {
      code: '22',
      name: 'ALMEIDA',
    },
    {
      code: '47',
      name: 'AQUITANIA',
    },
    {
      code: '51',
      name: 'ARCABUCO',
    },
    {
      code: '87',
      name: 'BELÉN',
    },
    {
      code: '90',
      name: 'BERBEO',
    },
    {
      code: '92',
      name: 'BETÉITIVA',
    },
    {
      code: '97',
      name: 'BOAVITA',
    },
    {
      code: '104',
      name: 'BOYACÁ',
    },
    {
      code: '106',
      name: 'BRICEÑO',
    },
    {
      code: '109',
      name: 'BUENAVISTA',
    },
    {
      code: '114',
      name: 'BUSBANZÁ',
    },
    {
      code: '131',
      name: 'CALDAS',
    },
    {
      code: '135',
      name: 'CAMPOHERMOSO',
    },
    {
      code: '162',
      name: 'CERINZA',
    },
    {
      code: '172',
      name: 'CHINAVITA',
    },
    {
      code: '176',
      name: 'CHIQUINQUIRÁ',
    },
    {
      code: '232',
      name: 'CHÍQUIZA',
    },
    {
      code: '180',
      name: 'CHISCAS',
    },
    {
      code: '183',
      name: 'CHITA',
    },
    {
      code: '185',
      name: 'CHITARAQUE',
    },
    {
      code: '187',
      name: 'CHIVATÁ',
    },
    {
      code: '236',
      name: 'CHIVOR',
    },
    {
      code: '189',
      name: 'CIÉNEGA',
    },
    {
      code: '204',
      name: 'CÓMBITA',
    },
    {
      code: '212',
      name: 'COPER',
    },
    {
      code: '215',
      name: 'CORRALES',
    },
    {
      code: '218',
      name: 'COVARACHÍA',
    },
    {
      code: '223',
      name: 'CUBARÁ',
    },
    {
      code: '224',
      name: 'CUCAITA',
    },
    {
      code: '226',
      name: 'CUÍTIVA',
    },
    {
      code: '238',
      name: 'DUITAMA',
    },
    {
      code: '244',
      name: 'EL COCUY',
    },
    {
      code: '248',
      name: 'EL ESPINO',
    },
    {
      code: '276',
      name: 'FLORESTA',
    },
    {
      code: '293',
      name: 'GACHANTIVÁ',
    },
    {
      code: '296',
      name: 'GAMEZA',
    },
    {
      code: '299',
      name: 'GARAGOA',
    },
    {
      code: '317',
      name: 'GUACAMAYAS',
    },
    {
      code: '322',
      name: 'GUATEQUE',
    },
    {
      code: '325',
      name: 'GUAYATÁ',
    },
    {
      code: '332',
      name: 'GÜICÁN',
    },
    {
      code: '362',
      name: 'IZA',
    },
    {
      code: '367',
      name: 'JENESANO',
    },
    {
      code: '368',
      name: 'JERICÓ',
    },
    {
      code: '380',
      name: 'LA CAPILLA',
    },
    {
      code: '403',
      name: 'LA UVITA',
    },
    {
      code: '401',
      name: 'LA VICTORIA',
    },
    {
      code: '377',
      name: 'LABRANZAGRANDE',
    },
    {
      code: '425',
      name: 'MACANAL',
    },
    {
      code: '442',
      name: 'MARIPÍ',
    },
    {
      code: '455',
      name: 'MIRAFLORES',
    },
    {
      code: '464',
      name: 'MONGUA',
    },
    {
      code: '466',
      name: 'MONGUÍ',
    },
    {
      code: '469',
      name: 'MONIQUIRÁ',
    },
    {
      code: '476',
      name: 'MOTAVITA',
    },
    {
      code: '480',
      name: 'MUZO',
    },
    {
      code: '491',
      name: 'NOBSA',
    },
    {
      code: '272',
      name: 'FIRAVITOBA',
    },
    {
      code: '494',
      name: 'NUEVO COLÓN',
    },
    {
      code: '500',
      name: 'OICATÁ',
    },
    {
      code: '507',
      name: 'OTANCHE',
    },
    {
      code: '511',
      name: 'PACHAVITA',
    },
    {
      code: '514',
      name: 'PÁEZ',
    },
    {
      code: '516',
      name: 'PAIPA',
    },
    {
      code: '518',
      name: 'PAJARITO',
    },
    {
      code: '522',
      name: 'PANQUEBA',
    },
    {
      code: '531',
      name: 'PAUNA',
    },
    {
      code: '533',
      name: 'PAYA',
    },
    {
      code: '537',
      name: 'PAZ DE RÍO',
    },
    {
      code: '542',
      name: 'PESCA',
    },
    {
      code: '572',
      name: 'PUERTO BOYACÁ',
    },
    {
      code: '550',
      name: 'PISBA',
    },
    {
      code: '580',
      name: 'QUÍPAMA',
    },
    {
      code: '599',
      name: 'RAMIRIQUÍ',
    },
    {
      code: '600',
      name: 'RÁQUIRA',
    },
    {
      code: '621',
      name: 'RONDÓN',
    },
    {
      code: '632',
      name: 'SABOYÁ',
    },
    {
      code: '638',
      name: 'SÁCHICA',
    },
    {
      code: '646',
      name: 'SAMACÁ',
    },
    {
      code: '660',
      name: 'SAN EDUARDO',
    },
    {
      code: '664',
      name: 'SAN JOSÉ DE PARE',
    },
    {
      code: '667',
      name: 'SAN LUIS DE GACENO',
    },
    {
      code: '673',
      name: 'SAN MATEO',
    },
    {
      code: '676',
      name: 'SAN MIGUEL DE SEMA',
    },
    {
      code: '681',
      name: 'SAN PABLO DE BORBUR',
    },
    {
      code: '690',
      name: 'SANTA MARÍA',
    },
    {
      code: '693',
      name: 'SANTA ROSA DE VITERBO',
    },
    {
      code: '696',
      name: 'SANTA SOFÍA',
    },
    {
      code: '686',
      name: 'SANTANA',
    },
    {
      code: '720',
      name: 'SATIVANORTE',
    },
    {
      code: '723',
      name: 'SATIVASUR',
    },
    {
      code: '740',
      name: 'SIACHOQUE',
    },
    {
      code: '753',
      name: 'SOATÁ',
    },
    {
      code: '757',
      name: 'SOCHA',
    },
    {
      code: '755',
      name: 'SOCOTÁ',
    },
    {
      code: '759',
      name: 'SOGAMOSO',
    },
    {
      code: '761',
      name: 'SOMONDOCO',
    },
    {
      code: '762',
      name: 'SORA',
    },
    {
      code: '764',
      name: 'SORACÁ',
    },
    {
      code: '763',
      name: 'SOTAQUIRÁ',
    },
    {
      code: '774',
      name: 'SUSACÓN',
    },
    {
      code: '776',
      name: 'SUTAMARCHÁN',
    },
    {
      code: '778',
      name: 'SUTATENZA',
    },
    {
      code: '790',
      name: 'TASCO',
    },
    {
      code: '798',
      name: 'TENZA',
    },
    {
      code: '804',
      name: 'TIBANÁ',
    },
    {
      code: '806',
      name: 'TIBASOSA',
    },
    {
      code: '808',
      name: 'TINJACÁ',
    },
    {
      code: '810',
      name: 'TIPACOQUE',
    },
    {
      code: '814',
      name: 'TOCA',
    },
    {
      code: '816',
      name: 'TOGÜÍ',
    },
    {
      code: '820',
      name: 'TÓPAGA',
    },
    {
      code: '822',
      name: 'TOTA',
    },
    {
      code: '1',
      name: 'TUNJA',
    },
    {
      code: '832',
      name: 'TUNUNGUÁ',
    },
    {
      code: '835',
      name: 'TURMEQUÉ',
    },
    {
      code: '837',
      name: 'TUTA',
    },
    {
      code: '839',
      name: 'TUTAZÁ',
    },
    {
      code: '842',
      name: 'UMBITA',
    },
    {
      code: '861',
      name: 'VENTAQUEMADA',
    },
    {
      code: '407',
      name: 'VILLA DE LEYVA',
    },
    {
      code: '879',
      name: 'VIRACACHÁ',
    },
    {
      code: '897',
      name: 'ZETAQUIRA',
    },
  ],
  17: [
    {
      code: '13',
      name: 'AGUADAS',
    },
    {
      code: '42',
      name: 'ANSERMA',
    },
    {
      code: '50',
      name: 'ARANZAZU',
    },
    {
      code: '88',
      name: 'BELALCÁZAR',
    },
    {
      code: '174',
      name: 'CHINCHINÁ',
    },
    {
      code: '272',
      name: 'FILADELFIA',
    },
    {
      code: '380',
      name: 'LA DORADA',
    },
    {
      code: '388',
      name: 'LA MERCED',
    },
    {
      code: '1',
      name: 'MANIZALES',
    },
    {
      code: '433',
      name: 'MANZANARES',
    },
    {
      code: '442',
      name: 'MARMATO',
    },
    {
      code: '444',
      name: 'MARQUETALIA',
    },
    {
      code: '446',
      name: 'MARULANDA',
    },
    {
      code: '486',
      name: 'NEIRA',
    },
    {
      code: '495',
      name: 'NORCASIA',
    },
    {
      code: '513',
      name: 'PÁCORA',
    },
    {
      code: '524',
      name: 'PALESTINA',
    },
    {
      code: '541',
      name: 'PENSILVANIA',
    },
    {
      code: '614',
      name: 'RIOSUCIO',
    },
    {
      code: '616',
      name: 'RISARALDA',
    },
    {
      code: '653',
      name: 'SALAMINA',
    },
    {
      code: '662',
      name: 'SAMANÁ',
    },
    {
      code: '665',
      name: 'SAN JOSÉ',
    },
    {
      code: '777',
      name: 'SUPÍA',
    },
    {
      code: '867',
      name: 'VICTORIA',
    },
    {
      code: '873',
      name: 'VILLAMARÍA',
    },
    {
      code: '877',
      name: 'VITERBO',
    },
  ],
  18: [
    {
      code: '29',
      name: 'ALBANIA',
    },
    {
      code: '94',
      name: 'BELÉN DE LOS ANDAQUIES',
    },
    {
      code: '150',
      name: 'CARTAGENA DEL CHAIRÁ',
    },
    {
      code: '205',
      name: 'CURILLO',
    },
    {
      code: '247',
      name: 'EL DONCELLO',
    },
    {
      code: '256',
      name: 'EL PAUJIL',
    },
    {
      code: '1',
      name: 'FLORENCIA',
    },
    {
      code: '410',
      name: 'LA MONTAÑITA',
    },
    {
      code: '460',
      name: 'MILÁN',
    },
    {
      code: '479',
      name: 'MORELIA',
    },
    {
      code: '592',
      name: 'PUERTO RICO',
    },
    {
      code: '610',
      name: 'SAN JOSÉ DEL FRAGUA',
    },
    {
      code: '753',
      name: 'SAN VICENTE DEL CAGUÁN',
    },
    {
      code: '756',
      name: 'SOLANO',
    },
    {
      code: '785',
      name: 'SOLITA',
    },
    {
      code: '860',
      name: 'VALPARAÍSO',
    },
  ],
  19: [
    {
      code: '22',
      name: 'ALMAGUER',
    },
    {
      code: '50',
      name: 'ARGELIA',
    },
    {
      code: '75',
      name: 'BALBOA',
    },
    {
      code: '100',
      name: 'BOLÍVAR',
    },
    {
      code: '110',
      name: 'BUENOS AIRES',
    },
    {
      code: '130',
      name: 'CAJIBÍO',
    },
    {
      code: '137',
      name: 'CALDONO',
    },
    {
      code: '142',
      name: 'CALOTO',
    },
    {
      code: '212',
      name: 'CORINTO',
    },
    {
      code: '256',
      name: 'EL TAMBO',
    },
    {
      code: '290',
      name: 'FLORENCIA',
    },
    {
      code: '318',
      name: 'GUAPI',
    },
    {
      code: '355',
      name: 'INZÁ',
    },
    {
      code: '364',
      name: 'JAMBALÓ',
    },
    {
      code: '392',
      name: 'LA SIERRA',
    },
    {
      code: '397',
      name: 'LA VEGA',
    },
    {
      code: '418',
      name: 'LÓPEZ',
    },
    {
      code: '450',
      name: 'MERCADERES',
    },
    {
      code: '455',
      name: 'MIRANDA',
    },
    {
      code: '473',
      name: 'MORALES',
    },
    {
      code: '513',
      name: 'PADILLA',
    },
    {
      code: '517',
      name: 'PAEZ',
    },
    {
      code: '532',
      name: 'PATÍA',
    },
    {
      code: '533',
      name: 'PIAMONTE',
    },
    {
      code: '548',
      name: 'PIENDAMÓ',
    },
    {
      code: '573',
      name: 'PUERTO TEJADA',
    },
    {
      code: '585',
      name: 'PURACÉ',
    },
    {
      code: '1',
      name: 'POPAYÁN',
    },
    {
      code: '622',
      name: 'ROSAS',
    },
    {
      code: '693',
      name: 'SAN SEBASTIÁN',
    },
    {
      code: '701',
      name: 'SANTA ROSA',
    },
    {
      code: '698',
      name: 'SANTANDER DE QUILICHAO',
    },
    {
      code: '743',
      name: 'SILVIA',
    },
    {
      code: '760',
      name: 'SOTARA',
    },
    {
      code: '780',
      name: 'SUÁREZ',
    },
    {
      code: '785',
      name: 'SUCRE',
    },
    {
      code: '807',
      name: 'TIMBÍO',
    },
    {
      code: '809',
      name: 'TIMBIQUÍ',
    },
    {
      code: '821',
      name: 'TORIBIO',
    },
    {
      code: '824',
      name: 'TOTORÓ',
    },
    {
      code: '845',
      name: 'VILLA RICA',
    },
    {
      code: '300',
      name: 'GUACHENE',
    },
  ],
  20: [
    {
      code: '11',
      name: 'AGUACHICA',
    },
    {
      code: '13',
      name: 'AGUSTÍN CODAZZI',
    },
    {
      code: '32',
      name: 'ASTREA',
    },
    {
      code: '45',
      name: 'BECERRIL',
    },
    {
      code: '60',
      name: 'BOSCONIA',
    },
    {
      code: '175',
      name: 'CHIMICHAGUA',
    },
    {
      code: '178',
      name: 'CHIRIGUANÁ',
    },
    {
      code: '228',
      name: 'CURUMANÍ',
    },
    {
      code: '238',
      name: 'EL COPEY',
    },
    {
      code: '250',
      name: 'EL PASO',
    },
    {
      code: '295',
      name: 'GAMARRA',
    },
    {
      code: '310',
      name: 'GONZÁLEZ',
    },
    {
      code: '383',
      name: 'LA GLORIA',
    },
    {
      code: '400',
      name: 'LA JAGUA DE IBIRICO',
    },
    {
      code: '621',
      name: 'LA PAZ',
    },
    {
      code: '443',
      name: 'MANAURE',
    },
    {
      code: '517',
      name: 'PAILITAS',
    },
    {
      code: '550',
      name: 'PELAYA',
    },
    {
      code: '570',
      name: 'PUEBLO BELLO',
    },
    {
      code: '614',
      name: 'RÍO DE ORO',
    },
    {
      code: '710',
      name: 'SAN ALBERTO',
    },
    {
      code: '750',
      name: 'SAN DIEGO',
    },
    {
      code: '770',
      name: 'SAN MARTÍN',
    },
    {
      code: '787',
      name: 'TAMALAMEQUE',
    },
    {
      code: '1',
      name: 'VALLEDUPAR',
    },
  ],
  23: [
    {
      code: '68',
      name: 'AYAPEL',
    },
    {
      code: '79',
      name: 'BUENAVISTA',
    },
    {
      code: '90',
      name: 'CANALETE',
    },
    {
      code: '162',
      name: 'CERETÉ',
    },
    {
      code: '168',
      name: 'CHIMÁ',
    },
    {
      code: '182',
      name: 'CHINÚ',
    },
    {
      code: '189',
      name: 'CIÉNAGA DE ORO',
    },
    {
      code: '300',
      name: 'COTORRA',
    },
    {
      code: '350',
      name: 'LA APARTADA',
    },
    {
      code: '417',
      name: 'LORICA',
    },
    {
      code: '419',
      name: 'LOS CÓRDOBAS',
    },
    {
      code: '464',
      name: 'MOMIL',
    },
    {
      code: '466',
      name: 'MONTELÍBANO',
    },
    {
      code: '1',
      name: 'MONTERÍA',
    },
    {
      code: '500',
      name: 'MOÑITOS',
    },
    {
      code: '570',
      name: 'PUEBLO NUEVO',
    },
    {
      code: '574',
      name: 'PUERTO ESCONDIDO',
    },
    {
      code: '580',
      name: 'PUERTO LIBERTADOR',
    },
    {
      code: '586',
      name: 'PURÍSIMA',
    },
    {
      code: '555',
      name: 'PLANETA RICA',
    },
    {
      code: '660',
      name: 'SAHAGÚN',
    },
    {
      code: '670',
      name: 'SAN ANDRÉS SOTAVENTO',
    },
    {
      code: '672',
      name: 'SAN ANTERO',
    },
    {
      code: '675',
      name: 'SAN BERNARDO DEL VIENTO',
    },
    {
      code: '678',
      name: 'SAN CARLOS',
    },
    {
      code: '686',
      name: 'SAN PELAYO',
    },
    {
      code: '807',
      name: 'TIERRALTA',
    },
    {
      code: '855',
      name: 'VALENCIA',
    },
  ],
  25: [
    {
      code: '1',
      name: 'AGUA DE DIOS',
    },
    {
      code: '19',
      name: 'ALBÁN',
    },
    {
      code: '35',
      name: 'ANAPOIMA',
    },
    {
      code: '40',
      name: 'ANOLAIMA',
    },
    {
      code: '599',
      name: 'APULO',
    },
    {
      code: '53',
      name: 'ARBELÁEZ',
    },
    {
      code: '86',
      name: 'BELTRÁN',
    },
    {
      code: '95',
      name: 'BITUIMA',
    },
    {
      code: '99',
      name: 'BOJACÁ',
    },
    {
      code: '120',
      name: 'CABRERA',
    },
    {
      code: '123',
      name: 'CACHIPAY',
    },
    {
      code: '126',
      name: 'CAJICÁ',
    },
    {
      code: '148',
      name: 'CAPARRAPÍ',
    },
    {
      code: '151',
      name: 'CAQUEZA',
    },
    {
      code: '154',
      name: 'CARMEN DE CARUPA',
    },
    {
      code: '168',
      name: 'CHAGUANÍ',
    },
    {
      code: '175',
      name: 'CHÍA',
    },
    {
      code: '178',
      name: 'CHIPAQUE',
    },
    {
      code: '181',
      name: 'CHOACHÍ',
    },
    {
      code: '183',
      name: 'CHOCONTÁ',
    },
    {
      code: '200',
      name: 'COGUA',
    },
    {
      code: '214',
      name: 'COTA',
    },
    {
      code: '224',
      name: 'CUCUNUBÁ',
    },
    {
      code: '245',
      name: 'EL COLEGIO',
    },
    {
      code: '258',
      name: 'EL PEÑÓN',
    },
    {
      code: '260',
      name: 'EL ROSAL',
    },
    {
      code: '269',
      name: 'FACATATIVÁ',
    },
    {
      code: '279',
      name: 'FOMEQUE',
    },
    {
      code: '281',
      name: 'FOSCA',
    },
    {
      code: '286',
      name: 'FUNZA',
    },
    {
      code: '288',
      name: 'FÚQUENE',
    },
    {
      code: '290',
      name: 'FUSAGASUGÁ',
    },
    {
      code: '293',
      name: 'GACHALA',
    },
    {
      code: '295',
      name: 'GACHANCIPÁ',
    },
    {
      code: '297',
      name: 'GACHETÁ',
    },
    {
      code: '299',
      name: 'GAMA',
    },
    {
      code: '307',
      name: 'GIRARDOT',
    },
    {
      code: '312',
      name: 'GRANADA',
    },
    {
      code: '317',
      name: 'GUACHETÁ',
    },
    {
      code: '320',
      name: 'GUADUAS',
    },
    {
      code: '322',
      name: 'GUASCA',
    },
    {
      code: '324',
      name: 'GUATAQUÍ',
    },
    {
      code: '326',
      name: 'GUATAVITA',
    },
    {
      code: '328',
      name: 'GUAYABAL DE SIQUIMA',
    },
    {
      code: '335',
      name: 'GUAYABETAL',
    },
    {
      code: '339',
      name: 'GUTIÉRREZ',
    },
    {
      code: '368',
      name: 'JERUSALÉN',
    },
    {
      code: '372',
      name: 'JUNÍN',
    },
    {
      code: '377',
      name: 'LA CALERA',
    },
    {
      code: '386',
      name: 'LA MESA',
    },
    {
      code: '394',
      name: 'LA PALMA',
    },
    {
      code: '398',
      name: 'LA PEÑA',
    },
    {
      code: '402',
      name: 'LA VEGA',
    },
    {
      code: '407',
      name: 'LENGUAZAQUE',
    },
    {
      code: '426',
      name: 'MACHETA',
    },
    {
      code: '430',
      name: 'MADRID',
    },
    {
      code: '436',
      name: 'MANTA',
    },
    {
      code: '438',
      name: 'MEDINA',
    },
    {
      code: '473',
      name: 'MOSQUERA',
    },
    {
      code: '483',
      name: 'NARIÑO',
    },
    {
      code: '486',
      name: 'NEMOCÓN',
    },
    {
      code: '488',
      name: 'NILO',
    },
    {
      code: '489',
      name: 'NIMAIMA',
    },
    {
      code: '491',
      name: 'NOCAIMA',
    },
    {
      code: '513',
      name: 'PACHO',
    },
    {
      code: '518',
      name: 'PAIME',
    },
    {
      code: '524',
      name: 'PANDI',
    },
    {
      code: '530',
      name: 'PARATEBUENO',
    },
    {
      code: '535',
      name: 'PASCA',
    },
    {
      code: '572',
      name: 'PUERTO SALGAR',
    },
    {
      code: '580',
      name: 'PULÍ',
    },
    {
      code: '592',
      name: 'QUEBRADANEGRA',
    },
    {
      code: '594',
      name: 'QUETAME',
    },
    {
      code: '596',
      name: 'QUIPILE',
    },
    {
      code: '612',
      name: 'RICAURTE',
    },
    {
      code: '645',
      name: 'SAN ANTONIO DEL TEQUENDAMA',
    },
    {
      code: '649',
      name: 'SAN BERNARDO',
    },
    {
      code: '653',
      name: 'SAN CAYETANO',
    },
    {
      code: '658',
      name: 'SAN FRANCISCO',
    },
    {
      code: '662',
      name: 'SAN JUAN DE RÍO SECO',
    },
    {
      code: '718',
      name: 'SASAIMA',
    },
    {
      code: '736',
      name: 'SESQUILÉ',
    },
    {
      code: '740',
      name: 'SIBATÉ',
    },
    {
      code: '743',
      name: 'SILVANIA',
    },
    {
      code: '745',
      name: 'SIMIJACA',
    },
    {
      code: '754',
      name: 'SOACHA',
    },
    {
      code: '758',
      name: 'SOPÓ',
    },
    {
      code: '769',
      name: 'SUBACHOQUE',
    },
    {
      code: '772',
      name: 'SUESCA',
    },
    {
      code: '777',
      name: 'SUPATÁ',
    },
    {
      code: '779',
      name: 'SUSA',
    },
    {
      code: '781',
      name: 'SUTATAUSA',
    },
    {
      code: '785',
      name: 'TABIO',
    },
    {
      code: '793',
      name: 'TAUSA',
    },
    {
      code: '797',
      name: 'TENA',
    },
    {
      code: '799',
      name: 'TENJO',
    },
    {
      code: '805',
      name: 'TIBACUY',
    },
    {
      code: '807',
      name: 'TIBIRITA',
    },
    {
      code: '815',
      name: 'TOCAIMA',
    },
    {
      code: '817',
      name: 'TOCANCIPÁ',
    },
    {
      code: '823',
      name: 'TOPAIPÍ',
    },
    {
      code: '839',
      name: 'UBALÁ',
    },
    {
      code: '841',
      name: 'UBAQUE',
    },
    {
      code: '845',
      name: 'UNE',
    },
    {
      code: '851',
      name: 'ÚTICA',
    },
    {
      code: '506',
      name: 'VENECIA',
    },
    {
      code: '862',
      name: 'VERGARA',
    },
    {
      code: '867',
      name: 'VIANÍ',
    },
    {
      code: '843',
      name: 'VILLA DE SAN DIEGO DE UBATE',
    },
    {
      code: '871',
      name: 'VILLAGÓMEZ',
    },
    {
      code: '873',
      name: 'VILLAPINZÓN',
    },
    {
      code: '875',
      name: 'VILLETA',
    },
    {
      code: '878',
      name: 'VIOTÁ',
    },
    {
      code: '885',
      name: 'YACOPÍ',
    },
    {
      code: '898',
      name: 'ZIPACÓN',
    },
    {
      code: '899',
      name: 'ZIPAQUIRÁ',
    },
  ],
  27: [
    {
      code: '6',
      name: 'ACANDÍ',
    },
    {
      code: '25',
      name: 'ALTO BAUDO',
    },
    {
      code: '50',
      name: 'ATRATO',
    },
    {
      code: '73',
      name: 'BAGADÓ',
    },
    {
      code: '75',
      name: 'BAHÍA SOLANO',
    },
    {
      code: '77',
      name: 'BAJO BAUDÓ',
    },
    {
      code: '86',
      name: 'BELÉN DE BAJIRÁ',
    },
    {
      code: '99',
      name: 'BOJAYA',
    },
    {
      code: '150',
      name: 'CARMEN DEL DARIEN',
    },
    {
      code: '160',
      name: 'CÉRTEGUI',
    },
    {
      code: '205',
      name: 'CONDOTO',
    },
    {
      code: '135',
      name: 'EL CANTÓN DEL SAN PABLO',
    },
    {
      code: '245',
      name: 'EL CARMEN DE ATRATO',
    },
    {
      code: '250',
      name: 'EL LITORAL DEL SAN JUAN',
    },
    {
      code: '361',
      name: 'ISTMINA',
    },
    {
      code: '372',
      name: 'JURADÓ',
    },
    {
      code: '413',
      name: 'LLORÓ',
    },
    {
      code: '425',
      name: 'MEDIO ATRATO',
    },
    {
      code: '430',
      name: 'MEDIO BAUDÓ',
    },
    {
      code: '450',
      name: 'MEDIO SAN JUAN',
    },
    {
      code: '491',
      name: 'NÓVITA',
    },
    {
      code: '495',
      name: 'NUQUÍ',
    },
    {
      code: '1',
      name: 'QUIBDÓ',
    },
    {
      code: '580',
      name: 'RÍO IRO',
    },
    {
      code: '600',
      name: 'RÍO QUITO',
    },
    {
      code: '615',
      name: 'RIOSUCIO',
    },
    {
      code: '660',
      name: 'SAN JOSÉ DEL PALMAR',
    },
    {
      code: '745',
      name: 'SIPÍ',
    },
    {
      code: '787',
      name: 'TADÓ',
    },
    {
      code: '800',
      name: 'UNGUÍA',
    },
    {
      code: '810',
      name: 'UNIÓN PANAMERICANA',
    },
  ],
  41: [
    {
      code: '6',
      name: 'ACEVEDO',
    },
    {
      code: '13',
      name: 'AGRADO',
    },
    {
      code: '16',
      name: 'AIPE',
    },
    {
      code: '20',
      name: 'ALGECIRAS',
    },
    {
      code: '26',
      name: 'ALTAMIRA',
    },
    {
      code: '78',
      name: 'BARAYA',
    },
    {
      code: '132',
      name: 'CAMPOALEGRE',
    },
    {
      code: '206',
      name: 'COLOMBIA',
    },
    {
      code: '244',
      name: 'ELÍAS',
    },
    {
      code: '298',
      name: 'GARZÓN',
    },
    {
      code: '306',
      name: 'GIGANTE',
    },
    {
      code: '319',
      name: 'GUADALUPE',
    },
    {
      code: '349',
      name: 'HOBO',
    },
    {
      code: '357',
      name: 'IQUIRA',
    },
    {
      code: '359',
      name: 'ISNOS',
    },
    {
      code: '378',
      name: 'LA ARGENTINA',
    },
    {
      code: '396',
      name: 'LA PLATA',
    },
    {
      code: '483',
      name: 'NÁTAGA',
    },
    {
      code: '1',
      name: 'NEIVA',
    },
    {
      code: '503',
      name: 'OPORAPA',
    },
    {
      code: '518',
      name: 'PAICOL',
    },
    {
      code: '524',
      name: 'PALERMO',
    },
    {
      code: '530',
      name: 'PALESTINA',
    },
    {
      code: '548',
      name: 'PITAL',
    },
    {
      code: '551',
      name: 'PITALITO',
    },
    {
      code: '615',
      name: 'RIVERA',
    },
    {
      code: '660',
      name: 'SALADOBLANCO',
    },
    {
      code: '668',
      name: 'SAN AGUSTÍN',
    },
    {
      code: '676',
      name: 'SANTA MARÍA',
    },
    {
      code: '770',
      name: 'SUAZA',
    },
    {
      code: '791',
      name: 'TARQUI',
    },
    {
      code: '799',
      name: 'TELLO',
    },
    {
      code: '801',
      name: 'TERUEL',
    },
    {
      code: '797',
      name: 'TESALIA',
    },
    {
      code: '807',
      name: 'TIMANÁ',
    },
    {
      code: '872',
      name: 'VILLAVIEJA',
    },
    {
      code: '885',
      name: 'YAGUARÁ',
    },
  ],
  44: [
    {
      code: '35',
      name: 'ALBANIA',
    },
    {
      code: '78',
      name: 'BARRANCAS',
    },
    {
      code: '90',
      name: 'DIBULLA',
    },
    {
      code: '98',
      name: 'DISTRACCIÓN',
    },
    {
      code: '110',
      name: 'EL MOLINO',
    },
    {
      code: '279',
      name: 'FONSECA',
    },
    {
      code: '378',
      name: 'HATONUEVO',
    },
    {
      code: '420',
      name: 'LA JAGUA DEL PILAR',
    },
    {
      code: '430',
      name: 'MAICAO',
    },
    {
      code: '560',
      name: 'MANAURE',
    },
    {
      code: '1',
      name: 'RIOHACHA',
    },
    {
      code: '650',
      name: 'SAN JUAN DEL CESAR',
    },
    {
      code: '847',
      name: 'URIBIA',
    },
    {
      code: '855',
      name: 'URUMITA',
    },
    {
      code: '874',
      name: 'VILLANUEVA',
    },
  ],
  47: [
    {
      code: '30',
      name: 'ALGARROBO',
    },
    {
      code: '53',
      name: 'ARACATACA',
    },
    {
      code: '58',
      name: 'ARIGUANÍ',
    },
    {
      code: '161',
      name: 'CERRO SAN ANTONIO',
    },
    {
      code: '170',
      name: 'CHIBOLO',
    },
    {
      code: '189',
      name: 'CIÉNAGA',
    },
    {
      code: '205',
      name: 'CONCORDIA',
    },
    {
      code: '245',
      name: 'EL BANCO',
    },
    {
      code: '258',
      name: 'EL PIÑON',
    },
    {
      code: '268',
      name: 'EL RETÉN',
    },
    {
      code: '288',
      name: 'FUNDACIÓN',
    },
    {
      code: '318',
      name: 'GUAMAL',
    },
    {
      code: '460',
      name: 'NUEVA GRANADA',
    },
    {
      code: '541',
      name: 'PEDRAZA',
    },
    {
      code: '545',
      name: 'PIJIÑO DEL CARMEN',
    },
    {
      code: '570',
      name: 'PUEBLOVIEJO',
    },
    {
      code: '551',
      name: 'PIVIJAY',
    },
    {
      code: '555',
      name: 'PLATO',
    },
    {
      code: '605',
      name: 'REMOLINO',
    },
    {
      code: '660',
      name: 'SABANAS DE SAN ANGEL',
    },
    {
      code: '675',
      name: 'SALAMINA',
    },
    {
      code: '692',
      name: 'SAN SEBASTIÁN DE BUENAVISTA',
    },
    {
      code: '703',
      name: 'SAN ZENÓN',
    },
    {
      code: '707',
      name: 'SANTA ANA',
    },
    {
      code: '720',
      name: 'SANTA BÁRBARA DE PINTO',
    },
    {
      code: '1',
      name: 'SANTA MARTA',
    },
    {
      code: '745',
      name: 'SITIONUEVO',
    },
    {
      code: '798',
      name: 'TENERIFE',
    },
    {
      code: '960',
      name: 'ZAPAYÁN',
    },
    {
      code: '980',
      name: 'ZONA BANANERA',
    },
  ],
  50: [
    {
      code: '6',
      name: 'ACACÍAS',
    },
    {
      code: '110',
      name: 'BARRANCA DE UPÍA',
    },
    {
      code: '124',
      name: 'CABUYARO',
    },
    {
      code: '150',
      name: 'CASTILLA LA NUEVA',
    },
    {
      code: '223',
      name: 'CUBARRAL',
    },
    {
      code: '226',
      name: 'CUMARAL',
    },
    {
      code: '245',
      name: 'EL CALVARIO',
    },
    {
      code: '251',
      name: 'EL CASTILLO',
    },
    {
      code: '270',
      name: 'EL DORADO',
    },
    {
      code: '287',
      name: 'FUENTE DE ORO',
    },
    {
      code: '313',
      name: 'GRANADA',
    },
    {
      code: '318',
      name: 'GUAMAL',
    },
    {
      code: '350',
      name: 'LA MACARENA',
    },
    {
      code: '400',
      name: 'LEJANÍAS',
    },
    {
      code: '325',
      name: 'MAPIRIPÁN',
    },
    {
      code: '330',
      name: 'MESETAS',
    },
    {
      code: '450',
      name: 'PUERTO CONCORDIA',
    },
    {
      code: '568',
      name: 'PUERTO GAITÁN',
    },
    {
      code: '577',
      name: 'PUERTO LLERAS',
    },
    {
      code: '573',
      name: 'PUERTO LÓPEZ',
    },
    {
      code: '590',
      name: 'PUERTO RICO',
    },
    {
      code: '606',
      name: 'RESTREPO',
    },
    {
      code: '680',
      name: 'SAN CARLOS DE GUAROA',
    },
    {
      code: '683',
      name: 'SAN JUAN DE ARAMA',
    },
    {
      code: '686',
      name: 'SAN JUANITO',
    },
    {
      code: '689',
      name: 'SAN MARTÍN',
    },
    {
      code: '370',
      name: 'URIBE',
    },
    {
      code: '1',
      name: 'VILLAVICENCIO',
    },
    {
      code: '711',
      name: 'VISTAHERMOSA',
    },
  ],
  52: [
    {
      code: '19',
      name: 'ALBÁN',
    },
    {
      code: '22',
      name: 'ALDANA',
    },
    {
      code: '36',
      name: 'ANCUYÁ',
    },
    {
      code: '51',
      name: 'ARBOLEDA',
    },
    {
      code: '79',
      name: 'BARBACOAS',
    },
    {
      code: '83',
      name: 'BELÉN',
    },
    {
      code: '110',
      name: 'BUESACO',
    },
    {
      code: '240',
      name: 'CHACHAGÜÍ',
    },
    {
      code: '203',
      name: 'COLÓN',
    },
    {
      code: '207',
      name: 'CONSACA',
    },
    {
      code: '210',
      name: 'CONTADERO',
    },
    {
      code: '215',
      name: 'CÓRDOBA',
    },
    {
      code: '224',
      name: 'CUASPUD',
    },
    {
      code: '227',
      name: 'CUMBAL',
    },
    {
      code: '233',
      name: 'CUMBITARA',
    },
    {
      code: '250',
      name: 'EL CHARCO',
    },
    {
      code: '254',
      name: 'EL PEÑOL',
    },
    {
      code: '256',
      name: 'EL ROSARIO',
    },
    {
      code: '258',
      name: 'EL TABLÓN DE GÓMEZ',
    },
    {
      code: '260',
      name: 'EL TAMBO',
    },
    {
      code: '520',
      name: 'FRANCISCO PIZARRO',
    },
    {
      code: '287',
      name: 'FUNES',
    },
    {
      code: '317',
      name: 'GUACHUCAL',
    },
    {
      code: '320',
      name: 'GUAITARILLA',
    },
    {
      code: '323',
      name: 'GUALMATÁN',
    },
    {
      code: '352',
      name: 'ILES',
    },
    {
      code: '354',
      name: 'IMUÉS',
    },
    {
      code: '356',
      name: 'IPIALES',
    },
    {
      code: '378',
      name: 'LA CRUZ',
    },
    {
      code: '381',
      name: 'LA FLORIDA',
    },
    {
      code: '385',
      name: 'LA LLANADA',
    },
    {
      code: '390',
      name: 'LA TOLA',
    },
    {
      code: '399',
      name: 'LA UNIÓN',
    },
    {
      code: '405',
      name: 'LEIVA',
    },
    {
      code: '411',
      name: 'LINARES',
    },
    {
      code: '418',
      name: 'LOS ANDES',
    },
    {
      code: '427',
      name: 'MAGÜI',
    },
    {
      code: '435',
      name: 'MALLAMA',
    },
    {
      code: '473',
      name: 'MOSQUERA',
    },
    {
      code: '480',
      name: 'NARIÑO',
    },
    {
      code: '490',
      name: 'OLAYA HERRERA',
    },
    {
      code: '506',
      name: 'OSPINA',
    },
    {
      code: '1',
      name: 'PASTO',
    },
    {
      code: '573',
      name: 'PUERRES',
    },
    {
      code: '585',
      name: 'PUPIALES',
    },
    {
      code: '540',
      name: 'POLICARPA',
    },
    {
      code: '612',
      name: 'RICAURTE',
    },
    {
      code: '621',
      name: 'ROBERTO PAYÁN',
    },
    {
      code: '560',
      name: 'POTOSÍ',
    },
    {
      code: '678',
      name: 'SAMANIEGO',
    },
    {
      code: '685',
      name: 'SAN BERNARDO',
    },
    {
      code: '687',
      name: 'SAN LORENZO',
    },
    {
      code: '693',
      name: 'SAN PABLO',
    },
    {
      code: '694',
      name: 'SAN PEDRO DE CARTAGO',
    },
    {
      code: '683',
      name: 'SANDONÁ',
    },
    {
      code: '696',
      name: 'SANTA BÁRBARA',
    },
    {
      code: '699',
      name: 'SANTACRUZ',
    },
    {
      code: '720',
      name: 'SAPUYES',
    },
    {
      code: '565',
      name: 'PROVIDENCIA',
    },
    {
      code: '786',
      name: 'TAMINANGO',
    },
    {
      code: '788',
      name: 'TANGUA',
    },
    {
      code: '835',
      name: 'TUMACO',
    },
    {
      code: '838',
      name: 'TÚQUERRES',
    },
    {
      code: '885',
      name: 'YACUANQUER',
    },
  ],
  54: [
    {
      code: '3',
      name: 'ABREGO',
    },
    {
      code: '51',
      name: 'ARBOLEDAS',
    },
    {
      code: '99',
      name: 'BOCHALEMA',
    },
    {
      code: '109',
      name: 'BUCARASICA',
    },
    {
      code: '128',
      name: 'CACHIRÁ',
    },
    {
      code: '125',
      name: 'CÁCOTA',
    },
    {
      code: '172',
      name: 'CHINÁCOTA',
    },
    {
      code: '174',
      name: 'CHITAGÁ',
    },
    {
      code: '206',
      name: 'CONVENCIÓN',
    },
    {
      code: '1',
      name: 'CÚCUTA',
    },
    {
      code: '223',
      name: 'CUCUTILLA',
    },
    {
      code: '239',
      name: 'DURANIA',
    },
    {
      code: '245',
      name: 'EL CARMEN',
    },
    {
      code: '250',
      name: 'EL TARRA',
    },
    {
      code: '261',
      name: 'EL ZULIA',
    },
    {
      code: '313',
      name: 'GRAMALOTE',
    },
    {
      code: '344',
      name: 'HACARÍ',
    },
    {
      code: '347',
      name: 'HERRÁN',
    },
    {
      code: '385',
      name: 'LA ESPERANZA',
    },
    {
      code: '398',
      name: 'LA PLAYA',
    },
    {
      code: '377',
      name: 'LABATECA',
    },
    {
      code: '405',
      name: 'LOS PATIOS',
    },
    {
      code: '418',
      name: 'LOURDES',
    },
    {
      code: '480',
      name: 'MUTISCUA',
    },
    {
      code: '498',
      name: 'OCAÑA',
    },
    {
      code: '518',
      name: 'PAMPLONA',
    },
    {
      code: '520',
      name: 'PAMPLONITA',
    },
    {
      code: '553',
      name: 'PUERTO SANTANDER',
    },
    {
      code: '599',
      name: 'RAGONVALIA',
    },
    {
      code: '660',
      name: 'SALAZAR',
    },
    {
      code: '670',
      name: 'SAN CALIXTO',
    },
    {
      code: '673',
      name: 'SAN CAYETANO',
    },
    {
      code: '680',
      name: 'SANTIAGO',
    },
    {
      code: '720',
      name: 'SARDINATA',
    },
    {
      code: '743',
      name: 'SILOS',
    },
    {
      code: '800',
      name: 'TEORAMA',
    },
    {
      code: '810',
      name: 'TIBÚ',
    },
    {
      code: '820',
      name: 'TOLEDO',
    },
    {
      code: '871',
      name: 'VILLA CARO',
    },
    {
      code: '874',
      name: 'VILLA DEL ROSARIO',
    },
  ],
  63: [
    {
      code: '1',
      name: 'ARMENIA',
    },
    {
      code: '111',
      name: 'BUENAVISTA',
    },
    {
      code: '130',
      name: 'CALARCA',
    },
    {
      code: '190',
      name: 'CIRCASIA',
    },
    {
      code: '212',
      name: 'CÓRDOBA',
    },
    {
      code: '272',
      name: 'FILANDIA',
    },
    {
      code: '302',
      name: 'GÉNOVA',
    },
    {
      code: '401',
      name: 'LA TEBAIDA',
    },
    {
      code: '470',
      name: 'MONTENEGRO',
    },
    {
      code: '548',
      name: 'PIJAO',
    },
    {
      code: '594',
      name: 'QUIMBAYA',
    },
    {
      code: '690',
      name: 'SALENTO',
    },
  ],
  66: [
    {
      code: '45',
      name: 'APÍA',
    },
    {
      code: '75',
      name: 'BALBOA',
    },
    {
      code: '88',
      name: 'BELÉN DE UMBRÍA',
    },
    {
      code: '170',
      name: 'DOSQUEBRADAS',
    },
    {
      code: '318',
      name: 'GUÁTICA',
    },
    {
      code: '383',
      name: 'LA CELIA',
    },
    {
      code: '400',
      name: 'LA VIRGINIA',
    },
    {
      code: '440',
      name: 'MARSELLA',
    },
    {
      code: '456',
      name: 'MISTRATÓ',
    },
    {
      code: '1',
      name: 'PEREIRA',
    },
    {
      code: '572',
      name: 'PUEBLO RICO',
    },
    {
      code: '594',
      name: 'QUINCHÍA',
    },
    {
      code: '682',
      name: 'SANTA ROSA DE CABAL',
    },
    {
      code: '687',
      name: 'SANTUARIO',
    },
  ],
  68: [
    {
      code: '13',
      name: 'AGUADA',
    },
    {
      code: '20',
      name: 'ALBANIA',
    },
    {
      code: '51',
      name: 'ARATOCA',
    },
    {
      code: '77',
      name: 'BARBOSA',
    },
    {
      code: '79',
      name: 'BARICHARA',
    },
    {
      code: '81',
      name: 'BARRANCABERMEJA',
    },
    {
      code: '92',
      name: 'BETULIA',
    },
    {
      code: '101',
      name: 'BOLÍVAR',
    },
    {
      code: '1',
      name: 'BUCARAMANGA',
    },
    {
      code: '121',
      name: 'CABRERA',
    },
    {
      code: '132',
      name: 'CALIFORNIA',
    },
    {
      code: '147',
      name: 'CAPITANEJO',
    },
    {
      code: '152',
      name: 'CARCASÍ',
    },
    {
      code: '160',
      name: 'CEPITÁ',
    },
    {
      code: '162',
      name: 'CERRITO',
    },
    {
      code: '167',
      name: 'CHARALÁ',
    },
    {
      code: '169',
      name: 'CHARTA',
    },
    {
      code: '176',
      name: 'CHIMA',
    },
    {
      code: '179',
      name: 'CHIPATÁ',
    },
    {
      code: '190',
      name: 'CIMITARRA',
    },
    {
      code: '207',
      name: 'CONCEPCIÓN',
    },
    {
      code: '209',
      name: 'CONFINES',
    },
    {
      code: '211',
      name: 'CONTRATACIÓN',
    },
    {
      code: '217',
      name: 'COROMORO',
    },
    {
      code: '229',
      name: 'CURITÍ',
    },
    {
      code: '235',
      name: 'EL CARMEN DE CHUCURÍ',
    },
    {
      code: '245',
      name: 'EL GUACAMAYO',
    },
    {
      code: '250',
      name: 'EL PEÑÓN',
    },
    {
      code: '255',
      name: 'EL PLAYÓN',
    },
    {
      code: '264',
      name: 'ENCINO',
    },
    {
      code: '266',
      name: 'ENCISO',
    },
    {
      code: '271',
      name: 'FLORIÁN',
    },
    {
      code: '276',
      name: 'FLORIDABLANCA',
    },
    {
      code: '296',
      name: 'GALÁN',
    },
    {
      code: '298',
      name: 'GAMBITA',
    },
    {
      code: '307',
      name: 'GIRÓN',
    },
    {
      code: '318',
      name: 'GUACA',
    },
    {
      code: '320',
      name: 'GUADALUPE',
    },
    {
      code: '322',
      name: 'GUAPOTÁ',
    },
    {
      code: '324',
      name: 'GUAVATÁ',
    },
    {
      code: '327',
      name: 'GÜEPSA',
    },
    {
      code: '344',
      name: 'HATO',
    },
    {
      code: '368',
      name: 'JESÚS MARÍA',
    },
    {
      code: '370',
      name: 'JORDÁN',
    },
    {
      code: '377',
      name: 'LA BELLEZA',
    },
    {
      code: '397',
      name: 'LA PAZ',
    },
    {
      code: '385',
      name: 'LANDÁZURI',
    },
    {
      code: '406',
      name: 'LEBRÍJA',
    },
    {
      code: '418',
      name: 'LOS SANTOS',
    },
    {
      code: '425',
      name: 'MACARAVITA',
    },
    {
      code: '432',
      name: 'MÁLAGA',
    },
    {
      code: '444',
      name: 'MATANZA',
    },
    {
      code: '464',
      name: 'MOGOTES',
    },
    {
      code: '468',
      name: 'MOLAGAVITA',
    },
    {
      code: '498',
      name: 'OCAMONTE',
    },
    {
      code: '500',
      name: 'OIBA',
    },
    {
      code: '502',
      name: 'ONZAGA',
    },
    {
      code: '522',
      name: 'PALMAR',
    },
    {
      code: '524',
      name: 'PALMAS DEL SOCORRO',
    },
    {
      code: '533',
      name: 'PÁRAMO',
    },
    {
      code: '547',
      name: 'PIEDECUESTA',
    },
    {
      code: '549',
      name: 'PINCHOTE',
    },
    {
      code: '572',
      name: 'PUENTE NACIONAL',
    },
    {
      code: '573',
      name: 'PUERTO PARRA',
    },
    {
      code: '575',
      name: 'PUERTO WILCHES',
    },
    {
      code: '615',
      name: 'RIONEGRO',
    },
    {
      code: '655',
      name: 'SABANA DE TORRES',
    },
    {
      code: '669',
      name: 'SAN ANDRÉS',
    },
    {
      code: '673',
      name: 'SAN BENITO',
    },
    {
      code: '679',
      name: 'SAN GIL',
    },
    {
      code: '682',
      name: 'SAN JOAQUÍN',
    },
    {
      code: '684',
      name: 'SAN JOSÉ DE MIRANDA',
    },
    {
      code: '686',
      name: 'SAN MIGUEL',
    },
    {
      code: '689',
      name: 'SAN VICENTE DE CHUCURÍ',
    },
    {
      code: '705',
      name: 'SANTA BÁRBARA',
    },
    {
      code: '720',
      name: 'SANTA HELENA DEL OPÓN',
    },
    {
      code: '745',
      name: 'SIMACOTA',
    },
    {
      code: '755',
      name: 'SOCORRO',
    },
    {
      code: '770',
      name: 'SUAITA',
    },
    {
      code: '773',
      name: 'SUCRE',
    },
    {
      code: '780',
      name: 'SURATÁ',
    },
    {
      code: '820',
      name: 'TONA',
    },
    {
      code: '855',
      name: 'VALLE DE SAN JOSÉ',
    },
    {
      code: '861',
      name: 'VÉLEZ',
    },
    {
      code: '867',
      name: 'VETAS',
    },
    {
      code: '872',
      name: 'VILLANUEVA',
    },
    {
      code: '895',
      name: 'ZAPATOCA',
    },
  ],
  70: [
    {
      code: '110',
      name: 'BUENAVISTA',
    },
    {
      code: '124',
      name: 'CAIMITO',
    },
    {
      code: '230',
      name: 'CHALÁN',
    },
    {
      code: '204',
      name: 'COLOSO',
    },
    {
      code: '215',
      name: 'COROZAL',
    },
    {
      code: '221',
      name: 'COVEÑAS',
    },
    {
      code: '233',
      name: 'EL ROBLE',
    },
    {
      code: '235',
      name: 'GALERAS',
    },
    {
      code: '265',
      name: 'GUARANDA',
    },
    {
      code: '400',
      name: 'LA UNIÓN',
    },
    {
      code: '418',
      name: 'LOS PALMITOS',
    },
    {
      code: '429',
      name: 'MAJAGUAL',
    },
    {
      code: '473',
      name: 'MORROA',
    },
    {
      code: '508',
      name: 'OVEJAS',
    },
    {
      code: '523',
      name: 'PALMITO',
    },
    {
      code: '670',
      name: 'SAMPUÉS',
    },
    {
      code: '678',
      name: 'SAN BENITO ABAD',
    },
    {
      code: '702',
      name: 'SAN JUAN DE BETULIA',
    },
    {
      code: '708',
      name: 'SAN MARCOS',
    },
    {
      code: '713',
      name: 'SAN ONOFRE',
    },
    {
      code: '717',
      name: 'SAN PEDRO',
    },
    {
      code: '820',
      name: 'SANTIAGO DE TOLÚ',
    },
    {
      code: '742',
      name: 'SINCÉ',
    },
    {
      code: '1',
      name: 'SINCELEJO',
    },
    {
      code: '771',
      name: 'SUCRE',
    },
    {
      code: '823',
      name: 'TOLÚ VIEJO',
    },
  ],
  73: [
    {
      code: '24',
      name: 'ALPUJARRA',
    },
    {
      code: '26',
      name: 'ALVARADO',
    },
    {
      code: '30',
      name: 'AMBALEMA',
    },
    {
      code: '43',
      name: 'ANZOÁTEGUI',
    },
    {
      code: '55',
      name: 'ARMERO',
    },
    {
      code: '67',
      name: 'ATACO',
    },
    {
      code: '124',
      name: 'CAJAMARCA',
    },
    {
      code: '148',
      name: 'CARMEN DE APICALÁ',
    },
    {
      code: '152',
      name: 'CASABIANCA',
    },
    {
      code: '168',
      name: 'CHAPARRAL',
    },
    {
      code: '200',
      name: 'COELLO',
    },
    {
      code: '217',
      name: 'COYAIMA',
    },
    {
      code: '226',
      name: 'CUNDAY',
    },
    {
      code: '236',
      name: 'DOLORES',
    },
    {
      code: '268',
      name: 'ESPINAL',
    },
    {
      code: '270',
      name: 'FALAN',
    },
    {
      code: '275',
      name: 'FLANDES',
    },
    {
      code: '283',
      name: 'FRESNO',
    },
    {
      code: '319',
      name: 'GUAMO',
    },
    {
      code: '347',
      name: 'HERVEO',
    },
    {
      code: '349',
      name: 'HONDA',
    },
    {
      code: '1',
      name: 'IBAGUÉ',
    },
    {
      code: '352',
      name: 'ICONONZO',
    },
    {
      code: '408',
      name: 'LÉRIDA',
    },
    {
      code: '411',
      name: 'LÍBANO',
    },
    {
      code: '443',
      name: 'MARIQUITA',
    },
    {
      code: '449',
      name: 'MELGAR',
    },
    {
      code: '461',
      name: 'MURILLO',
    },
    {
      code: '483',
      name: 'NATAGAIMA',
    },
    {
      code: '504',
      name: 'ORTEGA',
    },
    {
      code: '520',
      name: 'PALOCABILDO',
    },
    {
      code: '547',
      name: 'PIEDRAS',
    },
    {
      code: '585',
      name: 'PURIFICACIÓN',
    },
    {
      code: '555',
      name: 'PLANADAS',
    },
    {
      code: '616',
      name: 'RIOBLANCO',
    },
    {
      code: '622',
      name: 'RONCESVALLES',
    },
    {
      code: '624',
      name: 'ROVIRA',
    },
    {
      code: '671',
      name: 'SALDAÑA',
    },
    {
      code: '675',
      name: 'SAN ANTONIO',
    },
    {
      code: '678',
      name: 'SAN LUIS',
    },
    {
      code: '686',
      name: 'SANTA ISABEL',
    },
    {
      code: '770',
      name: 'SUÁREZ',
    },
    {
      code: '563',
      name: 'PRADO',
    },
    {
      code: '854',
      name: 'VALLE DE SAN JUAN',
    },
    {
      code: '861',
      name: 'VENADILLO',
    },
    {
      code: '870',
      name: 'VILLAHERMOSA',
    },
    {
      code: '873',
      name: 'VILLARRICA',
    },
  ],
  76: [
    {
      code: '20',
      name: 'ALCALÁ',
    },
    {
      code: '36',
      name: 'ANDALUCÍA',
    },
    {
      code: '41',
      name: 'ANSERMANUEVO',
    },
    {
      code: '54',
      name: 'ARGELIA',
    },
    {
      code: '100',
      name: 'BOLÍVAR',
    },
    {
      code: '109',
      name: 'BUENAVENTURA',
    },
    {
      code: '113',
      name: 'BUGALAGRANDE',
    },
    {
      code: '122',
      name: 'CAICEDONIA',
    },
    {
      code: '1',
      name: 'CALI',
    },
    {
      code: '126',
      name: 'CALIMA',
    },
    {
      code: '130',
      name: 'CANDELARIA',
    },
    {
      code: '147',
      name: 'CARTAGO',
    },
    {
      code: '233',
      name: 'DAGUA',
    },
    {
      code: '243',
      name: 'EL ÁGUILA',
    },
    {
      code: '246',
      name: 'EL CAIRO',
    },
    {
      code: '248',
      name: 'EL CERRITO',
    },
    {
      code: '250',
      name: 'EL DOVIO',
    },
    {
      code: '275',
      name: 'FLORIDA',
    },
    {
      code: '306',
      name: 'GINEBRA',
    },
    {
      code: '318',
      name: 'GUACARÍ',
    },
    {
      code: '111',
      name: 'GUADALAJARA DE BUGA',
    },
    {
      code: '364',
      name: 'JAMUNDÍ',
    },
    {
      code: '377',
      name: 'LA CUMBRE',
    },
    {
      code: '400',
      name: 'LA UNIÓN',
    },
    {
      code: '403',
      name: 'LA VICTORIA',
    },
    {
      code: '497',
      name: 'OBANDO',
    },
    {
      code: '520',
      name: 'PALMIRA',
    },
    {
      code: '606',
      name: 'RESTREPO',
    },
    {
      code: '616',
      name: 'RIOFRÍO',
    },
    {
      code: '622',
      name: 'ROLDANILLO',
    },
    {
      code: '563',
      name: 'PRADERA',
    },
    {
      code: '670',
      name: 'SAN PEDRO',
    },
    {
      code: '736',
      name: 'SEVILLA',
    },
    {
      code: '823',
      name: 'TORO',
    },
    {
      code: '828',
      name: 'TRUJILLO',
    },
    {
      code: '834',
      name: 'TULUÁ',
    },
    {
      code: '845',
      name: 'ULLOA',
    },
    {
      code: '863',
      name: 'VERSALLES',
    },
    {
      code: '869',
      name: 'VIJES',
    },
    {
      code: '890',
      name: 'YOTOCO',
    },
    {
      code: '892',
      name: 'YUMBO',
    },
    {
      code: '895',
      name: 'ZARZAL',
    },
  ],
  81: [
    {
      code: '1',
      name: 'ARAUCA',
    },
    {
      code: '65',
      name: 'ARAUQUITA',
    },
    {
      code: '220',
      name: 'CRAVO NORTE',
    },
    {
      code: '300',
      name: 'FORTUL',
    },
    {
      code: '591',
      name: 'PUERTO RONDÓN',
    },
    {
      code: '736',
      name: 'SARAVENA',
    },
    {
      code: '794',
      name: 'TAME',
    },
  ],
  85: [
    {
      code: '10',
      name: 'AGUAZUL',
    },
    {
      code: '15',
      name: 'CHAMEZA',
    },
    {
      code: '125',
      name: 'HATO COROZAL',
    },
    {
      code: '136',
      name: 'LA SALINA',
    },
    {
      code: '139',
      name: 'MANÍ',
    },
    {
      code: '162',
      name: 'MONTERREY',
    },
    {
      code: '225',
      name: 'NUNCHÍA',
    },
    {
      code: '230',
      name: 'OROCUÉ',
    },
    {
      code: '250',
      name: 'PAZ DE ARIPORO',
    },
    {
      code: '279',
      name: 'RECETOR',
    },
    {
      code: '300',
      name: 'SABANALARGA',
    },
    {
      code: '315',
      name: 'SÁCAMA',
    },
    {
      code: '263',
      name: 'PORE',
    },
    {
      code: '325',
      name: 'SAN LUIS DE PALENQUE',
    },
    {
      code: '400',
      name: 'TÁMARA',
    },
    {
      code: '410',
      name: 'TAURAMENA',
    },
    {
      code: '430',
      name: 'TRINIDAD',
    },
    {
      code: '440',
      name: 'VILLANUEVA',
    },
    {
      code: '1',
      name: 'YOPAL',
    },
  ],
  86: [
    {
      code: '219',
      name: 'COLÓN',
    },
    {
      code: '573',
      name: 'LEGUÍZAMO',
    },
    {
      code: '1',
      name: 'MOCOA',
    },
    {
      code: '320',
      name: 'ORITO',
    },
    {
      code: '568',
      name: 'PUERTO ASÍS',
    },
    {
      code: '569',
      name: 'PUERTO CAICEDO',
    },
    {
      code: '571',
      name: 'PUERTO GUZMÁN',
    },
    {
      code: '755',
      name: 'SAN FRANCISCO',
    },
    {
      code: '757',
      name: 'SAN MIGUEL',
    },
    {
      code: '760',
      name: 'SANTIAGO',
    },
    {
      code: '749',
      name: 'SIBUNDOY',
    },
    {
      code: '865',
      name: 'VALLE DEL GUAMUEZ',
    },
    {
      code: '885',
      name: 'VILLAGARZÓN',
    },
  ],
  88: [
    {
      code: '1',
      name: 'SAN ANDRÉS',
    },
    {
      code: '564',
      name: 'PROVIDENCIA',
    },
  ],
  91: [
    {
      code: '263',
      name: 'EL ENCANTO',
    },
    {
      code: '405',
      name: 'LA CHORRERA',
    },
    {
      code: '407',
      name: 'LA PEDRERA',
    },
    {
      code: '430',
      name: 'LA VICTORIA',
    },
    {
      code: '1',
      name: 'LETICIA',
    },
    {
      code: '460',
      name: 'MIRITI - PARANÁ',
    },
    {
      code: '530',
      name: 'PUERTO ALEGRÍA',
    },
    {
      code: '536',
      name: 'PUERTO ARICA',
    },
    {
      code: '540',
      name: 'PUERTO NARIÑO',
    },
    {
      code: '669',
      name: 'PUERTO SANTANDER',
    },
    {
      code: '798',
      name: 'TARAPACÁ',
    },
  ],
  94: [
    {
      code: '343',
      name: 'BARRANCO MINAS',
    },
    {
      code: '886',
      name: 'CACAHUAL',
    },
    {
      code: '1',
      name: 'INÍRIDA',
    },
    {
      code: '885',
      name: 'LA GUADALUPE',
    },
    {
      code: '663',
      name: 'MAPIRIPANA',
    },
    {
      code: '888',
      name: 'MORICHAL',
    },
    {
      code: '887',
      name: 'PANA PANA',
    },
    {
      code: '884',
      name: 'PUERTO COLOMBIA',
    },
    {
      code: '883',
      name: 'SAN FELIPE',
    },
  ],
  95: [
    {
      code: '15',
      name: 'CALAMAR',
    },
    {
      code: '25',
      name: 'EL RETORNO',
    },
    {
      code: '200',
      name: 'MIRAFLORES',
    },
    {
      code: '1',
      name: 'SAN JOSÉ DEL GUAVIARE',
    },
  ],
  97: [
    {
      code: '161',
      name: 'CARURU',
    },
    {
      code: '1',
      name: 'MITÚ',
    },
    {
      code: '511',
      name: 'PACOA',
    },
    {
      code: '777',
      name: 'PAPUNAUA',
    },
    {
      code: '666',
      name: 'TARAIRA',
    },
    {
      code: '889',
      name: 'YAVARATÉ',
    },
  ],
  99: [
    {
      code: '773',
      name: 'CUMARIBO',
    },
    {
      code: '524',
      name: 'LA PRIMAVERA',
    },
    {
      code: '1',
      name: 'PUERTO CARREÑO',
    },
    {
      code: '624',
      name: 'SANTA ROSALÍA',
    },
    {
      code: '999',
      name: 'OTROS',
    },
  ],
};

module.exports = {
  departments,
  cities,
};
