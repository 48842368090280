import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import QuoteIssuanceTable from 'components/dasboard_components/QuoteIssuanceTable';
import EmitProcessTable from 'components/dasboard_components/EmitProcessTable';
import QuoteIssuanceFilters from 'components/dasboard_components/QuoteIssuanceFilters';
import IssuedPoliciesTable from 'components/dasboard_components/IssuedPoliciesTable';
import NewQuote from 'components/NewQuote';
import { QuotesContext } from 'context/QuotesContext';
import { emitParser } from 'components/dasboard_components/utils';

import dashboardService from '../services/dashboard';

const isProdEnv = process.env.REACT_APP_IS_PROD === 'true';

const BoxShadow = styled.div`
  box-shadow: none;
  @media (min-width: 768px) {
    margin-bottom: 3px;
    box-shadow: 0 4px 5px -2px var(--gray-300);
  }
`;

const getComponent = (actualTab) =>
  ({
    quotation: QuoteIssuanceTable,
    emission: EmitProcessTable,
    issued: IssuedPoliciesTable,
  }[actualTab] || QuoteIssuanceTable);

const Dashboard = () => {
  const { quotes } = useContext(QuotesContext);
  const [issuedPolicies, setIssuedPolicies] = useState([]);
  const [emitProcesses, setEmitProcesses] = useState([]);
  const [actualTab, setActualTab] = useState('quotation');

  // eslint-disable-next-line camelcase
  const broker_id = JSON.parse(sessionStorage.getItem('info')).agente;

  const [filter, setFilter] = useState('');
  const Component = getComponent(actualTab);
  let tabs = [
    { name: 'quotation', label: 'Cotización', count: quotes.length },
    {
      name: 'emission',
      label: 'Proceso de emisión',
      count: emitProcesses.length,
    },
    { name: 'issued', label: 'Emitidos', count: issuedPolicies.length },
  ];

  if (isProdEnv) {
    tabs = [{ name: 'quotation', label: 'Cotización', count: quotes.length }];
  }

  const getEmitProcesses = () => {
    dashboardService
      .getProcesses({
        broker_id,
        status: 'EMISSION_IN_PROGRESS',
      })
      .then((response) => {
        const emitProcessesAux = emitParser(response.data.data).sort(
          (a, b) => moment(b.quote_date) - moment(a.quote_date)
        );
        setEmitProcesses(emitProcessesAux);
      });
  };

  const getIssuedPolicies = () => {
    dashboardService
      .getProcesses({
        broker_id,
        status: 'EMISSION_COMPLETE',
      })
      .then((response) => {
        const issuedPoliciesAux = emitParser(response.data.data).sort(
          (a, b) => moment(b.emit_date) - moment(a.emit_date)
        );
        setIssuedPolicies(issuedPoliciesAux);
      });
  };

  useEffect(() => {
    getEmitProcesses();
    getIssuedPolicies();
  }, []);

  const data = {
    emission: emitProcesses,
    quotation: quotes,
    issued: issuedPolicies,
  };

  return (
    <>
      <BoxShadow>
        <NewQuote />
        {/* <SarlaftUpload /> */}
        <QuoteIssuanceFilters
          tabs={tabs}
          setFilter={setFilter}
          actualTab={actualTab}
          onTabChange={setActualTab}
        />
      </BoxShadow>
      <Component data={data[actualTab]} filter={filter} />
    </>
  );
};

export default Dashboard;
