/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import FileDownload from 'js-file-download';
import pdfService from 'utils/helpers/pdfService';

import ButtonAction from './ButtonAction';
import ButtonNumber from './ButtonNumber';
import CustomTable from './CustomTable';
import useWindowDimensions from '../../utils/helpers/resolution';
import Loader from '../Loader';

import './dashboard.css';

const optionsTypesPDF = [
  'POLICY_PYMES',
  'ENDORSEMENT_RISK',
  'ENDORSEMENT_ELECTRONIC',
];

const IssuedPolicies = ({ filter, data }) => {

  const dimensions = useWindowDimensions();
  const [desktop, setDesktop] = useState(true);
  const [loading, setLoading] = useState(false);
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const pdfData = useSelector((state) => state.pdfQuote);
  const handleDownload = (item) => {
    // eslint-disable-next-line camelcase
    const { emission_id, compBrokerId } = item;
    const body = {
      compDate: `${emission_id.split('#')[0]}#${emission_id.split('#')[1]}`,
      compBrokerId,
      emission_id,
      pdfData,
      optionsTypesPDF,
      quoteNumber: item.quote_number,
    };
    const { token, ip } = sessionInfoObj;
    const headers = {
      token,
      ip,
      authorization: `Bearer ${sessionInfoObj.token}`,
      authorizedip: sessionInfoObj.ip,
    };
    setLoading(true);
    pdfService
      .callServiceEmit('/emit/createPdf', body, headers)
      .then((response) => {
        const { file, file_name } = response.data;
        FileDownload(Buffer.from(file, 'base64'), file_name);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useMemo(() => {
    const { width } = dimensions;

    if (width < 701) {
      setDesktop(false);
    } else {
      setDesktop(true);
    }
  }, [dimensions]);

  const columns = useMemo(
    () => [
      {
        Header: 'No. de Póliza',
        accessor: 'quote_number',
        sticky: !desktop,
        render: ({ row }) => (
          <div className="flex flex-col items-center pl-2">
            <div className="shadow-2xl md:hidden">
              <ButtonNumber onClick={() => handleDownload(row)}>
                ...
              </ButtonNumber>
            </div>
            <div>
              <p className="m-0 text-sm-size text-gray-600">
                {row.quote_number}
              </p>
            </div>
          </div>
        ),
      },
      {
        Header: 'Tomador',
        accessor: 'taker',
        render: ({ row }) => (
          <p className="text-sm-size text-gray-600">{row.taker}</p>
        ),
      },
      {
        Header: 'Identificación',
        accessor: 'document_number',
        render: ({ row }) => (
          <div>
            <p className="text-sm-size text-gray-600">{row.document_number}</p>
            <p className="text-xs-size text-gray-400">{row.document_type}</p>
          </div>
        ),
      },
      {
        Header: 'Fecha Emisión',
        accessor: 'emit_date',
        render: ({ row }) => (
          <p className="text-sm-size text-gray-600">
            {moment(row.emit_date).format('DD/MM/YYYY')}
          </p>
        ),
      },
      {
        Header: 'Inicio Vigencia',
        accessor: 'vigency_start_date',
        render: ({ row }) => (
          <p className="text-sm-size text-gray-600">
            {moment(row.vigency_start_date).format('DD/MM/YYYY')}
          </p>
        ),
      },
      {
        Header: 'Valor prima',
        accessor: 'prime_value',
        render: ({ row }) => (
          <p className="text-sm-size text-gray-600">
            {`$${new Intl.NumberFormat('de-DE').format(row.prime_value)}`}
          </p>
        ),
      },
      {
        Header: 'Accion',
        sorter: false,
        hide: !desktop,
        render: ({ row }) => (
          <div className="w-full">
            <ButtonAction onClick={() => handleDownload(row)}>
              Descargar PDF
            </ButtonAction>
          </div>
        ),
      },
    ],
    [desktop]
  );

  return (
    <div style={{ background: 'var(--gray-100)' }}>
      <div className="sm:px-6 mt-2 md:pt-4 md:px-8 lg:px-16 max-w-screen-xl mx-auto">
        <CustomTable columns={columns} data={data} filter={filter} />
      </div>
      {loading && <Loader />}
    </div>
  );
};

IssuedPolicies.propTypes = {
  filter: PropTypes.string.isRequired,
};

export default IssuedPolicies;
