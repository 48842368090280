import axios from 'axios';

const urlAuth = process.env.REACT_APP_URL_AUTH;

/**
 * Verifies if url starts with the base or adds the indicated path
 * @param {string} url
 */
const readUrl = (url = '') =>
  url.startsWith('http://') || url.startsWith('https://')
    ? url
    : `${urlAuth}/${url}`;

/**
 * Gets the app token related service retrieving the basic broker info confirm
 * @param {string} url
 * @param {Object} body
 * @param {Object} headers
 */

const getCognitoToken = async () => {
  try {
    const {
      REACT_APP_COGNITO_SERVICE_URL,
      REACT_APP_COGNITO_CLIENT_ID,
      REACT_APP_COGNITO_CLIENT_SECRET,
    } = process.env;
    const basicToken = Buffer.from(
      `${REACT_APP_COGNITO_CLIENT_ID}:${REACT_APP_COGNITO_CLIENT_SECRET}`
    ).toString('base64');
    const requestData = new URLSearchParams();
    requestData.append('grant_type', 'client_credentials');
    const { data } = await axios.post(
      `${REACT_APP_COGNITO_SERVICE_URL}/oauth2/token`,
      requestData,
      {
        headers: {
          Authorization: `Basic ${basicToken}`,
        },
      }
    );

    return data.access_token;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const tokenAPI = async (url = '', body = {}, headers = {}) => {
  const tokenCognito = await getCognitoToken();
  const tokenResponse = await axios.post(readUrl(url), body, {
    headers: {
      ...headers?.headers,
      AuthorizationCognito: `Bearer ${tokenCognito}`,
    },
  });
  return tokenResponse;
};

export default tokenAPI;
