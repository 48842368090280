/* eslint-disable no-restricted-syntax */
import CLAUSES from 'utils/constants/clauses';

const generateCluasesData = (
  draft,
  coverages,
  RC,
  business,
  automaticTransport,
  moneyTransport
) => {
  const requestClauses = [];
  let isVariableIndex = false;
  let isEdificio = false;
  coverages.forEach((coverage) => {
    const clause = CLAUSES.find(
      (item) =>
        item.codigoAmparo === coverage.codigoAmparo &&
        coverage.value !== '$0' &&
        coverage.value !== null
    );
    if (clause) requestClauses.push(clause);
  });
  const { risks } = draft;
  for (const risk of risks) {
    const { VariableIndex, ValoAsegur } = risk;
    if (
      VariableIndex.code &&
      VariableIndex.value &&
      VariableIndex.selected === '1'
    ) {
      isVariableIndex = true;
    }
    if ('VlaEdificio' in ValoAsegur) {
      isEdificio = true;
    }
  }
  // if selected variable index
  if (isVariableIndex) {
    const clause = CLAUSES.find(
      (item) => item.codigoAmparo === '8901' && item.items[0].codigo === 6127
    );
    requestClauses.push(clause);
  }
  // if building is selected
  if (isEdificio) {
    const clause = CLAUSES.find(
      (item) => item.codigoAmparo === '8901' && item.items[0].codigo === 8901
    );
    requestClauses.push(clause);
  }
  if (RC.selected === '1') {
    let clause = CLAUSES.find(
      (item) => item.codigoAmparo === '8884' && item.items[0].codigo === 0
    );
    requestClauses.push(clause);
    clause = CLAUSES.find(
      (item) =>
        item.codigoAmparo === '8884' &&
        (item.actividad === '' || item.actividad !== 0)
    );
    clause.actividad = business.answerCode;
    requestClauses.push(clause);
  }
  if (automaticTransport !== null) {
    const clause = CLAUSES.find((item) => item.codigoAmparo === '8894');
    requestClauses.push(clause);
  }
  if (moneyTransport !== 0) {
    const clause = CLAUSES.find((item) => item.codigoAmparo === '8899');
    requestClauses.push(clause);
  }
  return requestClauses;
};

export default generateCluasesData;
