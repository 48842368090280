const filterUnselectedCoverages = (json, draft) => {
  const toRemove = [];

  draft.risks.forEach((risk) => {
    if (risk.amparos)
      risk.amparos.forEach((coverage) => {
        let atLeastOneChildIsSelected = false;
        coverage.propiedadesAmparo.forEach((child) => {
          if (child.selected === 1) {
            atLeastOneChildIsSelected = true;
          } else {
            toRemove.push({ address: risk.DireccionEei, code: child.codigo });
          }
        });
        if (!atLeastOneChildIsSelected || coverage.selected === 0) {
          toRemove.push({
            address: risk.DireccionEei,
            code: coverage.codigoAmparo,
          });
        }
      });
  });

  json.poliza.riesgos.forEach((risk) => {
    const filterList = risk.amparos.filter((coverage) => {
      return !toRemove.some(
        (matchCov) =>
          matchCov.code === coverage.codigoAmparo &&
          matchCov.address === risk.direccion
      );
    });
    risk.amparos = filterList;
  });

  return json;
};

module.exports = {
  filterUnselectedCoverages,
};
