const CONST_SCV = {
  SCV_CHILDRENS: [
    {
      parentCode: '8862',
      code: 8863,
      desc: 'SCV Contenido',
      childrens: [8867, 8872],
    },
    {
      parentCode: '8862',
      code: 8864,
      desc: 'SCV Equipo electrónico',
      childrens: [8868, 8873],
    },
  ],
  CALC: [8872, 8873],
  SCV_CODE: 8862,
  LOCAL_MONEY_CODE: 8877,
};

export default CONST_SCV;
