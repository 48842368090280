import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * Default classes
 */
const defaultClasses =
  'w-full sm:w-auto my-2 sm:mx-4 font-bold rounded shadow-md text-base';

/**
 * Button style component
 */
const ButtonStyle = styled.button`
  background-color: ${(props) =>
    props.primary ? 'var(--teal)' : 'transparent'};
  color: ${(props) => (props.primary ? 'var(--liberty-blue)' : 'initial')};
  border: 1px solid
    ${(props) => (props.primary ? 'var(--teal)' : 'var(--liberty-blue)')};

  &:disabled {
    background-color: var(--gray-200);
    border: 1px solid var(--gray-200);
    color: var(--gray-300);
  }
  &:hover,
  &:active {
    outline: none;
  }
`;

/**
 * Button component
 * @param {*} props
 */
const Button = (props) => {
  const { primary, type, children, onClick, lg, disabled, className } = props;
  return (
    <ButtonStyle
      data-testid="custom-btn"
      type={type}
      className={`${defaultClasses} ${
        lg ? 'py-2 px-20' : 'py-2 px-4'
      } ${className}`}
      primary={primary}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </ButtonStyle>
  );
};

export default Button;

/**
 * PropsTypes
 */
Button.propTypes = {
  /**
   * Primary validation
   * @param {bool} primary
   */
  primary: PropTypes.bool,
  /**
   * type
   * @param {string} type
   */
  type: PropTypes.string,
  /**
   * Children component
   * @param {oneOfType} children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Action call
   * @param {func} onClick
   */
  onClick: PropTypes.func,
  /**
   * Large mode
   * @param {bool} lg
   */
  lg: PropTypes.bool,
  /**
   * Disabled
   * @param {bool} disabled
   */
  disabled: PropTypes.bool,
  /**
   * className
   * @param {bool} className
   */
  className: PropTypes.string,
};

/**
 * Default props
 */
Button.defaultProps = {
  /**
   * Primary validation
   * @param {bool} primary
   */
  primary: false,
  /**
   * type
   * @param {string} type
   */
  type: '',
  /**
   * Children component
   * @param {oneOfType} children
   */
  children: null,
  /**
   * Action call
   * @param {func} onClick
   */
  onClick: null,
  /**
   * Large mode
   * @param {bool} lg
   */
  lg: false,
  /**
   * Disabled
   * @param {bool} disabled
   */
  disabled: false,
  /**
   * class name
   * @param {bool} className
   */
  className: null,
};
