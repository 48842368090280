const TEMPLATE_SIG = {
  OBJECTID: 1,
  FID_T_Features: 1,
  X: 0.0,
  Y: 0.0,
  FID_AMENAZAS_COLOMBIA_TOTAL7_PYME: '0000',
  COD_AMENAZ_CR: 8,
  AMENAZA_VOLCANICA: 'SIG NO DISPONIBLE',
  COD_AMENAZA_VOLCANICA: 8,
  COD_AMENAZA_MOV_MASA: 8,
  AMENAZA_INCENDIO: 'SIG NO DISPONIBLE',
  COD_AMENAZA_INCENDIO: 8,
  AMENAZA_INUNDACION: 'SIG NO DISPONIBLE',
  AMENAZA_MOV_MASA: 'SIG NO DISPONIBLE',
  AMENAZA_AMIT: 'SIG NO DISPONIBLE',
  COD_AMENAZA_AMIT: 8,
  AMENAZA_TSUNAMI: 'SIG NO DISPONIBLE',
  COD_AMENAZA_TSUNAMI: 8,
  AMENAZA_SISMICA: 'SIG NO DISPONIBLE',
  COD_AMENAZA_SISMICA: 8,
  AMENAZA_IN_ESTRUC: 'SIG NO DISPONIBLE',
  COD_AMENAZA_IN_ESTRUC: 8,
  AMENAZA_EXPLOSION: 'SIG NO DISPONIBLE',
  COD_AMENAZA_EXPLOSION: 8,
  AMENAZA_HUMO: 'SIG NO DISPONIBLE',
  COD_AMENAZA_HUMO: 8,
  AMENAZA_HURACAN: 'SIG NO DISPONIBLE',
  COD_AMENAZA_HURACAN: 8,
  AMENAZA_GRANIZO: 'SIG NO DISPONIBLE',
  COD_AMENAZA_GRANIZO: 8,
  AMENAZA_VI_FUERTES: 'SIG NO DISPONIBLE',
  COD_AMENAZA_VI_FUERTES: 8,
  AMENAZA_IMPACTO: 'SIG NO DISPONIBLE',
  COD_AMENAZA_IMPACTO: 8,
  AMENAZA_HUR_CAL: 'SIG NO DISPONIBLE',
  COD_AMENAZA_HUR_CAL: 8,
  AMENAZA_HUR_SIM: 'SIG NO DISPONIBLE',
  COD_AMENAZA_HUR_SIM: 8,
  AMENAZA_RC: 'SIG NO DISPONIBLE',
  COD_AMENAZA_RC: 8,
  AMENAZA_DANO_AGUA: 'SIG NO DISPONIBLE',
  COD_AMENAZA_DANO_AGUA: 8,
  AMENAZA_DANOS_EE: 'SIG NO DISPONIBLE',
  COD_AMENAZA_DANOS_EE: 8,
  AMENAZA_ROTURA_MAQUINA: 'SIG NO DISPONIBLE',
  COD_AMENAZA_ROTURA_MAQUINA: 8,
  AMENAZA_MERC_REFRIGERADA: 'SIG NO DISPONIBLE',
  COD_AMENAZA_MERC_REFRIGERADA: 8,
  AMENAZA_TRANS_VALORES: 'SIG NO DISPONIBLE',
  COD_AMENAZA_TRANS_VALORES: 8,
  AMENAZA_TRANS_MERCANCIAS: 'SIG NO DISPONIBLE',
  COD_AMENAZA_TRANS_MERCANCIAS: 8,
  COD_AMENAZA_INUNDACION: 8,
  AMENAZA_CR: 'SIG NO DISPONIBLE',
};

export default TEMPLATE_SIG;
