import axios from 'axios';

const urlBase = process.env.REACT_APP_URL_PDF;
const urlBaseEmit = process.env.REACT_APP_URL_PDF_EMIT;
// const urlBaseEmit = 'http://localhost:3001/local/v1';
const readUrl = (url = '') =>
  url.startsWith('http://') || url.startsWith('https://')
    ? url
    : `${urlBase}/${url}`;

const callService = (url = '', body = {}, headers = {}) =>
  axios.post(readUrl(url), body, {
    headers: {
      Accept: 'application/json',
      ...headers,
      'Content-Type': 'application/json',
    },
  });

const callServiceEmit = (url = '', body = {}, headers = {}) =>
  axios.post(urlBaseEmit + url, body, {
    headers: {
      Accept: 'application/json',
      ...headers,
      'Content-Type': 'application/json',
    },
  });

export default {
  callService,
  callServiceEmit,
};
