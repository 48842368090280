import TEMPLATE_SIG from 'utils/constants/templateSIG';

const urlCoordinates = process.env.REACT_APP_URL_COORDINATES;
const initialState = {
  loading: false,
  data: {
    value: {
      features: [
        {
          attributes: { ...TEMPLATE_SIG },
        },
      ],
    },
  },
  drafts: [
    {
      risks: [
        {
          ...TEMPLATE_SIG,
        },
      ],
    },
  ],
  error: null,
};

const FETCH_COORDINATES_START = 'FETCH_COORDINATES_START';
const FETCH_COORDINATES_SUCCESS = 'FETCH_COORDINATES_SUCCESS';
const FETCH_COORDINATES_ERROR = 'FETCH_COORDINATES_ERROR';
const FETCH_COORDINATES_SUCCESS_WHIT_INDEX_RISK =
  'FETCH_COORDINATES_SUCCESS_WHIT_INDEX_RISK';
const DUPLICATE_DRAFT = 'DUPLICATE_DRAFT';
const CREATE_NEW_RISK = 'CREATE_NEW_RISK';
const UPDATE_RISK_DRAFT = 'UPDATE_RISK_DRAFT';
const SAVE_DUPLICATE_DRAFT = 'SAVE_DUPLICATE_DRAFT';
const UPDATE_COORDINATES_SIG_LAT_AND_LON = 'UPDATE_COORDINATES_SIG_LAT_AND_LON';
const MAX_ATTEMPTS_CONSUMER = 5;

export const fetchCoordinatesStart = () => ({
  type: FETCH_COORDINATES_START,
  error: false,
});

export const fetchCoordinatesSuccess = (payload) => ({
  type: FETCH_COORDINATES_SUCCESS,
  payload,
});
export const fetchCoordinatesSuccessWhitIndexRisk = (payload) => ({
  type: FETCH_COORDINATES_SUCCESS_WHIT_INDEX_RISK,
  payload,
});

export const fetchCoordinatesError = (payload) => ({
  type: FETCH_COORDINATES_ERROR,
  payload,
  error: true,
});
export const duplicateDraft = (payload) => ({
  type: DUPLICATE_DRAFT,
  payload,
});
export const creaeteNewRisk = (payload) => ({
  type: CREATE_NEW_RISK,
  payload,
});
export const updateRiskDraft = (payload) => ({
  type: UPDATE_RISK_DRAFT,
  payload,
});
export const SaveDuplicateDraft = (payload) => ({
  type: SAVE_DUPLICATE_DRAFT,
  payload,
});

export const updateCoordinatesSigLatAndLon = (payload) => ({
  type: UPDATE_COORDINATES_SIG_LAT_AND_LON,
  payload,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_COORDINATES_START: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case FETCH_COORDINATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case 'LOADING_QUOTE_SUCCESS': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'FALSE_LOADING_QUOTE_SUCCESS': {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_COORDINATES_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case FETCH_COORDINATES_SUCCESS_WHIT_INDEX_RISK: {
      const { res, draftIndex, riskIndex } = action.payload;
      const draft = state.drafts?.[draftIndex]
      const risk = state.drafts[draftIndex]?.risks[riskIndex];
      //  will create draft if it no exits in the store
      if (!draft) {
        state.drafts.push({
          risks: []
        })
      }
      //  will create risk if it no exits in the store
      if (!risk) {
        state.drafts[draftIndex].risks.push(res?.features[0]?.attributes);
        return state;
      }
      state.drafts[draftIndex].risks[riskIndex] = res?.features[0]?.attributes;
      return state;
    }
    case DUPLICATE_DRAFT: {
      const newRisk = action.payload.risks.map((x) => x?.featureSIG);
      state.drafts.push({ risks: newRisk });
      return state;
    }
    case CREATE_NEW_RISK: {
      const { draftIndex } = action.payload;
      state.drafts[draftIndex].risks.push({ ...TEMPLATE_SIG });
      return state;
    }
    case SAVE_DUPLICATE_DRAFT: {
      const { dataDraft } = action.payload;
      state.drafts = dataDraft;
      return state;
    }
    case UPDATE_RISK_DRAFT: {
      const { draftIndex, arr } = action.payload;
      state.drafts[draftIndex].risks = arr;
      return state;
    }

    case UPDATE_COORDINATES_SIG_LAT_AND_LON: {
      const { draftIndex, riskIndex, parameters } = action.payload;
      state.drafts[draftIndex].risks[riskIndex] = {
        ...TEMPLATE_SIG,
        X: parameters?.Lat,
        Y: parameters?.Lon,
      };
      state.data.value.features[0].attributes = {
        ...TEMPLATE_SIG,
        X: parameters?.Lat,
        Y: parameters?.Lon,
      };
      return state;
    }

    default: {
      return state;
    }
  }
}

export const fetchCoordinates = (payload, customHeaders) => async (
  dispatch,
  getState,
  { axios }
) => {
  const { draftIndex, riskIndex } = payload;
  dispatch(fetchCoordinatesStart());
  try {
    const response = await axios.post(`${urlCoordinates}/request`, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...customHeaders,
      },
    });
    let attemps = 0;
    const interval = setInterval(async () => {
      if (attemps < MAX_ATTEMPTS_CONSUMER) {
        const responseConsumer = await axios.post(
          `${urlCoordinates}/request`,
          {
            path: 'PymeCoordinatesConsumer',
            method: 'get',
            parameters: {
              jobId: response.data.jobId,
              f: 'pjson',
            },
          },
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              ...customHeaders,
            },
          }
        );
        if (responseConsumer.data.value) {

          dispatch(fetchCoordinatesSuccess(responseConsumer.data));
          dispatch(
            fetchCoordinatesSuccessWhitIndexRisk({
              res: responseConsumer.data.value,
              draftIndex,
              riskIndex,
            })
          );
          clearInterval(interval);
        }
        attemps += 1;
      } else {
        clearInterval(interval);
        dispatch(updateCoordinatesSigLatAndLon(payload));
        dispatch(fetchCoordinatesError('Max attemps to consumer coordenates'));
      }
    }, 2000);
  } catch (e) {
    dispatch(updateCoordinatesSigLatAndLon(payload));
    dispatch(fetchCoordinatesError(e));
  }
};
