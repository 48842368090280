import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// assets
import close from 'assets/icons/close.svg';
import 'assets/styles/scss/components/Modal.scss';

/**
 * ModalContainer
 */
const ModalContainer = styled.div`
  z-index: 1003;
  display: block;
  opacity: 1;
  top: 10%;
  position: absolute;
  left: 0;
  right: 0;
  background-color: white !important;
  padding: 0;
  width: ${(props) => (props.lg ? '90%' : 'fit-content')};
  margin: auto;
  border-radius: 2px;
  margin-bottom: 3rem;
`;

const Modal = ({
  showModal,
  setShowModal,
  showButtonClose,
  title,
  children,
  lg
}) => {
  return (
    <>
      {showModal && (
        <div className="modalContainer">
          <div className="modalContainer-background" />
          <div className="modalContainer-scroll">
            <ModalContainer
              className="modalContainer-content "
              data-testid="modal-test-id"
              lg={lg}
            >
              {/* header */}
              <div className="flex items-center justify-center p-5">
                {title && (
                  <h3 className="m-6 dark-blue text-center">{title}</h3>
                )}
                {showButtonClose && (
                  <button
                    type="button"
                    data-testid="close-modal"
                    className="absolute top-0 right-0 p-2 m-3 bg-transparent border-0 leading-none outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <img className="w-5" src={close} alt="close" />
                  </button>
                )}
              </div>
              {/* body */}
              {children}
            </ModalContainer>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;

Modal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func,
  showButtonClose: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  lg: PropTypes.bool,
};

Modal.defaultProps = {
  setShowModal: undefined,
  showButtonClose: true,
  title: null,
  lg: true,
};
