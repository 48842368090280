export const parseDataBeneficiary = (
  data,
  onlyBeneficiaries = false,
  indexBeneficiary = 0
) => {
  const beneficiariosArray = [];
  const dataRepresentativeLegal = {};
  const keys = Object.keys(data);

  if (onlyBeneficiaries) {
    const beneficiaryData = Object.keys(data)
      .filter((key) => key.includes(`Beneficiary${indexBeneficiary}`))
      .reduce((result, key) => {
        result[key] = data[key];
        return result;
      }, {});
    return beneficiaryData;
  }

  keys
    .filter((key) => /Beneficiary\d+/.test(key))
    .forEach((key) => {
      const index = RegExp(/(\d+)/).exec(key)[0]; // Extraer el número de índice
      if (!beneficiariosArray[index]) {
        beneficiariosArray[index] = {};
      }

      // Agregar las propiedades al objeto en el índice correspondiente
      const objetoBeneficiario = beneficiariosArray[index];
      objetoBeneficiario[`documentNumberBeneficiary${index}`] =
        data[`documentNumberBeneficiary${index}`];
      objetoBeneficiario[`documentTypeBeneficiary${index}`] =
        data[`documentTypeBeneficiary${index}`];
      const tipoid = data[`documentTypeBeneficiary${index}`];
      if (tipoid.code === '37') {
        objetoBeneficiario[`businessNameBeneficiary${index}`] =
          data[`businessNameBeneficiary${index}`];
      } else {
        objetoBeneficiario[`firstNameBeneficiary${index}`] =
          data[`firstNameBeneficiary${index}`];
        objetoBeneficiario[`secondNameBeneficiary${index}`] =
          data[`secondNameBeneficiary${index}`];
        objetoBeneficiario[`surnameBeneficiary${index}`] =
          data[`surnameBeneficiary${index}`];
        objetoBeneficiario[`secondSurnameBeneficiary${index}`] =
          data[`secondSurnameBeneficiary${index}`];
        objetoBeneficiario[`surnameBeneficiary${index}`] =
          data[`surnameBeneficiary${index}`];
      }
    });
  const beneficiaries = beneficiariosArray.filter(
    (beneficiario) => Object.keys(beneficiario).length > 0
  );
  Object.keys(data).forEach((key) => {
    if (key.toLowerCase().includes('Representative'.toLowerCase())) {
      dataRepresentativeLegal[key] = data[key];
    }
  });

  return { dataRepresentativeLegal, beneficiaries };
};

export const deleteDataBeneficiary = (data) => {
  const claves = Object.keys(data);

  // Filtra las claves que contienen "Beneficiary" y hacen match con el índice 4
  const clavesAEliminar = claves.filter(
    (clave) => clave.includes('Beneficiary') || clave.includes('Representative')
  );

  // Crea una copia del objeto original sin las claves a eliminar
  const nuevoObjeto = { ...data };
  clavesAEliminar.forEach((clave) => delete nuevoObjeto[clave]);
  return nuevoObjeto;
};


const requestDataBeneficiarie = (generalInfo, startDate) => {
  if (!generalInfo || typeof generalInfo !== 'object') {
    // Manejar el caso en que los datos no sean válidos
    return null;
  }

  const {
    documentType,
    documentNumber,
    businessName,
    city,
    department,
    address,
    phoneNumber,
    email,
    documentTypeLegalRepresentative,
    firstNameLegalRepresentative,
    secondNameLegalRepresentative,
    surnameLegalRepresentative,
    secondSurnameLegalRepresentative,
    documentNumberLegalRepresentative,
    isLegalRepresentativeBeneficiarie,
    beneficiarie,
  } = generalInfo;

  if (!documentType || !documentNumber) {
    // Manejar el caso en que las claves no estén presentes en los datos
    return null;
  }
  const beneficiariosActuales = beneficiarie;
  if (isLegalRepresentativeBeneficiarie.code === 'Si') {
    const nuevoBeneficiario = {
      documentTypeBeneficiary: documentTypeLegalRepresentative,
      documentNumberBeneficiary: documentNumberLegalRepresentative,
      firstNameBeneficiary: firstNameLegalRepresentative,
      secondNameBeneficiary: secondNameLegalRepresentative,
      surnameBeneficiary: surnameLegalRepresentative,
      secondSurnameBeneficiary: secondSurnameLegalRepresentative,
    };
    beneficiariosActuales.unshift(nuevoBeneficiario);
  }

  return {
    tipo_identificacion: documentType.code,
    numero_identificacion: documentNumber,
    solicitudes: {
      fecha_diligenciamiento: startDate,
      aplicacion: 'PYMELT',
    },
    cli_basico: {
      razon_social: businessName,
      digito_verificacion: '',
      par_tipoid: documentType.code,
      num_identificacion: documentNumber,
    },
    cli_domicilio: {
      telefono: '',
      dir_sin_estandar: address,
      ciudad: `00${city.code}`,
      departamento: department.code,
      pais: '170',
    },
    cli_general: {
      tel_celular: phoneNumber,
      par_act_economica: '',
      par_ciiu: '',
      email,
      par_tipo_empresa: '',
      desc_act_economica: '',
    },
    matriz_archivos: [{}],
    cli_representante_legal: {
      ciudad: '',
      departamento: '',
      dir_sin_estandar: '',
      email: '',
      fecha_nacimiento: '',
      indique_obligatributaria_otrop: '',
      obligatributaria_otropais: '',
      par_replegal_tipoid: documentTypeLegalRepresentative.code,
      replegal_apellido1: surnameLegalRepresentative,
      replegal_apellido2: secondSurnameLegalRepresentative,
      replegal_ciudad: '',
      replegal_depto: '',
      replegal_nacionalidad: '170',
      replegal_nombre1: firstNameLegalRepresentative,
      replegal_nombre2: secondNameLegalRepresentative,
      replegal_num_identificacion: documentNumberLegalRepresentative,
      replegal_pais: '',
      tel_celular: '',
      telefono: '',
    },
    cli_accionistas: beneficiariosActuales,
  };
};

export const parseRequestBeneficiary = (data, startDate) => {
  const indexOfTaker = data.findIndex((role) => role.isTaker === true);
  const takerInfo = data[indexOfTaker];

  return requestDataBeneficiarie(takerInfo.info, startDate);
};
