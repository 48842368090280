import React from 'react';
import Proptypes from 'prop-types';
import Button from '../../../basics/form/Button';
import ModalWrapper from '../../../basics/modal/ModalWrapper';

const ModalErrorValidateIdentity = ({
  showModal,
  onMyBussiness,
  setCloseModal,
}) => {
  return (
    <ModalWrapper size="xl" showButtonClose={false} showModal={showModal}>
      <div className="text-center bg-gray-100 py-12 px-6">
        <h2 className="text-2xl mb-3 dark-blue font-medium leading-tight sm:mx-24">
          Lo sentimos, la validación del rol no fue exitosa
        </h2>
        <p className="text-sm text-gray-600 mb-5">
          El rol no pudo ser validado. Por favor revisa los datos ingresados.
        </p>
        <p className="text-sm text-gray-600 mb-8 mx-0 md:mx-24">
          Nota: En caso que la información diligenciada esté correcta, consulta
          en oficina en linea
        </p>
        <div className="flex flex-col px-4 sm:flex-row  sm:gap-4 m-auto justify-center">
          <Button
            className="w-full sm:m-0 sm:w-48 md:m-0"
            onClick={onMyBussiness}
          >
            Mis Negocios
          </Button>
          <Button
            className="w-full sm:m-0 sm:w-48 md:m-0"
            primary
            onClick={() => setCloseModal(false)}
          >
            Continuar
          </Button>
        </div>
      </div>
      <div className="bg-gray-200 w-full px-12 py-4 sm:px-24 sm:py-6">
        <p className="text-gray-900 text-xs font-normal m-0 text-center">
          {`Para más información comunícate desde Bogotá a la línea `}
          <span className="medium-teal">307 7050</span>
          {` o desde cualquier lugar del país `}
          <span className="medium-teal">018000 113390</span>
        </p>
      </div>
    </ModalWrapper>
  );
};

ModalErrorValidateIdentity.propTypes = {
  showModal: Proptypes.bool.isRequired,
  onMyBussiness: Proptypes.func.isRequired,
  setCloseModal: Proptypes.func.isRequired,
};

export default ModalErrorValidateIdentity;
