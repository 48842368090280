import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';
import { styles, inputStyles } from './Calendar.styles';

const useStyles = makeStyles(styles);
const StyledInput = withStyles(inputStyles)(TextField);

const CustomTextField = ({
  onClick,
  fullWidth,
  value,
  disabled,
  placeholder,
}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.inputContainer, {
        [classes.fullWidth]: fullWidth,
      })}
    >
      <div className={clsx(classes.fullHeightCenter, classes.absoluteRight)}>
        <CalendarIcon className={classes.inputIcon} onClick={onClick} />
      </div>
      <StyledInput
        fullWidth={fullWidth}
        variant="outlined"
        onClick={onClick}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomTextField;

CustomTextField.propTypes = {
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
};

CustomTextField.defaultProps = {
  onClick: () => {
    // do nothing
  },
};
