/* eslint-disable no-empty */
/* eslint-disable array-callback-return */

const filterVariableIndex = (body) => {
  const { riesgos } = body.poliza;
  riesgos.map((risk) => {
    risk.amparos.map((item) => {
      if (
        item.codigoAmparo === '8901' ||
        item.codigoAmparo === '8906' ||
        item.codigoAmparo === '8922' ||
        item.codigoAmparo === '8941' ||
        item.codigoAmparo === '8946' ||
        item.codigoAmparo === '8951' ||
        item.codigoAmparo === '8858'
      ) {
      } else if (item.propiedadesAmparo) {
        item.propiedadesAmparo.map((itemTwo) => {
          if (itemTwo.codigo === '9739') {
            itemTwo.valor = '0';
          }
          if (itemTwo.codigo === '6124') {
            itemTwo.valor = '0';
          }
        });
      }
    });
  });
  return body;
};

module.exports = {
  filterVariableIndex,
};
