/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Modal from 'components/Modal';
import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import { useForm, Controller } from 'react-hook-form';
import { updateCoverage } from 'reducers/quoteInfo';
import { getValidations } from 'utils/helpers/reactFormValidations';
import documentPlus from 'assets/images/document-plus.svg';

const H1 = styled.h1`
  color: var(--liberty-blue);
`;

export default function RentExpense(props) {
  const {
    showModal,
    setShowModal,
    setSaveRentExpense,
    setMonthValue,
    setCanonValue,
    draftIndex,
    riskIndex,
    rentExpenses,
    code,
    monthValue,
    canonValue,
    lengthRentExpenses,
    monthSelect,
    setMonthSelect,
  } = props;
  const defaultValues = { lease: canonValue };
  if (monthValue > 0) {
    defaultValues.months = { value: monthValue };
  }
  const { errors, control, setValue, trigger, handleSubmit } = useForm({
    defaultValues,
    reValidateMode: 'onBlur',
  });
  const dispatch = useDispatch();
  const { minlength, maxlength } = lengthRentExpenses;
  const months = [];
  for (let i = Number(minlength); i <= Number(maxlength); i++) {
    months.push({ value: i });
  }
  const [oldMonth, setOldMonth] = useState({});
  const [oldCanon, setOldCanon] = useState('');
  useEffect(() => {
    setOldCanon(canonValue);
    setOldMonth(monthSelect);
  }, []);
  const onSubmit = (values) => {
    dispatch(
      updateCoverage({
        draftIndex,
        riskIndex,
        coverage: {
          codigoAmparo: code,
          selected: '1',
          propiedadesAmparo: [
            {
              codigoAmparoCap: rentExpenses[1][0][1][0].codigoAmparoCap,
              codigo: rentExpenses[1][0][1][0].codigo,
              id: rentExpenses[1][0][1][0].id,
              valor: values.lease,
            },
            {
              codigo: rentExpenses[1][0][1][1].codigo,
              codigoAmparoCap: rentExpenses[1][0][1][1].codigoAmparoCap,
              id: rentExpenses[1][0][1][1].id,
              valor: monthValue,
            },
          ],
        },
      })
    );
    setSaveRentExpense(true);
    return setShowModal(false);
  };
  const cancelSubmit = () => {
    setCanonValue(oldCanon);
    setMonthValue(oldMonth.value);
    setSaveRentExpense(false);
    return setShowModal(false);
  };

  const title = rentExpenses[0][0].garantia;
  return (
    <Modal showModal={showModal} setShowModal={setShowModal} lg={false}>
      <div className="flex flex-col m-auto">
        <img
          src={documentPlus}
          alt="Gastos de arrendamiento"
          className="my-4 h-16 md:h-auto w-96 m-auto"
        />
        <H1 className="text-center text-2xl md:text-3xl font-medium w-full md:w-2/3 md:m-auto px-1">
          {title}
        </H1>
        <p className="text-center text-base font-medium mt-8 mb-4">
          Para contratar esta cobertura necesitamos conocer:
        </p>
        <div className="w-full">
          <div className="md:w-1/2 m-auto px-4">
            <Controller
              name="lease"
              control={control}
              as={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Input
                  placeholder="Valor del canon de arrendamiento"
                  type="currency"
                  className="my-4"
                  errorMessage={errors.lease && errors.lease.message}
                />
              }
              onValueChange={(values) => {
                setTimeout(() => {
                  setValue(`lease`, values.value, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                  setCanonValue(values.value);
                }, 0);
              }}
              rules={getValidations({
                patternValue: '^[1-9][0-9]*$',
                patternMsjError: 'Solo se admiten valores positivos',
                obligatoriedad: '1',
              })}
            />
            <div className="flex space-x-1 grid-cols-2">
              <div className="w-2 mt-3">
                {' '}
                <span className="text-red-600	"> * </span>{' '}
              </div>
              <Controller
                key="controller-months"
                as={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Select
                    name="months"
                    placeholder="Número de meses"
                    options={months}
                    className="mt-4 mt-2"
                    onValueChange={(option) => {
                      setValue('months', option);
                      trigger('months');
                      setMonthValue(option.value);
                      setMonthSelect(option);
                    }}
                    optionKey="value"
                    optionLabel="value"
                    errorMessage={errors.months && errors.months.message}
                  />
                }
                name="months"
                control={control}
                defaultValue={null}
                rules={{
                  required: {
                    value: 'true',
                    message: 'Campo obligatorio',
                  },
                }}
                valueDefault={defaultValues.months}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-center my-4 px-4">
          <Button type="button" onClick={() => cancelSubmit()}>
            Cancelar
          </Button>
          <Button primary onClick={handleSubmit(onSubmit)}>
            Continuar
          </Button>
        </div>
      </div>
    </Modal>
  );
}

/**
 * PropsTypes
 */
RentExpense.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func,
  setSaveRentExpense: PropTypes.func,
  setMonthValue: PropTypes.func,
  setCanonValue: PropTypes.func,
  setMonthSelect: PropTypes.func,
  draftIndex: PropTypes.number.isRequired,
  riskIndex: PropTypes.number.isRequired,
  code: PropTypes.string,
  monthValue: PropTypes.string.isRequired,
  monthSelect: PropTypes.string.isRequired,
  canonValue: PropTypes.string.isRequired,
  rentExpenses: PropTypes.array.isRequired,
  lengthRentExpenses: PropTypes.array.isRequired,
};

/**
 * Default props
 */
RentExpense.defaultProps = {
  setShowModal: undefined,
  setSaveRentExpense: undefined,
  setMonthValue: PropTypes.func,
  setCanonValue: PropTypes.func,
  setMonthSelect: PropTypes.func,
  code: PropTypes.string,
};
