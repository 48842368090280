import { isObjectEmpty } from './object';
import verifyDigit from './stringValidations';

const validations = {
  maxLength: ({ maxlenght }) => {
    if (maxlenght) {
      return {
        value: parseInt(maxlenght, 10),
        message: `El tamaño maximo es de ${maxlenght} caracteres`,
      };
    }
    return null;
  },
  minLength: ({ minlenght }) => {
    if (minlenght) {
      return {
        value: parseInt(minlenght, 10),
        message: `El tamaño minimo es de ${minlenght} caracteres`,
      };
    }
    return null;
  },
  required: ({ obligatoriedad }) => {
    if (obligatoriedad === '1') {
      return 'El valor es requerido';
    }
    return null;
  },
  pattern: ({ patternValue, patternMsjError }) => {
    if (patternValue) {
      return {
        value: new RegExp(patternValue),
        message: patternMsjError || 'El valor ingresado es incorrecto',
      };
    }
    return null;
  },
  validate: (params) => {
    let options = {};
    const {
      validacionMayorQue,
      validacionMenorQue,
      validacionMenorIgualQue,
      validacionMenorIgualQueMsjError,
      validacionDigito,
      validacionObjetoVacio,
      validacionMinMax,
    } = params;

    if (validacionMayorQue || validacionMayorQue == 0) {
      options = {
        ...options,
        greaterThan: (value) =>
          parseInt(value, 10) > parseInt(validacionMayorQue, 10) ||
          `El valor debe ser mayor de ${validacionMayorQue}`,
      };
    }
    if (validacionMenorQue) {
      options = {
        ...options,
        lessThan: (value) =>
          parseInt(value, 10) < parseInt(validacionMenorQue, 10) ||
          `El valor debe ser menor de ${validacionMenorQue}`,
      };
    }
    if (validacionMenorIgualQue) {
      options = {
        ...options,
        lessEqualThan: (value) =>
          parseInt(value, 10) <= parseInt(validacionMenorIgualQue, 10) ||
          validacionMenorIgualQueMsjError ||
          `El valor debe ser menor o igual a ${validacionMenorIgualQue}`,
      };
    }
    if (validacionDigito) {
      options = {
        ...options,
        digitValidation: (value) =>
          verifyDigit(value) || `No es un número de identificación válida.`,
      };
    }
    if (validacionObjetoVacio) {
      options = {
        ...options,
        valiationEmptyObject: (value) =>
          !isObjectEmpty(value) || `El valor es requerido.`,
      };
    }

    if (validacionMinMax) {
      options = {
        ...options,
        valid: () =>
          validacionMinMax !== '0' && validacionMinMax !== null
            ? validacionMinMax
            : null,
      };
    }

    return options;
  },
};

// eslint-disable-next-line import/prefer-default-export
export const getValidations = (item) => {
  const options = {
    validate: undefined,
  };
  Object.entries(validations).forEach(([key]) =>
    Object.assign(options, { [key]: validations[key](item) })
  );
  return options;
};

export const toJSONValidation = (validationsArray) => {
  let validationJSON = null;
  validationsArray.map((rule) => {
    validationJSON = {
      ...validationJSON,
      [rule.tipoValidacion]:
        rule.tipoValidacion === 'validate'
          ? rule.value
          : {
            value:
              rule.tipoValidacion === 'pattern'
                ? new RegExp(rule.value.replace(/[\/]/g, '')) //eslint-disable-line
                : rule.value,
            message: rule.message,
          },
    };
    return null;
  });
  return validationJSON;
};

export const addRulesValidation = (
  id,
  getValues,
  setError,
  clearErrors,
  validationsRules = []
) => {
  switch (id) {
    case 'NumeroPisos':
      validationsRules.push({
        tipoValidacion: 'validate',
        value: (value) => {
          const validate =
            +value?.descripcion >= +getValues('NumPiso')?.descripcion ||
            getValues('NumeroPisos')?.descripcion === 'Mas de 30';
          if (!validate) {
            setError('NumPiso', {
              message:
                '# de piso no puede ser mayor al número de pisos del inmueble',
            });
            return 'El valor no puede ser inferior al piso informado en el riesgo';
          }
          clearErrors(id);
          clearErrors('NumPiso');
          return validate;
        },
      });
      return validationsRules;
    case 'NumPiso':
      validationsRules.push({
        tipoValidacion: 'validate',
        value: (value) => {
          const validate =
            +value?.descripcion <= +getValues('NumeroPisos')?.descripcion ||
            getValues('NumeroPisos')?.descripcion === 'Mas de 30';
          if (!validate && getValues('NumeroPisos')?.descripcion) {
            setError('NumeroPisos', {
              message:
                'El valor no puede ser inferior al piso informado en el riesgo',
            });
            return '# de piso no puede ser mayor al número de pisos del inmueble';
          }
          clearErrors(id);
          clearErrors('NumeroPisos');
          return validate;
        },
      });
      return validationsRules;
    case 'DineroLimite':
      validationsRules.push({
        tipoValidacion: 'validate',
        value: (value) => {
          if (value > +getValues('PresupuAnual')) {
            setError('DineroLimite', {
              message: 'El Límite no puede ser superior al Presupuesto Anual',
            });
            return 'El Límite no puede ser superior al Presupuesto Anual';
          }
          clearErrors(id);
          return null;
        },
      });
      return validationsRules;
    default:
      return validationsRules;
  }
};
