/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

const useStylesBootstrap = makeStyles({
  arrow: {
    color: '#1A1446',
  },
  tooltip: {
    backgroundColor: '#1E1045',
    fontSize: 13,
    padding: 20,
    maxWidth: 250,
    margin: 0,
  },
});

const NotificationTooltip = ({
  children,
  title,
  disableListeners,
  disableHoverListener,
  placement,
  ...moreProps
}) => {
  const classes = useStylesBootstrap();

  return (
    <Tooltip
      arrow
      disableFocusListener={disableListeners}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableListeners}
      classes={classes}
      placement={placement}
      title={title}
      {...moreProps}
    >
      {children}
    </Tooltip>
  );
};

NotificationTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  disableListeners: PropTypes.bool,
  disableHoverListener: PropTypes.bool,
  placement: PropTypes.string,
};

NotificationTooltip.defaultProps = {
  placement: 'bottom-start',
  disableHoverListener: false,
  disableListeners: true,
};

export default NotificationTooltip;
