import React from 'react';
import loader from 'assets/images/loader.svg';
import libertyArmYellow from 'assets/images/liberty-arm-yellow.svg';
import 'assets/styles/scss/components/Loader.scss';

const Loader = () => {
  return (
    <React.Fragment key="loader">
      <div className="overlay-container">
        <div className="overlay-backdrop" />
        <div className="overlay-wrapper">
          <img src={loader} alt="Cargando..." />
          <img
            src={libertyArmYellow}
            alt="Cargando..."
            className="position-absolute"
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Loader;
