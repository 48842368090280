/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import infoIcon from 'assets/icons/information.svg';
import fileAttachIcon from 'assets/icons/file-attach.svg';
import alertIcon from 'assets/icons/alert.svg';

import styled from 'styled-components';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import LinearProgressCustom from 'components/emit_components/basics/LinearProgressCustom/LinearProgressCustom';
import { FILE_TYPES, documentTypesString } from 'utils/constants/emit_constants';

import {
  downloadFile,
  uploadFile,
} from '../../../../services/emit/sarlaftFiles';

const DashedContainer = styled.div`
  background-color: white;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='2' stroke-dasharray='7' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
`;

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
  'application/pdf',
];
const MAX_FILE_SIZE_IN_MB = 4;
const MAX_FILE_SIZE = MAX_FILE_SIZE_IN_MB * 1048576;

const fileSchema = yup.object().shape({
  fileKey: yup.string().required(),
  file: yup
    .mixed()
    .test(
      'fileSize',
      `El documento que desea adjuntar supera el límite maximo permitido de ${MAX_FILE_SIZE_IN_MB}MB`,
      (value) => value[0].size < MAX_FILE_SIZE
    )
    .test(
      'fileFormat',
      'El documento que desea adjuntar no corresponde a un formato permitido: PDF, PNG, GIF o JPG',
      (value) => SUPPORTED_FORMATS.includes(value[0].type)
    ),
});

const FileUpload = ({
  documentType,
  documentNumber,
  value = '',
  onChange = () => {
    // do nothing
  },
  typeFile = FILE_TYPES.SARLAFT,
}) => {
  const {
    register,
    errors,
    formState: { isValid, isValidating },
    watch,
    trigger,
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    resolver: yupResolver(fileSchema),
  });

  const file = watch('file');

  const [fileState, setFileState] = useState({
    loadPercent: 0,
    success: false,
    fileName: value,
    countFiles: 1,
  });

  useEffect(() => {
    trigger();
  }, [file]);

  const timerRef = useRef();

  const increaseLoadPercent = () => {
    setFileState(({ loadPercent }) => ({
      ...fileState,
      loadPercent: Math.min(95, loadPercent + 5),
    }));
    if (fileState.loadPercent >= 100) clearInterval(timerRef.current);
  };

  useEffect(() => {
    timerRef.current = setInterval(increaseLoadPercent, 500);

    return () => clearInterval(timerRef.current);
  }, []);

  useEffect(() => {
    if (fileState.loading) {
      timerRef.current = setInterval(increaseLoadPercent, 500);
    } else {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, [fileState.loading]);

  const formErrorTop = errors.file && errors.file.type !== 'fileSize';

  useEffect(() => {
    if (errors.file) {
      setFileState({ ...fileState, loadPercent: formErrorTop ? 100 : 0 });
    } else if (file?.length > 0 && !isValidating) {
      setFileState({
        ...fileState,
        loadPercent: 1,
        loading: true,
        error: false,
        success: false,
      });

      const fileName = `${typeFile}-${
        documentTypesString[documentType]
      }-${documentNumber}-${fileState.countFiles}.${file[0].name
        .split('.')
        .pop()}`;

      uploadFile(file[0], fileName)
        .then((res) => {
          setFileState({
            ...fileState,
            fileName: res.data.fileKey,
            loadPercent: 100,
            success: true,
            loading: false,
            error: false,
            countFiles: fileState.countFiles + 1,
          });
          onChange(res.data.fileKey, typeFile);
        })
        .catch((err) => {
          console.error(err);
          setFileState({
            ...fileState,
            loadPercent: 100,
            error: 'No se pudo completar la carga del documento',
          });
        });
    }
  }, [errors.file, file, isValidating, isValid]);

  const handleDownloadFileClick = () => {
    downloadFile(fileState.fileName);
  };
  

  const isErrorFile =
    fileState.error || errors.file ? 'Intenta nuevamente. ' : '';
  return (
    <div
      className="flex flex-col items-center py-8 px-18 my-4"
      style={{ background: '#F0FBFC' }}
    >
      <img className="mb-2 h-5" alt="info-icon" src={infoIcon} />
      {typeFile === FILE_TYPES.CERL ? (
        <>
          {' '}
          <p
            className="text-2xl text-blue-500 m-0"
            style={{ color: '#51B5BE' }}
          >
            Es necesario el Certificado de Existencia y Representación Legal
          </p>
          <p className="m-0 mb-3 text-sm font-medium text-gray-600">
            Adjunta este certificado o un documento equivalente para las
            Estructuras sin Personería Jurídica (con vigencia no superior a 30
            días)
          </p>
        </>
      ) : (
        <>
          {' '}
          <p
            className="text-2xl text-blue-500 m-0"
            style={{ color: '#51B5BE' }}
          >
            Es necesario el {typeFile === FILE_TYPES.HABEAS_DATA ? 'HABEAS DATA' : 'SARLAFT'}
          </p>
          <p className="m-0 mb-3 text-sm font-medium text-gray-600">
            Adjunta el {typeFile === FILE_TYPES.HABEAS_DATA ? 'Habeas data' : 'Sarlaft '}{' '}
            diligenciado
          </p>
        </>
      )}

      <DashedContainer className="w-full lg:w-8/12">
        <div className="relative py-5 px-32 ">
          <input
            type="file"
            accept=".gif,.jpg,.jpeg,.png,.pdf"
            name="file"
            ref={register}
            style={{
              opacity: 0,
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              height: '100%',
              width: '100%',
              cursor: 'pointer',
            }}
          />
          <div className="flex justify-center items-center gap-2 mb-2">
            <img
              src={fileAttachIcon}
              alt="fileAttach"
              className={fileState.loadPercent > 0 ? 'w-12 h-12' : 'w-20 h-20'}
            />
            {fileState.loadPercent > 0 && (
              <div className="w-10/12 lg:w-8/12">
                <div
                  style={{
                    display: 'grid',
                    gridTemplateRows: '15px 10px 1fr',
                  }}
                >
                  <div />
                  <LinearProgressCustom
                    value={fileState.loadPercent}
                    error={fileState.error || formErrorTop}
                    loading={fileState.loading}
                    success={fileState.success}
                  />
                  <div className="flex items-center gap-2 mt-1">
                    {(fileState.error || formErrorTop) && (
                      <>
                        <img className="h-4" alt="info-icon" src={alertIcon} />
                        <p className="text-red-600 text-sm leading-3">
                          {fileState.error || errors?.file?.message}
                        </p>
                      </>
                    )}

                    {fileState.success && !(fileState.error || formErrorTop) && (
                      <p style={{ color: '#03AC63' }} className="text-sm">
                        El archivo se ha adjuntado con éxito
                      </p>
                    )}

                    {fileState.loading &&
                      !(fileState.error || formErrorTop) && (
                        <p className="text-sm">Cargando...</p>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>

          {fileState.loading ? (
            <div className="h-4" />
          ) : (
            <p className="text-center text-md font-medium ">
              {isErrorFile}
              Arrastra archivos para adjuntar o
              <span style={{ color: '#64A8B7' }}> buscar</span>
            </p>
          )}

          <p className="text-center text-sm">
            <b>{`Peso máximo ${MAX_FILE_SIZE_IN_MB}MB.`}</b>
            Formatos permitidos: .PDF, .PNG, .GIF, .JPG
          </p>
        </div>
      </DashedContainer>
      {errors?.file?.type === 'fileSize' && (
        <p className="text-red-600 text-sm leading-3 mt-2">
          {errors?.file?.message}
        </p>
      )}
      {fileState.fileName && (
        <p
          className="text-sm my-2 font-medium"
          onClick={handleDownloadFileClick}
          style={{ cursor: 'pointer', color: '#64A8B7' }}
        >
          {fileState.fileName || ''}
        </p>
      )}
    </div>
  );
};

FileUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  documentType: PropTypes.string.isRequired,
  documentNumber: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  typeFile: PropTypes.string.isRequired,
};

export default FileUpload;
