import axios from 'axios';
import FileDownload from 'js-file-download';
import { getSessionHeaders } from 'utils/helpers/sessionData';

const { REACT_APP_URL_EMIT } = process.env;
const BASE_URL = `${REACT_APP_URL_EMIT}/sarlaft`;

export const downloadFile = (fileKey) => {
  return axios({
    url: `${BASE_URL}/get-file/${fileKey}`,
    method: 'GET',
    headers: getSessionHeaders(),
  }).then((response) => {
    FileDownload(Buffer.from(response.data.file, 'base64'), fileKey);
  });
};

export const uploadFile = (file, fileName) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('fileKey', fileName || file.name);
  const sessionHeaders = getSessionHeaders();

  return axios.post(`${BASE_URL}/upload`, formData, {
    headers: {
      // eslint-disable-next-line no-underscore-dangle
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
      Accept: '*/*',
      ...sessionHeaders,
    },
  });
};

export default {
  downloadFile,
  uploadFile,
};
