export const URL_API_PERSON = process.env.REACT_APP_URL_PERSON;

// ids person type
export const ID_PERSON_LEGAL = 2;

// ids contact type
export const ID_TYPE_CONTACT_EMAIL = 3;

// ids inputs
export const ID_INPUT_EMAIL = 'Nemial';
export const ID_INPUT_FIRST_NAME = 'PrimNombre';
export const ID_INPUT_FIRST_LASTNAME = 'PrimApellido';
export const ID_INPUT_BUSINESS_NAME = 'RazSocial';
export const ID_INPUT_IDENTIFICATION_NUMBER = 'NumIdentificacion';
