import styled from 'styled-components';

export default styled.button`
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  justify-content: center;
  padding: 0;
  background-color: var(--gray-100);
  display: flex;
  outline: none;
  border-radius: 100px;
  font-weight: 1000;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 14px;

  :disabled {
    background: var(--gray-300);
    cursor: default;
  }
`;
