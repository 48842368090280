/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { requestGetEmitInfo } from 'emit_store/actions/emitInfo';
import { useHistory } from 'react-router-dom';
import { isObjectEmpty } from 'utils/helpers/object';
import LeftTimeLabel from './LeftTimeLabel';
import CustomTable from './CustomTable';
import ButtonAction from './ButtonAction';
import ButtonNumber from './ButtonNumber';
import useWindowDimensions from '../../utils/helpers/resolution';
import './dashboard.css';

const EmitProcessTable = ({ filter, data }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const dimensions = useWindowDimensions();
  const [desktop, setDesktop] = useState(true);

  useMemo(() => {
    const { width } = dimensions;

    if (width < 701) {
      setDesktop(false);
    } else {
      setDesktop(true);
    }
  }, [dimensions]);
  const emitInfo = useSelector((state) => state.emitInfo);

  // eslint-disable-next-line camelcase
  const handleRetaqueEmit = (emission_id) => {
    dispatch(requestGetEmitInfo(emission_id));
  };

  useEffect(() => {
    if (!isObjectEmpty(emitInfo.data)) {
      history.push('/emit');
    }
  }, [emitInfo.data]);

  const columns = useMemo(
    () => [
      {
        Header: 'No. de solicitud',
        accessor: 'quote_number',
        sticky: !desktop,
        width: 150,
        render: ({ row }) => (
          <div className="flex gap-1 items-center pl-2">
            <div className="shadow-2xl md:hidden">
              <ButtonNumber
                onClick={() => handleRetaqueEmit(row.emission_id)}
                disabled={row.isSqsInProgress}
              >
                ...
              </ButtonNumber>
            </div>

            <div>
              <p className="m-0 text-sm-size text-gray-600">
                {row.quote_number}
              </p>
            </div>
          </div>
        ),
      },
      {
        Header: 'Fecha',
        accessor: 'quote_date_str',
        render: ({ row }) => (
          <div>
            <p className="text-center text-sm-size text-gray-600">
              {row.quote_date_str}
            </p>
          </div>
        ),
      },
      {
        Header: 'Tomador',
        accessor: 'taker',
        render: ({ row }) => (
          <div>
            <p className="text-sm-size text-gray-600">{row.taker}</p>
          </div>
        ),
      },
      {
        Header: 'Identificación',
        accessor: 'document_number',
        render: ({ row }) => (
          <div>
            <p className="sm:text-center md:text-left text-sm text-gray-600">
              {row.document_number}
            </p>
            <p className="text-gray-400 sm:text-center md:text-left text-xs-size">
              {row.document_type}
            </p>
          </div>
        ),
      },

      {
        Header: 'Vence en',
        accessor: 'substractDate',
        render: ({ row }) => (
          <p className="text-left text-sm-size sm:text-center">
            {row.substractDate && (
              <LeftTimeLabel leftTime={parseInt(row.substractDate, 10)} />
            )}
          </p>
        ),
      },
      {
        Header: 'Estado',
        sorter: false,
        render: ({ row }) => (
          <p
            className="text-sm-size text-left sm:text-center"
            style={{ color: '#343741' }}
          >
            {row.status === 'EMISSION_IN_PROGRESS' ? 'Proceso de emisión' : ''}
          </p>
        ),
      },
      {
        Header: 'Acción',
        sorter: false,
        hide: !desktop,
        render: ({ row }) => (
          <div>
            <ButtonAction
              onClick={() => handleRetaqueEmit(row.emission_id)}
              disabled={row.isSqsInProgress}
            >
              Retomar
            </ButtonAction>
          </div>
        ),
      },
    ],
    [desktop]
  );

  return (
    <div style={{ background: 'var(--gray-100)' }}>
      <div className="sm:px-6 pt-6 md:px-8 lg:px-16 max-w-screen-xl mx-auto">
        <CustomTable
          columns={columns}
          data={data}
          filter={filter}
          desktop={desktop}
        />
      </div>
    </div>
  );
};

EmitProcessTable.propTypes = {
  filter: PropTypes.string.isRequired,
};

export default EmitProcessTable;
