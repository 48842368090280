export const OPENL_CONFIG = {
  method: 'post',
  path: '/pyme-rule/newBusiness',
  parameters: {
    productoCat: '10024',
    modalidadCat: '341',
    paisCat: '1',
  },
};

export const INFO_REQUEST = {
  requestID: '12345',
  fecha: new Date().toISOString(),
  aplicacionCliente: 'pyme-lowtouch',
  terminal: '1',
  ip: '1.1.1.1',
};

export const HEADERS_JSON = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const HEADERS_JSON_NOCACHE = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
};

export const OPENL_CAPITAL = {
  method: 'post',
  path: '/newBusinessCapital',
  parameters: {
    poliza: {
      capitalMaximo: 5000000000,
      riesgos: [
        {
          amparos: [
            {
              codigoAmparo: '8900',
              propiedadesAmparo: [
                {
                  codigo: '8901',
                  valor: 500000000,
                },
                {
                  codigo: '8902',
                  valor: 500000000,
                },
                {
                  codigo: '8903',
                  valor: 500000000,
                },
                {
                  codigo: '8904',
                  valor: 10000000,
                },
              ],
              valorAsegurado: 0,
            },
            {
              codigoAmparo: '8905',
              propiedadesAmparo: [
                {
                  codigo: '8906',
                  valor: 500000000,
                },
                {
                  codigo: '8907',
                  valor: 500000000,
                },
                {
                  codigo: '8908',
                  valor: 1000000000,
                },
                {
                  codigo: '8910',
                  valor: 10000000,
                },
              ],
              valorAsegurado: 0,
            },
            {
              codigoAmparo: '8921',
              propiedadesAmparo: [
                {
                  codigo: '8922',
                  valor: 500000000,
                },
                {
                  codigo: '8923',
                  valor: 500000000,
                },
                {
                  codigo: '8924',
                  valor: 100000000,
                },
                {
                  codigo: '8925',
                  valor: 10000000,
                },
              ],
              valorAsegurado: 0,
            },
            {
              codigoAmparo: '8940',
              propiedadesAmparo: [
                {
                  codigo: '8941',
                  valor: 500000000,
                },
                {
                  codigo: '8942',
                  valor: 500000000,
                },
                {
                  codigo: '8943',
                  valor: 500000000,
                },
                {
                  codigo: '8944',
                  valor: 10000000,
                },
              ],
              valorAsegurado: 0,
            },
            {
              codigoAmparo: '8945',
              propiedadesAmparo: [
                {
                  codigo: '8946',
                  valor: 500000000,
                },
                {
                  codigo: '8947',
                  valor: 500000000,
                },
                {
                  codigo: '8948',
                  valor: 1000000000,
                },
                {
                  codigo: '8949',
                  valor: 10000000,
                },
              ],
              valorAsegurado: 0,
            },
            {
              codigoAmparo: '8950',
              propiedadesAmparo: [
                {
                  codigo: '8951',
                  valor: 500000000,
                },
                {
                  codigo: '8952',
                  valor: 500000000,
                },
                {
                  codigo: '8953',
                  valor: 100000000,
                },
                {
                  codigo: '8954',
                  valor: 10000000,
                },
              ],
              valorAsegurado: 0,
            },
            {
              codigoAmparo: '8857',
              propiedadesAmparo: [
                {
                  codigo: '8858',
                  valor: 500000000,
                },
                {
                  codigo: '8859',
                  valor: 500000000,
                },
                {
                  codigo: '8860',
                  valor: 500000000,
                },
                {
                  codigo: '8861',
                  valor: 10000000,
                },
              ],
              valorAsegurado: 0,
            },
            {
              codigoAmparo: '8862',
              propiedadesAmparo: [
                {
                  codigo: '8863',
                  valor: 0,
                },
                {
                  codigo: '8864',
                  valor: 0,
                },
                {
                  codigo: '8865',
                  valor: 0,
                },
              ],
            },
            {
              codigoAmparo: '8866',
              propiedadesAmparo: [
                {
                  codigo: '8867',
                  valor: 500000000,
                },
                {
                  codigo: '8868',
                  valor: 100000000,
                },
              ],
            },
            {
              codigoAmparo: '8869',
              propiedadesAmparo: [
                {
                  codigo: '8870',
                  valor: 500000000,
                },
              ],
            },
            {
              codigoAmparo: '8871',
              propiedadesAmparo: [
                {
                  codigo: '8872',
                  valor: 50000000,
                },
                {
                  codigo: '8873',
                  valor: 100000000,
                },
              ],
            },
            {
              codigoAmparo: '8874',
              propiedadesAmparo: [
                {
                  codigo: '8875',
                  valor: 10000000,
                },
              ],
            },
            {
              codigoAmparo: '8876',
              propiedadesAmparo: [
                {
                  codigo: '8026',
                  valor: 1500000,
                },
                {
                  codigo: '8027',
                  valor: 20,
                },
              ],
            },
            {
              codigoAmparo: '8877',
            },
            {
              codigoAmparo: '8879',
              propiedadesAmparo: [
                {
                  codigo: '8004',
                  valor: 50000000,
                },
                {
                  codigo: '8003',
                  valor: 10,
                },
              ],
            },
            {
              codigoAmparo: '8880',
              propiedadesAmparo: [
                {
                  codigo: '8005',
                  valor: 5000000,
                },
                {
                  codigo: '8006',
                  valor: 2,
                },
              ],
            },
            {
              codigoAmparo: '8881',
              propiedadesAmparo: [
                {
                  codigo: '8005',
                  valor: 5000000,
                },
                {
                  codigo: '8006',
                  valor: 3,
                },
              ],
            },
            {
              codigoAmparo: '8884',
              valorRiesgo: 10000000,
            },
            {
              codigoAmparo: '8894',
              propiedadesAmparo: [
                {
                  codigo: '7999',
                  valor: 4,
                },
                {
                  codigo: '7998',
                  valor: 1000000,
                },
              ],
            },
            {
              codigoAmparo: '8899',
              propiedadesAmparo: [
                {
                  codigo: '8001',
                  valor: 1500000,
                },
                {
                  codigo: '8002',
                  valor: 10,
                },
              ],
            },
          ],
        },
      ],
    },
  },
};
