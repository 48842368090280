import React from 'react';
import PropTypes from 'prop-types';
import restrictedUserIcon from 'assets/icons/restricted-user.svg';
import styled from 'styled-components';
import Button from 'components/Button';

/**
 * ContainerIcon
 */
const ContainerIcon = styled.div`
  width: 134px;
  height: 134px;
  padding: 1rem;
  margin: 1rem;
  border-radius: 4rem;
  background-color: var(--light-teal);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function RestrictedPerson({ setShowModal, finishProcess }) {
  return (
    <div>
      <div className="flex flex-col space-y-8 items-center px-8 sm:px-16 pb-8">
        <h1 className="mt-4 font-bold text-center dark-blue">Lo sentimos</h1>
        <p className="text-center">
          La identificación que ingresaste no nos permite continuar con el
          proceso
        </p>
        <ContainerIcon>
          <img src={restrictedUserIcon} alt="Restricted User Icon" />
        </ContainerIcon>
        <div className="sm:self-center sm:flex-row flex w-full flex-col justify-center">
          <Button className="sm:px-10" onClick={finishProcess}>
            Terminar
          </Button>

          <Button
            className="sm:px-12"
            primary
            onClick={() => setShowModal(false)}
          >
            Ajustar
          </Button>
        </div>
      </div>
      <div className="bg-gray-100 px-10 sm:px-16 py-8 flex justify-center">
        <p className="max-w-md text-center text-xs">
          Para más información comunícate desde Bogotá a la línea
          <span className="mx-1 medium-teal">307 7050</span>
          <span>o desde cualquier lugar del país</span>
          <span className="ml-1 medium-teal">018000 113390</span>
        </p>
      </div>
    </div>
  );
}

RestrictedPerson.propTypes = {
  setShowModal: PropTypes.func,
  finishProcess: PropTypes.func,
};

RestrictedPerson.defaultProps = {
  setShowModal: undefined,
  finishProcess: undefined,
};
