/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  BeneficiaryBuildingSchema,
  BeneficiaryMachinerySchema,
  NaturalPersonSchema,
  LegalPersonSchema,
  LegalPersonSchemaWithBeneficiaries
} from './shemaValidations';
import Card from './Card';
import deleteIcon from '../../../../assets/icons/delete.svg';
import roleUser from '../../../../assets/icons/role-user.svg';
import alert from '../../../../assets/icons/alert.svg';

import './RoleItem.scss';
import RolesChecks from '../../collections/cards/RolesChecks';
import NotificationTooltip from '../NotificationTooltip/NotificationTooltip';

const RoleItem = ({
  role,
  roles,
  setSelectCard,
  setDeselectCard,
  setUpdateRoleObject,
  handleValidateIdentity,
  isMaximumTakers,
  isMaximumInsured,
  isMaximumBeneficiaries,
}) => {
  const { exceptionSarlaft } = useSelector(
    (state) => state.emitInfo.data?.quote_data
  );
  const handleSelectCard = () => {
    setSelectCard(role.idCard);
  };

  const handleDeselectCard = (e) => {
    e.stopPropagation();
    setDeselectCard(role.idCard);
  };

  const getAcronym = (documentType) => {
    switch (documentType) {
      case '33':
        return 'CE: ';
      case '34':
        return 'TI: ';
      case '35':
        return 'RC: ';
      case '36':
        return 'CC: ';
      case '38':
        return 'NUIP: ';
      case '40':
        return 'P: ';
      case '44':
        return 'CD: ';
      case '48':
        return 'PPT: ';
      default:
        return 'CC: ';
    }
  };

  useEffect(() => {
    switch (role.type) {
      case 'taker':
      case 'insured':
      case 'beneficiary':
        if (role.info.documentType?.code === '37') {
          if (role.IsNewPersonIaxis) {
            LegalPersonSchema.isValid(role.info).then((valid) => {
              setUpdateRoleObject(role.idCard, 'isCompleteInfo', valid);
            });
          } else {
            LegalPersonSchemaWithBeneficiaries.isValid(role.info).then((valid) => {
              setUpdateRoleObject(role.idCard, 'isCompleteInfo', valid);
            });
          }

        } else {
          NaturalPersonSchema.isValid(role.info).then((valid) => {
            setUpdateRoleObject(role.idCard, 'isCompleteInfo', valid);
          });
        }
        break;
      case 'beneficiaryBuilding':
        BeneficiaryBuildingSchema.isValid(role.info).then((valid) => {
          setUpdateRoleObject(role.idCard, 'isCompleteInfo', valid);
        });
        break;
      case 'beneficiaryMachinery':
        BeneficiaryMachinerySchema.isValid(role.info).then((valid) => {
          setUpdateRoleObject(role.idCard, 'isCompleteInfo', valid);
        });
        break;
      default:
        break;
    }
  }, [role.info]);

  const showNIT =
    role.info.documentType?.code === '37' ||
    role.type === 'beneficiaryBuilding' ||
    role.type === 'beneficiaryMachinery';

  const [isInsuredAlreadyExists, setIsInsuredAlreadyExists] = useState(false);
  const [isBeneficiaryAlreadyExists, setIsBeneficiaryAlreadyExists] = useState(
    false
  );

  useEffect(() => {
    const insuredRole = roles.filter(
      (roleItem) => roleItem.isInsured && roleItem.idCard !== role.idCard
    );
    const beneficiaryRoles = roles.filter(
      (roleItem) => roleItem.isBeneficiary && roleItem.idCard !== role.idCard
    );

    setIsInsuredAlreadyExists(
      insuredRole.some(
        (roleItem) => roleItem.info.documentNumber === role.info.documentNumber
      )
    );
    setIsBeneficiaryAlreadyExists(
      beneficiaryRoles.some(
        (roleItem) => roleItem.info.documentNumber === role.info.documentNumber
      )
    );
  }, [roles]);

  const isRequiredSarlaft =
    role?.isTaker && !exceptionSarlaft && !role?.isSarlaft;

  const isRequiredCertificate = role?.isTaker && !role?.IsNewPersonIaxis && !role?.isCertificateFile && role.info.documentType?.code === '37';

  const isRequiredHabeasData =
    (role?.isTaker &&
      exceptionSarlaft &&
      !role?.isHabeasData &&
      !role?.isSarlaft) && (!role.hasHabeasData || !exceptionSarlaft);

  return (
    role.isSelectCard && (
      <Card
        isAlert={
          !role.isCompleteInfo ||
          role.isNotValidRole ||
          isRequiredSarlaft ||
          isRequiredHabeasData
        }
      >
        <div className="flex justify-around flex-col align-middle min-w-220 h-full p-3 bg-gray-100">
          {(role.type === 'taker' ||
            role.type === 'insured' ||
            role.type === 'beneficiary') && (
              <div className="flex h-full justify-evenly mb-3">
                <RolesChecks
                  role={role}
                  setToggleCheck={setUpdateRoleObject}
                  handleValidateIdentity={handleValidateIdentity}
                  isMaximumTakers={isMaximumTakers}
                  isMaximumInsured={isMaximumInsured}
                  isMaximumBeneficiaries={isMaximumBeneficiaries}
                  isInsuredAlreadyExists={isInsuredAlreadyExists}
                  isBeneficiaryAlreadyExists={isBeneficiaryAlreadyExists}
                />
              </div>
            )}
          {role.type === 'beneficiaryBuilding' && (
            <p className="text-xs">Beneficiario Oneroso del Edificio</p>
          )}
          {role.type === 'beneficiaryMachinery' && (
            <p className="text-xs">
              Beneficiario Oneroso para Equipo Electrónico - Maquinaria
            </p>
          )}
          <div
            onClick={handleSelectCard}
            role="presentation"
            className="flex justify-between items-center cursor-pointer"
          >
            <img className="w-12" src={roleUser} alt="" />
            <div className="text-left px-2 w-3/5">
              {showNIT ? (
                <>
                  <p className="text-xxs">Razón social</p>
                  <p className="text-base font-medium truncate">
                    {role.info.businessName}
                  </p>
                  <p className="text-xxs">
                    {`${'NIT: '}`}
                    {role.info.documentNumber}
                  </p>
                </>
              ) : (
                <>
                  <p className="text-xxs">Nombre y apellido</p>
                  <p className="text-base font-medium truncate">
                    {role.info.firstName} {role.info.surname}
                  </p>
                  <p className="text-xxs">
                    {getAcronym(role.info.documentType?.code)}
                    {role.info.documentNumber}
                  </p>
                </>
              )}
            </div>
            <div className="self-end flex">
              {!role.isCompleteInfo && (
                <NotificationTooltip
                  disableListeners
                  placement="bottom-end"
                  title="Campos pendientes por completar"
                >
                  <img className="w-4 mr-2" src={alert} alt="" />
                </NotificationTooltip>
              )}
              {role.isNotValidRole && (
                <NotificationTooltip
                  disableListeners
                  placement="bottom-end"
                  title="Rol sin verificación de identidad"
                >
                  <img className="w-4 mr-2" src={alert} alt="" />
                </NotificationTooltip>
              )}
              {(isRequiredSarlaft || isRequiredHabeasData) && (
                <NotificationTooltip
                  disableListeners
                  placement="bottom-end"
                  title={`Rol sin ${isRequiredHabeasData ? 'habeas data' : 'sarlaft'
                    }`}
                >
                  <img className="w-4 mr-2" src={alert} alt="" />
                </NotificationTooltip>
              )}
              {isRequiredCertificate && (
                <NotificationTooltip
                  disableListeners
                  placement="bottom-end"
                  title="Rol sin certificado de existencia y representación legal"
                >
                  <img className="w-4 mr-2" src={alert} alt="" />
                </NotificationTooltip>
              )}
              <button
                type="button"
                className="outline-none focus:outline-none"
                onClick={(e) => handleDeselectCard(e)}
              >
                <img className="w-3" src={deleteIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
      </Card>
    )
  );
};

RoleItem.propTypes = {
  role: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  setSelectCard: PropTypes.func.isRequired,
  setDeselectCard: PropTypes.func.isRequired,
  setUpdateRoleObject: PropTypes.func.isRequired,
  isMaximumTakers: PropTypes.bool.isRequired,
  isMaximumInsured: PropTypes.bool.isRequired,
  isMaximumBeneficiaries: PropTypes.bool.isRequired,
};

export default RoleItem;
