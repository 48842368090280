const initialState = {
    index: 0,
};

export const updateTabQuote = (payload) => ({
    type: 'UPDATE_QUOTE_TAB',
    payload,
});

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_QUOTE_TAB':
            {
                return {
                    ...state,
                    ...action.payload,
                };
            }
        default:
            {
                return state;
            }
    }
}