import React from 'react';
import PropTypes from 'prop-types';
import RadioButtonItem from 'components/RadioButtonItem';
import styled from 'styled-components';

const ItemContainer = styled.div`
  border: 1px solid var(--gray-300);
  with: calc(100% - 31px);
  margin: 8px 0px;
  position: relative;
  height: 51px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  width: 100%;
  cursor: pointer;

  &.selected {
    border: 1px solid var(--teal);
    background-color: var(--light-teal);
  }

  &.error {
    border-color: red;
  }

  @media (min-width: 768px) {
    margin: 15px 10px;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
`;
const ItemContainerLegalPerson = styled.div`
  with: 30px;  
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  
`;

const RadioButtonComponent = (props) => {
  const {
    description,
    constructionSelect,
    setConstructionType,
    onChange,
    errorMessage,
    isLegalRepresentative
  } = props;
  return (
    isLegalRepresentative
      ? (
        <ItemContainerLegalPerson
          onClick={() => {
            onChange(description);
          }}>
          <span className="ml-4 text-sm color-gray-600">{description}</span>
          <RadioButtonItem
            select={description === constructionSelect}
            left=""
            width="17px"
            height="17px"
            bottom=""
            direction="center"
            right=""
          />
        </ItemContainerLegalPerson>
      )
      : (

        <ItemContainer
          className={
            (description === constructionSelect && 'selected ') ||
            (errorMessage && 'error bg-red-100')
          }
          onClick={() => {
            if (setConstructionType) setConstructionType(description);
            onChange(description);
          }}
        >
          <RadioButtonItem
            select={description === constructionSelect}
            left="15px"
            width="17px"
            height="17px"
            bottom="15px"
            direction="left"
            right=""
          />
          <span className="ml-12 text-sm color-gray-600">{description}</span>
        </ItemContainer>
      )
  );
};

export default RadioButtonComponent;

RadioButtonComponent.propTypes = {
  setConstructionType: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  constructionSelect: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isLegalRepresentative: PropTypes.bool,
};
