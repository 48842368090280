import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../basics/form/Button';
import emitModal from '../../../assets/images/emit-modal.png';
import Checkbox from '../basics/form/Checkbox';
import ModalWrapper from '../basics/modal/ModalWrapper';

const ModalEmit = ({
  modalContent,
  setShowModal,
  showModal,
  handleContinue,
}) => {
  const [checkContinue, setCheckContinue] = useState(false);

  const handleCheckContinue = () => {
    setCheckContinue(!checkContinue);
  };

  return (
    <ModalWrapper showModal={showModal} setShowModal={setShowModal}>
      <div className="grid grid-cols-1 md:grid-cols-2 md:grid-flow-col">
        <div className="flex justify-center items-center leading-tight px-5 pt-10">
          <div className="text-center dark-blue mb-2">
            <h2 className="text-2xl mb-2">
              {modalContent.type === 'initEmision' && modalContent.title}
              {modalContent.type === 'declaracion' && 'Declaración'}
            </h2>
            <p className="text-xs text-left max-w-xs mx-auto">
              {modalContent?.body ||
                modalContent?.bodyNode.props.children ||
                ''}
            </p>
            <div className="py-8 w-4/5 m-auto">
              <Checkbox
                dontModify={false}
                changeProp={handleCheckContinue}
                checkedProp={checkContinue}
                idCheck={modalContent.type}
                label={
                  modalContent.type === 'initEmision'
                    ? 'Estoy totalmente seguro y deseo continuar'
                    : 'He leído y acepto los terminos y condiciones'
                }
              />
            </div>
            <Button primary disabled={!checkContinue} onClick={handleContinue}>
              Continuar
            </Button>
          </div>
        </div>
        <div className="md:col-start-1">
          <img src={emitModal} alt="" className="" />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ModalEmit;

ModalEmit.propTypes = {
  modalContent: PropTypes.shape({
    type: PropTypes.oneOf(['initEmision', 'declaracion']).isRequired,
    title: PropTypes.string,
    body: PropTypes.string,
    bodyNode: PropTypes.node,
  }),
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  handleContinue: PropTypes.func,
};

ModalEmit.defaultProps = {
  modalContent: PropTypes.shape({
    title: '',
    body: '',
    type: '',
  }),
  showModal: false,
  setShowModal: () => {
    // do nothing
  },
  handleContinue: () => {
    // do nothing
  },
};
