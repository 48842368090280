/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable prefer-const */
// ITCON TEAM
const BLOCKED_CODES = [8880, 8884, 8894, 8899];

// return indexes blocked codes
const filterBlockedAmparos = (amparos) => {
  let restrictedCodes = amparos.map((amparo) => {
    let codigoAmparo = amparo.codigoAmparoPadre;
    let indexBlocked = BLOCKED_CODES.indexOf(parseInt(codigoAmparo));
    return indexBlocked > -1 ? null : amparo;
  });

  restrictedCodes = restrictedCodes.filter((restrictedCode) => {
    return restrictedCode != null;
  });
  return restrictedCodes;
};

const filterRisksAmparos = (requestPayload) => {
  let risks = requestPayload.poliza.riesgos;
  risks = risks.map((risk, index) => {
    if (index != 0) {
      let amparos = filterBlockedAmparos(risk.amparos);
      risk.amparos = [];
      risk.amparos = amparos;
    }
    return risk;
  });
  requestPayload.poliza.riesgos = risks;
  return requestPayload;
};

// ITCON test
// let payload = {
//     poliza: {
//         id: '1234434',
//         riesgos: [
//             {
//                 amparos: [
//                     {
//                         codigoAmparoPadre: '8884'
//                     },
//                     {
//                         codigoAmparoPadre: '7777'
//                     }
//                 ]
//             },
//             {
//                 amparos: [
//                     {
//                         codigoAmparoPadre: '8884'
//                     },
//                     {
//                         codigoAmparoPadre: '8890'
//                     }
//                 ]
//             }
//         ]
//     }
// };

module.exports = {
  filterRisksAmparos,
};
