import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import arrow from '../../../../assets/icons/arrow-blue.svg';
import pdf from '../../../../assets/icons/pdf.svg';
import cloudDownload from '../../../../assets/icons/cloud-download.svg';

const SelectDownload = ({ onChangeOptions }) => {
  const roles = useSelector((state) => state.emitInfo?.data?.roles);
  const hasBuildingRisk = roles.some(
    (rol) => rol.type === 'beneficiaryBuilding'
  );
  const hasMachinaryRisk = roles.some(
    (rol) => rol.type === 'beneficiaryMachinery'
  );

  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionPDFS = (option) => {
    setIsOpen(false);
    if (option === 'ALL') {
      const options = ['POLICY_PYMES'];
      if (hasBuildingRisk) options.push('ENDORSEMENT_RISK');
      if (hasMachinaryRisk) options.push('ENDORSEMENT_ELECTRONIC');
      onChangeOptions(options);
    } else {
      onChangeOptions([option]);
    }
  };

  const List = styled.div`
    position: absolute;
    font-size: 0.875rem;
    font-weight: 500;
    font-weight: 500;
    bottom: 0;
    text-align: left;
    min-width: 300px;
    margin-bottom: 4.5rem;
    margin-left: calc(50% - 150px);

    .item-list {
      padding: 0.5rem 1.5rem;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
    .item-list img {
      margin-left: 10px;
    }

    .item-list:hover {
      background-color: #f5f5f5;
    }
  `;

  return (
    <div className="relative">
      {isOpen && (
        <List className="dark-blue shadow-lg bg-white py-4">
          <div
            className="item-list"
            role="presentation"
            onClick={() => handleOptionPDFS('POLICY_PYMES')}
          >
            Póliza Liberty PYME
            <img src={cloudDownload} alt="pdf" />
          </div>
          {hasBuildingRisk && (
            <div
              className="item-list"
              role="presentation"
              onClick={() => handleOptionPDFS('ENDORSEMENT_RISK')}
            >
              Endoso del riesgo
              <img src={cloudDownload} alt="pdf" />
            </div>
          )}
          {hasMachinaryRisk && (
            <div
              className="item-list"
              role="presentation"
              onClick={() => {
                handleOptionPDFS('ENDORSEMENT_ELECTRONIC');
              }}
            >
              Endoso de maquinaria o equipo electrónico
              <img src={cloudDownload} alt="pdf" />
            </div>
          )}
          {(hasMachinaryRisk || hasBuildingRisk) && (
            <div
              className="item-list"
              role="presentation"
              onClick={() => handleOptionPDFS('ALL')}
            >
              Descargar todos los PDFs
              <img src={cloudDownload} alt="pdf" />
            </div>
          )}
        </List>
      )}
      <button
        type="button"
        className="px-6 py-2 my-4 bg-gray-100 shadow-md focus:outline-none inline-flex justify-center items-center"
        onClick={toggling}
      >
        <div className="mr-2 bg-white rounded-full p-2">
          <img src={pdf} alt="pdf" />
        </div>
        <p className="font-medium text-gray-500">Descargar PDFs</p>
        <img src={arrow} alt="arrow" className="ml-3" />
      </button>
    </div>
  );
};

export default SelectDownload;

SelectDownload.propTypes = {
  onChangeOptions: PropTypes.func.isRequired,
};
