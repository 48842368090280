const coverageExceptions = [
  {
    id: '8875',
    parentId: '8874',
    exceptionBy: 'VlaMercancia',
    percentage: 80,
  },
];

export default coverageExceptions;
