import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

import Loader from '../../../Loader';
import calendarCircle from '../../../../assets/icons/calendar-circle.svg';
import Button from '../../basics/form/Button';
import RoleCards from '../../collections/cards/RoleCards';
import Calendar from '../../collections/Calendar/Calendar';
import ModalInfoPeople from '../../collections/modals/ModalInfoPeople/ModalInfoPeople';
import ModalOnerous from '../../collections/modals/ModalOnerous/ModalOnerous';
import ModalRemoveRol from '../../collections/modals/ModalRemoveRol';
import ModalErrorService from '../../collections/modals/ModalErrorCreatePolicy';
import ModalErrorValidateIdentity from '../../collections/modals/ModalErrorValidateIdentity';
import ModalSQS from '../../collections/modals/ModalSQS';

import { isObjectEmpty } from '../../../../utils/helpers/object';
import emitServices from '../../../../services/emit';
import './Roles.scss';

const Roles = ({
  bussinessType,
  vigencyDate,
  maxDaysCalendar,
  handleUpdateVigencyDate,
  roleInfo,
  handleUpdateRoleObject,
  handleCreateNewRole,
  handleDeleteRole,
  handleSaveEmitInfo,
  handleContinue,
  handleRetryCreatePolicy,
  showModalSQS,
}) => {
  const isFirstRender = useRef(true);
  const [loading, setLoading] = useState(false);

  const [showModalInfoPeople, setShowModalInfoPeople] = useState(false);
  const [showRemoveRolModal, setShowRemoveRolModal] = useState(false);
  const [showModalOnerous, setShowModalOnerous] = useState(false);
  const [beneficiaryType, setBeneficiaryType] = useState('');

  const [roleIdSelectionByUser, setRoleIdSelectionByUser] = useState('');
  const [roleSelectionByUser, setRoleSelectionByUser] = useState({});
  const [isCreateNewRole, setIsCreateNewRole] = useState(false);
  const [roleValidateIdentity, setRoleValidateIdentity] = useState({});

  const [isMaximumTakers, setIsMaximumTakers] = useState(false);
  const [isMaximumInsured, setIsMaximumInsured] = useState(false);
  const [isMaximumBeneficiaries, setIsMaximumBeneficiaries] = useState(false);
  const [
    isMaximumBeneficiariesBuilding,
    setIsMaximumBeneficiariesBuilding,
  ] = useState(false);
  const [
    isMaximumBeneficiariesMachinery,
    setIsMaximumBeneficiariesMachinery,
  ] = useState(false);

  const [hasSelectedAllRoles, setHasSelectedAllRoles] = useState(false);
  const [hasTakerRole, setHasTakerRole] = useState(false);
  const [hasInsuredRole, setHasInsuredRole] = useState(false);
  const [hasBeneficiaryRole, setHasBeneficiaryRole] = useState(false);

  const [
    showModalErrorCreatePolicyService,
    setShowModalErrorCreatePolicyService,
  ] = useState(false);
  const [
    showModalErrorValidateIdentityService,
    setShowModalErrorValidateIdentityService,
  ] = useState(false);
  const [
    showModalErrorValidateIdentityRole,
    setShowModalErrorValidateIdentityRole,
  ] = useState(false);

  const handleCloseModalRemoveRol = () => {
    setShowRemoveRolModal(false);
    setRoleIdSelectionByUser('');
    setRoleSelectionByUser({});
  };

  const handleCloseModalInfoPeople = () => {
    if (isCreateNewRole) {
      handleDeleteRole(roleIdSelectionByUser);
    }
    setShowModalInfoPeople(false);
    setShowModalOnerous(false);
    setRoleIdSelectionByUser('');
    setRoleSelectionByUser({});
    setIsCreateNewRole(false);
  };

  const handleSelectCard = (roleid) => {
    setRoleIdSelectionByUser(roleid);
    const selected = roleInfo.filter((role) => role.idCard === roleid)[0];
    if (
      selected.type === 'taker' ||
      selected.type === 'insured' ||
      selected.type === 'beneficiary'
    ) {
      setShowModalInfoPeople(true);
    } else {
      setShowModalOnerous(true);
      setBeneficiaryType(selected.type);
    }
  };

  const handleCreateCard = (type) => {
    let newRole = {};
    if (type !== 'beneficiaryBuilding' && type !== 'beneficiaryMachinery') {
      newRole = {
        idCard: `${uuidv4()}`,
        isSelectCard: true,
        isTaker: false,
        isInsured: false,
        isBeneficiary: false,
        type,
        business_name: bussinessType,
        isCompleteInfo: false,
        info: {},
        IsNewPersonIaxis: false,
        isCertificateFile: false,
      };
      if (type === 'taker') {
        newRole = { ...newRole, isTaker: true };
      } else if (type === 'insured') {
        newRole = { ...newRole, isInsured: true };
      } else if (type === 'beneficiary') {
        newRole = { ...newRole, isBeneficiary: true };
      }
      setShowModalInfoPeople(true);
    } else {
      newRole = {
        idCard: `${uuidv4()}`,
        isSelectCard: true,
        type,
        isCompleteInfo: false,
        info: {},
        IsNewPersonIaxis: false,
        isCertificateFile: false,
      };
      setShowModalOnerous(true);
      setBeneficiaryType(type);
    }
    setRoleIdSelectionByUser(newRole.idCard);
    handleCreateNewRole(newRole);
    setIsCreateNewRole(true);
  };

  const handleValidateIdentity = async (role) => {
    setLoading(true);
    const { currentRoleId, ...payload } = role;
    try {
      const response = await emitServices.user.validateIdentity(payload);
      if (response.status === 200) {
        if (response.data.payload[0].status !== 1) {
          setShowModalErrorValidateIdentityRole(true);
          handleUpdateRoleObject(currentRoleId, 'isNotValidRole', true);
        } else {
          handleUpdateRoleObject(currentRoleId, 'isNotValidRole', false);
        }
      }
    } catch (error) {
      handleUpdateRoleObject(currentRoleId, 'isNotValidRole', true);
      setShowModalErrorValidateIdentityService(true);
    } finally {
      setLoading(false);
    }
  };

  const handleContinueFormRoleInfo = (
    formInfo = {},
    dataRepresentativeLegal = {},
    beneficiaries = [],
    idCard = null,
    role = { IsNewPersonIaxis: false }
  ) => {
    const id = idCard || roleIdSelectionByUser;
    const { isTaker, isInsured, isBeneficiary } = roleSelectionByUser;
    const { habeasData, sarlaftFile, certificateFile, documentType } = formInfo;
    if (!isTaker && !isInsured && !isBeneficiary) {
      delete formInfo.habeasData;
    }
    if (certificateFile && isTaker && !role.IsNewPersonIaxis) {
      handleUpdateRoleObject(id, 'isCertificateFile', true);
    }
    if (sarlaftFile && isTaker) handleUpdateRoleObject(id, 'isSarlaft', true);
    if (habeasData && isTaker) handleUpdateRoleObject(id, 'isHabeasData', true);
    if (habeasData && !isTaker && isInsured)
      handleUpdateRoleObject(id, 'isHabeasData', true);
    handleUpdateRoleObject(id, 'isSelectCard', true);
    handleUpdateRoleObject(id, 'info', formInfo);

    setShowModalInfoPeople(false);
    setShowModalOnerous(false);
    setIsCreateNewRole(false);
    if (isTaker || role.isTaker) {
      const payload = {
        currentRoleId: id,
        documentType: formInfo.documentType.code,
        documentNumber: formInfo.documentNumber,
        name: formInfo.surname || 'PersJurid',
      };
      setRoleValidateIdentity(payload);
      handleValidateIdentity(payload);
    }
    setRoleIdSelectionByUser('');
    setRoleSelectionByUser({});
  };

  const handleContinueFormRoleInfoOnerous = (formInfo = {}) => {
    handleUpdateRoleObject(roleIdSelectionByUser, 'isSelectCard', true);
    handleUpdateRoleObject(roleIdSelectionByUser, 'info', formInfo);
    handleUpdateRoleObject(roleIdSelectionByUser, 'isSarlaft', true);
    handleUpdateRoleObject(roleIdSelectionByUser, 'IsNewPersonIaxis', false);
    setShowModalInfoPeople(false);
    setShowModalOnerous(false);
    setIsCreateNewRole(false);
    setRoleIdSelectionByUser('');
    setRoleSelectionByUser({});
  };

  const handleDeselectCard = (roleId) => {
    setRoleIdSelectionByUser(roleId);
    setShowRemoveRolModal(true);
  };

  const handleRemoveRole = () => {
    handleUpdateRoleObject(roleIdSelectionByUser, 'isSelectCard', false);
    handleDeleteRole(roleIdSelectionByUser);
    handleCloseModalRemoveRol();
  };

  const risks = useSelector((state) => state.emitInfo?.data?.quote_data?.risks);
  const hasBuildingRisk = risks.some((risk) => risk?.insuredValue?.VlaEdificio);
  const hasMachinaryRisk = risks.some(
    (risk) => risk?.insuredValue?.VlaEquipEle
  );

  useEffect(() => {
    if (roleIdSelectionByUser !== '') {
      const selected = roleInfo.filter(
        (role) => role.idCard === roleIdSelectionByUser
      )[0];
      setRoleSelectionByUser(selected);
    }
  }, [roleIdSelectionByUser]);

  useEffect(() => {
    let taker = 0;
    let insured = 0;
    let beneficiary = 0;
    let beneficiaryMachinery = 0;
    let beneficiaryBuilding = 0;

    roleInfo.forEach((role) => {
      if (role.isTaker) taker += 1;
      if (role.isInsured) insured += 1;
      if (role.isBeneficiary) beneficiary += 1;
      if (role.type === 'beneficiaryBuilding') beneficiaryBuilding += 1;
      if (role.type === 'beneficiaryMachinery') beneficiaryMachinery += 1;
    });

    if (taker >= 1) {
      setIsMaximumTakers(true);
    } else {
      setIsMaximumTakers(false);
    }
    if (insured >= 5) {
      setIsMaximumInsured(true);
    } else {
      setIsMaximumInsured(false);
    }
    if (beneficiary >= 5) {
      setIsMaximumBeneficiaries(true);
    } else {
      setIsMaximumBeneficiaries(false);
    }
    if (taker >= 1 && insured >= 1 && beneficiary >= 1) {
      setHasSelectedAllRoles(true);
    } else {
      setHasSelectedAllRoles(false);
    }

    if (taker >= 1) {
      setHasTakerRole(true);
    } else {
      setHasTakerRole(false);
    }

    if (insured >= 1) {
      setHasInsuredRole(true);
    } else {
      setHasInsuredRole(false);
    }

    if (beneficiary >= 1) {
      setHasBeneficiaryRole(true);
    } else {
      setHasBeneficiaryRole(false);
    }

    if (beneficiaryBuilding >= 5) {
      setIsMaximumBeneficiariesBuilding(true);
    } else {
      setIsMaximumBeneficiariesBuilding(false);
    }
    if (beneficiaryMachinery >= 5) {
      setIsMaximumBeneficiariesMachinery(true);
    } else {
      setIsMaximumBeneficiariesMachinery(false);
    }
  }, [roleInfo]);

  const isNotCompleteRole = useMemo(
    () =>
      roleInfo.some(
        (role) =>
          !role.isCompleteInfo ||
          role.isNotValidRole ||
          (role.isTaker &&
            !role?.isHabeasData &&
            !role.hasHabeasData &&
            !role?.isSarlaft) ||
          (role.isTaker &&
            !role?.sarlaftFile &&
            !role?.isHabeasData &&
            !role?.isSarlaft &&
            !role.hasHabeasData)
      ),
    [roleInfo]
  );

  const rolesUpdate = () => {
    const role = roleInfo.find((item) => item.type === 'taker');
    if (role) {
      setLoading(true);
      const { idCard, isSarlaft } = role;
      const { documentNumber, documentType } = role.info;
      emitServices.user
        .search({
          document_number: documentNumber,
          document_type: documentType.code,
        })
        .then((response) => {
          const { roleData, sarlaft, hasHabeasData } = response.data.data;
          if (!isSarlaft) {
            handleUpdateRoleObject(idCard, 'isSarlaft', sarlaft);
          }

          handleUpdateRoleObject(idCard, 'IsNewPersonIaxis', true);
          handleUpdateRoleObject(idCard, 'IsNewPersonIaxis', true);
          handleUpdateRoleObject(idCard, 'hasHabeasData', hasHabeasData);
          handleContinueFormRoleInfo(roleData, {}, [], idCard, {
            isTaker: true,
          });
        })
        .catch((err) => {
          handleUpdateRoleObject(idCard, 'IsNewPersonIaxis', false);
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  if (isFirstRender.current) {
    rolesUpdate();
    isFirstRender.current = false;
  }

  const emitInfo = useSelector((state) => state.emitInfo);
  useEffect(() => {
    if (emitInfo.error) {
      setShowModalErrorCreatePolicyService(true);
    }
  }, [emitInfo.error]);
  return (
    <>
      <div className="text-center mb-32">
        <div className="sm:shadow-customb mt-8 sm:mt-10 dark-blue">
          <h1 className="text-xl sm:text-4xl font-semibold mb-2">
            Vigencia de la póliza
          </h1>
          <p className="text-xs sm:text-lg">
            Selecciona la fecha de inicio de vigencia de la póliza seleccionada.
            <br />
            Tipo de negocio:
            <span className="medium-teal font-medium ml-1">
              {bussinessType}
            </span>
          </p>
          <div className="relative h-10">
            <img
              className="absolute top-0 pt-4 center-icon"
              src={calendarCircle}
              alt=""
            />
          </div>
        </div>
        <div className="bg-lightTeal py-8 dark-blue">
          <h2 className="text-lg sm:text-2xl font-semibold">
            Fecha de inicio de vigencia
          </h2>
          <div className="flex align-center justify-center">
            <div className="rounded border border-solid border-gray-200 bg-white w-10/12 sm:w-6/12 md:w-4/12 lg:w-3/12">
              <Calendar
                onChange={handleUpdateVigencyDate}
                value={vigencyDate || Date.now().toString()}
                fullWidth
                maxDays={maxDaysCalendar}
              />
            </div>
          </div>
        </div>
        <div className="mt-4 sm:mt-8 mb-10 dark-blue">
          <h3 className="text-xl sm:text-4xl font-semibold">
            Cuéntanos sobre las personas
          </h3>
          <p className="text-xs sm:text-lg mx-2 mb-10">
            Agrega a los beneficiarios, asigna roles y no olvides ningún dato,
            puede ser importante en el futuro.
          </p>
          <RoleCards
            handleValidateIdentity={handleValidateIdentity}
            roleState={roleInfo}
            setCreateCard={handleCreateCard}
            handleSelectCard={handleSelectCard}
            handleDeselectCard={handleDeselectCard}
            handleUpdateRoleObject={handleUpdateRoleObject}
            isMaximumTakers={isMaximumTakers}
            isMaximumInsured={isMaximumInsured}
            isMaximumBeneficiaries={isMaximumBeneficiaries}
            isMaximumBeneficiariesBuilding={isMaximumBeneficiariesBuilding}
            isMaximumBeneficiariesMachinery={isMaximumBeneficiariesMachinery}
            hasMachinaryRisk={hasMachinaryRisk}
            hasBuildingRisk={hasBuildingRisk}
            hasTakerRole={hasTakerRole}
            hasInsuredRole={hasInsuredRole}
            hasBeneficiaryRole={hasBeneficiaryRole}
          />
        </div>
        <Button
          className="mr-4"
          type="button"
          onClick={handleSaveEmitInfo}
          disabled={!isMaximumTakers}
        >
          Guardar y Salir
        </Button>
        <Button
          primary
          disabled={!hasSelectedAllRoles || !vigencyDate || isNotCompleteRole}
          // disablled={false}
          onClick={handleContinue}
        >
          Emitir
        </Button>
      </div>
      {showModalInfoPeople && !isObjectEmpty(roleSelectionByUser) && (
        <ModalInfoPeople
          roleSelection={roleSelectionByUser}
          showModal={showModalInfoPeople}
          handleContinueFormRoleInfo={handleContinueFormRoleInfo}
          setCloseModal={handleCloseModalInfoPeople}
          handleUpdateRoleObject={handleUpdateRoleObject}
          isMaximumTakers={isMaximumTakers}
          isMaximumInsured={isMaximumInsured}
          isMaximumBeneficiaries={isMaximumBeneficiaries}
          setLoading={setLoading}
        />
      )}
      {showModalOnerous && !isObjectEmpty(roleSelectionByUser) && (
        <ModalOnerous
          roleSelection={roleSelectionByUser}
          showModal={showModalOnerous}
          setContinueFormRoleInfo={handleContinueFormRoleInfoOnerous}
          setCloseModal={handleCloseModalInfoPeople}
          beneficiaryType={beneficiaryType}
          setLoading={setLoading}
        />
      )}
      {showRemoveRolModal && (
        <ModalRemoveRol
          showModal={showRemoveRolModal}
          setRemoveRole={handleRemoveRole}
          setCloseModal={handleCloseModalRemoveRol}
        />
      )}
      {showModalErrorCreatePolicyService && (
        <ModalErrorService
          showModal={showModalErrorCreatePolicyService}
          onMyBussiness={handleSaveEmitInfo}
          onRetry={handleRetryCreatePolicy}
          showButtonBussines={showModalErrorCreatePolicyService}
        />
      )}
      {showModalErrorValidateIdentityService && (
        <ModalErrorService
          showModal={showModalErrorValidateIdentityService}
          onMyBussiness={handleSaveEmitInfo}
          onRetry={() => handleValidateIdentity(roleValidateIdentity)}
        />
      )}
      {showModalErrorValidateIdentityRole && (
        <ModalErrorValidateIdentity
          showModal={showModalErrorValidateIdentityRole}
          onMyBussiness={handleSaveEmitInfo}
          setCloseModal={setShowModalErrorValidateIdentityRole}
        />
      )}
      {showModalSQS && <ModalSQS showModal={showModalSQS} />}
      {loading && <Loader />}
    </>
  );
};

Roles.propTypes = {
  bussinessType: PropTypes.string.isRequired,
  maxDaysCalendar: PropTypes.number.isRequired,
  vigencyDate: PropTypes.string.isRequired,
  handleUpdateVigencyDate: PropTypes.func.isRequired,
  roleInfo: PropTypes.array.isRequired,
  handleUpdateRoleObject: PropTypes.func.isRequired,
  handleCreateNewRole: PropTypes.func.isRequired,
  handleDeleteRole: PropTypes.func.isRequired,
  handleSaveEmitInfo: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  handleRetryCreatePolicy: PropTypes.func.isRequired,
  showModalSQS: PropTypes.bool.isRequired,
};

export default Roles;
