import React from 'react';
import PropTypes from 'prop-types';

import close from 'assets/icons/close-white.svg';
import checkMark from 'assets/icons/checkmark-circle-green.svg';

const ToolTipSuccesDeleteBeneficiarie = ({ handleCancelRemove }) => (
  <div className="px-3 confirmationMessage success">
    <img src={checkMark} alt="checkmark" className="h-4 w-3" />
    <p>Los datos del Beneficiario Final fueron borrados exitosamente</p>
    <button type="button" onClick={handleCancelRemove}>
      <img src={close} alt="close" className="h-2 w-2 close" />
    </button>
  </div>
);

ToolTipSuccesDeleteBeneficiarie.propTypes = {
  handleCancelRemove: PropTypes.func,
};

ToolTipSuccesDeleteBeneficiarie.defaultProps = {
  handleCancelRemove: () => {},
};

export default ToolTipSuccesDeleteBeneficiarie;
