import React from 'react';
import PropTypes from 'prop-types';
import RoleItem from '../../basics/card/RoleItem';
import DashedCard from '../../basics/card/DashedCard';

import plus from '../../../../assets/icons/plus.svg';

const RoleCards = ({
  roleState,
  setCreateCard,
  handleSelectCard,
  handleDeselectCard,
  handleUpdateRoleObject,
  handleValidateIdentity,
  isMaximumTakers,
  isMaximumInsured,
  isMaximumBeneficiaries,
  isMaximumBeneficiariesBuilding,
  isMaximumBeneficiariesMachinery,
  hasMachinaryRisk,
  hasBuildingRisk,
  hasTakerRole,
  hasInsuredRole,
  hasBeneficiaryRole,
}) => (
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-8 lg:gap-y-5 mx-5 md:mx-20 lg:mx-40 xl:mx-48">
    {roleState.map((role) => (
      <RoleItem
        key={role.idCard}
        role={role}
        roles={roleState}
        setSelectCard={handleSelectCard}
        setDeselectCard={handleDeselectCard}
        handleValidateIdentity={handleValidateIdentity}
        setUpdateRoleObject={handleUpdateRoleObject}
        isMaximumTakers={isMaximumTakers}
        isMaximumInsured={isMaximumInsured}
        isMaximumBeneficiaries={isMaximumBeneficiaries}
      />
    ))}
    {!isMaximumTakers && (
      <DashedCard isRequired={!hasTakerRole}>
        <div
          onClick={() => setCreateCard('taker')}
          role="presentation"
          className="cursor-pointer p-5 sm:px-8 sm:py-12 h-full"
        >
          <div className="inline-flex justify-center items-center bg-teal w-6 h-6 rounded-sm">
            <img className="w-3" src={plus} alt="" />
          </div>
          <span className="ml-1 text-sm font-semibold">Tomador</span>
        </div>
      </DashedCard>
    )}
    {!isMaximumInsured && (
      <DashedCard isRequired={!hasInsuredRole}>
        <div
          onClick={() => setCreateCard('insured')}
          role="presentation"
          className="cursor-pointer p-5 sm:px-8 sm:py-12 h-full"
        >
          <div className="inline-flex justify-center items-center bg-teal w-6 h-6 rounded-sm">
            <img className="w-3" src={plus} alt="" />
          </div>
          <span className="ml-1 text-sm font-semibold">Asegurado</span>
          {hasInsuredRole && <span className="text-xxs">(Opcional)</span>}
        </div>
      </DashedCard>
    )}
    {!isMaximumBeneficiaries && (
      <DashedCard isRequired={!hasBeneficiaryRole}>
        <div
          onClick={() => setCreateCard('beneficiary')}
          role="presentation"
          className="cursor-pointer p-5 sm:px-8 sm:py-12 h-full"
        >
          <div className="inline-flex justify-center items-center bg-teal w-6 h-6 rounded-sm">
            <img className="w-3" src={plus} alt="" />
          </div>
          <span className="ml-1 text-sm font-semibold">Beneficiario</span>
          {hasBeneficiaryRole && <span className="text-xxs">(Opcional)</span>}
        </div>
      </DashedCard>
    )}
    {!isMaximumBeneficiariesBuilding && hasBuildingRisk && (
      <DashedCard isRequired={false}>
        <div
          onClick={() => setCreateCard('beneficiaryBuilding')}
          role="presentation"
          className="cursor-pointer p-5 sm:px-8 sm:py-12 h-full"
        >
          <div className="inline-flex justify-center items-center bg-teal w-6 h-6 rounded-sm">
            <img className="w-3" src={plus} alt="" />
          </div>
          <span className="ml-1 text-sm font-semibold">
            Beneficiario oneroso del edificio
          </span>
          <span className="text-xxs">(Opcional)</span>
        </div>
      </DashedCard>
    )}
    {!isMaximumBeneficiariesMachinery && hasMachinaryRisk && (
      <DashedCard isRequired={false}>
        <div
          onClick={() => setCreateCard('beneficiaryMachinery')}
          role="presentation"
          className="cursor-pointer p-5 sm:px-8 sm:py-12 h-full"
        >
          <div className="inline-flex justify-center items-center bg-teal w-6 h-6 rounded-sm">
            <img className="w-3" src={plus} alt="" />
          </div>
          <span className="ml-1 text-sm font-semibold">
            Beneficiario oneroso del equipo electrónico - maquinaria
          </span>
          <span className="text-xxs">(Opcional)</span>
        </div>
      </DashedCard>
    )}
  </div>
);

RoleCards.propTypes = {
  roleState: PropTypes.array.isRequired,
  setCreateCard: PropTypes.func.isRequired,
  handleSelectCard: PropTypes.func.isRequired,
  handleDeselectCard: PropTypes.func.isRequired,
  handleUpdateRoleObject: PropTypes.func.isRequired,
  isMaximumTakers: PropTypes.bool.isRequired,
  isMaximumInsured: PropTypes.bool.isRequired,
  isMaximumBeneficiaries: PropTypes.bool.isRequired,
  isMaximumBeneficiariesBuilding: PropTypes.bool.isRequired,
  isMaximumBeneficiariesMachinery: PropTypes.bool.isRequired,
  hasMachinaryRisk: PropTypes.bool.isRequired,
  hasBuildingRisk: PropTypes.bool.isRequired,
  hasTakerRole: PropTypes.bool.isRequired,
  hasInsuredRole: PropTypes.bool.isRequired,
  hasBeneficiaryRole: PropTypes.bool.isRequired,
};

export default RoleCards;
