import React from 'react';
import PropTypes from 'prop-types';

export default function Card({ children, isAlert }) {
  return (
    <div
      className={`rounded overflow-hidden shadow-md bg-white ${
        isAlert && 'border border-red-500'
      }`}
    >
      {children}
    </div>
  );
}

Card.propTypes = {
  children: PropTypes.object.isRequired,
  isAlert: PropTypes.bool,
};

Card.defaultProps = {
  isAlert: false,
};
