import { HEADERS_JSON } from 'utils/constants/DefaultBodies';
import { applyAmparoSelections } from './applyAmparoSelections';
import { client, clientRetry } from 'services/axios';

const urlOpenlConfig = process.env.REACT_APP_URL_OPENL_CONFIG;

const initialState = {
  focusedDraft: 0,
  loading: false,
  data: [],
  error: null,
  vlsRecentlyChanged: [],
};
export const updateFocusedDraft = (payload) => ({
  type: 'UPDATE_FOCUSED_DRAFT',
  payload,
});
export const fetchOpenlCapitalStart = () => ({
  type: 'FETCH_OPENL_CAPITAL_START',
  error: false,
});

export const fetchOpenlCapitalSuccess = (payload) => ({
  type: 'FETCH_OPENL_CAPITAL_SUCCESS',
  payload,
});

export const fetchOpenlCapitalError = (payload) => ({
  type: 'FETCH_OPENL_CAPITAL_ERROR',
  payload,
  error: true,
});

export const openlCapitalRemove = (payload) => ({
  type: 'OPENL_CAPITAL_REMOVE',
  payload,
});

export const openlCapitalSetVlsPending = (payload) => ({
  type: 'OPENL_CAPITAL_VALUES_PENDING',
  payload,
});

export default function reducer(state = initialState, action) {
  
  switch (action.type) {
    case 'UPDATE_FOCUSED_DRAFT': {
      return {
        ...state,
        focusedDraft: action.payload,
      };
    }
    case 'FETCH_OPENL_CAPITAL_START': {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case 'FETCH_OPENL_CAPITAL_SUCCESS': {
      let dataIndex = action.payload.draftIndex;
      if (!dataIndex) {
        dataIndex = state.focusedDraft;
      }

      state.data[dataIndex] = action.payload.data;
      state.loading = false;
      return state;
    }
    case 'FETCH_OPENL_CAPITAL_ERROR': {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case 'OPENL_CAPITAL_REMOVE': {
      state = initialState;
      return state;
    }
    case 'OPENL_CAPITAL_VALUES_PENDING': {
      return {
        ...state,
        vlsRecentlyChanged: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const fetchOpenlCapital = (body, customHeaders, draftIndex) => async (
  dispatch,
  getState,
  { axios }
) => {
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };
  dispatch(fetchOpenlCapitalStart());
  try {
    const data = await axios
      .post(
        `${urlOpenlConfig}/request`,
        {
          method: 'post',
          path: '/pyme-rule/newBusinessCapital',
          parameters: body,
        },
        { headers }
      )
      .then((response) => {
        response.data.results[0][1].riesgos = applyAmparoSelections(
          body.poliza.riesgos,
          response.data.results[0][1].riesgos
        );
        return response.data;
      });
    dispatch(fetchOpenlCapitalSuccess({ data, draftIndex }));
  } catch (e) {
    dispatch(fetchOpenlCapitalError(e));
  }
};
