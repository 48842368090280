import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from 'components/Input';
import { formatWord } from 'utils/helpers/normalizeValues';

/**
 * Div style for rows filtered
 */
const Results = styled.div`
  max-height: 150px;
  width: 100%;

  button:hover {
    background-color: #f0fbfc;
    outline: none;
    width: 100%;
  }
`;

/**
 * Filter component
 * @param {*} props
 */
const Filter = forwardRef((props, ref) => {
  const {
    name,
    onChange,
    options,
    optionKey,
    optionLabel,
    placeholder,
    controlled,
    errorMessage,
    inputClass,
    onValueChange,
    defaultInputValue,
  } = props;
  const [inputValue, setInputValue] = useState(defaultInputValue);
  const [optionsFiltered, setOptionsFiltered] = useState([]);
  const [optionSelected, setOptionSelected] = useState(null);

  /**
   * Options filter
   */
  const optionsFilter = (value) => {
    setOptionsFiltered([]);
    if (value.length > 0) {
      const filtered = options.filter((option) => {
        const n = formatWord(option[optionLabel]).indexOf(formatWord(value));
        if (n >= 0) {
          return true;
        }
        return false;
      });
      setOptionsFiltered(filtered);
    }
  };

  /**
   * Call function props when button is clicked
   * @param {*} option
   */
  const onOptionClicked = (option) => {
    optionsFilter('');
    setInputValue(option ? option[optionLabel] : '');
    setOptionSelected(option);
    onChange(option);
    onValueChange(option);
  };

  /**
   * Is selected option
   */
  const isSelectedOption = (value) => {
    if (optionSelected && optionsFiltered.length === 0) {
      if (!optionSelected[optionLabel].includes(value)) {
        onOptionClicked(null);
      }
    }
  };

  return (
    <React.Fragment key="Filter">
      <div className="relative">
        <Input
          data-testid="custom-input"
          name={name}
          value={inputValue}
          placeholder={placeholder}
          onChange={(e) => {
            const { target } = e;
            setInputValue(target.value);
            if (target.value.length > 2) {
              optionsFilter(target.value);
            } else {
              optionsFilter('');
              onChange(null);
            }
          }}
          ref={ref}
          controlled={controlled}
          errorMessage={errorMessage}
          inputClass={inputClass}
          onBlur={(e) => {
            e.preventDefault();
            isSelectedOption(e.target.value);
          }}
        />
        <Results className="absolute bg-white shadow-md rounded-l-sm z-10 overflow-y-scroll">
          {optionsFiltered.map((option) => (
            <button
              key={option[optionKey]}
              type="button"
              className="flex items-center h-12 text-sm text-gray-800 text-left"
              onClick={() => {
                onOptionClicked(option);
              }}
            >
              <span className="px-4">{option[optionLabel]}</span>
            </button>
          ))}
        </Results>
      </div>
    </React.Fragment>
  );
});

export default Filter;

/**
 * Option proptypes
 */
const Option = PropTypes.shape();

/**
 * PropsTypes
 */
Filter.propTypes = {
  /**
   * Name
   * @param {string} name
   */
  name: PropTypes.string,
  /**
   * Placeholder
   * @param {string} placeholder
   */
  placeholder: PropTypes.string,
  /**
   * OnChange event
   * @param {func} action
   */
  onChange: PropTypes.func,
  /**
   * Options array
   * @param {arrayOf} options
   */
  options: PropTypes.arrayOf(Option),
  /**
   * Key
   * @param {string} optionKey
   */
  optionKey: PropTypes.string,
  /**
   * Label
   * @param {string} optionLabel
   */
  optionLabel: PropTypes.string,
  /**
   * Controlled
   * @param {bool} controlled
   */
  controlled: PropTypes.bool,
  /**
   * Name
   * @param {string} errorMessage
   */
  errorMessage: PropTypes.string,
  /**
   * Name
   * @param {string} inputClass
   */
  inputClass: PropTypes.string,
  /**
   * Name
   * @param {func} onValueChange
   */
  onValueChange: PropTypes.func,
  /**
   * Name
   * @param {func} defaultInputValue
   */
  defaultInputValue: PropTypes.string,
};

/**
 * Default props
 */
Filter.defaultProps = {
  /**
   * Name
   * @param {string} name
   */
  name: '',
  /**
   * Placeholder
   * @param {string} placeholder
   */
  placeholder: '',
  /**
   * OnChange event
   * @param {func} action
   */
  onChange: () => {
    // do nothing
  },
  /**
   * Options array
   * @param {} options
   */
  options: [],
  /**
   * Key
   * @param {string} optionKey
   */
  optionKey: '',
  /**
   * Label
   * @param {string} optionLabel
   */
  optionLabel: '',
  /**
   * Controlled
   * @param {bool} controlled
   */
  controlled: false,
  /**
   * errorMessage
   * @param {string} errorMessage
   */
  errorMessage: '',
  /**
   * inputClass
   * @param {string} inputClass
   */
  inputClass: '',
  onValueChange: () => {
    // do nothing
  },
  defaultInputValue: '',
};
