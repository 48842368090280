import React from 'react';
import PropTypes from 'prop-types';
// components
import Modal from 'components/Modal';
import Button from 'components/Button';
// assets
import bigTrash from 'assets/icons/big-trash.svg';

const ConfirmationDeleteRisk = ({
  showModal,
  setShowModal,
  onClick,
  onCancel,
}) => {
  return (
    <Modal showModal={showModal} setShowModal={setShowModal} lg={false}>
      <div className="m-6 flex flex-col items-center justify-center px-6 md:px-20 space-y-3 md:space-y-6">
        <div className="bg-">
          <img className="w-24" src={bigTrash} alt="Alt - Delete" />
        </div>
        <h3 className="mt-4 font-semibold text-center dark-blue">
          ¿Desea eliminar el riesgo?
        </h3>
        <div className="flex flex-col md:flex-row w-full">
          <Button className="sm:px-10" onClick={onCancel}>
            Cancelar
          </Button>
          <Button className="sm:px-12" primary onClick={onClick}>
            Continuar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationDeleteRisk;

/**
 * PropsTypes
 */
ConfirmationDeleteRisk.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

/**
 * Default props
 */
ConfirmationDeleteRisk.defaultProps = {
  onCancel: () => {
    // do nothing
  },
};
