import React from 'react';
import { useHistory } from 'react-router-dom';

const NewQuote = () => {
  const history = useHistory();
  const handleClick = async () => {
    history.push('/quote');
  };

  return (
    <div className="border-b border-gray-200">
      <div className="flex flex-col md:flex-row justify-center align-center md:items-center md:justify-between md:py-8 md:w-c-container md:m-auto md:pl-4 md:pr-8 m:h-16 md:pt-12">
        <p className="text-2xl sm:text-4xl text-center font-medium mt-8 sm:mt-10 text-blue md:m-0 md:leading-none md:my-auto">
          Mis negocios en línea
        </p>
        <button
          type="button"
          className="bg-mediumTeal max-w-sm m-auto px-6 py-2 rounded text-white font-medium mt-2 mb-8 md:mt-0 md:mb-0 md:mx-0"
          onClick={handleClick}
        >
          <span className="text-white m-0 p-0">Nueva cotización</span>
        </button>
      </div>
    </div>
  );
};

export default NewQuote;
