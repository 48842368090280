/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import RadioButtonItem from 'components/RadioButtonItem';

const DefaultCard = (props) => {
  const {
    respuestaIaxis2,
    activity,
    activitySelected,
    select,
    code,
    imageName,
  } = props;
  return (
    <div
      onClick={() => {
        activitySelected(code, activity, imageName, respuestaIaxis2);
      }}
      className={`cursor-pointer bg-white px-3 w-full md:w-defaultCard m-1 py-4 flex items-center rounded relative  shadow-md max-h-4 flex align-center TestDefaultCard ${
        select && 'border-solid border border-mediumTeal bg-lightTeal'
      }`}
    >
      <figure
        className={`${
          select ? 'bg-white' : 'bg-lightTeal'
        } w-10 p-2 rounded-full flex align-center justify-center `}
      >
        <img
          className="m-0 p-0 w-full"
          src={require(`assets/icons/activities/${imageName}`)}
          alt="Liberty Seguros - Alt"
        />
      </figure>
      <div className="flex flex-col w-9/12">
        <p className="m-0 py-0 px-3 max-w-3/4 text-sm text-gray-600 font-medium">
          {activity}
        </p>
        {/* literals && (
          <p className="m-0 py-0 px-3 text-xs text-gray-600 leading-3">
            {literals}
          </p>
        ) */}
      </div>
      <RadioButtonItem
        select={select}
        width="20px"
        height="20px"
        direction="right"
        bottom="25px"
        right="10px"
        left=""
      />
    </div>
  );
};

export default DefaultCard;

DefaultCard.propTypes = {
  respuestaIaxis2: PropTypes.string.isRequired,
  activity: PropTypes.string.isRequired,
  activitySelected: PropTypes.func.isRequired,
  select: PropTypes.bool.isRequired,
  code: PropTypes.string.isRequired,
  imageName: PropTypes.string.isRequired,
};
