import axios from 'axios';
import { barcodeIsValid, sleep } from 'utils';
import { HEADERS_JSON } from 'utils/constants/DefaultBodies';

const urlEmit = process.env.REACT_APP_URL_EMIT;
const MAX_RETRY = 6;
const TIMEOUT = 10000;

export const getBarcode = async ({ npoliza, emission_id: emissionId }) => {
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${sessionInfoObj.token}`,
    authorizedip: sessionInfoObj.ip,
  };
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };

  const requestPayload = {
    npoliza,
    emission_id: emissionId,
  };

  let barcode = '';
  let retry = 0;
  while (retry < MAX_RETRY) {
    try {
      const { data } = await axios.post(
        `${urlEmit}/emission/generate-barcode`,
        requestPayload,
        {
          headers,
        }
      );
      barcode = data.barcodeResult.barcode;
      retry += 1;
    } catch (e) {
      retry += 1;
      if (retry >= MAX_RETRY) throw e;
    }
    if (!barcodeIsValid(barcode)) await sleep(TIMEOUT);
    else break;
  }
  return barcode;
};
