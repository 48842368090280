export const isActive = (amparo) => {
  if (+amparo.selected === 1) return true;
  if (amparo.codigoAmparo !== '8882' && +amparo.valorRiesgo === 0) return false;
  return false;
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const barcodeIsValid = (value) => !!value;
