const FILE_TYPES = {
  SARLAFT: 'SARLAFT',
  HABEAS_DATA: 'HABEAS_DATA',
  CERL: 'CERL',
};

const NaturalPersonRegisterOptions = {
  documentType: {
    required: 'Tipo de identificación es requerido',
  },
  documentNumber: {
    required: 'Número de identificación requerido',
    pattern: {
      value: /^\d+$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬|',
    },
  },
  firstName: {
    required: 'Primer nombre requerido',
    pattern: {
      value: /^[a-zA-ZÀ-ÿ\u00F1\u00D1 0-9-./&]*$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬| o números',
    },
  },
  secondName: {
    pattern: {
      value: /^[a-zA-ZÀ-ÿ\u00F1\u00D1 0-9-./&]*$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬| o números',
    },
  },
  surname: {
    required: 'Primer apellido requerido',
    pattern: {
      value: /^[a-zA-ZÀ-ÿ\u00F1\u00D1 0-9-./&]*$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬| o números',
    },
  },
  secondSurname: {
    pattern: {
      value: /^[a-zA-ZÀ-ÿ\u00F1\u00D1 0-9-./&]*$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬| o números',
    },
  },
  gender: {
    required: 'Género es requerido',
  },
  day: {
    required: 'Día es requerido',
  },
  month: {
    required: 'Mes es requerido',
  },
  year: {
    required: 'Año es requerido',
  },
  email: {
    required: 'Email requerido',
    pattern: {
      value: /^\S+@\S+$/i,
      message: 'Por favor usar el formato email',
    },
  },
  department: {
    required: 'Departamento es requerido',
  },
  city: {
    required: 'Ciudad es requerida',
  },
  phoneNumber: {
    required: 'Teléfono celular requerido',
    pattern: {
      value: /^\d+$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬|',
    },
  },
  addressType: {
    required: 'Tipo dirección requerido',
  },
  address: {
    required: 'Dirección requerido',
    pattern: {
      value: /^[a-zA-ZÀ-ÿ\u00F1\u00D1 0-9-./&]*$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬|',
    },
  },
};

const LegalPersonRegisterOptions = {
  documentType: {
    required: 'Tipo de identificación es requerido',
  },
  documentNumber: {
    required: 'Número de identificación requerido',
    pattern: {
      value: /^\d+$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬|',
    },
  },
  businessName: {
    required: 'Razón social requerido',
    pattern: {
      value: /^[a-zA-ZÀ-ÿ\u00F1\u00D1 0-9-./&]*$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬| o números',
    },
    maxLength: {
      value: 60,
      message: 'La razón social no debe ser mayor a 60 caracteres',
    },
  },
  email: {
    required: 'Email requerido',
    pattern: {
      value: /^\S+@\S+$/i,
      message: 'Por favor usar el formato email',
    },
  },
  department: {
    required: 'Departamento es requerido',
  },
  city: {
    required: 'Ciudad es requerida',
  },
  phoneNumber: {
    required: 'Teléfono celular requerido',
    pattern: {
      value: /^\d+$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬|',
    },
  },
  addressType: {
    required: 'Tipo dirección requerido',
  },
  address: {
    required: 'Dirección requerido',
    pattern: {
      value: /^[a-zA-ZÀ-ÿ\u00F1\u00D1 0-9-./&]*$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬|',
    },
  },
  isLegalRepresentativeBeneficiarie: {
    required: 'Este campo es requerido',
  },
  documentTypeLegalRepresentative: {
    required: 'Tipo de identificación es requerido',
  },
  documentNumberLegalRepresentative: {
    required: 'Número de identificación requerido',
    pattern: {
      value: /^\d+$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬|',
    },
  },
  documentNumberLegalRepresentativePassport: {
    required: 'Número de identificación requerido',
    pattern: {
      value: /^[a-zA-ZÀ-ÿ\u00F1\u00D1 0-9-./&]*$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬|',
    },
  },
  firstNameLegalRepresentative: {
    required: 'Primer nombre requerido',
    pattern: {
      value: /^[a-zA-ZÀ-ÿ\u00F1\u00D1 0-9-./&]*$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬| o números',
    },
  },
  secondNameLegalRepresentative: {
    pattern: {
      value: /^[a-zA-ZÀ-ÿ\u00F1\u00D1 0-9-./&]*$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬| o números',
    },
  },
  surnameLegalRepresentative: {
    required: 'Primer apellido requerido',
    pattern: {
      value: /^[a-zA-ZÀ-ÿ\u00F1\u00D1 0-9-./&]*$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬| o números',
    },
  },
  secondSurnameLegalRepresentative: {
    pattern: {
      value: /^[a-zA-ZÀ-ÿ\u00F1\u00D1 0-9-./&]*$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬| o números',
    },
  },
};

const OnerousRegisterOptions = {
  documentNumber: {
    required: 'Número de identificación requerido',
    pattern: {
      value: /^\d+$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬|',
    },
  },
  businessName: {
    required: 'Razón social requerido',
    pattern: {
      value: /^[a-zA-ZÀ-ÿ\u00F1\u00D1 0-9-./&]*$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬| o números',
    },
    maxLength: {
      value: 60,
      message: 'La razón social no debe ser mayor a 60 caracteres',
    },
  },
  risk: {
    required: 'Riesgo requerido',
  },
  equipmentDetails: {
    required: 'Detalle del equipo requerido',
    pattern: {
      value: /^[a-zA-ZÀ-ÿ\u00F1\u00D1 0-9-./&]*$/,
      message:
        'No se permiten caracteres especiales \'=)(%$#”"!||*{​​}[]+<>?¡¿^:;,°¬|',
    },
    maxLength: {
      value: 50,
      message:
        'El detalle de la maquinaria o equipo electrónico no debe ser mayor a 50 caracteres',
    },
  },
};

const LegalPersonSchema = {
  documentType: '',
  documentNumber: '',
  businessName: '',
  phoneNumber: '',
  email: '',
  department: '',
  city: '',
  addressType: '',
  address: '',
  userRestriction: false,
};

const NaturalPersonSchema = {
  documentType: '',
  documentNumber: '',
  firstName: '',
  secondName: '',
  secondSurname: '',
  surname: '',
  email: '',
  phoneNumber: '',
  gender: '',
  year: '',
  day: '',
  month: '',
  department: '',
  city: '',
  addressType: '',
  address: '',
  userRestriction: false,
};

const optionsIdentitiesTaker = [
  {
    name: 'Cédula ciudadanía',
    code: '36',
  },
  {
    name: 'Cédula extranjería',
    code: '33',
  },
  {
    name: 'Carnet diplomático',
    code: '44',
  },
  {
    name: 'Pasaporte',
    code: '40',
  },
  {
    name: 'Número único identificación personal (NUIP)',
    code: '38',
  },
  {
    name: 'NIT',
    code: '37',
  },
  /* {
    name: 'PPT- Permiso por Protección Temporal  venezolanos',
    code: '48',
  }, */
];
const optionsIdentitiesLegalRepresentative = [
  {
    name: 'Cédula ciudadanía',
    code: '36',
  },
  {
    name: 'Cédula extranjería',
    code: '33',
  },
  {
    name: 'Carnet diplomático',
    code: '44',
  },
  {
    name: 'Pasaporte',
    code: '40',
  },
  {
    name: 'Número único identificación personal (NUIP)',
    code: '38',
  },
];

const optionsIdentitiesInsuredBeneficiary = [
  {
    name: 'Cédula ciudadanía',
    code: '36',
  },
  {
    name: 'Cédula extranjería',
    code: '33',
  },
  {
    name: 'Carnet diplomático',
    code: '44',
  },
  {
    name: 'Pasaporte',
    code: '40',
  },
  {
    name: 'Tarjeta de identidad',
    code: '34',
  },
  {
    name: 'Registro civil',
    code: '35',
  },
  {
    name: 'Número único identificación personal (NUIP)',
    code: '38',
  },
  {
    name: 'NIT',
    code: '37',
  },
  /*  {
    name: 'PPT- Permiso por Protección Temporal  venezolanos',
    code: '48',
  }, */
];

const optionsAddress = [
  {
    name: 'Residencia',
    code: '9',
  },
  {
    name: 'Oficina Principal',
    code: '10',
  },
  {
    name: 'Sede Principal',
    code: '11',
  },
  {
    name: 'Sucursal',
    code: '12',
  },
  {
    name: 'Correspondencia',
    code: '13',
  },
  {
    name: 'Apartado Aereo',
    code: '14',
  },
];

const optionsMonths = [
  {
    name: 'Enero',
    code: '0',
  },
  {
    name: 'Febrero',
    code: '1',
  },
  {
    name: 'Marzo',
    code: '2',
  },
  {
    name: 'Abril',
    code: '3',
  },
  {
    name: 'Mayo',
    code: '4',
  },
  {
    name: 'Junio',
    code: '5',
  },
  {
    name: 'Julio',
    code: '6',
  },
  {
    name: 'Agosto',
    code: '7',
  },
  {
    name: 'Septiembre',
    code: '8',
  },
  {
    name: 'Octubre',
    code: '9',
  },
  {
    name: 'Noviembre',
    code: '10',
  },
  {
    name: 'Diciembre',
    code: '11',
  },
];
const documentTypesString = {
  24: 'PAS',
  33: 'CE',
  34: 'TI',
  35: 'RC',
  36: 'CC',
  37: 'NIT',
  38: 'NUIP',
  44: 'CD',
  45: 'NE',
  46: 'PEP',
  47: 'PECP',
  48: 'PPT',
};

const CURRENT_TIMEZONE = 'America/Bogota';

module.exports = {
  NaturalPersonRegisterOptions,
  LegalPersonRegisterOptions,
  OnerousRegisterOptions,
  LegalPersonSchema,
  NaturalPersonSchema,
  optionsIdentitiesTaker,
  optionsIdentitiesInsuredBeneficiary,
  optionsIdentitiesLegalRepresentative,
  optionsAddress,
  optionsMonths,
  documentTypesString,
  FILE_TYPES,
  CURRENT_TIMEZONE,
};
