import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { setDefaults, fromAddress, fromLatLng } from 'react-geocode'
import {
  MAP_CONTAINER_STYLE,
  MAP_DEFAULT_CENTER,
  MAP_DEFAULT_STYLES,
  MAP_DEFAULT_ZOOM,
  MAP_GEOCODE_RESPONSE,
} from 'utils/constants/MapOptions';
import {
  replaceHashtagAddress,
  replaceAddressSpecialChars,
  extractValueFromSplit,
} from 'utils/helpers/normalizeValues';

const googleKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
// default params by google geocode
setDefaults(
  {
    key: googleKey,
    language: "en",
    region: 'es'
  }
)

const Map = ({
  filledAddress,
  setLocatedAddress,
  showZeroResults,
  onValueChange,
  draftIndex,
  riskIndex,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleKey,
  });

  const [marker, setMarker] = useState({});
  const [centerMap, setCenterMap] = useState(MAP_DEFAULT_CENTER);
  const riskDetails = useSelector((state) => state.quoteInfo.data);
  const riskInfo = riskDetails.drafts[draftIndex].risks[riskIndex];
  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const locatePoint = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(MAP_DEFAULT_ZOOM.level);
  }, []);

  useEffect(() => {
    if (filledAddress !== '') {
      fromAddress(filledAddress).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setMarker({ lat, lng });
          onValueChange({ lat, lng });
          locatePoint({ lat, lng });
        },
        (error) => {
          console.error(" MAPS --- get geo codes from address service: ", error)
          showZeroResults(
            true
          );
        }
      );
    }
  }, [filledAddress, locatePoint]);

  useEffect(() => {
    const { lat = 0, lng = 0 } = riskInfo?.Mapa || {}
    if (lat !== 0 && lng !== 0) {
      setMarker(riskInfo.Mapa);
      setCenterMap(riskInfo.Mapa);
    }
  }, []);
  const onMapClick = useCallback(
    (event) => {
      setMarker({ lat: event.latLng.lat(), lng: event.latLng.lng() });
      onValueChange({ lat: event.latLng.lat(), lng: event.latLng.lng() });
      fromLatLng(event.latLng.lat(), event.latLng.lng()).then(
        (response) => {
          let resultAddress = response.results[0].formatted_address;
          resultAddress = replaceHashtagAddress(resultAddress);
          resultAddress = replaceAddressSpecialChars(resultAddress);
          resultAddress = extractValueFromSplit(resultAddress, ',', 0);
          setLocatedAddress(resultAddress);
        },
        (error) => {
          console.error("MAPS ---- get geo codes from Lat & Lng service :", error);
          showZeroResults(
            true
          );
        }
      );
    },
    [setLocatedAddress]
  );

  if (loadError)
    return (
      <div className="text-mediumTeal">
        Ocurrio un error al cargar el mapa, por favor escribe la dirección
      </div>
    );
  if (!isLoaded) return <div className="text-mediumTeal">Cargando mapa</div>;

  return (
    <div className="border border-solid border-gray-300 rounded">
      <GoogleMap
        mapContainerStyle={MAP_CONTAINER_STYLE}
        zoom={MAP_DEFAULT_ZOOM.level}
        center={centerMap}
        options={MAP_DEFAULT_STYLES}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        <Marker
          position={{ lat: Number(marker.lat), lng: Number(marker.lng) }}
          icon={{
            url: './map-marker.svg',
            scaledSize: new window.google.maps.Size(70, 70),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(35, 55),
          }}
        />
      </GoogleMap>
    </div>
  );
};

/**
 * PropTypes
 */
Map.propTypes = {
  /**
   * input filledAddress from form
   * @param {string} filledAddress
   */
  filledAddress: PropTypes.string.isRequired,
  /**
   * output setLocatedAddress from googlemap
   * @param {func} setFilledAddress
   */
  setLocatedAddress: PropTypes.func.isRequired,
  /**
   * output showZeroResults
   * @param {func} showZeroResults
   */
  showZeroResults: PropTypes.func.isRequired,
  /**
   * Action call
   * @param {func} onValueChange
   */
  onValueChange: PropTypes.func.isRequired,

  draftIndex: PropTypes.string.isRequired,
  riskIndex: PropTypes.string.isRequired,
};

export default Map;
