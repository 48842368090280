export const MAP_CONTAINER_STYLE = {
  width: '100%',
  height: '350px',
};

export const MAP_DEFAULT_CENTER = {
  lat: 4.681511899999999,
  lng: -74.0435528,
};

export const MAP_DEFAULT_ZOOM = {
  level: 18,
};

export const MAP_DEFAULT_STYLES = {
  disableDefaultUI: true,
  zoomControl: true,
  zoomControlOptions: { position: 6 },
};

export const MAP_GEOCODE_RESPONSE = {
  zeroResults: 'ZERO_RESULTS',
};

export const MAP_COUNTRY_ACCURACY = {
  country: 'Colombia',
};

export const MAP_ALERT_MESSAGES = {
  addressNotFound:
    'No encontramos la dirección ingresada, ubica en el mapa el lugar del riesgo y continúa con el proceso',
  unnamedRoad: 'Unnamed Road',
  unnamedRoadTraduction: 'Ubicación desconocida',
};
