import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import back from 'assets/icons/back.svg';

/**
 * Back button style
 */
const BackStyle = styled.button`
  border: 1px solid #1a1446;

  &:disabled {
    background-color: #e6e6e6;
    border: 1px solid #e6e6e6;
    color: #c0bfc0;
  }
`;

/**
 * Back button component
 * @param {*} props
 */
const Back = (props) => {
  const { onClick, disabled, className } = props;
  return (
    <BackStyle
      data-testid="back-btn"
      type="button"
      className={`bg-transparent h-8 w-8 my-2 mx-4 rounded ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <img className="inline" src={back} alt="regresar" />
    </BackStyle>
  );
};

export default Back;

/**
 * PropsTypes
 */
Back.propTypes = {
  /**
   * Action call
   * @param {func} onClick
   */
  onClick: PropTypes.func,
  /**
   * Disabled
   * @param {bool} disabled
   */
  disabled: PropTypes.bool,
  /**
   * className
   * @param {bool} className
   */
  className: PropTypes.string,
};

/**
 * Default props
 */
Back.defaultProps = {
  /**
   * Action call
   * @param {func} onClick
   */
  onClick: null,
  /**
   * Disabled
   * @param {bool} disabled
   */
  disabled: false,
  /**
   * class name
   * @param {bool} className
   */
  className: null,
};
