import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-grid-system';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

// Components
import toolTipBeneficiary from 'assets/icons/icon-tooltip-beneficiarie.svg';
import cautionGold from 'assets/icons/caution-gold.svg';
import close from 'assets/icons/close-white.svg';
import ToolTipSuccesDeleteBeneficiarie from './ToolTipSuccesDeleteBeneficiarie';
import Input from '../../../basics/form/Input';
import Select from '../../../basics/form/Select';

// Helpers

// Constant
import {
  LegalPersonRegisterOptions,
  NaturalPersonRegisterOptions,
  optionsIdentitiesTaker,
} from '../../../../../utils/constants/emit_constants';

// Hooks

// Assets

// Styles
import './styles/FormBeneficiaries.scss';

const SpanBold = styled.span`
  font-weight: bold;
  color: #1a1446;
`;
const SpanGold = styled.span`
  font-weight: bold;
  color: #b85d00;
`;

const BeneficiaryAddButton = styled.button`
  display: block;
  content: '';
  width: 822px;
  height: 80px;
  border: 1px dashed #28a3af;
  border-radius: 3px;
  opacity: 1;
  background-color: #99e5ea64;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
  margin: 2rem 0;

  @media screen and (max-width: 1200px) {
    width: 80%;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
  }

  &.error-button {
    border: 1px dashed #ff0000;
    background-color: #ff9999cc;
  }
`;

const BeneficiaryItemCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
  padding: 0 1rem 1rem;
  border-bottom: 1px solid #dadce0;
`;
const TitleInfoBeneficiarie = styled.p`
  color: #1a1446;
  opacity: 0.6;
  height: 14px;
  font-size: 12px;
  font-family: Roboto;
`;
const SubTitleInfoBeneficiarie = styled.p`
  color: #1a1446;
  opacity: 1;
  height: 19px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
`;

const BeneficiaryInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 1rem;
  padding: 0 1rem;
  width: 70%;
`;

const FormBeneficiaries = ({
  handleSearchPerson,
  legalPersonIsBeneficiarie,
  fields,
  append,
  remove,
}) => {
  const {
    trigger,
    errors,
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState,
  } = useFormContext();

  const MAX_BENEFICIARIES_NUMBER = 10;
  const IS_LEGAL_REPRESENTATIVE_BENERIFICARIE_NUMBER = 9;
  const TOTAL_BENEFICIARIES_NUMBER =
    legalPersonIsBeneficiarie?.code === 'Si'
      ? IS_LEGAL_REPRESENTATIVE_BENERIFICARIE_NUMBER
      : MAX_BENEFICIARIES_NUMBER;

  const [contador, setContador] = useState(
    legalPersonIsBeneficiarie?.code === 'Si'
      ? IS_LEGAL_REPRESENTATIVE_BENERIFICARIE_NUMBER - fields.length
      : MAX_BENEFICIARIES_NUMBER - fields.length
  );

  useEffect(() => {
    // Actualiza el contador con la cantidad actual de elementos en fields

    if (legalPersonIsBeneficiarie?.code === 'Si') {
      setContador(IS_LEGAL_REPRESENTATIVE_BENERIFICARIE_NUMBER - fields.length);
    } else {
      setContador(MAX_BENEFICIARIES_NUMBER - fields.length);
    }
  }, [legalPersonIsBeneficiarie]);

  const [itemActive, setItemActive] = useState(0);
  const [itemDelete, setItemDelete] = useState(0);
  const [isLastItem, setIsLastItem] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleAddEntry = () => {
    setContador((prevContador) => prevContador - 1);
    setItemActive(fields.length);
    append({});
  };

  const handleRemoveEntry = (index) => {
    setConfirmationVisible(true);
    setItemToRemove(index);
    setItemDelete(index);
    if (index === fields.length - 1 && fields.length >= 1) {
      setIsLastItem(true);
    }
  };

  const handleConfirmRemove = () => {
    setContador((prevContador) => prevContador + 1);
    remove(itemToRemove);
    trigger();
    setConfirmationVisible(false);
    setSuccessMessageVisible(true);

    // Limpiar el mensaje de éxito después de 3 segundos
    const id = setTimeout(() => {
      setSuccessMessageVisible(false);
      setItemToRemove(null);
      setIsLastItem(false);
    }, 10000);

    setTimeoutId(id);
  };

  useEffect(
    () => () => {
      if (timeoutId) {
        setItemToRemove(null);
        clearTimeout(timeoutId);
      }
    },
    [timeoutId]
  );

  const handleCancelRemove = () => {
    setConfirmationVisible(false);
    setSuccessMessageVisible(false);
    setIsLastItem(false);
    setItemToRemove(null);
  };

  const handleItemPress = (id) => {
    if (id === itemActive) {
      setItemActive(-1);
      return;
    }
    setItemActive(id);
  };

  return (
    <>
      <div className="px-3 md:max-w-3xl mx-auto centerContainer">
        <h1 className="text-2xl mb-2 leading-tight">
          Datos de Beneficiarios Finales
        </h1>
        <div className="text-xs mb-6 leading-tight toolTipBeneficiary">
          <div className="toolTipBeneficiarie">
            <img
              src={toolTipBeneficiary}
              alt="Liberty Seguros - Nuevos beneficiarios ToolTip"
            />
          </div>
          <p>
            Puedes ingresar hasta{' '}
            <SpanBold>{contador} Beneficiarios Finales</SpanBold> en total
          </p>
        </div>
      </div>
      {successMessageVisible && fields.length === 0 && (
        <ToolTipSuccesDeleteBeneficiarie
          handleCancelRemove={handleCancelRemove}
        />
      )}
      <Row
        className="rowBeneficiaries"
        style={{ width: '100%', justifyContent: 'flex-end' }}
      >
        {fields.length > 0 &&
          fields.map((field, index) => (
            <>
              {confirmationVisible && itemToRemove === index && (
                <div className="px-3 confirmationMessage">
                  <img src={cautionGold} alt="caution" className="h-4 w-3" />
                  <p>
                    <SpanGold>¿Deseas borrar este Beneficiario Final?</SpanGold>{' '}
                    Esta acción no puede deshacerse.{' '}
                  </p>
                  <button type="button" onClick={handleCancelRemove}>
                    Cancelar
                  </button>
                  <button type="button" onClick={handleConfirmRemove}>
                    Eliminar
                  </button>
                  <button type="button" onClick={handleCancelRemove}>
                    <img src={close} alt="close" className="h-2 w-2 close" />
                  </button>
                </div>
              )}
              {!isLastItem && successMessageVisible && itemDelete === index && (
                <ToolTipSuccesDeleteBeneficiarie
                  handleCancelRemove={handleCancelRemove}
                />
              )}
              <div
                className={`space-y-8 px-3 beneficiarieItem ${
                  itemActive === index ? '' : 'noPaddingItem'
                }`}
                key={field.id}
              >
                <BeneficiaryItemCount>
                  <img
                    src={require(`assets/icons/icon-item-beneficiarie.svg`)}
                    alt="Liberty Seguros - item beneficiario"
                    className=""
                  />
                  <h2 className="text-lg leading-tight ">
                    {`Beneficiario Final ${
                      legalPersonIsBeneficiarie?.code === 'Si'
                        ? index + 2
                        : index + 1
                    }`}
                  </h2>
                </BeneficiaryItemCount>
                <button
                  className="deleteBeneficiarie"
                  onClick={() => handleRemoveEntry(index)}
                  type="button"
                >
                  Delete
                </button>
                <button
                  className={`arrowShowBeneficirieInfo ${
                    itemActive === index ? 'Item360' : ''
                  }`}
                  onClick={() => handleItemPress(index)}
                  type="button"
                >
                  Show/Hide
                </button>

                <div
                  className={`m-auto space-y-8 px-3 md:max-w-3xl mx-auto ${
                    itemActive === index ? 'activeItem' : 'hideItem'
                  }`}
                >
                  <Col
                    key={field.id}
                    sm={12}
                    className={`flex flex-col space-y-8  lg:space-y-0 lg:flex-row lg:space-x-6 `}
                  >
                    <Controller
                      key={field.id}
                      render={() => (
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Select
                          placeholder="Tipo de identificación"
                          options={optionsIdentitiesTaker}
                          optionKey="name"
                          optionLabel="name"
                          onChange={(option) => {
                            setValue(
                              `beneficiarie[${index}].documentTypeBeneficiary`,
                              option
                            );
                            trigger(
                              `beneficiarie[${index}].documentTypeBeneficiary`
                            );
                            trigger(
                              `beneficiarie[${index}].documentNumberBeneficiary`
                            );

                            if (option.code === '37') {
                              clearErrors(
                                `beneficiarie.[${index}].firstNameBeneficiary`
                              );
                              clearErrors(
                                `beneficiarie.[${index}].surnameBeneficiary`
                              );
                              setError(
                                `beneficiarie.[${index}].businessNameBeneficiary`,
                                {
                                  message: 'Razon social es requerido',
                                }
                              );
                              trigger(
                                `beneficiarie.[${index}].businessNameBeneficiary`
                              );
                            } else {
                              clearErrors(
                                `beneficiarie.[${index}].businessNameBeneficiary`
                              );
                              setError(
                                `beneficiarie.[${index}].firstNameBeneficiary`,
                                {
                                  message: 'Primer nombre es requerido',
                                }
                              );
                              setError(
                                `beneficiarie.[${index}].surnameBeneficiary`,
                                {
                                  message: 'Primer apellido es requerido',
                                }
                              );
                              trigger(
                                `beneficiarie.[${index}].firstNameBeneficiary`
                              );
                              trigger(
                                `beneficiarie.[${index}].surnameBeneficiary`
                              );
                            }
                          }}
                          errorMessage={false}
                          disabled={false}
                          valueDefault={field?.documentTypeBeneficiary}
                        />
                      )}
                      name={`beneficiarie[${index}].documentTypeBeneficiary`}
                      control={control}
                      rules={LegalPersonRegisterOptions.documentType}
                      defaultValue={field.documentTypeBeneficiary}
                    />

                    <Controller
                      render={() => (
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Input
                          placeholder="Número de identificación"
                          type="number"
                          name={`beneficiarie[${index}].documentNumberBeneficiary`}
                          controlled
                          errorMessage={
                            errors?.beneficiarie?.[index]
                              ?.documentNumberBeneficiary?.message
                          }
                          disabled={false}
                          onBlur={() => {
                            if (
                              getValues(
                                `beneficiarie[${index}].documentTypeBeneficiary`
                              )
                            ) {
                              handleSearchPerson(index, true);
                            }
                          }}
                          onChange={(e) => {
                            const { value } = e.target;
                            setValue(
                              `beneficiarie[${index}].documentNumberBeneficiary`,
                              value
                            );
                            trigger(
                              `beneficiarie[${index}].documentNumberBeneficiary`
                            );
                          }}
                          value={getValues(
                            `beneficiarie[${index}].documentNumberBeneficiary`
                          )}
                        />
                      )}
                      name={`beneficiarie[${index}].documentNumberBeneficiary`}
                      control={control}
                      rules={LegalPersonRegisterOptions.documentNumber}
                      defaultValue={field.documentNumberBeneficiary || ''}
                    />
                  </Col>

                  <Col
                    sm={6}
                    className={`flex flex-col space-y-8  lg:space-y-0 lg:flex-row lg:space-x-6  beneciarieItems ${
                      getValues(
                        `beneficiarie[${index}].documentTypeBeneficiary`
                      )?.code === '37' ||
                      field?.documentTypeBeneficiary?.code === '37'
                        ? 'bussinesItemActive'
                        : 'bussinesItemHide'
                    }`}
                  >
                    <Controller
                      key={`businessNameBeneficiary${index}`}
                      render={() => (
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Input
                          placeholder="Razon Social *"
                          type="text"
                          name={`beneficiarie[${index}].businessNameBeneficiary`}
                          controlled
                          errorMessage={
                            errors?.beneficiarie?.[index]
                              ?.businessNameBeneficiary?.message
                          }
                          disabled={false}
                          onChange={(e) => {
                            const { value } = e.target;
                            setValue(
                              `beneficiarie[${index}].businessNameBeneficiary`,
                              value
                            );
                            trigger(
                              `beneficiarie[${index}].businessNameBeneficiary`
                            );
                          }}
                          value={getValues(
                            `beneficiarie[${index}].businessNameBeneficiary`
                          )}
                        />
                      )}
                      name={`beneficiarie[${index}].businessNameBeneficiary`}
                      control={control}
                      rules={
                        getValues(
                          `beneficiarie[${index}].documentTypeBeneficiary`
                        )?.code === '37' ||
                        field?.documentTypeBeneficiary?.code === '37'
                          ? LegalPersonRegisterOptions.businessName
                          : NaturalPersonRegisterOptions.secondName
                      }
                      defaultValue={field.businessNameBeneficiary || ''}
                    />
                  </Col>

                  <Col
                    sm={12}
                    className={`flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-6 ${
                      getValues(
                        `beneficiarie[${index}].documentTypeBeneficiary`
                      )?.code === '37' ||
                      field?.documentTypeBeneficiary?.code === '37'
                        ? 'naturalItemHide'
                        : 'naturalItemActive'
                    }`}
                  >
                    <Controller
                      key={`firstNameBeneficiary${index}`}
                      render={() => (
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Input
                          placeholder="Primer nombre *"
                          type="text"
                          name={`beneficiarie[${index}].firstNameBeneficiary`}
                          controlled
                          errorMessage={
                            errors?.beneficiarie?.[index]?.firstNameBeneficiary
                              ?.message
                          }
                          disabled={false}
                          onChange={(e) => {
                            const { value } = e.target;
                            setValue(
                              `beneficiarie[${index}].firstNameBeneficiary`,
                              value
                            );
                            trigger(
                              `beneficiarie[${index}].firstNameBeneficiary`
                            );
                          }}
                          value={getValues(
                            `beneficiarie[${index}].firstNameBeneficiary`
                          )}
                        />
                      )}
                      name={`beneficiarie[${index}].firstNameBeneficiary`}
                      control={control}
                      rules={
                        getValues(
                          `beneficiarie[${index}].documentTypeBeneficiary`
                        )?.code === '37' ||
                        field?.documentTypeBeneficiary?.code === '37'
                          ? NaturalPersonRegisterOptions.secondName
                          : NaturalPersonRegisterOptions.firstName
                      }
                      defaultValue={field?.firstNameBeneficiary || ''}
                    />
                    <Controller
                      key={`secondNameBeneficiary${index}`}
                      render={() => (
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Input
                          placeholder="Segundo nombre"
                          type="text"
                          name={`beneficiarie[${index}].secondNameBeneficiary`}
                          controlled
                          errorMessage={false}
                          disabled={false}
                          onChange={(e) => {
                            const { value } = e.target;
                            setValue(
                              `beneficiarie[${index}].secondNameBeneficiary`,
                              value
                            );
                            trigger(
                              `beneficiarie[${index}].secondNameBeneficiary`
                            );
                          }}
                          value={getValues(
                            `beneficiarie[${index}].secondNameBeneficiary`
                          )}
                        />
                      )}
                      name={`beneficiarie[${index}].secondNameBeneficiary`}
                      control={control}
                      rules={NaturalPersonRegisterOptions.secondName}
                      defaultValue={field?.secondNameBeneficiary || ''}
                    />
                  </Col>
                  <Col
                    sm={12}
                    className={`flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-6 ${
                      getValues(
                        `beneficiarie[${index}].documentTypeBeneficiary`
                      )?.code === '37' ||
                      field?.documentTypeBeneficiary?.code === '37'
                        ? 'naturalItemHide'
                        : 'naturalItemActive'
                    }`}
                  >
                    <Controller
                      key={`surnameBeneficiary${index}`}
                      render={() => (
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Input
                          placeholder="Primer apellido *"
                          type="text"
                          name={`beneficiarie[${index}].surnameBeneficiary`}
                          controlled
                          errorMessage={
                            errors?.beneficiarie?.[index]?.surnameBeneficiary
                              ?.message
                          }
                          disabled={false}
                          onChange={(e) => {
                            const { value } = e.target;
                            setValue(
                              `beneficiarie[${index}].surnameBeneficiary`,
                              value
                            );
                            trigger(
                              `beneficiarie[${index}].surnameBeneficiary`
                            );
                          }}
                          value={getValues(
                            `beneficiarie[${index}].surnameBeneficiary`
                          )}
                        />
                      )}
                      name={`beneficiarie[${index}].surnameBeneficiary`}
                      control={control}
                      rules={
                        getValues(
                          `beneficiarie[${index}].documentTypeBeneficiary`
                        )?.code === '37' ||
                        field?.documentTypeBeneficiary?.code === '37'
                          ? NaturalPersonRegisterOptions.secondName
                          : NaturalPersonRegisterOptions.surname
                      }
                      defaultValue={field?.surnameBeneficiary || ''}
                    />
                    <Controller
                      key={`secondSurnameBeneficiary${index}`}
                      render={() => (
                        // eslint-disable-next-line react/jsx-wrap-multilines
                        <Input
                          placeholder="Segundo apellido "
                          type="text"
                          name={`beneficiarie[${index}].secondSurnameBeneficiary`}
                          controlled
                          errorMessage={false}
                          disabled={false}
                          onChange={(e) => {
                            const { value } = e.target;
                            setValue(
                              `beneficiarie[${index}].secondSurnameBeneficiary`,
                              value
                            );
                            trigger(
                              `beneficiarie[${index}].secondSurnameBeneficiary`
                            );
                          }}
                          value={getValues(
                            `beneficiarie[${index}].secondSurnameBeneficiary`
                          )}
                        />
                      )}
                      name={`beneficiarie[${index}].secondSurnameBeneficiary`}
                      control={control}
                      rules={NaturalPersonRegisterOptions.secondSurname}
                      defaultValue={field?.secondSurnameBeneficiary || ''}
                    />
                  </Col>
                </div>
                {itemActive !== index && (
                  <BeneficiaryInfo>
                    <div>
                      <TitleInfoBeneficiarie>Nombre</TitleInfoBeneficiarie>
                      <SubTitleInfoBeneficiarie>
                        {getValues(
                          `beneficiarie[${index}].documentTypeBeneficiary`
                        )?.code === '37' ||
                        field?.documentTypeBeneficiary?.code === '37' ? (
                          field?.businessNameBeneficiary ||
                          getValues(
                            `beneficiarie[${index}].businessNameBeneficiary`
                          )
                        ) : (
                          <>
                            {field?.firstNameBeneficiary ||
                              getValues(
                                `beneficiarie[${index}].firstNameBeneficiary`
                              )}{' '}
                            {field?.secondNameBeneficiary ||
                              getValues(
                                `beneficiarie[${index}].secondNameBeneficiary`
                              )}
                          </>
                        )}
                      </SubTitleInfoBeneficiarie>
                    </div>
                    <div>
                      <TitleInfoBeneficiarie>
                        Tipo de documento
                      </TitleInfoBeneficiarie>
                      <SubTitleInfoBeneficiarie>
                        {field?.documentTypeBeneficiary?.name ||
                          getValues(
                            `beneficiarie[${index}].documentTypeBeneficiary`
                          )?.name}
                      </SubTitleInfoBeneficiarie>
                    </div>
                    <div>
                      <TitleInfoBeneficiarie>
                        Identificación{' '}
                      </TitleInfoBeneficiarie>
                      <SubTitleInfoBeneficiarie>
                        {field?.documentNumberBeneficiary ||
                          getValues(
                            `beneficiarie[${index}].documentNumberBeneficiary`
                          )}
                      </SubTitleInfoBeneficiarie>
                    </div>
                  </BeneficiaryInfo>
                )}
              </div>
              {successMessageVisible &&
                isLastItem &&
                +itemDelete - 1 === index && (
                  <ToolTipSuccesDeleteBeneficiarie
                    handleCancelRemove={handleCancelRemove}
                  />
                )}
            </>
          ))}
      </Row>

      <BeneficiaryAddButton
        onClick={() => {
          handleAddEntry();
        }}
        disabled={
          fields.length >= TOTAL_BENEFICIARIES_NUMBER || !formState.isValid
        }
        className={
          fields.length === 0 && legalPersonIsBeneficiarie.code !== 'Si'
            ? 'error-button'
            : ''
        }
      >
        <img
          src={require(`assets/icons/iconPlusBeneficiaries.svg`)}
          alt="Liberty Seguros - Nuevos beneficiarios"
          className=""
        />
        <SpanBold>Agregar Beneficiario Final</SpanBold>
      </BeneficiaryAddButton>
    </>
  );
};

FormBeneficiaries.propTypes = {
  handleSearchPerson: PropTypes.func,
  legalPersonIsBeneficiarie: PropTypes.object,
  fields: PropTypes.array,
  append: PropTypes.func,
  remove: PropTypes.func,
};

FormBeneficiaries.defaultProps = {
  handleSearchPerson: () => {},
  legalPersonIsBeneficiarie: {},
  fields: [],
  append: () => {},
  remove: () => {},
};

export default FormBeneficiaries;
