export const getSessionData = () =>
  JSON.parse(sessionStorage.getItem('info')) || {};

export const getSessionHeaders = () => {
  const sessionData = getSessionData();
  const tokenAuthorization = sessionData.token;
  const headers = {
    authorizedip: sessionData.ip,
    authorization: `Bearer ${tokenAuthorization}`,
  };
  return headers;
};
