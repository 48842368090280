/* eslint-disable camelcase */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import fileDownload from 'js-file-download';
import pdfService from 'utils/helpers/pdfService';
import dashboardService from '../../../../services/dashboard';

import bellIcon from '../../../../assets/icons/bell.svg';
import policyIcon from '../../../../assets/icons/policy.svg';
import forwardIcon from '../../../../assets/icons/forward.svg';

const NotificationPolicyEmit = ({
  isOpen,
  setIsOpen,
  notificationData,
  setLoading,
}) => {
  const [hasNotifications, setHasNotifications] = useState(0);
  const pdfData = useSelector((state) => state.pdfQuote);

  const handleOptionPDFS = (item) => {
    setIsOpen(false);
    const optionsTypesPDF = [
      'POLICY_PYMES',
      'ENDORSEMENT_RISK',
      'ENDORSEMENT_ELECTRONIC',
    ];
    const { emission_id, compDate, compBrokerId, policyNumber } = item;
    const body = {
      emission_id,
      compDate,
      compBrokerId,
      pdfData,
      optionsTypesPDF,
      quoteNumber: policyNumber,
    };
    const { token, ip } = JSON.parse(sessionStorage.getItem('info'));
    const headers = {
      token,
      ip,
      authorization: `Bearer ${token}`,
      authorizedip: ip,
    };
    setLoading(true);
    pdfService
      .callServiceEmit('/emit/createPdf', body, headers)
      .then((response) => {
        const { file, file_name } = response.data;
        fileDownload(Buffer.from(file, 'base64'), file_name);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const List = styled.div`
    position: absolute;
    top: 40px;
    right: -10px;
    z-index: 1;
    width: 300px;
    border-radius: 3px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    @media (min-width: 768px) {
      width: 350px;
    }

    .item-list {
      padding: 0.5rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f5f5f5;
    }
  `;

  const Menu = styled.div`
    position: absolute;
    top: 32px;
    right: 8px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid white;
  `;

  const Circle = styled.div`
    position: absolute;
    top: -3px;
    right: -3px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: #f00;
    color: #fff;
    font-size: 10px;
    line-height: 18px;
    font-weight: bold;
  `;

  useEffect(() => {
    const notificationNumber = notificationData.reduce((acc, item) => {
      return item.isSeen ? acc : acc + 1;
    }, 0);
    setHasNotifications(notificationNumber);
  }, []);

  useEffect(() => {
    if (isOpen && hasNotifications > 0) {
      setHasNotifications(0);
      const emission_ids = [];
      notificationData.forEach((item) => {
        if (!item.isSeen) {
          emission_ids.push(item.emission_id);
        }
      });
      dashboardService.updateSeenNotifications({
        emission_ids,
      });
    }
  }, [isOpen]);

  return (
    <>
      <button
        type="button"
        style={{ outline: 'none' }}
        className="bg-white w-8 h-8 rounded-full ml-3 mt-1"
        onClick={() => setIsOpen(!isOpen)}
      >
        {hasNotifications === 0 ? (
          <img src={bellIcon} alt="icon bell" />
        ) : (
          <>
            <Circle>{hasNotifications}</Circle>
            <img src={bellIcon} alt="icon bell" />
          </>
        )}
      </button>
      {isOpen && (
        <>
          <Menu />
          <List className="shadow-lg bg-white py-4 text-sm font-normal">
            <h6 className="pb-2 medium-teal font-medium">Notificaciones</h6>
            {notificationData.map((item) => (
              <div className="item-list">
                <div className="flex items-start">
                  <img src={policyIcon} alt="policy icon" />
                  <div className="pl-2 leading-4">
                    <div className="text-base dark-blue text-black">
                      Póliza N° {item.policyNumber}
                    </div>
                    <div className="text-xs text-gray-300">{item.fullname}</div>
                  </div>
                </div>
                <div
                  className="medium-teal font-medium cursor-pointer"
                  role="presentation"
                  onClick={() => handleOptionPDFS(item)}
                >
                  Descargar
                  <img
                    src={forwardIcon}
                    className="w-2 inline-block sm:ml-3"
                    alt="forwardIcon"
                  />
                </div>
              </div>
            ))}
          </List>
        </>
      )}
    </>
  );
};

export default NotificationPolicyEmit;

NotificationPolicyEmit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  notificationData: PropTypes.array.isRequired,
  setLoading: PropTypes.func.isRequired,
};
