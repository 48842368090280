import React, { useState } from 'react';
import styled from 'styled-components';
import iconExpandMore from '../assets/icons/expand-more.svg';
import iconExpandLess from '../assets/icons/expand-less.svg';
import user from '../assets/icons/user.svg';

const UserArticle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  .user {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    cursor: pointer;
    width: 302px;
    background-color: ${(props) => (props.expand ? '#c8eff3' : '#ffffff')};
    :hover {
      background: #c8eff3;
    }
    .userCircle {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100px;
      box-sizing: border-box;
      height: 47px;
      width: 47px;
      border: 1px solid #d8d8d8;
      background-color: #ffffff;
      margin-right: 10px;
    }

    .dataUser {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      width: 80%;
      margin-left: 5%;
      margin-right: 5%;
      margin-top: 8px;

      .pName {
        font-size: 16px;
        color: #343741;
        font-family: Roboto;
        letter-spacing: 0;
        line-height: 19px;
        width: 100%;
      }
      .pIp {
        font-size: 12px;
        color: #808080;
        font-family: Roboto;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        width: 96px;
      }
    }
  }

  .userInfo {
    top: 20px;
    width: 100%;
    height: ${(props) => (props.expand ? '0px' : '100px')};
    overflow: hidden;
    display: ${(props) => (props.expand ? 'flex' : 'none')};
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    border: 1px solid #f5f5f5;
    animation: transition 0.2s forwards;

    @keyframes transition {
      0% {
        height: ${(props) => (props.expand ? '0px' : '100px')};
      }

      100% {
        height: ${(props) => (props.expand ? '100px' : '0px')};
      }
    }

    .articleInfo {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      width: 100%;

      div {
        margin-bottom: 10px;
        margin-left: 10px;
        .pName {
          height: 14px;
          width: 200%;
          color: #808080;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 14px;
        }
        .pValue {
          height: 16px;
          width: 79px;
          color: #343741;
          font-family: Roboto;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 16px;
        }
      }
    }
  }
`;
const UserData = () => {
  const [expand, setExpand] = useState(false);
  const sessionInfo = sessionStorage.getItem('info');
  let sessionInfoObj;
  if (sessionInfo) {
    sessionInfoObj = JSON.parse(sessionInfo);
  }
  return (
    <UserArticle expand={expand}>
      <section
        key={expand}
        onMouseOver={(e) => {
          e.preventDefault();
          setExpand(!expand);
        }}
        onFocus={(e) => {
          e.preventDefault();
          setExpand(!expand);
        }}
        className="user text-left p-3 text-sm font-regular hover:bg-lightTeal hover:font-medium focus:outline-none "
      >
        <div className="userCircle">
          <img src={user} alt="user" />
        </div>
        <div className="dataUser">
          <section>
            <p className="pName">
              {sessionInfoObj && sessionInfoObj.nombreUsuario}
            </p>
            <p className="pIp">
              IP
              {sessionInfoObj && sessionInfoObj.ip}
            </p>
          </section>

          <img
            src={expand ? iconExpandLess : iconExpandMore}
            alt="iconExpand"
          />
        </div>
      </section>
      <section className="userInfo text-left p-3  text-sm font-regular hover:bg-lightTeal hover:font-medium focus:outline-none ">
        <section className="articleInfo">
          <div>
            <p className="pName">Hora última conexión</p>
            <p className="pValue">02:00 pm</p>
          </div>
          <div>
            <p className="pName">Fecha última conexión</p>
            <p className="pValue">25/Nov/2020</p>
          </div>
        </section>
        <section className="articleInfo">
          <div>
            <p className="pName">Fecha actual</p>
            <p className="pValue">05/Dic/2020</p>
          </div>
        </section>
      </section>
    </UserArticle>
  );
};

export default UserData;
