/**
 * @typedef {{
 *  codigo: string;
 *  selected: 0 | 1;
 * }} PropiedadesAmparo
 */

/**
 * @typedef {{
 *  amparos: {
 *   codigoAmparo: string,
 *   selected: 0 | 1;
 *  }[];
 *  propiedadesAmparo: PropiedadesAmparo[]
 * }} Riesgo
 */

/**
 *
 * @param {PropiedadesAmparo[]} propiedadesAmparo
 * @param {*} mapAmparosHijos
 * @returns
 */
const mapPropiedadesAmparo = (propiedadesAmparo, mapAmparosHijos) =>
  propiedadesAmparo.map((propiedadAmparo) => {
    const propiedadAmparoBody = mapAmparosHijos.get(propiedadAmparo.codigo);
    const defaultValue = 1;
    if (propiedadAmparoBody) {
      const { selected = defaultValue } = propiedadAmparoBody;
      return {
        ...propiedadAmparo,
        selected,
      };
    }
    return { ...propiedadAmparo, selected: defaultValue };
  });

/**
 *
 * @param {Riesgo} amparo
 * @param {Riesgo | undefined} amparoBody
 */
const mapAmparo = (amparo, amparoBody) => {
  if (amparoBody) {
    const { propiedadesAmparo } = amparo;
    const mapAmparosHijos = new Map(
      amparoBody.propiedadesAmparo.map((propiedadAmparo) => [
        propiedadAmparo.codigo,
        propiedadAmparo,
      ])
    );
    return {
      ...amparo,
      selected: +amparoBody.selected,
      propiedadesAmparo: mapPropiedadesAmparo(
        propiedadesAmparo,
        mapAmparosHijos
      ),
    };
  }
  return { ...amparo };
};

/**
 * @param {{ amparos: { codigoAmparo: string, selected: 0 | 1, propiedadesAmparo: PropiedadesAmparo[] }[]}[]} bodyRiesgos
 * @param {Riesgo[]} riesgos
 */
export function applyAmparoSelections(bodyRiesgos, riesgos) {
  return riesgos.map((riesgo, i) => {
    const bodyRiesgo = bodyRiesgos[i];
    const bodyAmparos = new Map(
      bodyRiesgo.amparos.map((amparo) => [amparo.codigoAmparo, amparo])
    );
    return {
      ...riesgo,
      amparos: riesgo.amparos.map((amparo) => {
        const amparoBody = bodyAmparos.get(amparo.codigoAmparo);
        return mapAmparo(amparo, amparoBody);
      }),
    };
  });
}
