import { HEADERS_JSON } from 'utils/constants/DefaultBodies';

const urlOpenlConfig = process.env.REACT_APP_URL_OPENL_CONFIG;

const initialState = {
  loading: false,
  data: [],
  error: null,
};

export const fetchOpenlPricingStart = () => ({
  type: 'FETCH_OPENL_PRICING_START',
  error: false,
});

export const fetchOpenlPricingSuccess = (payload) => ({
  type: 'FETCH_OPENL_PRICING_SUCCESS',
  payload,
});

export const fetchOpenlPricingError = (payload) => ({
  type: 'FETCH_OPENL_PRICING_ERROR',
  payload,
  error: true,
});

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_OPENL_PRICING_START': {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case 'FETCH_OPENL_PRICING_SUCCESS': {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    case 'FETCH_OPENL_PRICING_ERROR': {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

const arrayEnSessionResponse = [];
const arrayEnSessionRequest = [];
function pushResponseToSessionStorage(objeto) {
  let quoteInfo = {};
  let time = '';
  let year = '';
  let month = '';
  let day = '';
  let date = '';
  if (sessionStorage.getItem('quoteInfo')) {
    quoteInfo = JSON.parse(sessionStorage.getItem('quoteInfo'));
    time = new Date(quoteInfo.Date);
    year = time.getFullYear();
    month = time.getMonth() + 1;
    day = time.getDate();
    date = `${year}-${month}-${day}`;
  }
  arrayEnSessionResponse.push(objeto);
  const ultimoDatoResponse =
    arrayEnSessionResponse[arrayEnSessionResponse.length - 1];
  sessionStorage.setItem('pricingResponse', JSON.stringify(ultimoDatoResponse));
  sessionStorage.setItem(
    'openlLogsRequestResponse',
    JSON.stringify({
      log_id: quoteInfo.quoteNumber,
      compBrokerId: quoteInfo.compBrokerId,
      compDate: quoteInfo.compDate,
      date,
      fullDate: quoteInfo.Date,
      response: JSON.parse(sessionStorage.getItem('pricingResponse')),
      request: JSON.parse(sessionStorage.getItem('pricingRequest')),
      drafts: quoteInfo.drafts,
    })
  );
}

function pushResquestToSessionStorage(objeto) {
  arrayEnSessionRequest.push(objeto);
  const ultimoDatoRequest =
    arrayEnSessionRequest[arrayEnSessionRequest.length - 1];
  sessionStorage.setItem('pricingRequest', JSON.stringify(ultimoDatoRequest));
}

export const fetchOpenlPricing = (body, customHeaders) => async (
  dispatch,
  getState,
  { axios }
) => {
  pushResquestToSessionStorage(body);
  const headers = { ...HEADERS_JSON.headers, ...customHeaders };
  dispatch(fetchOpenlPricingStart());
  try {
    const { data } = await axios.post(
      `${urlOpenlConfig}/request`,
      {
        method: 'post',
        path: '/pyme-pricing/newBusiness',
        parameters: body,
      },
      { headers }
    );
    dispatch(fetchOpenlPricingSuccess(data));
    pushResponseToSessionStorage(data);
  } catch (e) {
    dispatch(fetchOpenlPricingError(e));
  }
};