import React from 'react';
import PropTypes from 'prop-types';
import 'assets/styles/scss/components/Switch.scss';

const Switch = ({ label, onChange, defaultChecked }) => {
  return (
    <div className="switch">
      <input
        id="custom-checkbox"
        data-testid="checkbox-show-activities"
        type="checkbox"
        onChange={onChange}
        defaultChecked={defaultChecked}
      />
      <label
        htmlFor="custom-checkbox"
        className="toggle"
        data-testid="label-show-activities"
      >
        <span>
          <svg width="10px" height="10px" viewBox="0 0 10 10" />
        </span>
        <p>{label}</p>
      </label>
    </div>
  );
};

export default Switch;

Switch.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultChecked: PropTypes.bool.isRequired,
};
