import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isObjectEmpty } from 'utils/helpers/object';

const TooltipContainer = styled.div`
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 100%;

  /* Tooltip text */
  .tooltip-content {
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
    background-color: var(--liberty-blue);
    color: #fff;
    padding: 12px;
    border-radius: 5px;
    max-width: ${(props) => !props.widthContent && '250px'};
    z-index: 1;
    width: max-content;
    position: absolute;
    left: ${(props) => (props.left ? props.left : '50%')};
    right: ${(props) => (props.right ? props.right : 'auto')};
    border-bottom-right-radius: ${(props) => (props.right ? '0px' : '5px')};
    margin-left: -${(props) => props.width && props.width / 2}px;
    /* top tooltip*/
    bottom: ${(props) => props.position === 'top' && '100%'};
    margin-bottom: ${(props) => props.position === 'top' && '13px'};
    /* bottom tooltip */
    top: ${(props) => props.position === 'bottom' && '100%'};
    margin-top: ${(props) => props.position === 'bottom' && '13px'};
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  &:hover {
    .tooltip-content {
      visibility: visible;
    }
  }
`;

/**
 * Triangle style component
 */
const TriangleStyle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  left: 0;
  right: ${(props) => props.right ? '-92%' : '0px'};
  margin-left: auto;
  margin-right: auto;
  /* bottom triangle */
  bottom: ${(props) => props.position === 'top' && '-9px'};
  border-top: ${(props) => props.position === 'top' && '9px solid'}
    var(--liberty-blue);
  /* top triangle */
  top: ${(props) => props.position === 'bottom' && '-9px'};
  border-bottom: ${(props) => props.position === 'bottom' && '9px solid'}
    var(--liberty-blue);
`;

export default function Tooltip({
  content,
  children,
  position,
  left,
  right,
  defaultVisible,
  widthContent,
}) {
  const refTooltip = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(refTooltip.current ? refTooltip.current.offsetWidth : 0);
  }, []);

  return (
    <TooltipContainer
      width={width}
      position={position}
      left={left}
      right={right}
      visible={defaultVisible}
      widthContent={widthContent}
    >
      {children}
      {!isObjectEmpty(content) && (
        <span className="tooltip-content font-light" ref={refTooltip}>
          {content}
          <TriangleStyle position={position} right={right} />
        </span>
      )}
    </TooltipContainer>
  );
}

Tooltip.propTypes = {
  children: PropTypes.object.isRequired,
  content: PropTypes.object,
  position: PropTypes.string,
  left: PropTypes.string,
  defaultVisible: PropTypes.bool,
  widthContent: PropTypes.bool,
};

Tooltip.defaultProps = {
  content: {},
  position: 'top',
  left: '50%',
  defaultVisible: false,
  widthContent: false,
};
