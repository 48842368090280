import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ValuesEnsureCard from 'components/ValueEnsureCard';
import useKeyPress from 'hooks/useKeyPress';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { fetchOpenlCapital } from 'reducers/openlCapital';
import { updateDraft, updateRisk } from 'reducers/quoteInfo';
import { isArrayEmpty, isObjectEmpty } from 'utils/helpers/object';
import {
  getTemplateRequest,
  getRequestCapitalCalculation,
} from 'utils/helpers/businessCapital';
import styled from 'styled-components';
// assets
import caution from 'assets/icons/caution.svg';
import useWindowDimension from 'hooks/useWindowDimension';

const Container = styled.div`
  height: ${(props) => `${props.heightNode}px`};
  @media (max-width: 768px) {
    height: initial;
  }
`;
export default function ValuesEnsure({
  currentRisk,
  draftIndex,
  riskIndex,
  errorMessage,
  idComponent,
  onValueChange,
}) {
  const { width } = useWindowDimension();
  const dispatch = useDispatch();
  const refDiv = useRef(null);
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));
  const [height, setHeight] = useState(0);
  const [templateCapital, setTemplateCapital] = useState({});
  const [templateCapital2, setTemplateCapital2] = useState({});
  const valuesEnsure = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[3][0][1]
      : []
  );
  const basicTemplateCapital = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[9][0][1]
      : []
  );

  const basicData = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[6][0][1][0]
      : []
  );

  const drafts = useSelector((state) =>
    state.openlConfig.data.results ? state.quoteInfo.data.drafts : []
  );

  const openlCapital = useSelector((state) =>
    state.openlCapital && state.openlCapital.data[draftIndex]
      ? state.openlCapital.data[draftIndex]
      : []
  );

  const selectedBusiness = useSelector(
    (state) => state.quoteInfo.data.business
  );

  const openlCoveragesSpecs = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[4][0][1]
      : []
  );

  const capitalCalculation = () => {
    onValueChange();
    const request = getRequestCapitalCalculation(
      {
        capitalMaximo: basicData.cotizacionNegocio,
        businessCode: selectedBusiness.answerCode,
        draft: drafts[draftIndex],
        templateCapital,
        templateCapital2,
        idComponent,
      },
      openlCoveragesSpecs
    );

    const customHeaders = {
      authorization: `Bearer ${sessionInfoObj.token}`,
      authorizedip: sessionInfoObj.ip,
    };
    dispatch(fetchOpenlCapital(request, customHeaders, draftIndex));
  };

  useEffect(() => {
    if (!isObjectEmpty(openlCapital) && openlCapital.results) {
      dispatch(
        updateRisk({
          draftIndex,
          riskIndex,
          risk: {
            ...openlCapital.results[0][1].riesgos[riskIndex],
          },
        })
      );
      const {
        capitalMaximo,
        cantidadRiesgos,
        valorPorPoliza,
        valPoliza,
        porcentajePoliza,
        // riesgosPoliza,
      } = openlCapital.results[0][1];
      const riesgosPoliza = drafts[draftIndex].policyRisks.amparos
        ? drafts[draftIndex].policyRisks
        : openlCapital.results[0][1].riesgosPoliza;
      dispatch(
        updateDraft({
          draftIndex,
          draft: {
            capitalMaximo,
            cantidadRiesgos,
            valorPorPoliza,
            valPoliza,
            porcentajePoliza,
            policyRisks: riesgosPoliza,
          },
        })
      );
    }
  }, [openlCapital, dispatch, draftIndex, riskIndex]);

  useEffect(() => {
    if (!isArrayEmpty(basicTemplateCapital)) {
      setTemplateCapital(getTemplateRequest(basicTemplateCapital, 'Riesgos'));
      setTemplateCapital2(
        drafts[draftIndex].policyRisks.amparos
          ? drafts[draftIndex].policyRisks
          : getTemplateRequest(basicTemplateCapital, 'RiesgoPoliza')
      );
    }
  }, [basicTemplateCapital]);

  useEffect(() => {
    setHeight(refDiv.current ? refDiv.current.offsetHeight : 0);
  }, []);

  return (
    <Container heightNode={height}>
      <div
        ref={refDiv}
        className={clsx(
          width > 768 && 'absolute',
          'left-0 bg-lightTeal w-full p-8 flex flex-col items-center mb-8'
        )}
      >
        <div className="mb-6 flex justify-center items-center flex-col">
          <p className="text-lg font-semibold text-blue">Valores a asegurar</p>
          {errorMessage && (
            <div className="flex justify-center">
              <img className="w-4 mr-1" src={caution} alt="icon caution" />
              <p className="text-red-600 text-xs md:text-base">
                Por favor selecciona un ítem e ingresa el valor asegurado
              </p>
            </div>
          )}
        </div>
        {!isArrayEmpty(valuesEnsure) && (
          <div
            data-testid="container-values-ensure"
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 w-inherit max-w-screen-xl"
          >
            {valuesEnsure.map((item) => {
              if (item.visible === '1') {
                return (
                  <ValuesEnsureCard
                    idComponent={idComponent}
                    valueEnsure={item}
                    key={item.id}
                    currentRisk={currentRisk}
                    capitalCalculation={capitalCalculation}
                    draftIndex={draftIndex}
                    riskIndex={riskIndex}
                    type={errorMessage ? 'error' : 'info'}
                  />
                );
              }
              return null;
            })}
          </div>
        )}
      </div>
    </Container>
  );
}

ValuesEnsure.propTypes = {
  draftIndex: PropTypes.number.isRequired,
  riskIndex: PropTypes.number.isRequired,
  errorMessage: PropTypes.string,
  idComponent: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  currentRisk: PropTypes.object,
};

ValuesEnsure.defaultProps = {
  errorMessage: null,
  currentRisk: {},
};
