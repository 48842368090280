import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DEFAULT_CATEGORY_CODE } from 'utils/constants/Openl';
import { ACTIVITIES_MODAL_HELP } from 'utils/constants/StorageKeys';
import DropdownFinder from 'components/DropdownFinder';
import Pill from 'components/Pill';
import Subcategories from 'components/Subcategories';
import Navigation from 'layouts/modals/Navigation';
import { createUpdateBusiness } from 'reducers/quoteInfo';
import { updateTabQuote } from 'reducers/activeQuoteTab';
import { updateBusiness } from 'reducers/pdfQuote';
import { getData } from 'utils/helpers/localStorage';

const Business = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);
  const [categorySelected, setCategorySelected] = useState(
    DEFAULT_CATEGORY_CODE
  );
  const [subcategoriesArray, setSubcategoriesArray] = useState();
  const [map, setMap] = useState(true);
  const allowedActivities = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[5][0][1].filter(
          (activity) => activity.restringido === '0'
        )
      : []
  );

  const categories = useSelector((state) =>
    state.openlConfig.data.results
      ? state.openlConfig.data.results[0][0][1]
      : []
  );

  const { quoteInfo } = useSelector((state) => state);

  useMemo(() => {
    if (map) {
      if (quoteInfo.data.business.answerCode) {
        for (let i = 0; i < categories.length; i += 1) {
          for (let j = 0; j < categories[i].results[1][0][1].length; j += 1) {
            if (
              categories[i].results[1][0][1][j].respuestaIaxis ===
              quoteInfo.data.business.answerCode
            ) {
              setCategorySelected(
                categories[i].results[1][0][1][j].codigoCategoriaDet
              );
              setSubcategoriesArray(categories[i].results[1][0][1]);
            }
          }
        }
      }
    }
  }, [categories, quoteInfo]);

  useEffect(() => {
    if (categories.length !== 0)
      setSubcategoriesArray(categories[0].results[1][0][1]);
  }, [categories]);

  const resetActivity = () => {
    dispatch(
      createUpdateBusiness({ business: { answerCode: null, name: null } })
    );
  };

  const changeSubcategories = (category) => {
    resetActivity();
    setCategorySelected(category[0][0].codigoCategoria);
    setSubcategoriesArray(category[1][0][1]);
  };

  const sendActivity = (answerCode, name, imageName, respuestaIaxis2) => {
    setMap(false);
    dispatch(
      createUpdateBusiness({
        business: { answerCode, name, imageName, respuestaIaxis2 },
      })
    );
    dispatch(updateBusiness({ code: answerCode, name }));
  };

  return (
    <React.Fragment key="business">
      {getData(ACTIVITIES_MODAL_HELP) === 'true' ? null : (
        <Navigation showModal={showModal} setShowModal={setShowModal} />
      )}

      <div className="flex flex-col">
        <div className="flex flex-col items-center pb-2 sm:pb-4 sm:shadow-customb px-4">
          <p className="text-2xl sm:text-4xl text-center font-medium mt-8 sm:mt-10 mb-2 text-blue">
            Busca y selecciona el tipo de negocio
          </p>
          <DropdownFinder
            placeholder="Busca el negocio que deseas asegurar"
            listToFilter={allowedActivities}
            activitySelected={(
              answerCode,
              name,
              imageName,
              respuestaIaxis2
            ) => {
              dispatch(
                createUpdateBusiness({
                  business: { answerCode, name, imageName, respuestaIaxis2 },
                })
              );
              dispatch(updateBusiness({ code: answerCode, name }));
              dispatch(updateTabQuote({ index: 1 }));
            }}
          />
        </div>
        <div className="flex flex-col items-center">
          <p className="text-base md:w-auto md:h-auto visible sm:text-2xl text-center font-bold mt-1 sm:mt-10 w-full leading-tight text-blue">
            ¿No encuentras lo que estás buscando?
          </p>
          <p className="text-base md:w-auto md:h-auto sm:text-2xl visible text-center font-bold w-full leading-tight mb-2 text-blue">
            Revisa nuestras categorías
          </p>
        </div>
        <div className="flex w-full md:m-0 p-4 overflow-x-scroll overflow-y-hidden sm:overflow-x-hidden sm:items-center">
          <ul className="flex m-auto sm:overflow-hidden sm:w-c-container sm:flex-wrap sm:justify-center">
            {categories &&
              categories.map((category) => {
                return (
                  <Pill
                    key={category.results[0][0].codigoCategoria}
                    activated={
                      category.results[0][0].codigoCategoria ===
                      categorySelected
                    }
                    className="mr-1 sm:visible"
                    onClick={() => {
                      changeSubcategories(category.results);
                    }}
                  >
                    {category.results[0][0].descripcion}
                  </Pill>
                );
              })}
          </ul>
        </div>
        {subcategoriesArray && (
          <Subcategories
            subvalues={subcategoriesArray}
            activitySelected={sendActivity}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Business;
