import React, { useEffect, useState } from 'react';
import getQuotes from 'services/getQuotes';
import PropTypes from 'prop-types';

export const QuotesContext = React.createContext();
export const QuotesConsumer = QuotesContext.Consumer;

/**
 * Quotes
 * @class
 */
const QuotesProvider = (props) => {
  const { agente, token, ip } = JSON.parse(sessionStorage.getItem('info'));
  const customHeaders = {
    authorization: `Bearer ${token}`,
    authorizedip: ip,
  };
  const [quotes, setQuotes] = useState({});

  useEffect(() => {
    async function getListDashboard() {
      const { data } = await getQuotes(
        'listDashboard',
        {
          broker: agente,
          Status: 'Active',
        },
        customHeaders
      );
      setQuotes(data.Items);
    }
    getListDashboard();
  }, []);

  const { children } = props;
  return (
    <QuotesContext.Provider
      value={{
        quotes,
      }}
    >
      {children}
    </QuotesContext.Provider>
  );
};

export default QuotesProvider;

QuotesProvider.propTypes = {
  /**
   * Child component
   * @param {Component} children
   */
  children: PropTypes.node,
};

QuotesProvider.defaultProps = {
  /**
   * Child component
   * @param {Component} children
   */
  children: null,
};
