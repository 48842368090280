import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import 'assets/styles/scss/components/DigitalClock.scss';

const ClockDigitContainer = styled.div`
  max-height: 53px;
  border: solid 3px var(--gray-200);
  border-radius: 5px;
  padding: 2px 10px 52px 10px;
  overflow: hidden;
`;
const ClockDigit = styled.div`
  position: relative;
  color: var(--liberty-blue);
  opacity: 0.6;
  font-size: 35.5px;
  font-family: Roboto;
  font-weight: 500;
`;

const padZero = (num) => {
  if (num < 10) {
    num = `${0}${num}`;
  }
  return num;
};

const DigitalClock = ({ time }) => {
  const nextDigit = time.clone();
  nextDigit.subtract(1, 'minutes');

  return (
    <div className="flex flex-row items-center justify-center my-3">
      <ClockDigitContainer>
        <ClockDigit>{padZero(time.minutes())}</ClockDigit>
      </ClockDigitContainer>
      <ClockDigit className="px-1 --gray-600">:</ClockDigit>
      <ClockDigitContainer>
        <ClockDigit className="digitDownAnim">
          {padZero(time.seconds())}
        </ClockDigit>
        <ClockDigit className="digitDownAnim">
          {padZero(nextDigit.seconds())}
        </ClockDigit>
      </ClockDigitContainer>
    </div>
  );
};

export default DigitalClock;

DigitalClock.propTypes = {
  time: PropTypes.object.isRequired,
};
