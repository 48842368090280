import React, { useEffect } from 'react';
import { Route, MemoryRouter as Router, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { brokerUpdate } from 'reducers/quoteInfo';
import Header from 'layouts/Header';
import Quote from 'pages/Quote';
import QuotesProvider from 'context/QuotesContext';
import Dashboard from 'pages/Dashboard';
import Emit from 'pages/emit_pages/Emit';
import { fetchOpenlConfig } from 'reducers/openlConfig';
import { updateBodyPdf } from 'reducers/pdfQuote';

import Banner from 'components/Banner';

const Content = () => {
  const dispatch = useDispatch();
  const draftIndex = useSelector((state) => state.openlCapital.focusedDraft);
  const { valPoliza } = useSelector((state) =>
    state.openlCapital.data[draftIndex]
      ? state.openlCapital.data[draftIndex].results[0][1]
      : false
  );
  const bodyPdf = useSelector((state) => state.pdfQuote.requestBody);
  const sessionInfoObj = JSON.parse(sessionStorage.getItem('info'));

  useEffect(() => {
    const info = JSON.parse(sessionStorage.getItem('info'));
    if (info) {
      dispatch(
        updateBodyPdf({
          ...bodyPdf,
          type: 'quote',
          company: {
            code: 1,
            name: 'LIBERTY SEGUROS S.A.',
          },
          broker: {
            code: info.agente,
            name: info.nombreUsuario,
          },
        })
      );
    }
  }, []);

  useEffect(() => {
    const info = JSON.parse(sessionStorage.getItem('info'));
    if (info) {
      dispatch(
        brokerUpdate({
          broker: info.agente,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (sessionInfoObj) {
      const customHeaders = {
        authorization: `Bearer ${sessionInfoObj.token}`,
        authorizedip: sessionInfoObj.ip,
      };
      dispatch(fetchOpenlConfig(customHeaders));
    }
  }, []);

  return (
    <QuotesProvider>
      {valPoliza === 'true' && <Banner />}
      <Router>
        <Header />
        <div>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/quote" component={Quote} />
            <Route exact path="/emit" component={Emit} />
          </Switch>
        </div>
      </Router>
    </QuotesProvider>
  );
};

export default Content;
