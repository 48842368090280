import React from 'react';
import styled from 'styled-components';
import warning from 'assets/icons/warning.svg';
import PropTypes from 'prop-types';

const FormAlertContainer = styled.div`
  position: absolute;
  display: inline-block;

  background-color: ${(props) =>
    props.isError ? 'var(--error-background)' : 'var(--light-teal)'};
  border-radius: 5px;
  border: ${(props) =>
    props.isError ? '1px solid var(--error)' : '1px solid var(--dark-teal)'};
  z-index: 1;
`;

const IconContainer = styled.div`
  background-color: ${(props) =>
    props.isError ? 'var(--error)' : 'var(--dark-teal)'};
  width: 50px;
  height: auto;
`;

const TriangleStyle = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  z-index: 3;
  top: -9px;
  left: 200px;
  border-bottom: ${(props) =>
    props.isError
      ? '10px solid var(--error-background)'
      : '10px solid var(--light-teal)'};
`;

const TriangleStyleBorder = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: -10px;
  left: 200px;
  border-bottom: ${(props) =>
    props.isError ? '10px solid var(--error)' : '10px solid var(--dark-teal)'};
`;

const FormAlert = ({ content, isError }) => {
  return (
    <FormAlertContainer isError={isError} className="w-11/12 md:w-6/12">
      <TriangleStyle isError={isError} />
      <TriangleStyleBorder isError={isError} />
      <div className="flex">
        <IconContainer isError={isError} className="flex justify-center">
          <img src={warning} alt="regresar" className="ml-1 mr-1" />
        </IconContainer>
        <p className="m-3">{content}</p>
      </div>
    </FormAlertContainer>
  );
};

export default FormAlert;

FormAlert.propTypes = {
  content: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
};
